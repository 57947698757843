import { ArgumentMissingException } from 'neka-common';
import { Action, AnyAction, Dispatch, Reducer } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

/**
 * Represents the object which is associated with the redux `dispatch` method.
 * React-Redux inject the `dispatch` to `props` when the `mapDispatchToProps` is omitted in the `connect` call.
 */
export interface IDispatch {
    dispatch: Dispatch<Action<any>>;
}

export interface IThunkDispatch extends IDispatch {
    dispatch: ThunkDispatch<{}, {}, Action<any>>;
}

/** Represents a flux-standard-action. */
export interface IRdxAction<P, M> extends Action<string> {
    payload: P;
    error: boolean;
    meta: M;
}

/** 代表一个 action creator, 用于创建相应的 {@link IRdxAction}. */
export interface IActionCreator<Args extends any[], P, M> {
    /** 创建一个 action. */
    (...args: Args): IRdxAction<P, M>;
    /** action type. */
    type: string;
}

/** 从 {@link IActionCreator} 中获取其 {@link IRdxAction} 类型. */
export type ActionType<AC> = AC extends IActionCreator<infer Args, infer P, infer M> ? IRdxAction<P, M> : never;
/** 从 {@link IActionCreator} 中获取其 {@link IRdxAction.payload} 类型. */
export type PayloadType<AC> = AC extends IActionCreator<infer Args, infer P, infer M> ? P : never;
/** 从 {@link IActionCreator} 中获取其 {@link IRdxAction.meta} 类型. */
export type MetaType<AC> = AC extends IActionCreator<infer Args, infer P, infer M> ? M : undefined;

export type RetFn = (...args: any[]) => any;
export type OptionalReturnType<T> = T extends RetFn ? ReturnType<T> : never;

/** 创建一个 action creator. */
export function createActionCreator<PayloadFn extends RetFn, MetaFn extends RetFn>(
    type: string,
    payloadFn: PayloadFn,
    metaFn?: MetaFn
): IActionCreator<Parameters<typeof payloadFn>, ReturnType<typeof payloadFn>, OptionalReturnType<typeof metaFn>> {
    function createActionImpl(
        ...args: Parameters<typeof payloadFn>
    ): IRdxAction<ReturnType<typeof payloadFn>, OptionalReturnType<typeof metaFn>> {
        const payload: ReturnType<typeof payloadFn> = payloadFn(...args);
        const meta: OptionalReturnType<typeof metaFn> = metaFn !== undefined ? metaFn(...args) : undefined;
        const action: IRdxAction<ReturnType<typeof payloadFn>, OptionalReturnType<typeof metaFn>> = {
            type: type,
            error: payload !== undefined && (payload as any) instanceof Error,
            payload,
            meta,
        };
        return action;
    }

    createActionImpl.type = type;

    return createActionImpl;
}

export type RdxHandler<AC extends IActionCreator<any, any, any>, S> = (state: S, payload: PayloadType<AC>) => S;
export type RdxReducerWrapper<S> = (initialState?: S) => Reducer<S>;

export type RdxRunnableSuccessPayload<P, V> = {
    originalPayload: P;
    value: V;
};

export type RdxRunnableFailurePayload<P> = {
    originalPayload: P;
    reason: any;
};

export interface IRdxRunnableHandlers<S, P, V> {
    onStarted?: (state: S, payload: P) => S;
    onSucceed: (state: S, successPayload: RdxRunnableSuccessPayload<P, V>) => S;
    onFailed?: (state: S, failurePayload: Error & RdxRunnableFailurePayload<P>) => S;
}

/** 将单个的 reducer 组合成一个复合的 reducer, 并根据 action type 调用匹配的 reducer. */
export class RdxReducerComposer<S> {
    public constructor(protected readonly typePrefix?: string) {}

    private reducerMap: Map<string, Reducer<S>> = new Map();

    private setReducer<AC extends IActionCreator<any, any, any>>(
        creator: AC,
        reducer: Reducer<S>,
        replace: boolean = false
    ): Reducer<S> {
        const { type } = creator;
        if (this.reducerMap.has(type) && !replace) console.warn(`Reset the reducer for action type [${type}].`);
        this.reducerMap.set(type, reducer);
        return reducer;
    }

    private setHandler<AC extends IActionCreator<any, any, any>>(
        creator: AC,
        handler: RdxHandler<AC, S>,
        replace: boolean = false
    ): Reducer<S> {
        const reducer: Reducer<S> = (state, action) => {
            if (state === undefined) throw new Error(`The original state is undefined.`);
            return handler(state, action.payload);
        };

        return this.setReducer(creator, reducer, replace);
    }

    public registerHandler<PayloadFn extends RetFn, MetaFn extends RetFn>(
        type: string,
        payloadFn: PayloadFn,
        handler: (state: S, payload: ReturnType<typeof payloadFn>) => S,
        metaFn?: MetaFn
    ): IActionCreator<Parameters<typeof payloadFn>, ReturnType<typeof payloadFn>, OptionalReturnType<typeof metaFn>> {
        const withPrefix = this.typePrefix ? this.typePrefix + type : type;
        const creator = createActionCreator(withPrefix, payloadFn, metaFn);
        this.setHandler(creator, handler, false);
        return creator;
    }

    public registerRunnable<PayloadFn extends RetFn, V, MetaFn extends RetFn>(
        type: string,
        payloadFn: PayloadFn,
        runnable: (
            state: S,
            payload: ReturnType<typeof payloadFn>,
            dispatch: ThunkDispatch<S, any, Action<any>>
        ) => Promise<V>,
        handlers: IRdxRunnableHandlers<S, ReturnType<typeof payloadFn>, V>,
        metaFn?: MetaFn
    ): (...args: Parameters<typeof payloadFn>) => ThunkAction<Promise<V>, any, any, Action<any>> {
        const startedType = type + `_$_STARTED`;
        const succeedType = type + `_$_SUCCEED`;
        const failedType = type + `_$_FAILED`;

        const { onSucceed, onStarted, onFailed } = handlers;

        const onSucceedAction = this.registerHandler(
            succeedType,
            (value: V, originalPayload: ReturnType<typeof payloadFn>) => ({ originalPayload, value }),
            onSucceed,
            metaFn
        );
        const onStartedAction = onStarted ? this.registerHandler(startedType, payloadFn, onStarted, metaFn) : undefined;
        const onFailedAction = onFailed
            ? this.registerHandler(
                  failedType,
                  (reason: any, originalPayload: ReturnType<typeof payloadFn>) => {
                      const e = new Error(reason ? reason.toString() : 'Unknown Error');
                      const failurePayload = Object.assign(e, { originalPayload, reason });
                      return failurePayload;
                  },
                  onFailed,
                  metaFn
              )
            : undefined;

        const thunkAction = (...args: Parameters<typeof payloadFn>) => async (
            dispatch: ThunkDispatch<S, any, Action<any>>,
            getState: () => any
        ) => {
            if (onStartedAction) dispatch(onStartedAction(...args));
            const payload = payloadFn(...args);
            try {
                const rootState = getState();
                const state = this.getStateFromRoot(rootState);

                const value: V = await runnable(state, payload, dispatch);

                dispatch(onSucceedAction(value, payload));
                return value;
            } catch (reason) {
                if (onFailedAction) {
                    dispatch(onFailedAction(reason, payload));
                }
                throw reason;
            }
        };
        return thunkAction;
    }

    private getStateFromRootFn: (rootState: any) => S;

    // TODO: 使 composeReducer 可以被多次调用
    private getStateFromRoot(rootState: any): S {
        if (!this.getStateFromRootFn) throw new Error(`The getStateFromRootFn is undefined.`);
        return this.getStateFromRootFn(rootState);
    }

    /**
     * 生成 Reducer. 如果 action type 在当前的 {@link RdxReducerComposer} 中已经注册, 则使用相应的 handler 处理;
     * 如果未注册, 则由 `fallbackReducer` 处理.
     */
    public composeReducer<TRootState>(
        getStateFromRoot: (rootState: TRootState) => S,
        fallbackReducer?: Reducer<S>,
        initialState?: S
    ): Reducer<S> {
        if (getStateFromRoot === undefined) throw new ArgumentMissingException('getStateFromRoot');
        if (this.getStateFromRootFn !== undefined) throw new Error(`composeReducer can be called only once.`);

        this.getStateFromRootFn = getStateFromRoot;

        return (state, action) => {
            state = state !== undefined ? state : initialState;
            if (state === undefined) throw Error(`both state and initialState are undefined.`);

            const reducer = this.reducerMap.get(action.type);
            if (reducer !== undefined) {
                return reducer(state, action);
            }
            if (fallbackReducer) {
                return fallbackReducer(state, action);
            }
            return state;
        };
    }
}
