import { Tabs } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IDispatch } from '../../../common/redux-utils';
import { isDevelopment } from '../../../core/app-config';
import { IRootState } from '../../../store/app-redux';
import { IMindDiagram, ToolPaneKind, ToolPaneKinds } from '../../mind-models';
import { ChangeActiveToolPane } from '../../store/mind-redux';
import MindDiscussionPane from './discussions/mind-discussion-pane';
import MindBlankPane from './mind-blank-panel';
import { MindDeveloperToolPane } from './mind-developer.pane';
import MindMembersPanel from './mind-members-panel';
import MindShortcutPane from './mind-shortcut-pane';
import MindStylePane from './mind-style.pane';
import './mind-tool-window.less';

interface IConnectedProps extends Pick<IRootState, 'principal' | 'diagram' | 'discussions'> {}

interface IOwnProps {
    className?: string;
}

interface IProps extends IConnectedProps, IOwnProps, IDispatch {
    /** The {@link IMindDiagram}, typically passed from its wrapper {@link MindBoard}. */
}

/**
 * 编辑器右侧的面板窗体.
 * 面板窗体包含若干 Tab, 每个 tab 中有一个 toolPane
 */
class MindToolWindowView extends Component<IProps, {}> {
    public constructor(props: IProps) {
        super(props);
    }

    public render() {
        const { diagram, discussions, principal, dispatch, className } = this.props;
        if (!diagram) return null;

        const { allowEdit } = diagram;

        let { activeToolPaneKind } = diagram;
        if (!activeToolPaneKind) activeToolPaneKind = ToolPaneKinds.discussionPane;

        return (
            <div className={className ? 'mind-tool-window ' + className : 'mind-tool-window'}>
                <div className="mind-tool-content">
                    <Tabs type="card" activeKey={activeToolPaneKind} onChange={this.handleTabChange}>
                        {isDevelopment && (
                            <Tabs.TabPane key={ToolPaneKinds.developerPane} tab="调试">
                                <MindDeveloperToolPane diagram={diagram} principal={principal} dispatch={dispatch} />
                            </Tabs.TabPane>
                        )}
                        <Tabs.TabPane
                            key={ToolPaneKinds.discussionPane}
                            tab={<span title="帖子" className="discussion-tab-icon" />}
                        >
                            {activeToolPaneKind === ToolPaneKinds.discussionPane &&
                                (diagram.selection && diagram.selection.length > 0 ? (
                                    <MindDiscussionPane
                                        diagram={diagram}
                                        discussions={discussions}
                                        principal={principal}
                                        dispatch={dispatch}
                                    />
                                ) : (
                                    <MindBlankPane tabKey="2" />
                                ))}
                        </Tabs.TabPane>
                        {allowEdit && (
                            <Tabs.TabPane
                                key={ToolPaneKinds.stylesPane}
                                tab={<span title="样式" className="style-tab-icon" />}
                            >
                                {activeToolPaneKind === ToolPaneKinds.stylesPane &&
                                    (diagram.selection && diagram.selection.length > 0 ? (
                                        <MindStylePane diagram={diagram} dispatch={dispatch} />
                                    ) : (
                                        <MindBlankPane tabKey="3" />
                                    ))}
                            </Tabs.TabPane>
                        )}
                        <Tabs.TabPane
                            key={ToolPaneKinds.membersPane}
                            tab={<span title="成员列表/邀请成员" className="members-tab-icon" />}
                        >
                            {activeToolPaneKind === ToolPaneKinds.membersPane && (
                                <MindMembersPanel diagram={diagram} dispatch={dispatch} />
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key={ToolPaneKinds.helpPane}
                            tab={<span title="快捷键" className="shortcut-tab-icon" />}
                        >
                            {activeToolPaneKind === ToolPaneKinds.helpPane && <MindShortcutPane />}
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        );
    }

    private handleTabChange = (activeKey: string) => {
        this.props.dispatch(ChangeActiveToolPane(activeKey as ToolPaneKind));
    };
}

const MindToolWindow = connect<IConnectedProps, {}, IOwnProps, IRootState>((state: IRootState) => ({
    diagram: state.diagram,
    discussions: state.discussions,
    principal: state.principal,
}))(MindToolWindowView);
export default MindToolWindow;
