import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Modal } from 'antd';
import { IPrincipal } from 'neka-common';
import { doRedirectPath } from '../../account-util';
import { ExceptionType } from '../../constant';
import { getErrorInfoName, showErrorNotification } from '../../../common/ui-utils';
import { IDispatch } from '../../../common/redux-utils';
import { ServerInfo, localStoragePrincipal, RouteUrls, localStorageRedirectUrl } from '../../../core/app-config';
import { apis } from '../../../core/server-apis';
import { logIn } from '../../../store/app-redux';
// import { IRootState } from '../../../store/app-redux';
import { saveWechatInfo, setPwLoginCounter } from '../../store/account-redux';

const wechatStyle = {
    width: '108px',
    height: '17px',
    fontSize: '12px',
    fontWeight: 400,
    color: 'rgba(0,0,0,0.45)',
    lineHeight: '17px',
    textAlign: 'center' as 'center',
    margin: '40px auto 0',
};

const weChatConstInfo = Object.freeze({
    // appid 应用唯一标识
    clientId: 'wx7b292a36a0c7a26a',
    urls: {
        oauth: 'https://open.weixin.qq.com',
        authorization: '/connect/qrconnect',
        // loginRedirectUri: 'http://dui.our.tm/users/login/wechat',
        // RegisterRedirectUri: 'http://dui.our.tm/users/register',
        loginRedirectUri: ServerInfo.webBaseUrl + RouteUrls.users.wechatLogin,
        RegisterRedirectUri: ServerInfo.webBaseUrl + RouteUrls.users.register,
        // data-url  wechet.css文件的二进制码的形式
        href:
            'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsNCiAgICBkaXNwbGF5OiBub25lOw0KfQ0' +
            'KLmltcG93ZXJCb3ggLnFyY29kZSB7DQogICAgd2lkdGg6IDEzNHB4Ow0KICAgIG1hcmdpbi10b3A6IDd' +
            'weDsNCn0NCi5pbXBvd2VyQm94IC5pbmZvIHsNCiAgICBkaXNwbGF5OiBub25lOw0KfQ0K',
    },
    state: 'neka',
    scope: 'snsapi_login',
});

interface IOwnProps {
    currentPage: string;
}

interface IProps extends IOwnProps, IDispatch, RouteComponentProps<{}> {}

class WechatView extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    state = {
        modelVisible: false,
    };

    componentWillMount(): void {
        let { location } = this.props;
        let code = new URLSearchParams(location.search).get('code');
        if (code) {
            this.wechatLogin(code);
        }
    }

    wechatLogin = async (code: string) => {
        try {
            let wechatInfo = await apis.account.getTokenByCode(code);
            if ((wechatInfo as IPrincipal).authInfo) {
                this.props.dispatch(logIn(wechatInfo));
                this.props.dispatch(setPwLoginCounter(0));
                localStoragePrincipal.save(wechatInfo as IPrincipal);
                // this.props.history.push(RouteUrls.dashboard);
                doRedirectPath(this.props.history, true);
            } else {
                this.props.dispatch(saveWechatInfo(wechatInfo));
                this.props.history.replace(RouteUrls.users.bindAccount);
            }
        } catch (e) {
            showErrorNotification(e);
        }
    };

    clickModelCancel = () => {
        this.setState({ modelVisible: false });
    };

    clickModelOK = () => {
        this.setState({ modelVisible: false });
        this.props.history.push(RouteUrls.users.register);
    };

    render() {
        let redirectUri =
            this.props.currentPage === 'login'
                ? weChatConstInfo.urls.loginRedirectUri
                : weChatConstInfo.urls.RegisterRedirectUri;
        let uri =
            weChatConstInfo.urls.oauth +
            weChatConstInfo.urls.authorization +
            '?appid=' +
            weChatConstInfo.clientId +
            '&redirect_uri=' +
            redirectUri +
            '&href=' +
            weChatConstInfo.urls.href +
            '&scope=' +
            weChatConstInfo.scope +
            '&state=' +
            weChatConstInfo.state +
            '#wechat_redirect';
        return (
            <div>
                <div style={wechatStyle}>使用微信扫一扫</div>
                <iframe
                    className="wechatImg"
                    frameBorder="0"
                    sandbox="allow-scripts allow-same-origin allow-top-navigation"
                    scrolling="no"
                    src={uri}
                    width="316"
                    height="160"
                />
                {this.props.currentPage === 'login' ? (
                    <Modal
                        title="此微信号尚未注册脑问账号"
                        visible={this.state.modelVisible}
                        onOk={this.clickModelOK}
                        onCancel={this.clickModelCancel}
                        okText="注册新账号"
                        cancelText="使用其他账号"
                        className="nw-account-model"
                    >
                        <div>是否使用该微信号注册新账号</div>
                    </Modal>
                ) : (
                    <div className="nw-register-service-agreement nw-in-wechat">注册则表示您已同意 《服务协议》</div>
                )}
            </div>
        );
    }
}

// const connected: React.ComponentClass<IOwnProps & RouteComponentProps> = connect<
//     {},
//     IDispatch,
//     IOwnProps & RouteComponentProps,
//     any
// >(
//     () => ({}),
//     dispatch => ({ dispatch })
// )(WechatView);
const connected: React.ComponentClass<IOwnProps & RouteComponentProps> = connect()(WechatView);
const routed = withRouter(connected);
export default routed;
