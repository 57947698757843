import { Directions, isHori, ISize, isVert } from './mind-draw2d';
import { IMindItem } from './mind-models';

export const TextPadding = Object.freeze({
    left: 8,
    top: 8,
    right: 8,
    bottom: 8,
});

/** 结点文字右侧的图标, 其每一个的高度和宽度值. */
export const TopicIconSize = 14;
/** 图标之间或与边框之间的空白. */
const iconSpace = 4;

export function calcBodySize(item: IMindItem, content: { textSize: ISize }): ISize {
    let { width, height } = content.textSize;

    width += TextPadding.left + TextPadding.right + (item.hasDiscussion ? TopicIconSize + iconSpace : 0);
    height = Math.max(height, TopicIconSize) + TextPadding.top + TextPadding.bottom;

    return { width, height };
}

/** 布局过程中的可配置项, 每一种布局方式有一个默认值. */
interface ILayoutStyles {
    /** 结点横向间距. 对横向布局, 它必须大于 {@link gripSpace} + {@link gripHalf}. */
    hSpace: number;
    /** 结点纵向间距. 对纵向布局, 它必须大于 {@link gripSpace} + {@link gripHalf}. */
    vSpace: number;
    /** 结点边缘至把手边缘的距离. 它必须大于 {@link gripHalf}. */
    gripSpace: number;
    /** 把手的半长. 注意: 把手的长和宽是相等的. */
    gripHalf: number;
}

function checkLayoutStyle(layoutStyles: ILayoutStyles, direction: Directions): ILayoutStyles {
    const { hSpace, vSpace, gripSpace, gripHalf } = layoutStyles;
    if (isHori(direction)) {
        if (hSpace <= gripSpace + gripHalf) throw new Error(`hSpace 不够显示 Grip.`);
    }
    if (isVert(direction)) {
        if (vSpace <= gripSpace + gripHalf) throw new Error(`vSpace 不够显示 Grip.`);
    }
    if (gripSpace <= gripHalf) throw new Error(`gripSpace 不够显示 grip`);
    return layoutStyles;
}

export const GripHalf = 8;

export const LogicRightStyles: Readonly<ILayoutStyles> = Object.freeze(
    checkLayoutStyle(
        {
            vSpace: 8,
            hSpace: 48, // 8 * 4 + 12 + 4,
            gripSpace: 12,
            gripHalf: GripHalf,
        },
        Directions.right
    )
);

export const OrgDownStyles: Readonly<ILayoutStyles> = Object.freeze(
    checkLayoutStyle(
        {
            vSpace: 8 * 4 + 12 + 4,
            hSpace: 8,
            gripSpace: 12,
            gripHalf: GripHalf,
        },
        Directions.down
    )
);
