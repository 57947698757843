import { protectProperties, protectProperty } from './sys-utils';

export interface IException {
    name: string;
    message: string;
    stack?: string;
    innerException?: IException;
}

/** Checks whether an {@link obj} is compatible to the {@link IException} interface. */
export function isException(obj: any): obj is IException {
    return typeof obj === 'object' && obj !== null && typeof obj.name === 'string' && typeof obj.message === 'string';
}

/** Try casting {@link obj} to {@link IException}. */
export function asException(obj: any): IException | undefined {
    return isException(obj) ? (obj as IException) : undefined;
}

interface IErrorConstructor {
    new (message: string): Error;
}

/**
 * @summary A function which helps resolving the prototype chain of custom errors.
 * @remarks
 *   * We create this intermediate function as the base of {@link CustomError}
 *     because of we cannot setting the `prototype` of a `class` in ES6 environment as it is readonly.
 *   * We define this function using `Function constructor`
 *     because of `this` in `Error.call(this, message)` will cause TypeScript compilation error
 *     `TS2683: 'this' implicitly has type 'any' because it does not have a type annotation.`
 *     when `noImplicitThis` is specified in `tsconfig.json`.
 * @see [`Function constructor`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function)
 */
const ErrorHacker = Function('message', '"use strict"; Error.call(this, message);') as IErrorConstructor;
ErrorHacker.prototype = Object.create(Error.prototype);

/**
 * @summary The base of custom error.
 * @remarks It resolves the `instanceof` problem when subclassing Error in ES5 target.
 * See https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
 */
export class CustomError extends ErrorHacker {
    constructor(message: string) {
        super(message);

        Error.call(this, message);
        this.message = message;
        this.name = new.target.name;
        captureStackTrace(this, new.target);
        protectProperties(this, 'name', 'message', 'stack');
    }
}

/**
 * Creates the `stack` property on a given object.
 * @param targetObject the object holds the `stack` property.
 * @param constructorOpt If given, all frames above `constructorOpt`, including `constructorOpt`,
 *      will be omitted from the generated stack trace.
 */
export function captureStackTrace(targetObject: { stack?: string }, constructorOpt?: Function) {
    if (Error.captureStackTrace) {
        Error.captureStackTrace(targetObject, constructorOpt);
    } else {
        const stack = new Error().stack;
        if (stack) {
            targetObject.stack = excludeFramesAbove(stack, constructorOpt);
        }
    }
}

/** Excludes stack frames above `constructorOpt`. */
export function excludeFramesAbove(stack: string, constructorOpt?: Function): string {
    if (!stack) return stack;
    if (!constructorOpt) return stack;
    if (!constructorOpt.name) return stack;

    // exclude lines starts with:  "  at functionName "
    const frameRegExp = new RegExp(`\\s+at\\s${constructorOpt.name}\\s`);

    const lines = stack.split('\n');
    const aboveIndex = lines.findIndex(line => frameRegExp.test(line));
    if (aboveIndex > 0) {
        // The first (which is index of 0) item is the Error type.
        lines.splice(1, aboveIndex);
    }
    return lines.join('\n');
}

/** Represents the base of exceptions. */
export class Exception extends CustomError implements IException {
    constructor(message: string, public readonly innerException?: Exception) {
        super(message);
        protectProperty(this, 'innerException');
    }
}

/** Represents the exception of trying to use a feature which has not been implemented yet. */
export class NotImplementedException extends Exception {
    constructor(feature: string | Function = 'Feature') {
        const name = typeof feature === 'function' ? feature.name : feature;
        super(`${name} is not implemented.`);
    }
}

/**
 * Represents the exception of trying to use a feature which is not supported,
 * typically due to the underlying platform.
 */
export class NotSupportedException extends Exception {
    constructor(feature: string) {
        super(`${feature} is not supported.`);
    }
}

/**
 * Represents the exception of invalid argument.
 */
export class ArgumentException extends Exception {
    constructor(argName: string, reason?: string) {
        super(`Argument [${argName}] error` + (reason ? ` ${reason}.` : '.'));
    }
}

/**
 * Represents the exception of invalid argument.
 */
export class ConfigurationException extends Exception {
    constructor(keyName: string) {
        super(`${keyName} is not configure`);
    }
}

/**
 * Represents the exception of missing a required argument.
 */
export class ArgumentMissingException extends ArgumentException {
    constructor(argName: string) {
        super(argName, `is null or undefined.`);
    }
}

export interface IFriendlyMessage {
    /** Gets the message for end-user. */
    readonly friendlyMessage: string;
}

export function isFriendlyException(obj: any): obj is IException & IFriendlyMessage {
    if (!isException(obj)) return false;
    return typeof (obj as any).friendlyMessage === 'string' && (obj as any).friendlyMessage;
}

/**
 * The base of business logic exceptions.
 */
export class BusinessException extends Exception implements Partial<IFriendlyMessage> {
    constructor(message: string, friendlyMessage?: string, innerException?: Exception) {
        super(message, innerException);
        this.friendlyMessage = friendlyMessage;
        protectProperty(this, 'friendlyMessage');
    }

    public readonly friendlyMessage?: string;
}

/**
 * The base of security exceptions.
 */
export class SecurityException extends BusinessException implements Partial<IFriendlyMessage> {
    constructor(message: string, friendlyMessage?: string, innerException?: Exception) {
        super(message, friendlyMessage, innerException);
    }
}
