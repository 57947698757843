import { Drawer } from 'antd';
import React, { ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { RouteUrls } from '../../../core/app-config';

import './admin-components.less';
import { UnderConstruction } from '../../../components/common.helpers';

interface IViewProps extends RouteComponentProps {
    children?: ReactNode;
    title?: string;
}

const AdminPageView: React.FunctionComponent<IViewProps> = props => {
    return (
        <div className="admin-bench">
            {/* <MainBar title={props.title} />*/}
            <Drawer className="admin-drawer">
                <NavLink to={RouteUrls.admin.users}>Manage Users</NavLink>
            </Drawer>
            <div className="admin-container">{props.children ? props.children : <UnderConstruction />}</div>
        </div>
    );
};

const routed = withRouter(AdminPageView);

export const AdminPage = routed;
