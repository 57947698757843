import { Icon, Menu, Table } from 'antd';
import Dropdown from 'antd/lib/dropdown';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { IMindDocument, IMindDocumentEntity, IPrincipal } from 'neka-common';
import React from 'react';
import { fromNow } from '../../common/ui-utils';
import { IDashboard } from '../dashboard-model';

interface IDocumentListProps {
    principal: IPrincipal;
    dashboard: IDashboard;
    onOpenDocument: (documentId: string) => void;
    onDeleteDocument: (document: IMindDocumentEntity) => void;
}

class DocumentListPane extends React.Component<IDocumentListProps, {}> {
    constructor(props: IDocumentListProps) {
        super(props);
    }

    private readonly columns: ColumnProps<IMindDocumentEntity>[] = [
        {
            title: '名称',
            dataIndex: 'title',
            key: 'title',
            width: '300px',
            render: (text: any, record: IMindDocumentEntity) => {
                return (
                    <span className="document-title" onClick={() => this.handleDocumentTitleClick(record.id)}>
                        {record.title}
                    </span>
                );
            },
        },
        {
            title: '归属人',
            dataIndex: 'ownerName',
            key: 'ownerName',
            width: '50px',
            render: (text: any, record: IMindDocumentEntity) => {
                const isMine = this.props.principal.userid === record.ownerId;
                const ownerStr = isMine ? '我' : record.owner ? record.owner.nickname : record.ownerId + ' (昵称未知)';
                return <span>{ownerStr}</span>;
            },
        },
        {
            title: '更新时间',
            dataIndex: 'lastUpdatedAt',
            key: 'lastUpdatedAt',
            width: '80px',
            render: (text: any, record: IMindDocumentEntity) => {
                const momentStr = fromNow(record.lastUpdatedAt);
                const timeStr = moment(record.lastUpdatedAt).format('YYYY/MM/DD HH:mm:ss');
                return <span title={timeStr}>{momentStr}</span>;
            },
        },
        {
            key: 'actions',
            width: '50px',
            render: (text: any, record: IMindDocumentEntity) => {
                return (
                    <Dropdown
                        placement="bottomRight"
                        overlay={
                            <Menu>
                                <Menu.Item onClick={() => this.handleDocumentDeleteClick(record)}>删除</Menu.Item>
                            </Menu>
                        }
                    >
                        <Icon type="more" />
                    </Dropdown>
                );
            },
        },
    ];

    private getRows() {
        const docs = this.props.dashboard.documents;
        const rows = docs
            ? docs.sort((a, b) => {
                  return a.lastUpdatedAt < b.lastUpdatedAt ? 1 : -1;
              })
            : undefined;
        return rows;
    }

    public render(): React.ReactNode {
        const rows = this.getRows();
        return (
            <div className="table-wrapper">
                <Table
                    columns={this.columns}
                    loading={this.props.dashboard.isDocumentsLoading}
                    dataSource={rows}
                    rowKey={'id'}
                    pagination={false}
                />
            </div>
        );
    }

    private handleDocumentTitleClick(documentId: string) {
        if (this.props.onOpenDocument) {
            this.props.onOpenDocument(documentId);
        }
    }

    private handleDocumentDeleteClick(document: IMindDocument) {
        if (this.props.onDeleteDocument) {
            this.props.onDeleteDocument(document);
        }
    }
}

export default DocumentListPane;
