import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Checkbox } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import RadioGroup from 'antd/lib/radio/group';
import RadioButton from 'antd/lib/radio/radioButton';
import { IPrincipal } from 'neka-common';
import React from 'react';
import { IDispatch } from '../../../common/redux-utils';
import { absoluteBodyRect } from '../../mind-layout';
import { getItem, IMindDiagram, MindId } from '../../mind-models';
import {
    ChangeDiagramAllowEdit,
    ChangeDiscussionStatus,
    ChangeRenderDebugHelpers,
    ForceReLayoutSubtree,
    ForceReLayoutUpward,
    IDiscussionStatus,
} from '../../store/mind-redux';

interface IProps extends IDispatch {
    /** The {@link IMindDiagram}, typically passed from its wrapper {@link MindBoard}. */
    diagram: IMindDiagram;
    principal?: IPrincipal;
}

/** 供开发者调试时使用的 {@link MindToolPane} */
export class MindDeveloperToolPane extends React.Component<IProps, {}> {
    public constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    public render() {
        const { diagram } = this.props;
        const { allowEdit } = diagram;

        return (
            <div>
                <div className="mind-tw-row">
                    <Checkbox className="mind-tw-label" onChange={this.handleDisplayDebugInfoChange}>
                        显示调试信息
                    </Checkbox>
                </div>
                <div className="mind-tw-row">
                    <Checkbox className="mind-tw-label" onChange={this.handleRenderDebugHelperChange}>
                        显示定位辅助元素
                    </Checkbox>
                </div>
                <div className="mind-tw-row">
                    <RadioGroup value={allowEdit} onChange={this.handleAllowEditChange}>
                        <RadioButton value={false}>无权编辑</RadioButton>
                        <RadioButton value={true}>有权编辑</RadioButton>
                    </RadioGroup>
                </div>
                <div className="mind-tw-row">
                    <span className="mind-tw-label">当前选中</span>
                    <span className="mind-tw-value">{diagram.selection}</span>
                </div>
                <hr />
                <div className="mind-tw-row">
                    <button onClick={this.handleHitClick}>位置</button>
                    <button onClick={this.handleReLayoutUpwardsClick}>重布局 Upwards</button>
                    <button onClick={this.handleReLayoutSubtreeClick}>重布局 Subtree</button>
                </div>
                <div className="mind-tw-row">
                    <button onClick={this.handleChangeDiscussionStatus}>改变讨论状态</button>
                </div>
                <hr />
            </div>
        );
    }

    private handleDisplayDebugInfoChange = (e: CheckboxChangeEvent) => {
        const shouldDisplay = e.target.checked;
        if (shouldDisplay) {
            localStorage.setItem('debug', 'neka:*');
        } else {
            localStorage.removeItem('debug');
        }
    };

    private handleRenderDebugHelperChange = (e: CheckboxChangeEvent) => {
        this.props.dispatch(ChangeRenderDebugHelpers(e.target.checked));
    };

    private handleAllowEditChange = (e: RadioChangeEvent) => {
        this.props.dispatch(ChangeDiagramAllowEdit(e.target.value));
    };

    private handleHitClick: React.MouseEventHandler = event => {
        const { selection } = this.props.diagram;
        if (selection && selection.length === 1) {
            const id = selection[0];
            console.log(absoluteBodyRect(this.props.diagram, id, true));
        }
    };

    private handleReLayoutUpwardsClick: React.MouseEventHandler = event => {
        const { selection } = this.props.diagram;
        if (selection && selection.length > 0) {
            const lastId: MindId = selection[selection.length - 1];
            this.props.dispatch(ForceReLayoutUpward(lastId));
        }
    };

    private handleReLayoutSubtreeClick: React.MouseEventHandler = event => {
        const { selection } = this.props.diagram;
        if (selection && selection.length > 0) {
            const lastId: MindId = selection[selection.length - 1];
            this.props.dispatch(ForceReLayoutSubtree(lastId));
        }
    };

    private handleChangeDiscussionStatus: React.MouseEventHandler = event => {
        const { selection, items } = this.props.diagram;
        if (selection && selection.length > 0) {
            const changes = selection.map<IDiscussionStatus>(id => {
                const item = getItem(items, id, true);
                const hasDiscussion = !item.hasDiscussion;
                const hasUnreadDiscussion = hasDiscussion && !item.hasUnreadDiscussion;
                return {
                    id,
                    hasDiscussion,
                    hasUnreadDiscussion,
                };
            });
            this.props.dispatch(ChangeDiscussionStatus(...changes));
        }
    };
}
