/**********************************************************/
//  Application Redux related
/**********************************************************/
import { deepLock, getDebug, IMindDocumentSampleOptions, IPrincipal, rootOnlySample } from 'neka-common';
import { AnyAction, applyMiddleware, compose, createStore, Dispatch, Middleware, MiddlewareAPI, Store } from 'redux';
import thunk from 'redux-thunk';
import { createSampleDiagramFor } from '../minds/mind-samples';
import { checkDiagram } from '../minds/store/diagram-checks';
import { IRootState, rootReducer } from '../store/app-redux';
import { isDevelopment, localStoragePrincipal, ServerInfo } from './app-config';
import { Anonymous } from './app-consts';
import { composeWithDevTools } from 'redux-devtools-extension';

const debug = getDebug(__filename);

const logger: Middleware = store => next => action => {
    const actionInfo = typeof action !== 'function' ? action : 'function';
    debug(`Dispatching: %o`, actionInfo);
    let result = next(action);
    debug(`Dispatched: %o, %s`, actionInfo, store.getState());
    return result;
};

const checker: Middleware = ({ getState }: MiddlewareAPI) => (next: Dispatch) => action => {
    let result = next(action);
    const state: IRootState = getState();
    const { diagram } = state;
    if (diagram) {
        try {
            checkDiagram(diagram, false);
        } catch (e) {
            console.error(e.toString(), e);
        }
    }
    return result;
};

function createNekaStore(): Store<IRootState> {
    if (isDevelopment && Object.getOwnPropertyDescriptor(Map.prototype, 'toJSON') === undefined) {
        console.warn(
            'The Map.toJSON is undefined. It is required for redux-dev-tools working properly.\n' +
                'Did you forget to patch `app-polyfills` ?'
        );
    }

    // 尝试加载之前的 token, 实现 `RememberMe`
    const savedPrincipal = localStoragePrincipal.load();
    const principal: IPrincipal = savedPrincipal ? savedPrincipal : Anonymous;

    const initialState: IRootState = deepLock({
        config: {
            apiBaseUrl: ServerInfo.apiBaseUrl,
        },
        principal: principal,
        admins: {},
        diagram: null,
        account: {
            selectedTabValue: 'sms',
            wechatInfo: null,
            passwordLoginCount: 0,
        },
        dashboard: {
            dummy: 'dummy',
            isDocumentsLoading: false,
        },
        toolWindowVisible: true,
        discussions: {
            isLoading: false,
        },
    });

    const middlewares = [logger, thunk, checker];

    /** 启用 Redux DevTools 浏览器扩展. @see https://github.com/zalmoxisus/redux-devtools-extension */
    const composeEnhancers = composeWithDevTools({
        serialize: {
            date: true,
            set: true,
            map: true, // require Map.toJSON polyfill in `common/app-polyfills.ts`
        },
    });

    const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));
    return store;
}

// The global redux store
export const nekaReduxStore = createNekaStore();
