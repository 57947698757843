import classnames from 'classnames';
import React, { CSSProperties } from 'react';

import './toolbar-button.less';

export interface IProps {
    title?: string;
    disabled?: boolean;
    onClick?: React.MouseEventHandler;
}

interface IState {}

const spanStyle: CSSProperties = {
    userSelect: 'none',
};

export class ToolbarButton extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    public render() {
        let { title, disabled, children } = this.props;

        const classes = classnames('nw-toolbar-button', disabled && 'disabled');

        return (
            <span className={classes} style={spanStyle} title={title} onClick={this.handleClick}>
                {children}
            </span>
        );
    }

    private handleClick: React.MouseEventHandler = event => {
        const { onClick, disabled } = this.props;
        if (onClick && !disabled) onClick(event);
    };
}
