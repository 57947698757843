import * as React from 'react';
import { connect } from 'react-redux';
import { Tabs, Modal, Button } from 'antd';
import { IWechatUserArgs } from 'neka-common';
import { doRedirectPath } from '../account-util';
import { logoImg, posterImg } from '../constant';
import { showErrorNotification } from '../../common/ui-utils';
import { localStoragePrincipal, RouteUrls } from '../../core/app-config';
import { apis } from '../../core/server-apis';
import { IRootState, logIn } from '../../store/app-redux';
import { IBindConnectedProps, IBindOwnProps, IBindAccount, setPwLoginCounter } from '../store/account-redux';
import NewAccount from './bind/new-account';
import ExistingAccount from './bind/existing-account';
import './account.less';

class BindAccount extends React.Component<IBindAccount, {}> {
    constructor(props: IBindAccount) {
        super(props);
    }

    state = {
        modelVisible: false,
    };

    componentWillMount(): void {
        if (!this.props.wechatInfo) {
            this.props.history.replace(RouteUrls.home);
        }
    }

    // 跳过绑定
    skipBind = () => {
        this.setState({ modelVisible: true });
    };

    clickModelOK = () => {
        this.setState({ modelVisible: false });
        // 跳过直接注册
        if (this.props.wechatInfo) {
            this.wechatNoMobile({
                unionId: this.props.wechatInfo.unionId,
                nickname: this.props.wechatInfo.nickname,
                avatar: this.props.wechatInfo.headImgURL,
            });
        }
    };

    wechatNoMobile = async (info: IWechatUserArgs) => {
        try {
            const principal = await apis.account.register(info);
            // 注册 成功跳到list页面以及保存session
            this.props.dispatch(logIn(principal));
            this.props.dispatch(setPwLoginCounter(0));
            localStoragePrincipal.save(principal);
            // this.props.history.push(RouteUrls.dashboard);
            doRedirectPath(this.props.history);
        } catch (e) {
            showErrorNotification(e);
        }
    };

    clickModelCancel = () => {
        this.setState({ modelVisible: false });
    };

    render() {
        return (
            <div className="nw-login-page">
                <div className="nw-login-header">
                    <img src={logoImg} />
                    NaoWen
                </div>
                <div className="nw-login-poster">
                    <img src={posterImg} />
                </div>
                <div className="nw-login-content-bg">
                    <div className="nw-login-content">
                        <div className="nw-login-content-header">绑定手机账号</div>
                        <div className="nw-login-content-center nw-login-content-center-hastab">
                            <Tabs defaultActiveKey={'phone'}>
                                <Tabs.TabPane tab={'注册新账号'} key="phone" forceRender={true}>
                                    <NewAccount {...this.props} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={'绑定已有账号'} key="wechat" forceRender={true}>
                                    <ExistingAccount {...this.props} />
                                </Tabs.TabPane>
                            </Tabs>
                        </div>
                        <div className="nw-login-content-footer">
                            <div className="nw-register">
                                <div className="nw-reg" onClick={this.skipBind}>
                                    跳过
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    title="跳过后，您将创建一个只与微信号绑定的脑问账号"
                    visible={this.state.modelVisible}
                    onOk={this.clickModelOK}
                    onCancel={this.clickModelCancel}
                    className="nw-account-model"
                    footer={[
                        <Button key="skip" onClick={this.clickModelOK}>
                            确认跳过
                        </Button>,
                        <Button key="back" type="primary" onClick={this.clickModelCancel}>
                            继续注册或者绑定脑问账号
                        </Button>,
                    ]}
                >
                    <div>建议您先绑定手机号，或者微信号与已注册的脑问账号绑定</div>
                </Modal>
            </div>
        );
    }
}

const BindAccountPage = connect<IBindConnectedProps, {}, IBindOwnProps, IRootState>((state: IRootState) => ({
    wechatInfo: state.account.wechatInfo,
}))(BindAccount);

export default BindAccountPage;
