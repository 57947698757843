import {
    getDebug,
    IBasicCredential,
    IMobileCredential,
    IPrincipal,
    IRegisterUserArgs,
    IMobileUserArgs,
    IWechatUserArgs,
    IUser,
    IWechatUserProfile,
    IBindWechatArgs,
    IMindDocument,
    IDiscussionPost,
    IDiscussionPostArgs,
    IDiscussionReplyArgs,
    IDiscussionReply,
    IBriefUser,
    ICreateCollaboratorArgs,
    IMindDocumentEntity,
    IMindItemEntity,
    ILastDiscussionTime,
    IMindDocumentCommand,
} from 'neka-common';
import { argsToBody, IWebApiOptions, WebApiFactory } from '../common/wapi-cli';
import { IRootState } from '../store/app-redux';
import { nekaReduxStore } from './app-store';

const debug = getDebug(__filename);

export function getWebApiOptionsFromRootState(state: IRootState): IWebApiOptions {
    return {
        apiBaseUrl: state.config.apiBaseUrl,
        principal: state.principal,
    };
}

function getDefaultOptions(): IWebApiOptions {
    return getWebApiOptionsFromRootState(nekaReduxStore.getState());
}

const c = new WebApiFactory(getDefaultOptions);

const token = c.definePost<IBasicCredential | IMobileCredential, IPrincipal>('auth/token', argsToBody);
const account = {
    token: c.definePost<IBasicCredential | IMobileCredential, IPrincipal>('auth/token', argsToBody),
    getSMSVCode: c.definePost<string, any>(param => `users/sendsms/${param}`), // 获取短信验证码
    register: c.definePost<IRegisterUserArgs | IMobileUserArgs | IWechatUserArgs, IPrincipal>( // 用户注册
        'users/register',
        argsToBody
    ),
    getUnionId: c.defineGet<string, IWechatUserProfile | IPrincipal>(param => `auth/wechat/userinfo?code=${param}`), // 获取微信用户信息
    getTokenByCode: c.defineGet<string, IPrincipal>(param => `auth/wechat/callback?code=${param}`), //  微信登录用cross code获取系统用户的token
    bindWechat: c.definePost<IBindWechatArgs, IPrincipal>('users/bindwechat', argsToBody), // 获取微信用户信息
    unbindWechat: c.definePost<any, any>('users/unbindwechat', argsToBody),
};

// 获取微信用户信息
const getWeChatInfo = c.defineGet<string, IWechatUserArgs>(code => 'auth/wechat/userinfo?code=' + code);

// 过code获取系统用户的token
const getWeChatToken = c.defineGet<string, IPrincipal>(code => 'auth/wechat/callback?code=' + code);

// 注册用户
const registerUsers = c.definePost<IWechatUserArgs, IPrincipal>('users/register', argsToBody);

const users = {
    ...c.defineEntityWebApis<string, IUser>('users'),
    register: c.definePost<IRegisterUserArgs, IUser>('users/register', argsToBody),
    logout: c.definePost<undefined, IUser>('users/logout'),
};

// ---------------------------------------------------------
// 文档列表 APIs
// ---------------------------------------------------------

const docs = {
    getDocuments: c.defineGet<undefined, (IMindDocumentEntity)[]>(`mind/docs/list`),
    getDocumentById: c.defineGet<string, IMindDocumentEntity>((documentId: string) => `mind/docs/${documentId}/full`),
    createDocument: c.definePost<IMindDocument, IMindDocument>(`mind/docs`, argsToBody),
    updateDocument: c.definePut<Partial<IMindDocument>, IMindDocument>(
        (document: Partial<IMindDocumentEntity>) => `mind/docs/${document.id}`,
        argsToBody
    ),
    deleteDocument: c.defineDelete((documentId: string) => `mind/docs/${documentId}`),
    getLastDiscussionTimes: c.defineGet<string, Array<ILastDiscussionTime>>(
        (documentId: string) => `mind/docs/${documentId}/lastDiscussionTimes`
    ),

    executeBatch: c.definePut<{ documentId: string; commands: IMindDocumentCommand[] }, void>(
        args => `mind/docs/${args.documentId}/executeBatch`,
        (init, args) => {
            init.body = JSON.stringify(args);
        }
    ),
};

// ---------------------------------------------------------
// 脑图主题 APIs
// ---------------------------------------------------------
const items = {
    createItem: c.definePost<Partial<IMindItemEntity>, IMindItemEntity>((item: Partial<IMindItemEntity>) => {
        if (!item.id) throw new Error('id is required');
        if (!item.documentId) throw new Error('documentId is required');
        return `mind/items`;
    }, argsToBody),
    updateItem: c.definePut<IMindItemEntity, IMindItemEntity>(
        (item: IMindItemEntity) => `mind/items/${item.id}`,
        argsToBody
    ),
    deleteItem: c.defineDelete((item: IMindItemEntity) => `mind/items/${item.id}`, argsToBody),
};

// ---------------------------------------------------------
// 讨论帖子 APIs
// ---------------------------------------------------------

const discussions = {
    list: c.defineGet<string, IDiscussionPost[]>(param => `discussion/posts/list/${param}`),
    createDiscuss: c.definePost<IDiscussionPostArgs, IDiscussionPost>(`discussion/posts/add`, argsToBody),
    createReplies: c.definePost<IDiscussionReplyArgs, IDiscussionReply>(`discussion/replies/add`, argsToBody),
    // 设置一个回复是否被采纳
    setReplyAdopt: c.definePost<string, boolean>(param => `discussion/replies/adopt/${param}`),
    // 设置一个帖子是否已解决
    setDiscussSolve: c.definePost<string, boolean>(param => `discussion/posts/solve/${param}`),
    // 设置一个帖子为已读状态
    setDiscussRead: c.definePost<string, boolean>(param => `discussion/posts/read/${param}`),
    remove: undefined,
};

// ---------------------------------------------------------
// 邀请成员、成员列表APIS
// ---------------------------------------------------------

const members = {
    // 根据文档Id获取成员列表
    list: c.defineGet<string, IBriefUser[]>(param => `mind/collaborators/list/${param}`),
    // 通过邀请码获取脑图信息
    findDoc: c.defineGet<string, IMindDocument>(param => `mind/invites/finddoc/${param}`),
    // 获取邀请码
    getInviteCode: c.defineGet<string, { data: string }>(param => `mind/invites/findcodebydocid/${param}`),
    // 添加一个成员
    add: c.definePost<ICreateCollaboratorArgs, boolean>(`mind/collaborators/add`, argsToBody),
};

export const apis = Object.freeze({
    token,
    account,
    getWeChatInfo,
    getWeChatToken,
    registerUsers,
    users,
    docs,
    items,
    discussions,
    members,
});
