import { History } from 'history';
import { localStorageRedirectUrl, RouteUrls } from '../core/app-config';
import { formConstant, sensitiveWordsArr } from './constant';

export const doRedirectPath = (currentHistory: History, replace?: boolean) => {
    let switchPath = replace ? currentHistory.replace : currentHistory.push;
    if (localStorageRedirectUrl.load()) {
        switchPath(localStorageRedirectUrl.load()!.url);
        localStorageRedirectUrl.clear();
    } else {
        switchPath(RouteUrls.dashboard);
    }
};

export const filterNumberInput = (value: any) => {
    return value ? value.replace(/[^\d]/g, '') : value;
};

// 汉字：[0x4e00,0x9fa5]（或十进制[19968,40869]）
export const filterNameInput = (value: string) => {
    console.log(value);
    let len = value.length;
    let byteNum = 0,
        traverseIndex = 0;
    for (let i = 0; i < len; ++i) {
        if (/[\u4e00-\u9fa5]/.test(value.charAt(i))) {
            byteNum += 2;
        } else {
            byteNum += 1;
        }
        if (byteNum === 33 || byteNum === 34) {
            traverseIndex = i;
            break;
        }
    }
    console.log(traverseIndex);
    if (traverseIndex === 0) {
        return value;
    } else if (byteNum === 33) {
        return value.slice(0, traverseIndex + 1);
    } else {
        return value.slice(0, traverseIndex);
    }
};

export function getSensitiveWord(value: string): string {
    for (let sensitiveWord of sensitiveWordsArr) {
        if (value.indexOf(sensitiveWord) > -1) return sensitiveWord;
    }
    return '';
}

export const validateSensitiveWord = (rule: any, value: string, callback: any) => {
    let sensitiveWord = getSensitiveWord(value);
    if (value && sensitiveWord) {
        callback(formConstant.validateRules.sensitiveWordErrorMassage.replace('%s', sensitiveWord));
    } else {
        callback();
    }
};

export function getNameErrorTips(trimStr: string): string | undefined {
    let sensitiveWord = getSensitiveWord(trimStr);
    if (trimStr.length === 0) {
        return formConstant.validateRules.nicknameNullErrorMassage;
    } else if (trimStr.length > 20) {
        return formConstant.validateRules.nicknameLengthErrorMassage;
    } else if (trimStr.match(/[^ A-Za-z0-9\u4e00-\u9fa5]/)) {
        return formConstant.validateRules.nicknameErrorMassage;
    } else if (sensitiveWord) {
        return formConstant.validateRules.sensitiveWordErrorMassage.replace('%s', sensitiveWord);
    } else {
        return undefined;
    }
}
