import { IDebugger } from 'debug';
import { filenameOf } from './sys-utils';

const debugImpl = require('debug');

/** 调试信息的根名称空间, 供 `npm-debug` 使用. */
const LogRootNS = 'neka';

/**
 * 返回用于输出调试信息的函数.
 * @param name 位于每行调试信息起始位置的名称, see https://www.npmjs.com/package/debug#usage
 * @example
 *      const debug = getDebug(__filename);
 *      // ...
 *      debug('Something happens');
 */
export function getDebug(name: string): IDebugger {
    const filename = filenameOf(name);
    const debugName = `${LogRootNS}:${filename}`;
    return debugImpl(debugName);
}

/** Represents the result of {@link timing}. */
export interface ITimingResult<T> {
    /** The execution time for a callback. */
    readonly milliSeconds: number;
    /** The callback result. */
    readonly result: T;
}

const isHrtimeDefined = process && process.hrtime && typeof process.hrtime === 'function';

/** A function which returns the current time in milliSeconds. */
export function currentTimeInMilliSeconds() {
    if (isHrtimeDefined) {
        const [seconds, nanoSeconds] = process.hrtime();
        // hrtime 返回纳秒, 1毫秒=1000微秒, 1微秒=1000纳秒
        return seconds * 1000 + Number(nanoSeconds / 1000000);
    }
    return new Date().getMilliseconds();
}

export function timing<T = never>(callback: () => T): ITimingResult<T> {
    const start = currentTimeInMilliSeconds();
    const result = callback();
    const end = currentTimeInMilliSeconds();

    const milliSeconds = end - start;
    return {
        milliSeconds,
        result,
    };
}
