import {
    applyUpdater,
    ArgumentMissingException,
    Exception,
    getDebug,
    getProvided,
    HttpMethod,
    IObjectWithId,
    IPrincipal,
    ObjectOrProvider,
    protectProperties,
    protectProperty,
    PartialWithId,
} from 'neka-common';

const debug = getDebug(__filename);

/**
 * Represents the error of WebApi call.
 */
export class WebApiException extends Exception {
    constructor(status: number, message: string) {
        super(message);
        this.status = status;
        protectProperty(this, 'status');
    }

    public readonly status: number;
}

export class WebApiResponseException extends WebApiException {
    constructor(status: number, responseBody: string) {
        super(status, `WebApi Response Error:\n${responseBody}.`);
        this.text = responseBody;
        this.json = tryParseJSON(responseBody);
        protectProperties(this, 'text', 'json');
    }

    /** The result of response.text() */
    public readonly text: string | Object;

    /** The result of response.body.json() when the response.body is in JSON */
    public readonly json?: { [key: string]: any };
}

export interface IWebApiOptions {
    /** The url fragment like `http://<neka-server>[:<port>]/apis`, for example `http://localhost:3000/apis` */
    readonly apiBaseUrl: string;
    readonly principal?: IPrincipal;
}

export type WebApi<TArgs, TResult> = (args: TArgs) => Promise<TResult>;

export type RequestInitCallback = (requestInit: RequestInit) => void;
export type RequestSetup = RequestInit | RequestInitCallback;
export type UrlOrProvider<TArgs> = string | ((args: TArgs) => string);
export type ArgsApplier<TArgs> = (init: RequestInit, args: TArgs) => void;

/** Represents a group of WebApis for entity persistence. */
export interface IEntityWebApis<TKey, TEntity extends IObjectWithId<TKey>> {
    createOne: WebApi<Partial<TEntity>, TEntity>;
    deleteOneById: WebApi<TKey, { exists: boolean }>;
    updateOneById: WebApi<PartialWithId<TKey, TEntity>, TEntity>;
    findOneById: WebApi<TKey, TEntity>;
    findAll: WebApi<void, TEntity[]>;
}

/**
 * Represents the factory for creating {@link WebApi}s.
 */
export class WebApiFactory {
    public constructor(private optionsProvider: ObjectOrProvider<IWebApiOptions>) {}

    protected getOptions() {
        return getProvided(this.optionsProvider);
    }

    /**
     * Defines a WebApi.
     * @param method the HttpMethod, such as `GET`, `POST`, etc.
     * @param url the url of the WebApi.
     * @param applyArgs the function to apply the argument to request, for example,
     *        {@link argsToBody} serialize the argument to the {@link RequestInit.body} using JSON formatter.
     * @returns an function which of type {@link WebApi<TArgs, TResult>}
     */
    // tslint:disable-next-line:max-line-length
    public defineApi<TArgs, TResult>(
        method: HttpMethod,
        url: UrlOrProvider<TArgs>,
        applyArgs?: ArgsApplier<TArgs>
    ): WebApi<TArgs, TResult> {
        return (args: TArgs) => {
            const options = this.getOptions();
            const relativeUrl = typeof url === 'string' ? url : url(args);

            return this.invokeApi<TResult>(`${options.apiBaseUrl}/${relativeUrl}`, init => {
                init.method = method;
                applyOptions(init, options);
                if (applyArgs) applyArgs(init, args);
            });
        };
    }

    public defineGet<TArgs, TResult>(
        url: UrlOrProvider<TArgs>,
        applyArgs?: ArgsApplier<TArgs>
    ): WebApi<TArgs, TResult> {
        return this.defineApi<TArgs, TResult>(HttpMethod.GET, url, applyArgs);
    }

    public definePost<TArgs, TResult>(
        url: UrlOrProvider<TArgs>,
        applyArgs: ArgsApplier<TArgs> = argsToBody
    ): WebApi<TArgs, TResult> {
        return this.defineApi<TArgs, TResult>(HttpMethod.POST, url, applyArgs);
    }

    public defineDelete<TArgs, TResult>(
        url: UrlOrProvider<TArgs>,
        applyArgs?: ArgsApplier<TArgs>
    ): WebApi<TArgs, TResult> {
        return this.defineApi<TArgs, TResult>(HttpMethod.DELETE, url, applyArgs);
    }

    public definePut<TArgs, TResult>(
        url: UrlOrProvider<TArgs>,
        applyArgs: ArgsApplier<TArgs> = argsToBody
    ): WebApi<TArgs, TResult> {
        return this.defineApi<TArgs, TResult>(HttpMethod.PUT, url, applyArgs);
    }

    /** Define a group of standard WebApis for an entity type. */
    public defineEntityWebApis<TKey, TEntity extends IObjectWithId<TKey>>(path: string): IEntityWebApis<TKey, TEntity> {
        const createOne = this.definePost<Partial<TEntity>, TEntity>(`${path}`, argsToBody);
        const deleteOneById = this.defineDelete<TKey, { exists: boolean }>(args => `${path}/${args}`);
        const updateOneById = this.definePut<Partial<TEntity>, TEntity>(args => `${path}/${args.id}`, argsToBody);
        const findOneById = this.defineGet<TKey, TEntity>(args => `${path}/${args}`);
        const findAll = this.defineGet<void, TEntity[]>(path);

        return {
            createOne,
            deleteOneById,
            updateOneById,
            findOneById,
            findAll,
        };
    }

    /**
     * Invokes a WebApi and returns its response.body as an object.
     * @param input the `input` argument for `fetch`
     * @param setups a list of object/callback to setup the `init` argument for `fetch`
     * @see https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
     */
    public async invokeApi<T>(input: Request | string, ...setups: RequestSetup[]): Promise<T> {
        const init: RequestInit = {
            headers: {
                Accept: 'application/json, application/xml, text/plain, text/html, *.*',
                'Content-Type': 'application/json; charset=utf-8',
                'Access-Control-Allow-Origin': this.getOptions().apiBaseUrl + '/*',
            },
        };

        if (setups) {
            setups.forEach(setup => {
                applyUpdater(init, setup);
            });
        }
        const res = await fetch(input, init);
        return await this.getResult<T>(res);
    }

    /**
     * Converts the response.body to an object.
     * @return the object when response.body is a valid JSON, or `undefined` when response.body is `null`.
     * @exception throws {@link WebApiResponseException} when response.ok is false.
     */
    public async getResult<T>(response: Response): Promise<T> {
        const isJSON = this.isJSON(response);

        if (!response.ok) {
            // The response.body may be empty, text, or JSON
            if (response.body) {
                const bodyText = await response.text();
                let bodyJson: any = undefined;
                if (isJSON) {
                    bodyJson = JSON.parse(bodyText);
                }
                throw new WebApiResponseException(response.status, bodyText);
            }
            throw new WebApiResponseException(
                response.status,
                `WebApi [${response.url}] response error [${response.status}].`
            );
        }

        if (!this.isJSON(response)) {
            throw new WebApiResponseException(
                response.status,
                `The Content-Type of WebApi [${response.url}] is not JSON.`
            );
        }

        if (response.body) {
            return await response.json();
        }
        return undefined as any;
    }

    /** Determinate if the response is type of JSON. */
    protected isJSON(response: Response): boolean {
        if (!response) throw new ArgumentMissingException('response');

        const contentType = response.headers && response.headers.get('Content-Type');
        if (!contentType) return false;
        return contentType.indexOf('application/json') > -1;
    }
}

function tryParseJSON(s: string): Object | undefined {
    if (!s) return undefined;
    try {
        return JSON.parse(s);
    } catch (e) {
        return undefined;
    }
}

function applyOptions(init: RequestInit, options: IWebApiOptions): RequestInit {
    if (options) {
        // set authorization header
        const { principal } = options;
        if (principal) {
            const { authInfo } = principal;
            if (authInfo) {
                if (authInfo.access_token) {
                    if (!init.headers) init.headers = {};
                    Object.assign(init.headers, {
                        Authorization: `Bearer ${authInfo.access_token}`,
                    });
                }
            }
        }
    }
    return init;
}

export function argsToBody<TArgs>(init: RequestInit, args: TArgs) {
    // if (args) {
    //     init.body = JSON.stringify(args);
    // }
    if (typeof args === 'string') {
        init.body = JSON.stringify({ args });
    } else {
        init.body = JSON.stringify(args);
    }
}
