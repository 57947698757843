import { Button, Input } from 'antd';
import { ErrorMessage, Form, Formik } from 'formik';
import { getDebug, IRegisterUserArgs } from 'neka-common';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import * as yup from 'yup';
import { IDispatch } from '../common/redux-utils';
import { showErrorNotification } from '../common/ui-utils';
import { RouteUrls } from '../core/app-config';
import { apis } from '../core/server-apis';
import { logIn } from '../store/app-redux';

import './account-components.less';

const debug = getDebug('user-register.tsx');

interface IFormValues extends IRegisterUserArgs {
    confirmPassword: string;
}

export const validationSchema: yup.ObjectSchema<IFormValues> = yup.object<IFormValues>().shape({
    username: yup.string().required(),
    password: yup.string().required(),
    confirmPassword: yup
        .string()
        .required()
        .oneOf([yup.ref('password')], 'Passwords does not match'),
});

interface IViewProps {
    initialValues: IFormValues;
    onRegister(values: IRegisterUserArgs): Promise<any>;
}

const RegisterView = (props: IViewProps) => (
    <div>
        <h1>Register</h1>
        <hr />
        <Formik<IFormValues>
            initialValues={props.initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
                try {
                    await props.onRegister(values);
                } finally {
                    actions.setSubmitting(false);
                }
            }}
            render={bag => (
                <Form>
                    <Input
                        name="username"
                        placeholder="User Name"
                        value={bag.values.username}
                        onChange={bag.handleChange}
                        onBlur={bag.handleBlur}
                    />
                    <ErrorMessage name="username">{message => <div>{message}</div>}</ErrorMessage>
                    <Input
                        name="password"
                        type="password"
                        placeholder="Password"
                        autoComplete="off"
                        value={bag.values.password}
                        onChange={bag.handleChange}
                        onBlur={bag.handleBlur}
                    />
                    <ErrorMessage name="password">{message => <div>{message}</div>}</ErrorMessage>
                    <Input
                        name="confirmPassword"
                        type="password"
                        placeholder="Confirm Password"
                        autoComplete="off"
                        value={bag.values.confirmPassword}
                        onChange={bag.handleChange}
                        onBlur={bag.handleBlur}
                    />
                    <ErrorMessage name="confirmPassword">{message => <div>{message}</div>}</ErrorMessage>
                    <Button disabled={bag.isSubmitting} className="big-button">
                        Register
                    </Button>
                </Form>
            )}
        />
        <NavLink to={RouteUrls.users.login}>Login</NavLink>
    </div>
);

type ISiteProps = RouteComponentProps & IDispatch;

class RegisterSite extends React.Component<ISiteProps, {}> {
    constructor(props: ISiteProps) {
        super(props);
    }

    private static initialValues: IFormValues = {
        username: '',
        password: '',
        confirmPassword: '',
    };

    private handleRegister = async (values: IRegisterUserArgs) => {
        try {
            await apis.users.register(values);
            const principal = await apis.token({
                username: values.username,
                password: values.password!,
            });
            this.props.dispatch(logIn(principal));
            if (this.props.history) {
                this.props.history.push(RouteUrls.users.profile);
            }
        } catch (e) {
            showErrorNotification(e);
        }
    };

    public render() {
        return <RegisterView initialValues={RegisterSite.initialValues} onRegister={this.handleRegister} />;
    }
}

export const UserRegisterPane = connect()(withRouter(RegisterSite));
