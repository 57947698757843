import { StructureKinds, LineKinds, NotImplementedException, StructureKind, LineKind } from 'neka-common';
import React, { PureComponent } from 'react';
import { IPos, NullPos } from '../mind-draw2d';
import { IMindItem } from '../mind-models';
import { LogicRightStyles, OrgDownStyles } from '../mind-styles';
import { SvgElKind, SvgElKinds } from './svg-helper';

interface ILinkProps {
    parentX: number;
    parentY: number;
    parent: IMindItem;
    item: IMindItem;
    structureKind: StructureKind;
}

type IPolylinePoints = {
    startPos: IPos;
    startBendPos: IPos;
    endBendPos: IPos;
    endPos: IPos;
};

/** 折线样式的父子结点连线. */
class MindPolyLink extends PureComponent<ILinkProps, {}> {
    public constructor(props: ILinkProps) {
        super(props);
    }

    private static NullPoints: IPolylinePoints = Object.freeze({
        startPos: NullPos,
        startBendPos: NullPos,
        endBendPos: NullPos,
        endPos: NullPos,
    });

    private static getEnds(props: ILinkProps): IPolylinePoints {
        const { item, parent, parentX, parentY, structureKind } = props;

        if (!item.relativePos) return MindPolyLink.NullPoints;
        if (!item.bodyPos) return MindPolyLink.NullPoints;
        if (!item.bodySize) return MindPolyLink.NullPoints;
        if (!parent.relativePos) return MindPolyLink.NullPoints;
        if (!parent.outPortPos) return MindPolyLink.NullPoints;

        const startCornerOffset = 12;

        switch (structureKind) {
            case StructureKinds.LogicRight: {
                const startPos: IPos = {
                    x: parentX + parent.outPortPos.x,
                    y: parentY + parent.outPortPos.y,
                };
                const endPos: IPos = {
                    x: parentX + item.relativePos.x + item.bodyPos.x,
                    y: parentY + item.relativePos.y + item.bodyPos.y + item.bodySize.height / 2,
                };
                const xMiddle = startPos.x + startCornerOffset;
                const startBendPos: IPos = {
                    x: xMiddle,
                    y: startPos.y,
                };
                const endBendPos: IPos = {
                    x: xMiddle,
                    y: endPos.y,
                };
                return { startPos, startBendPos, endBendPos, endPos };
            }
            case StructureKinds.OrgDown: {
                const startPos: IPos = {
                    x: parentX + parent.outPortPos.x,
                    y: parentY + parent.outPortPos.y,
                };
                const endPos: IPos = {
                    x: parentX + item.relativePos.x + item.bodyPos.x + item.bodySize.width / 2, // TODO: body left center
                    y: parentY + item.relativePos.y + item.bodyPos.y,
                };
                const yMiddle = startPos.y + startCornerOffset;
                const startBendPos: IPos = {
                    x: startPos.x,
                    y: yMiddle,
                };
                const endBendPos: IPos = {
                    x: endPos.x,
                    y: yMiddle,
                };
                return { startPos, startBendPos, endBendPos, endPos };
            }
            default:
                throw new NotImplementedException(`parent structureKind [${structureKind}]`);
        }
    }

    public render() {
        const { item, parent } = this.props;
        const { startPos, startBendPos, endBendPos, endPos } = MindPolyLink.getEnds(this.props);

        const d =
            `M ${startPos.x},${startPos.y} ` +
            `L ${startBendPos.x} ${startBendPos.y} ` +
            `L ${endBendPos.x} ${endBendPos.y}` +
            `L ${endPos.x} ${endPos.y}`;
        return (
            <path
                data-id={item.id}
                data-parent-id={parent.id}
                data-kind={SvgElKinds.itemLink}
                className="mg-link"
                d={d}
            />
        );
    }
}

interface IBezierLinkPoints {
    startPos: IPos;
    startBendPos: IPos;
    middlePos: IPos;
    endBendPos: IPos;
    endBezierPos: IPos;
    endPos: IPos;
}

/** 曲线样式的父子结点连线. */
class MindBezierLink extends PureComponent<ILinkProps, {}> {
    public constructor(props: ILinkProps) {
        super(props);
    }

    private static readonly NullPoints: IBezierLinkPoints = Object.freeze({
        startPos: NullPos,
        startBendPos: NullPos,
        middlePos: NullPos,
        endBendPos: NullPos,
        endBezierPos: NullPos,
        endPos: NullPos,
    });

    private static getEnds(props: ILinkProps): IBezierLinkPoints {
        const { item, parent, parentX, parentY, structureKind } = props;

        if (!item.relativePos) return MindBezierLink.NullPoints;
        if (!item.bodyPos) return MindBezierLink.NullPoints;
        if (!item.bodySize) return MindBezierLink.NullPoints;
        if (!parent.relativePos) return MindBezierLink.NullPoints;
        if (!parent.outPortPos) return MindBezierLink.NullPoints;

        const startOffset = 6; // 起始侧的拐点
        const endOffset = 6; // 终点侧的拐点
        const middleOffset = 12;

        switch (structureKind) {
            case StructureKinds.LogicRight: {
                const startPos: IPos = {
                    x: parentX + parent.outPortPos.x,
                    y: parentY + parent.outPortPos.y,
                };
                const endPos: IPos = {
                    x: parentX + item.relativePos.x + item.bodyPos.x,
                    y: parentY + item.relativePos.y + item.bodyPos.y + item.bodySize.height / 2,
                };
                const startBendPos: IPos = {
                    x: startPos.x + startOffset,
                    y: startPos.y,
                };
                const middlePos: IPos = {
                    x: startPos.x + middleOffset,
                    y: (startPos.y + endPos.y) / 2,
                };
                const endBezierPos: IPos = {
                    x: startPos.x + (LogicRightStyles.hSpace - LogicRightStyles.gripSpace - LogicRightStyles.gripHalf),
                    y: endPos.y,
                };
                const endBendPos: IPos = {
                    x: endBezierPos.x - endOffset,
                    y: endPos.y,
                };
                return { startPos, startBendPos, middlePos, endBendPos, endBezierPos, endPos };
            }
            case StructureKinds.OrgDown: {
                const startPos: IPos = {
                    x: parentX + parent.outPortPos.x,
                    y: parentY + parent.outPortPos.y,
                };
                const endPos: IPos = {
                    x: parentX + item.relativePos.x + item.bodyPos.x + item.bodySize.width / 2, // TODO: body left center
                    y: parentY + item.relativePos.y + item.bodyPos.y,
                };
                const startBendPos: IPos = {
                    x: startPos.x,
                    y: startPos.y + startOffset,
                };
                const middlePos: IPos = {
                    x: (startPos.x + endPos.x) / 2,
                    y: startPos.y + middleOffset,
                };
                const endBezierPos: IPos = {
                    x: endPos.x,
                    y: startPos.y + (OrgDownStyles.vSpace - OrgDownStyles.gripSpace - OrgDownStyles.gripHalf),
                };
                const endBendPos: IPos = {
                    x: endPos.x,
                    y: endBezierPos.y - endOffset,
                };
                return { startPos, startBendPos, middlePos, endBendPos, endBezierPos, endPos };
            }
            default:
                throw new NotImplementedException(`parent StructureKind [${structureKind}]`);
        }
    }

    public render() {
        const { item, parent } = this.props;
        const { startPos, startBendPos, middlePos, endBendPos, endBezierPos, endPos } = MindBezierLink.getEnds(
            this.props
        );

        const d =
            `M ${startPos.x} ${startPos.y}, ` +
            `C ${startPos.x} ${startPos.y}, ${startBendPos.x} ${startBendPos.y}, ${middlePos.x} ${middlePos.y} ` +
            `S ${endBendPos.x} ${endBendPos.y}, ${endBezierPos.x} ${endBezierPos.y} ` +
            `L ${endPos.x} ${endPos.y}`;
        return (
            <path
                data-id={item.id}
                data-parent-id={parent.id}
                data-kind={SvgElKinds.itemLink}
                className="mg-link"
                d={d}
            />
        );
    }
}

interface ICustomLinkProps extends ILinkProps {
    readonly lineKind: LineKind;
}

export const MindLink = (props: ICustomLinkProps) => {
    const { lineKind, ...rest } = props;
    switch (lineKind) {
        case LineKinds.poly:
            return <MindPolyLink {...rest} />;
        case LineKinds.bezier:
            return <MindBezierLink {...rest} />;
        default:
            throw new NotImplementedException(`lineKind [${lineKind}]`);
    }
};
