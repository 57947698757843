/******************************************************************************/
//  MindPainter
/******************************************************************************/

import { StructureKind, StructureKinds } from 'neka-common';
import React from 'react';
import { getDebug } from '../../core/app-diag';
import { NullPos } from '../mind-draw2d';
import { getItem, getStructureKind, getLineKind, IMindItem, IMindItemStyles, ItemGet, MindId } from '../mind-models';
import { MindLink } from './mind-link';
import { IWidgetEventHandlers, MindWidget } from './mind-widget';
import { SvgElKind, SvgElKinds, SvgHelper, svgTransformString } from './svg-helper';

const debug = getDebug(__filename);

type WidgetRefs = Map<MindId, React.RefObject<MindWidget>>;

interface IProps {
    items: ItemGet;
    theme: IMindItemStyles;
    handlers: IWidgetEventHandlers;
    rootId: MindId;
    svgHelper: SvgHelper;
    widgetRefs: WidgetRefs;
}

export const MindPainter = React.memo<IProps>(props => {
    debug(`MindPainter`);

    const { items, theme, rootId, handlers, svgHelper, widgetRefs } = props;
    const elements = new Array<React.ReactElement>();
    widgetRefs.clear();

    /**
     * 渲染 {@link IMindItem} 及其子孙结点.
     * @param item 子树的根结点
     * @param visible 结点是否可见 (受其祖先的 {@link IMindItem.isExpanded} 影响)
     * @param defaultStructureKind 默认的 {@link StructureKind}
     * @param parent 父结点
     * @param pX 父结点的绝对坐标 X
     * @param pY 父结点的绝对坐标 Y
     */
    function paintSubtree(
        item: IMindItem,
        visible: boolean,
        defaultStructureKind: StructureKind,
        parent: IMindItem | undefined,
        pX: number,
        pY: number
    ) {
        let { relativePos, structureKind, lineKind } = item;
        relativePos = relativePos || NullPos;
        structureKind = structureKind || getStructureKind(items, item);
        lineKind = lineKind || getLineKind(items, item);

        // item 的绝对坐标
        const x = pX + relativePos.x;
        const y = pY + relativePos.y;

        const selfVisibility = visible ? 'visible' : 'hidden';
        const showChildren = visible && item.isExpanded;
        const childrenVisibility = showChildren ? 'visible' : 'hidden';

        // 首先绘制子结点, 从而保证当前结点的 Grip 在其与子结点连线的上方 (zIndex)
        const { childIds } = item;
        if (childIds && childIds.length > 0) {
            for (let i = 0; i < childIds.length; i++) {
                const child = getItem(items, childIds[i], true);
                const link = (
                    <g key={`link-${child.id}`} visibility={childrenVisibility}>
                        <MindLink
                            item={child}
                            parentX={x}
                            parentY={y}
                            parent={item}
                            structureKind={structureKind}
                            lineKind={lineKind}
                        />
                    </g>
                );
                elements.push(link);
                paintSubtree(child, showChildren, structureKind, item, x, y);
            }
        }

        const widgetRef = React.createRef<MindWidget>();
        widgetRefs.set(item.id, widgetRef);

        const widgetTransform = svgTransformString(x, y);
        const el = (
            <React.Fragment key={item.id}>
                <g
                    data-id={item.id}
                    data-kind={SvgElKinds.itemContainer}
                    className="mg-widget"
                    transform={widgetTransform}
                    visibility={selfVisibility}
                >
                    <MindWidget
                        ref={widgetRef}
                        item={item}
                        theme={theme}
                        handlers={handlers}
                        defaultStructureKind={structureKind}
                        svgHelper={svgHelper}
                    />
                </g>
            </React.Fragment>
        );
        elements.push(el);
    }

    const root = getItem(items, rootId, true);
    const rootStructureKind = getStructureKind(items, root);
    const rootX = root.relativePos ? -root.relativePos.x : 0;
    const rootY = root.relativePos ? -root.relativePos.y : 0;
    paintSubtree(root, true, rootStructureKind, undefined, rootX, rootY);

    return <React.Fragment>{...elements}</React.Fragment>;
});
