import { getDebug } from 'neka-common';
import * as React from 'react';
import { Component } from 'react';
import { Provider } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { NavLink, withRouter, Redirect } from 'react-router-dom';
import LoginPage from '../account/components/account-login';
import RegisterPage from '../account/components/account-register';
import BindAccountPage from '../account/components/account-bind';

import { AdminHomePane } from '../mods/admin/components/admin-home.pane';
import { AdminPage } from '../mods/admin/components/admin.page';
import { AccountPage } from '../components/account.page';
import { NoMatch } from '../components/common.helpers';
import DashboardPage from '../dashboard/components/dashboard.page';
import { UserInvitePage } from '../components/user-invite';
// import UserLoginPage from '../users/components/user-login.page';
import MindBenchPage from '../minds/components/mind-bench.page';
import { UserAddPane } from '../mods/admin/components/user-add.pane';
import { UserDetailsPane } from '../mods/admin/components/user-details.pane';
import { UserEditPane } from '../mods/admin/components/user-edit.pane';
import { UserListPane } from '../mods/admin/components/user-list.pane';
import { UserRegisterPane } from '../components/user-register.pane';
import { RouteUrls, localStoragePrincipal, localStorageIsRememberLogin, localStorageRedirectUrl } from './app-config';
import { nekaReduxStore } from './app-store';

const debug = getDebug('App.tsx');

/** 默认首页. */
const IndexContent: React.FunctionComponent<{}> = () => (
    <div>
        This is the applications's default view.
        <NavLink to={RouteUrls.users.register}>Register</NavLink>
        <NavLink to={RouteUrls.users.login}>Login</NavLink>
        <p>
            <NavLink to={RouteUrls.mind._base}>Mind Workbench</NavLink>
        </p>
    </div>
);

const UserRegisterPage: React.FunctionComponent = () => (
    <AccountPage>
        <UserRegisterPane />
    </AccountPage>
);
/*const UserLoginPage: React.FunctionComponent = () => (
    <AccountPage>
        <UserLoginPane />
    </AccountPage>
);*/

const AdminHomePage: React.FunctionComponent = () => (
    <AdminPage title="系统管理">
        <AdminHomePane />
    </AdminPage>
);
const UserEditPage: React.FunctionComponent = () => (
    <AdminPage title="修改用户">
        <UserEditPane />
    </AdminPage>
);
const UserDetailsPage: React.FunctionComponent = () => (
    <AdminPage title="用户信息">
        <UserDetailsPane />
    </AdminPage>
);
const UserAddPage: React.FunctionComponent = () => (
    <AdminPage title="新增用户">
        <UserAddPane />
    </AdminPage>
);
const UserListPage: React.FunctionComponent = () => (
    <AdminPage title="用户管理">
        <UserListPane />
    </AdminPage>
);
const UserProfilePage: React.FunctionComponent = () => (
    <AdminPage title="个人信息">
        <div>The profile page.</div>
    </AdminPage>
);

interface IPrivateRouteProps extends RouteComponentProps {
    path?: string;
    exact?: boolean;
    component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

// 判断路由的登录情况
class PrivateRoutes extends Component<IPrivateRouteProps, {}> {
    constructor(props: IPrivateRouteProps) {
        super(props);
    }

    render() {
        let { path, component } = this.props;
        if (localStoragePrincipal.load() && localStoragePrincipal.load()!.authInfo) {
            return <Route path={path} component={component} />;
        } else {
            let { location } = this.props;
            localStorageRedirectUrl.save({ url: location.pathname });
            return <Redirect to={RouteUrls.home} />;
        }
    }
}
const PrivateRoute = withRouter(PrivateRoutes);

// 判断路由的登录情况
class LoginedFilterRoutes extends Component<IPrivateRouteProps, {}> {
    constructor(props: IPrivateRouteProps) {
        super(props);
    }

    render() {
        let { path, component } = this.props;
        if (localStoragePrincipal.load() && localStoragePrincipal.load()!.authInfo) {
            return <Redirect to={RouteUrls.dashboard} />;
        } else {
            let { location } = this.props;
            if (path === RouteUrls.home || location.pathname === RouteUrls.users.login) {
                return <Redirect to={RouteUrls.users.mobileLogin} />;
            } else {
                return <Route exact={this.props.exact ? this.props.exact : false} path={path} component={component} />;
            }
        }
    }
}
const LoginedFilterRoute = withRouter(LoginedFilterRoutes);

// 未知路由的跳转
class NoMatchRoutes extends Component<IPrivateRouteProps, {}> {
    constructor(props: IPrivateRouteProps) {
        super(props);
    }

    render() {
        if (localStoragePrincipal.load() && localStoragePrincipal.load()!.authInfo) {
            return <Redirect to={RouteUrls.dashboard} />;
        } else {
            return <Redirect to={RouteUrls.users.mobileLogin} />;
        }
    }
}
const NoMatchRoute = withRouter(NoMatchRoutes);
// 获取cookie中浏览器打开当前网站的数量
const getOpenSiteCount = (): number => {
    let cookies = document.cookie;
    let index = cookies.indexOf('open_site_count');
    if (index < 0) return NaN;
    return parseInt(cookies.slice(index + 'open_site_count='.length).split(';')[0], 10);
};

// 修改cookie中浏览器打开当前网站的数量
const changeOpenSiteCount = (count: number, isOpenSite: boolean): void => {
    // let currentOpenSiteCount = isNaN(count) ? 0 : count;
    // let cookies = '';
    // if (isOpenSite) {
    //     cookies = `${currentOpenSiteCount + 1}`;
    // } else if (currentOpenSiteCount > 1) {
    //     cookies = `${currentOpenSiteCount - 1}`;
    // } else {
    //     cookies = `0;expires=${new Date(new Date().getTime() + 5000).toUTCString()}`;
    // }
    // document.cookie = `open_site_count=${cookies}; path=/`;
    document.cookie = `open_site_count=1; path=/`;
};

class App extends Component<RouteComponentProps, {}> {
    componentWillMount() {
        let count: number = getOpenSiteCount();
        if (!localStorageIsRememberLogin.load() && isNaN(count)) {
            localStoragePrincipal.clear();
            localStorageRedirectUrl.clear();
            localStorageIsRememberLogin.clear();
        }
        changeOpenSiteCount(count, true);
        // window.addEventListener('unload', function() {
        //     changeOpenSiteCount(getOpenSiteCount(), false);
        // });
    }

    public render() {
        return (
            <Provider store={nekaReduxStore}>
                <Switch>
                    <LoginedFilterRoute exact={true} path={RouteUrls.home} component={LoginPage} />
                    <LoginedFilterRoute path={RouteUrls.users.register} component={RegisterPage} />
                    <LoginedFilterRoute path={RouteUrls.users.bindAccount} component={BindAccountPage} />
                    <LoginedFilterRoute path={RouteUrls.users.login} component={LoginPage} />
                    <PrivateRoute exact={true} path={RouteUrls.users.invite} component={UserInvitePage} />
                    <PrivateRoute path={RouteUrls.dashboard} component={DashboardPage} />
                    {/* MindBench Routes */}

                    <PrivateRoute path={RouteUrls.mind.edit} component={MindBenchPage} />
                    {/*<Route path={RouteUrls.admin.userAdd} component={UserAddPage} />*/}
                    {/*<Route path={RouteUrls.admin.userEdit} component={UserEditPage} />*/}
                    {/*<Route path={RouteUrls.admin.userInfo} component={UserDetailsPage} />*/}
                    {/*<Route path={RouteUrls.admin.users} component={UserListPage} />*/}
                    {/*<Route path={RouteUrls.admin.home} component={AdminHomePage} />*/}
                    {/*<Route exact={true} path={RouteUrls.admin._base} component={AdminHomePage} />*/}
                    {/*<Route component={NoMatch} />*/}
                    <NoMatchRoute />
                </Switch>
            </Provider>
        );
    }
}

export default withRouter(App);
