import { StructureKind } from 'neka-common';
import React, { Component, SyntheticEvent } from 'react';
import { isDevelopment } from '../../core/app-config';
import { getDebug } from '../../core/app-diag';
import { IPos, ISize, NullPos } from '../mind-draw2d';
import { IMindItem, IMindItemStyles } from '../mind-models';
import { TextPadding, TopicIconSize } from '../mind-styles';
import { SvgDiscussionIcon } from './mind-item-icons';
import { MindItemBorder, MindItemGrip, MindItemText } from './mind-widget-parts';
import { SvgElKinds, SvgHelper, svgTransformString } from './svg-helper';

const debug = getDebug(__filename);

export type ItemEventHandler<E extends SyntheticEvent> = (item: IMindItem, event: E) => void;
export type ItemMouseEventHandler = ItemEventHandler<React.MouseEvent>;

export type ItemLayoutCompleter = () => ISize;
export type ItemLayoutPendingHandler = (item: IMindItem, completer: ItemLayoutCompleter) => void;

/**
 * 由上级组件传递给 Widget 的事件处理函数.
 * 这些事件处理器通常不会改变, 将其作为一个对象, 可以简化 shouldComponentUpdate 的比较.
 */
export interface IWidgetEventHandlers {
    onLayoutPending: ItemLayoutPendingHandler;
    onDiscussionIconPointerDown: React.MouseEventHandler;
}

interface IWidgetProps {
    item: IMindItem;
    theme: IMindItemStyles;
    handlers: IWidgetEventHandlers;
    defaultStructureKind: StructureKind;
    svgHelper: SvgHelper;
}

class IWidgetState {}

function getUpdateReason(
    currentProps: IWidgetProps,
    nextProps: IWidgetProps,
    currentState: IWidgetState,
    nextState: IWidgetState
): Array<keyof IWidgetProps | keyof IWidgetState> | undefined {
    const unequals = new Array<keyof IWidgetProps | keyof IWidgetState>();
    if (currentProps.item !== nextProps.item) unequals.push('item');
    if (currentProps.handlers !== nextProps.handlers) unequals.push('handlers');
    if (currentProps.defaultStructureKind !== nextProps.defaultStructureKind) unequals.push('defaultStructureKind');
    if (currentProps.svgHelper !== nextProps.svgHelper) unequals.push('svgHelper');

    return unequals.length > 0 ? unequals : undefined;
}

/** 代表脑图中的一个主题. */
export class MindWidget extends Component<IWidgetProps, IWidgetState> {
    public constructor(props: IWidgetProps) {
        super(props);
        this.state = {};
    }

    private registerSelfAsLayoutPending() {
        const { item, handlers } = this.props;
        if (handlers && handlers.onLayoutPending) {
            handlers.onLayoutPending(item, this.getTextSize);
        }
    }

    componentDidMount(): void {
        this.registerSelfAsLayoutPending();
    }

    shouldComponentUpdate(
        nextProps: Readonly<IWidgetProps>,
        nextState: Readonly<IWidgetState>,
        nextContext: any
    ): boolean {
        const currentProps = this.props;
        const currentState = this.state;

        if (isDevelopment) {
            const reason = getUpdateReason(currentProps, nextProps, currentState, nextState);
            if (reason) this.logWithId(`shouldComponentUpdate reason: [${reason.join(', ')}]`);
        }

        if (currentProps.item !== nextProps.item) {
            const nextItem = nextProps.item;
            const currItem = this.props.item;
            /**
             * !!! 注意: 不能只判定 nextProps.item.text !== this.props.item.text
             * 在切换文档时, this.props.item 可能为旧文档中的元素.
             * 从而存在 this.props.item.bodySize 已经有值,
             * 但在 render 过程中使用 nextProps.item.bodySize 出错.
             */
            if (
                nextItem.bodySize === undefined ||
                nextItem.text !== currItem.text ||
                nextItem.textFontFamily !== currItem.textFontFamily ||
                nextItem.textFontBold !== currItem.textFontBold ||
                nextItem.textFontSize !== currItem.textFontSize
            ) {
                this.registerSelfAsLayoutPending();
            }

            return true;
        }

        if (currentProps.handlers !== nextProps.handlers) return true;
        if (currentProps.svgHelper !== nextProps.svgHelper) return true;
        if (currentProps.defaultStructureKind !== nextProps.defaultStructureKind) return true;

        return false;
    }

    private getTextSize = () => {
        const { item, svgHelper } = this.props;
        if (!this.textRef.current) throw new Error(`The textRef is undefined for [${item.id}].`);

        // 如果text 为'' 或 ' ' (空白), 则 getBBox 返回的大小为 (0, 0), 此时用 "|" 来代替空白
        if (item.text && item.text.length > 0) {
            return this.textRef.current.getBBox();
        }

        return svgHelper.calcTextSize('|');
    };

    private textRef = React.createRef<SVGTextElement>();

    public render() {
        const { item, theme } = this.props;
        const { id, hasDiscussion, hasUnreadDiscussion } = item;
        const { onDiscussionIconPointerDown } = this.props.handlers;

        const isComplete = item.bodySize !== undefined && item.bodyPos !== undefined;
        this.logWithId(`render() isComplete: [${isComplete}].`);

        // 初次绘制时, bodySize 和 layout 可能尚未计算, 此时使用空值避免 reflow
        const regionPos = item.bodyPos || NullPos;

        const bodyTransform = svgTransformString(regionPos.x, regionPos.y);

        const discussionIconPos: IPos =
            hasDiscussion && item.textSize && item.bodySize
                ? {
                      x: item.textSize.width + TextPadding.left + TextPadding.right,
                      y: (item.bodySize.height - TopicIconSize) / 2,
                  }
                : NullPos;
        return (
            <g data-id={id} data-kind={SvgElKinds.itemWrapper}>
                <g data-id={id} data-kind={SvgElKinds.bodyWrapper} className="mg-body" transform={bodyTransform}>
                    <MindItemBorder item={item} theme={theme} />
                    <MindItemText ref={this.textRef} item={item} theme={theme} />
                    {hasDiscussion && (
                        <SvgDiscussionIcon
                            item={item}
                            onPointerDown={onDiscussionIconPointerDown}
                            {...discussionIconPos}
                        />
                    )}
                </g>
                <MindItemGrip item={item} defaultStructureKind={this.props.defaultStructureKind} />
            </g>
        );
    }

    private logWithId(...params: any[]): void {
        debug(this.props.item.id, ...params);
    }
}
