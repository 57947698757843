import * as React from 'react';
import { formConstant } from '../../constant';
import { showErrorNotification } from '../../../common/ui-utils';
import { apis } from '../../../core/server-apis';

interface IProps {
    mobile: string;
}

interface ISendSMSCodeState {
    smsCode: string;
    mobile: string;
}

let countDownInterval = null as any;

export default class SendSMSCode extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    state = {
        smsCode: '获取验证码',
        mobile: '',
    };

    // noinspection JSUnusedGlobalSymbols
    static getDerivedStateFromProps: React.GetDerivedStateFromProps<IProps, ISendSMSCodeState> = (
        nextProps,
        prevState
    ) => {
        return { ...prevState, mobile: nextProps.mobile };
    };

    sendSMSCode: React.EventHandler<React.MouseEvent> = () => {
        if (this.state.smsCode === '获取验证码') {
            this.postSMSCode(this.state.mobile);
        }
    };

    postSMSCode = async (num: string) => {
        try {
            await apis.account.getSMSVCode(num);
            let count: number = 60;
            this.setState({ smsCode: `重新获取（60）` });
            if (countDownInterval) {
                clearInterval(countDownInterval);
            }
            countDownInterval = setInterval(() => {
                count -= 1;
                this.setState({ smsCode: `重新获取（${count}）` });
                if (count === 0) {
                    clearInterval(countDownInterval);
                    this.setState({ smsCode: '获取验证码' });
                }
            }, 1000);
        } catch (e) {
            showErrorNotification(e);
        }
    };

    componentWillUnmount(): void {
        if (countDownInterval) {
            clearInterval(countDownInterval);
        }
    }

    render() {
        let clickable: boolean =
            !!this.state.mobile &&
            !!this.state.mobile.match(formConstant.validateRules.mobileRegexp) &&
            this.state.smsCode === '获取验证码';
        return (
            <div
                onClick={this.sendSMSCode}
                className={clickable ? 'nw-smscode-button nw-smscode-button-clickable' : 'nw-smscode-button'}
            >
                {this.state.smsCode}
            </div>
        );
    }
}
