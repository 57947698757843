import { IDiscussionPost, IDiscussionReply } from 'neka-common';
import { RdxReducerComposer } from '../../common/redux-utils';
import { getDebug } from '../../core/app-diag';
import { IDiscussionView } from '../discussion-model';

const debug = getDebug(__filename);

/** 注册所有 IPostTopic 的 reducer */
export const discussionReducerComposer = new RdxReducerComposer<IDiscussionView>('DISCUSSION');

/** 加载 帖子 列表. */
export const FetchDiscussionListComplete = discussionReducerComposer.registerHandler(
    'FETCH_DISCUSSION_LIST',
    (discussions: IDiscussionView) => ({ discussions }),
    (state, payload) => {
        return payload.discussions;
    }
);

/** 加载 把一个帖子合并到列表. */
export const MergeDiscussionToArray = discussionReducerComposer.registerHandler(
    'MERGE_DISCUSSIONS',
    (discussion: IDiscussionPost) => ({ discussion }),
    (state, payload) => {
        return {
            ...state,
            discussions: [payload.discussion, ...state.discussions!],
        };
    }
);

/**  把一个回复合并到列表. */
export const MergeReplyToArray = discussionReducerComposer.registerHandler(
    'MERGE_DISCUSSIONS_REPLY',
    (reply: IDiscussionReply) => ({ reply }),
    (state, payload) => {
        let discussions = new Array<IDiscussionPost>();
        if (state.discussions && state.discussions.length > 0) {
            discussions = [...state.discussions];
            discussions = discussions.map(discuss => {
                if (discuss.id === payload.reply.postId) {
                    if (!discuss.replies) {
                        discuss.replies = [payload.reply];
                    } else {
                        discuss.replies = [...discuss.replies, payload.reply];
                    }
                }
                return discuss;
            });
            return {
                ...state,
                discussions: discussions,
            };
        } else {
            return state;
        }
    }
);

// 设置一个帖子的状态= =>>是否解决
export const SetDiscussState = discussionReducerComposer.registerHandler(
    'SET_DISCUSS_STATE',
    (id: string, isSolved: boolean) => ({ id, isSolved }),
    (state, payload) => {
        let discussions = new Array<IDiscussionPost>();
        if (state.discussions && state.discussions.length > 0) {
            discussions = [...state.discussions];
            discussions = discussions.map(discuss => {
                if (discuss.id === payload.id) {
                    discuss.isSolved = payload.isSolved;
                }
                return discuss;
            });
            return {
                ...state,
                discussions: discussions,
            };
        } else {
            return state;
        }
    }
);

// 设置一个回复的状态= =>>是否采纳
export const SetReplyState = discussionReducerComposer.registerHandler(
    'SET_REPLY_STATE',
    (postId: string, id: string, isAdopt: boolean) => ({ postId, id, isAdopt }),
    (state, payload) => {
        let discussions = new Array<IDiscussionPost>();
        if (state.discussions && state.discussions.length > 0) {
            discussions = [...state.discussions];
            discussions = discussions.map(discuss => {
                if (discuss.id === payload.postId && discuss.replies) {
                    discuss.replies = discuss.replies.map(reply => {
                        if (reply.id === payload.id) {
                            reply.isAdopt = payload.isAdopt;
                        }
                        return reply;
                    });
                }
                return discuss;
            });
            return {
                ...state,
                discussions: discussions,
            };
        } else {
            return state;
        }
    }
);
