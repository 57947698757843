import * as React from 'react';

import './check-browser.less';

export default class CheckBrowserPage extends React.Component<{}, {}> {
    public render() {
        return (
            <div
                title="点击去下载谷歌浏览器"
                className="not-chrome"
                onClick={() => {
                    window.location.href = 'https://www.google.cn/chrome/';
                }}
            >
                <div className="icon">
                    <span>脑问</span>
                </div>
                抱歉！目前网站仅支持<a href="https://www.google.cn/chrome/">谷歌浏览器</a>， 请更换浏览器后再尝试
            </div>
        );
    }
}
