import { Avatar, Badge, Dropdown, Icon, Menu, Modal } from 'antd';
import { IPrincipal } from 'neka-common';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import Unbind from '../account/components/common/test-unbind';
import { IDispatch } from '../common/redux-utils';
import { isDevelopment, localStoragePrincipal, RouteUrls } from '../core/app-config';
import { IRootState, logOut } from '../store/app-redux';

import './main-bar.less';

interface IProps extends RouteComponentProps, IDispatch {
    leftView: JSX.Element;
    principal?: IPrincipal;
}

interface IState {
    anchorEl: HTMLElement | null;
}

class MainBarView extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    componentWillUnmount(): void {
        // 销毁所有modal
        Modal.destroyAll();
    }

    private handleDropdownClick: React.MouseEventHandler<HTMLElement> = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    private handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    private handleProfileClick: React.MouseEventHandler<HTMLElement> = event => {
        this.handleMenuClose();
        this.props.history.push(RouteUrls.users.profile);
    };

    private handleAdminClick = (event: any) => {
        this.handleMenuClose();
        this.props.history.push(RouteUrls.admin.home);
    };

    public render() {
        const { anchorEl } = this.state;
        const isOpen = Boolean(anchorEl);
        const { principal, history, leftView } = this.props;
        const menu = (
            <Menu>
                {/*                <Menu.Item key="0">
                    <a target="_blank" rel="noopener noreferrer" href="javascript:;">
                        账号设置
                    </a>
                </Menu.Item>
                {isDevelopment && (
                    <Menu.Item key="3" onClick={this.handleAdminClick}>
                        系统管理
                    </Menu.Item>
                )}
                <Menu.Divider />*/}
                <Menu.Item key="1">
                    <a href="#" onClick={this.handleLogoutClick}>
                        退出
                    </a>
                </Menu.Item>
                {/*                <Menu.Item key="2">
                    <Unbind />
                </Menu.Item>*/}
            </Menu>
        );
        return (
            <div className="main-appbar">
                <div className="left-view">{leftView}</div>
                <div className="bar-right-handler">
                    <div className="notice">
                        <Badge count={5}>
                            <a href="#" className="notice-icon" />
                        </Badge>
                    </div>
                    <div className="user-avator">
                        <Avatar size={24} src={principal && principal.avatar ? principal.avatar : ''} icon="user" />
                    </div>
                    <div className="dropdown-handler">
                        <Dropdown overlay={menu}>
                            <a className="ant-dropdown-link" href="#">
                                {principal!.nickname} <Icon type="down" />
                            </a>
                        </Dropdown>
                    </div>
                </div>
            </div>
        );
    }

    private handleLogoutClick = (event?: React.MouseEvent<any>): void => {
        Modal.confirm({
            title: '确认退出登录?',
            onOk: () => {
                this.props.dispatch(logOut());
                localStoragePrincipal.clear();
                this.props.history.push(RouteUrls.home);
            },
        });
    };
}

export const MainBar = connect((state: IRootState) => ({
    principal: state.principal,
}))(withRouter(MainBarView));
