// *********************************************************
// 脑图的布局算法.
// *********************************************************
import { NotImplementedException, StructureKind, StructureKinds } from 'neka-common';
import { IPos, IRect, ISize } from './mind-draw2d';
import {
    getItem,
    getStructureKind,
    IMindItem,
    IMindTree,
    ItemGet,
    ItemGetSet,
    itemOf,
    ItemOrId,
    MindId,
    MindItemNotFoundException,
    MindItemStateException,
    SNoBodyPosErr,
    SNoBodySizeErr,
    SNoInPortError,
    SNoRegionSizeErr,
    SNoRelativePosErr,
    WithId,
} from './mind-models';
import { LogicRightStyles, OrgDownStyles } from './mind-styles';

// =========================================================
// Item Map 的查找函数
// =========================================================

/**
 * 代表对一个 {@link IMindItem} 的布局信息.
 * 不直接使用 {@link IMindItem}, 以防止意外更改与布局无关的属性.
 */
export type ItemLayout = Pick<IMindItem, 'id'> & {
    relativePos?: IPos;
    regionSize?: ISize;
    bodyPos?: IPos;
    poleStartPos?: IPos;
    gripPos?: IPos;
    inPortPos?: IPos;
    outPortPos?: IPos;
};

type ItemLayoutMap = Map<MindId, ItemLayout>;

/** 代表 {@link layoutItems} 的返回值. */
export interface ILayoutResult {
    layouts: Map<MindId, ItemLayout>;
}

/** 需要计算布局的结点的范围. `Subtree` 代表当前结点及其所有子孙结点, `Upward` 代表当前结点及其所有祖先结点. */
export type LayoutScopes = 'Subtree' | 'Upward';
/** 帮助 IDE 构建自动提示. */
export const LayoutScopes = Object.freeze({
    Subtree: 'Subtree' as 'Subtree',
    Upward: 'Upward' as 'Upward',
});

/**
 * 根据 `layoutScope` 计算相关结点布局信息.
 * 如果结点的布局信息发生变化, 该方法会创建新的 {@link IMindItem} 并置于 `items`.
 */
export function layoutItems(id: MindId, items: ItemGetSet, layoutScope: LayoutScopes): ItemLayoutMap {
    const changes: ItemLayoutMap = new Map<MindId, ItemLayout>();

    const layoutScopeFn = getLayoutScopeFn(layoutScope);
    layoutScopeFn(changes, id, items);
    applyLayoutResult(items, changes);

    return changes;
}

// =========================================================
// 结点范围算法
// =========================================================

type LayoutScopeFn = (changes: ItemLayoutMap, id: MindId, items: ItemGet) => void;

function getLayoutScopeFn(layoutScope: LayoutScopes): LayoutScopeFn {
    switch (layoutScope) {
        case LayoutScopes.Subtree:
            return layoutDeepFirstScope;
        case LayoutScopes.Upward:
            return layoutUpwardScope;
        default:
            throw new NotImplementedException(`layoutScope [${layoutScope}]`);
    }
}

/**
 * 以深度优先方式, 计算以 {@link item} 为根所的子树中所有结点的布局.
 * 其布局的结果存放于 {@param changes} 中.
 * @param changes 收集应更新的结点的布局信息
 * @param id 子树的根节点的 {@link IMindItem.id}
 * @param items 用于查找结点相关信息
 */
function layoutDeepFirstScope(changes: ItemLayoutMap, id: MindId, items: ItemGet): void {
    const item = getItem(items, id, true);

    // deep-first layout sub-tree
    const childIds = item.childIds;
    if (childIds && childIds.length > 0) {
        childIds.forEach(childId => {
            layoutDeepFirstScope(changes, childId, items);
        });
    }

    // 因采用深度优先, 所有此时子孙结点的布局已经完成.
    layoutItem(changes, item, items);
}

/**
 * 计算从 `id` 对应的结点开始, 依次向祖先结点布局. 它通常用在单个结点插入/删除/改变后.
 * 其布局的结果存放于 {@param changes} 中.
 * @param changes 收集应更新的结点的布局信息
 * @param id 子树的根节点的 {@link IMindItem.id}
 * @param items 用于查找结点相关信息
 */
function layoutUpwardScope(changes: ItemLayoutMap, id: MindId, items: ItemGet): void {
    let item: IMindItem | undefined = getItem(items, id, true);

    while (item !== undefined) {
        layoutItem(changes, item, items);

        item = item.parentId !== undefined ? getItem(items, item.parentId, true) : undefined;
    }
}

// =========================================================
// 单个结点布局
// =========================================================

/** 代表布局策略 ({@link LayoutStrategy}) 的返回值. */
interface ILayoutOutput {
    selfChange: ItemLayout;
    childChanges: Array<ItemLayout>;
}

/** 布局策略. 一个布局策略应当只涉及当前结点及其直接子结点的布局信息. */
type LayoutStrategy = (changes: ItemLayoutMap, item: IMindItem, items: ItemGet) => void;

/** 计算单个结点的布局信息. */
function layoutItem(changes: ItemLayoutMap, item: IMindItem, items: ItemGet): void {
    const layoutStrategy = getLayoutStrategy(items, item); // 获取当前结点上的布局策略
    layoutStrategy(changes, item, items); // 使用该布局策略, 计算结点的布局信息
}

/** 获取布局策略. */
function getLayoutStrategy(items: ItemGet, item: IMindItem): LayoutStrategy {
    const structureKind = getStructureKind(items, item);
    switch (structureKind) {
        case StructureKinds.LogicRight:
            return logicRight;
        case StructureKinds.OrgDown:
            return orgDown;
        default:
            throw new Error(`Unknown strategy [${structureKind}] for item [${item.id}].`);
    }
}

// =========================================================
// 布局策略
// =========================================================

/** 获取对结点及其直接子结点 logic-right 布局的结果. */
function logicRight(changes: ItemLayoutMap, item: IMindItem, items: ItemGet): void {
    if (!item.bodySize) throw new MindItemStateException(item.id, SNoBodySizeErr);

    const options = LogicRightStyles;
    const { id, bodySize } = item;

    let childrenHeight = 0; // 所有子节点的总高度
    let widthExtends = 0; // 因子结点而扩大的宽度
    const childChanges = new Array<ItemLayout>();

    if (item.childIds && item.childIds.length > 0) {
        if (item.isExpanded) {
            widthExtends += options.hSpace;

            const childrenLeft = bodySize.width + widthExtends;

            let maxChildWidth = 0; // 所有子节点的最大宽度, 它等同于所有子结点的总宽度
            item.childIds.forEach((childId, index) => {
                if (index > 0) childrenHeight += options.vSpace; //  设置两个纵向子结点的间距

                const child = getItem(items, childId, true);
                const childLayout = getOrCreateItemLayoutIn(changes, childId);
                childChanges.push(childLayout);

                const childRegionSize = childLayout.regionSize || child.regionSize;
                if (!childRegionSize) throw new MindItemStateException(childId, SNoRegionSizeErr);
                const childBodyPos = childLayout.bodyPos || child.bodyPos;
                if (!childBodyPos) throw new MindItemStateException(childId, SNoBodyPosErr);
                if (!child.bodySize) throw new MindItemStateException(childId, SNoBodySizeErr);

                childLayout.relativePos = { x: childrenLeft, y: childrenHeight };
                childLayout.inPortPos = calcInPort(childBodyPos, child.bodySize, StructureKinds.LogicRight);

                maxChildWidth = Math.max(maxChildWidth, childRegionSize.width);
                childrenHeight += childRegionSize.height;
            });

            widthExtends += maxChildWidth;
        } else {
            widthExtends += options.gripSpace + options.gripHalf * 2;
        }
    }

    const regionSize = {
        width: bodySize.width + widthExtends,
        height: Math.max(bodySize.height, childrenHeight),
    };

    const bodyPos: IPos = { x: 0, y: 0 };
    // 将 body.y 设置为所有子结点的中间位置
    if (childrenHeight < regionSize.height) {
        const yOffset = (regionSize.height - childrenHeight) / 2;
        childChanges.forEach(child => {
            if (!child.relativePos) throw new MindItemStateException(child.id, SNoRelativePosErr);
            child.relativePos.y += yOffset;
        });
        bodyPos.y = 0;
    } else {
        const inPortsCenter = getChildrenInPortCenter(childChanges);
        bodyPos.y = inPortsCenter ? inPortsCenter.y - bodySize.height / 2 : (regionSize.height - bodySize.height) / 2;
        if (bodyPos.y < 0) {
            const yOffset = -bodyPos.y;
            regionSize.height += yOffset;
            bodyPos.y = 0;
            childChanges.forEach(child => {
                if (!child.relativePos) throw new MindItemStateException(child.id, SNoRelativePosErr);
                child.relativePos.y += yOffset;
            });
        }
    }

    const itemLayout = getOrCreateItemLayoutIn(changes, id);
    itemLayout.regionSize = regionSize;
    itemLayout.bodyPos = bodyPos;
    itemLayout.poleStartPos = {
        x: bodyPos.x + bodySize.width,
        y: bodyPos.y + bodySize.height / 2,
    };
    itemLayout.gripPos = {
        x: bodyPos.x + bodySize.width + options.gripSpace,
        y: bodyPos.y + bodySize.height / 2,
    };
    itemLayout.outPortPos = {
        x: itemLayout.gripPos.x + options.gripHalf,
        y: itemLayout.gripPos.y,
    };
}

function orgDown(changes: ItemLayoutMap, item: IMindItem, items: ItemGet): void {
    if (!item.bodySize) throw new MindItemStateException(item.id, SNoBodySizeErr);

    const options = OrgDownStyles;
    const { id, bodySize } = item;

    let childrenWidth = 0; // 所有子节点的总宽度
    let heightExtends = 0; // 因子结点而扩大的高度
    const childChanges = new Array<ItemLayout>();

    if (item.childIds && item.childIds.length > 0) {
        // 当本结点展开时, 其 region 包含子结点, 否则包含展开符
        if (item.isExpanded) {
            heightExtends += options.vSpace;

            const childrenTop = bodySize.height + heightExtends;

            let maxChildHeight = 0; // 所有子节点的最大高度, 它等同于所有子结点的总高度
            item.childIds.forEach((childId, index) => {
                if (index > 0) childrenWidth += options.hSpace; //  设置两个纵向子结点的间距

                const child = getItem(items, childId, true);
                const childLayout = getOrCreateItemLayoutIn(changes, childId);
                childChanges.push(childLayout);

                const childRegionSize = childLayout.regionSize || child.regionSize;
                if (!childRegionSize) throw new MindItemStateException(childId, SNoRegionSizeErr);
                const childBodyPos = childLayout.bodyPos || child.bodyPos;
                if (!childBodyPos) throw new MindItemStateException(childId, SNoBodyPosErr);
                if (!child.bodySize) throw new MindItemStateException(childId, SNoBodySizeErr);

                childLayout.relativePos = { x: childrenWidth, y: childrenTop };
                childLayout.inPortPos = calcInPort(childBodyPos, child.bodySize, StructureKinds.OrgDown);

                maxChildHeight = Math.max(maxChildHeight, childRegionSize.height);
                childrenWidth += childRegionSize.width;
            });

            heightExtends += maxChildHeight;
        } else {
            heightExtends += options.gripSpace + options.gripHalf * 2;
        }
    }

    const regionSize = {
        width: Math.max(bodySize.width, childrenWidth),
        height: bodySize.height + heightExtends,
    };

    const bodyPos: IPos = { x: 0, y: 0 };
    // 将 body.x 设置为所有子结点的中间位置
    if (childrenWidth < regionSize.width) {
        const xOffset = (regionSize.width - childrenWidth) / 2;
        childChanges.forEach(child => {
            if (!child.relativePos) throw new MindItemStateException(child.id, SNoRelativePosErr);
            child.relativePos.x += xOffset;
        });
        bodyPos.x = 0;
    } else {
        const inPortsCenter = getChildrenInPortCenter(childChanges);
        bodyPos.x = inPortsCenter ? inPortsCenter.x - bodySize.width / 2 : (regionSize.width - bodySize.width) / 2;
        if (bodyPos.x < 0) {
            const xOffset = -bodyPos.x;
            regionSize.width += xOffset;
            bodyPos.x = 0;
            childChanges.forEach(child => {
                if (!child.relativePos) throw new MindItemStateException(child.id, SNoRelativePosErr);
                child.relativePos.x += xOffset;
            });
        }
    }

    const itemLayout = getOrCreateItemLayoutIn(changes, id);
    itemLayout.regionSize = regionSize;
    itemLayout.bodyPos = bodyPos;
    itemLayout.poleStartPos = {
        x: bodyPos.x + bodySize.width / 2,
        y: bodyPos.y + bodySize.height,
    };
    itemLayout.gripPos = {
        x: bodyPos.x + bodySize.width / 2,
        y: bodyPos.y + bodySize.height + options.gripSpace,
    };
    itemLayout.outPortPos = {
        x: itemLayout.gripPos.x,
        y: itemLayout.gripPos.y + options.gripHalf,
    };
}

/** 计算 @{link IMindItem.inPort}. */
function calcInPort(bodyPos: IPos, bodySize: ISize, parentStructureKind: StructureKind): IPos {
    switch (parentStructureKind) {
        case StructureKinds.LogicRight:
            return {
                x: bodyPos.x,
                y: bodyPos.y + bodySize.height / 2,
            };
        case StructureKinds.OrgDown:
            return {
                x: bodyPos.x + bodySize.width / 2,
                y: bodyPos.y,
            };
        default:
            throw new NotImplementedException(`structureKind [${parentStructureKind}].`);
    }
}

/** 返回所有子结点的 inPort 的中点 (相对于父节点的). 当没有子结点时返回 undefined. */
function getChildrenInPortCenter(withRelativePoses: Array<ItemLayout>): IPos | undefined {
    if (!withRelativePoses || withRelativePoses.length === 0) return undefined;

    const firstChild = withRelativePoses[0];
    if (firstChild.relativePos === undefined) throw new MindItemStateException(firstChild.id, SNoRelativePosErr);
    if (firstChild.inPortPos === undefined) throw new MindItemStateException(firstChild.id, SNoInPortError);
    const firstInPort = firstChild.inPortPos;
    const firstPos = relativeInPortPos(firstChild.relativePos, firstInPort);

    const lastChild = withRelativePoses[withRelativePoses.length - 1];
    if (lastChild.relativePos === undefined) throw new MindItemStateException(lastChild.id, SNoRelativePosErr);
    if (lastChild.inPortPos === undefined) throw new MindItemStateException(lastChild.id, SNoInPortError);
    const lastInPort = lastChild.inPortPos;
    const lastPos = relativeInPortPos(lastChild.relativePos, lastInPort);

    return {
        x: (firstPos.x + lastPos.x) / 2,
        y: (firstPos.y + lastPos.y) / 2,
    };
}

function relativeInPortPos(relativePos: IPos, inPort: IPos): IPos {
    return {
        x: relativePos.x + inPort.x,
        y: relativePos.y + inPort.y,
    };
}

// =========================================================
// 辅助类/函数
// =========================================================

/** 获取或创建一个 {@link ItemLayout}. */
function getOrCreateItemLayoutIn(changes: ItemLayoutMap, id: MindId): ItemLayout {
    const change = changes.get(id);
    if (change) return change;

    const created = { id };
    changes.set(id, created);
    return created;
}

export function absoluteRegionPos(tree: IMindTree, itemOrId: ItemOrId): IPos | undefined;
export function absoluteRegionPos(tree: IMindTree, itemOrId: ItemOrId, required: false): IPos | undefined;
export function absoluteRegionPos(tree: IMindTree, itemOrId: ItemOrId, required: true): IPos;
export function absoluteRegionPos(tree: IMindTree, itemOrId: ItemOrId, required?: boolean): IPos | undefined {
    const { items, rootId } = tree;

    let x = 0,
        y = 0;

    let item: IMindItem | undefined = itemOf(items, itemOrId);
    while (item && item.id !== rootId) {
        if (!item.relativePos) {
            if (required) throw new MindItemStateException(item.id, SNoRelativePosErr);
            return undefined;
        }

        x += item.relativePos.x;
        y += item.relativePos.y;

        item = item.parentId ? getItem(items, item.parentId, true) : undefined;
    }
    return { x, y };
}

export function absoluteBodyPos(tree: IMindTree, itemOrId: ItemOrId): IRect | undefined;
export function absoluteBodyPos(tree: IMindTree, itemOrId: ItemOrId, required: false): IPos | undefined;
export function absoluteBodyPos(tree: IMindTree, itemOrId: ItemOrId, required: true): IPos;
export function absoluteBodyPos(tree: IMindTree, itemOrId: ItemOrId, required?: boolean): IPos | undefined {
    const { items } = tree;
    const item = itemOf(items, itemOrId);
    const regionPos = required ? absoluteRegionPos(tree, itemOrId, true) : absoluteRegionPos(tree, itemOrId, false);
    if (!regionPos) {
        if (required) throw new MindItemStateException(item.id, `One or more relativePos missing in path.`);
        return undefined;
    }
    if (!item.bodyPos) {
        if (required) throw new MindItemStateException(item.id, SNoBodyPosErr);
        return undefined;
    }
    return {
        x: regionPos.x + item.bodyPos.x,
        y: regionPos.y + item.bodyPos.y,
    };
}

export function absoluteBodyRect(tree: IMindTree, itemOrId: ItemOrId): IRect | undefined;
export function absoluteBodyRect(tree: IMindTree, itemOrId: ItemOrId, required: false): IRect | undefined;
export function absoluteBodyRect(tree: IMindTree, itemOrId: ItemOrId, required: true): IRect;
export function absoluteBodyRect(tree: IMindTree, itemOrId: ItemOrId, required?: boolean): IRect | undefined {
    const item = itemOf(tree.items, itemOrId);
    if (!item.bodySize) {
        if (required) throw new MindItemStateException(item.id, SNoBodyPosErr);
        return undefined;
    }

    const pos = absoluteBodyPos(tree, item);
    if (!pos) {
        if (required) throw new MindItemStateException(item.id, `One or more bodyPos is undefined in its path.`);
        return undefined;
    }

    return {
        ...pos,
        ...item.bodySize,
    };
}

export function relativeToRegion(tree: IMindTree, absolutePos: IPos, id: MindId): IPos {
    const absItemRegion = absoluteRegionPos(tree, id, true);
    return {
        x: absolutePos.x - absItemRegion.x,
        y: absolutePos.y - absItemRegion.y,
    };
}

export function relativeToBody(tree: IMindTree, absolutePos: IPos, id: MindId): IPos {
    const absItemRegion = absoluteRegionPos(tree, id, true);
    const item = getItem(tree.items, id, true);
    if (!item.bodyPos) throw new MindItemStateException(id, SNoBodyPosErr);

    return {
        x: absolutePos.x - (absItemRegion.x + item.bodyPos.x),
        y: absolutePos.y - (absItemRegion.y + item.bodyPos.y),
    };
}

export function applyBodySizes<T extends WithId>(map: ItemGetSet<T>, bodySizes: ReadonlyMap<MindId, ISize>) {
    bodySizes.forEach((bodySize, id) => {
        applyBodySize(map, id, bodySize);
    });
}

export function applyBodySize<T extends WithId>(map: ItemGetSet<T>, id: MindId, bodySize: ISize) {
    const item: T | undefined = map.get(id);
    if (!item) throw new MindItemNotFoundException(id);
    const newItem: T = { ...(item as any), bodySize: bodySize };
    map.set(id, newItem);
}

function applyLayoutResult<T extends WithId>(items: ItemGetSet<T>, changes: ItemLayoutMap): void {
    changes.forEach(change => {
        const { id } = change;
        const origin = getItem(items, id, true);
        items.set(id, { ...(origin as any), ...change });
    });
}
