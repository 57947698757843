import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { IDispatch } from '../../../common/redux-utils';
import WechatView from '../common/account-wechatview';

export interface IProps extends IDispatch, RouteComponentProps {}

class WechatLogin extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    render() {
        return (
            <div className="nw-login-content-center">
                <WechatView currentPage="login" />
                <div className="nw-other-login">
                    <Link to={'/users/login/mobile'}>使用其他方式登录</Link>
                </div>
            </div>
        );
    }
}

const WechatLoginPage = connect()(WechatLogin);
export default WechatLoginPage;
