import { ArgumentMissingException } from './ex-utils';
import { IClassOf } from './sys-utils';
import deepFreeze = require('deep-freeze');

/**
 * Defines an enum type from a constant.
 * @see https://github.com/Hotell/rex-tils/blob/master/src/utils/functions.ts
 * @example
 * ```ts
 * // $ExpectType Readonly<{ No: "No"; Yes: "Yes"; }>
 * export const AnswerResponse = Enum('No', 'Yes')
 * // $ExpectType 'No' | 'Yes'
 * export type AnswerResponse = Enum(typeof AnswerResponse)
 * ```
 */
export type Enum<T extends object> = T[keyof T];

export type Mutable<T> = { -readonly [P in keyof T]: T[P] };

export type DeepPartial<T> = { [K in keyof T]?: DeepPartial<T[K]> };
export type DeepReadonly<T> = { readonly [K in keyof T]: DeepReadonly<T[K]> };

// tslint:disable-next-line
interface DeepMutableArray<T> extends Array<DeepMutable<T>> {}
type DeepMutableObject<T> = { -readonly [K in keyof T]: DeepMutable<T[K]> };
export type DeepMutable<T> = T extends Array<infer B> ? DeepMutableArray<B> : DeepMutableObject<T>;

export function pluck<T, K extends keyof T>(o: T, name: K): T[K] | undefined {
    return o[name];
}

/** Freeze an object without marking its type as readonly. */
export function deepLock<T>(o: T): T {
    return (deepFreeze(o) as any) as T;
}

/**
 * Represents a function which converts an instance of the source type (`S`) to the target type (`T`).
 * @returns the instance of target type, or `undefined` if no conversion is not applicable.
 */
export type ConvertFn<S, T> = (obj: S) => T | undefined;

export class CompositeConverter<S, T> {
    private readonly converters: Array<ConvertFn<S, T>> = [];

    /**
     * Converts an object to the target type.
     * @returns the instance of target type, or {@link undefined} if no conversion is not applicable.
     */
    public convert(s: S): T | undefined {
        for (let c of this.converters) {
            const result = c(s);
            if (result !== undefined) return result;
        }
        return undefined;
    }

    public byType<DerivedS extends S>(type: IClassOf<DerivedS>, convert: ConvertFn<DerivedS, T>): this {
        if (!type) throw new ArgumentMissingException('type');
        if (!convert) throw new ArgumentMissingException('convert');

        const item = (s: S) => {
            const isMatched: boolean = s instanceof type;
            return isMatched ? convert(s as DerivedS) : undefined;
        };
        return this.use(item);
    }

    public use(convert: ConvertFn<S, T>): this {
        if (!convert) throw new ArgumentMissingException('convert');

        this.converters.push(convert);
        return this;
    }
}

export type ObjectOrProvider<T> = T | (() => T);

export function getProvided<T extends Object>(provider: ObjectOrProvider<T>): T {
    if (provider === undefined) throw new Error(`provider is undefined.`);
    switch (typeof provider) {
        case 'object':
            return provider;
        case 'function':
            return (provider as (() => T))();
        default:
            throw new Error(`Unsupported provider of type [${typeof provider}].`);
    }
}

export type ObjectOrUpdater<T> = T | ((obj: T) => void);

export function applyUpdater<T extends Object>(obj: T, updater?: ObjectOrUpdater<T>): T {
    if (typeof obj !== 'object') throw new Error(`obj is not an object.`);
    if (updater) {
        switch (typeof updater) {
            case 'object':
                Object.assign(obj, updater);
                break;
            case 'function':
                (updater as (obj: T) => void)(obj);
                break;
            default:
                throw new Error(`Not supported setup of type ${typeof updater}`);
        }
    }
    return obj;
}
