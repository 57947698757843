import { message, Modal, notification } from 'antd';
import { delay, IMindDocument, IMindDocumentEntity, IPrincipal } from 'neka-common';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { IDispatch, IThunkDispatch } from '../../common/redux-utils';
import { showErrorDialog, showErrorNotification } from '../../common/ui-utils';
import { MainBar } from '../../components/main-bar';
import { RouteUrls } from '../../core/app-config';
import { getDebug } from '../../core/app-diag';
import { IRootState } from '../../store/app-redux';
import { IDashboard } from '../dashboard-model';
import { CreateNewDocumentAsync, DeleteDocumentAsync, FetchDocumentListAsync } from '../store/dashboard-redux';

import './dashboard.less';
import DocumentListPane from './document-list.pane';

const debug = getDebug(__filename);

interface IProps extends RouteComponentProps, IThunkDispatch {
    principal: IPrincipal;
    dashboard: IDashboard;
}

interface IState {
    docs?: IMindDocument[];
    isCreating: boolean;
}

class DashboardPageView extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isCreating: false,
        };
    }

    async componentDidMount() {
        const { principal, dispatch } = this.props;
        try {
            // TODO: handle 401 (Unauthorized) more user friendly
            await dispatch(FetchDocumentListAsync(principal));
        } catch (reason) {
            console.error(reason);
            showErrorDialog(reason);
        }
    }

    public render() {
        return (
            <div className="nw-dashboard">
                <MainBar
                    leftView={
                        <div className="logo">
                            <span>脑问</span>
                        </div>
                    }
                />
                <div className="main">
                    <div className="left-sidebar">
                        <div className="sidebar-item">脑图方案</div>
                    </div>
                    <div className="content">
                        <DocumentListPane
                            dashboard={this.props.dashboard}
                            principal={this.props.principal}
                            onOpenDocument={this.handleOpenDocument}
                            onDeleteDocument={this.handleDeleteDocument}
                        />
                    </div>
                    <div className="right-sidebar">
                        <button disabled={this.state.isCreating} onClick={this.handleCreateNew}>
                            新建
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    private handleCreateNew: React.MouseEventHandler = async event => {
        const { principal, dispatch, history } = this.props;
        try {
            this.setState({ isCreating: true });
            const document = await dispatch(CreateNewDocumentAsync(principal));
            this.setState({ isCreating: false });
            history.push(RouteUrls.mind.editUrl(document.id));
        } catch (reason) {
            showErrorDialog(reason);
        }
    };

    private handleOpenDocument = (documentId: string) => {
        const { history } = this.props;
        history.push(RouteUrls.mind.editUrl(documentId));
    };

    private handleDeleteDocument = (document: IMindDocumentEntity) => {
        const { dispatch } = this.props;
        Modal.confirm({
            title: `删除文档`,
            content: `您确信要删除文档 ${document.title} 吗?`,
            onOk() {
                dispatch(DeleteDocumentAsync(document.id))
                    .then(() => {
                        notification.info({ message: `文档 ${document.title} 已删除.` });
                    })
                    .catch(reason => {
                        showErrorNotification(reason);
                    });
            },
            onCancel() {
                // noop
            },
        });
    };
}

const connected = connect((state: IRootState) => ({
    principal: state.principal,
    dashboard: state.dashboard,
}))(DashboardPageView);
const routed = withRouter(connected);

export default routed;
