import { Button, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { IUser } from 'neka-common';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { IDispatch } from '../../../common/redux-utils';
import { getErrorText, showErrorNotification } from '../../../common/ui-utils';
import { RouteUrls } from '../../../core/app-config';
import { apis } from '../../../core/server-apis';

interface IViewProps {
    /** A list of users to be displayed. */
    users: Array<IUser>;
    onRefresh: () => void;
    onAdd: () => void;
    onDelete: (user: IUser) => void;
    onEdit: (user: IUser) => void;
    onDetails: (user: IUser) => void;
}

const UserListPaneView = (props: IViewProps) => {
    const columns: ColumnProps<IUser>[] = [
        { key: 'id', title: 'id', dataIndex: 'id' },
        { key: 'name', title: 'name', dataIndex: 'name' },
        { key: 'email', title: 'email', dataIndex: 'title' },
        {
            key: 'ops',
            title: 'Actions',
            dataIndex: 'Actions',
            render: (text, record, index) => {
                return (
                    <div>
                        <Button onClick={() => props.onDetails(record)}>详情</Button>
                        <Button onClick={() => props.onEdit(record)}>修改</Button>
                        <Button onClick={() => props.onDelete(record)}>删除</Button>
                    </div>
                );
            },
        },
    ];

    return (
        <div className="user-list-pane">
            <div className="head-buttons">
                <Button className="form-button" onClick={props.onRefresh}>
                    Refresh
                </Button>
                <Button className="form-button" onClick={props.onAdd}>
                    Add
                </Button>
            </div>
            <Table key={'id'} dataSource={props.users} columns={columns} />
        </div>
    );
};

type ISiteProps = RouteComponentProps & IDispatch;

interface ISiteState {
    list?: Array<IUser>;
    loadingError?: any;
}

class UserListPaneSite extends React.Component<ISiteProps, ISiteState> {
    constructor(props: ISiteProps) {
        super(props);
        this.state = {};
    }

    private async loadList() {
        try {
            const list = await apis.users.findAll(undefined);
            this.setState({ list });
        } catch (e) {
            this.setState({ loadingError: e });
        }
    }

    public async componentDidMount() {
        await this.handleReload();
    }

    private handleReload = async () => {
        await this.loadList();
    };

    private handleAdd = () => {
        this.props.history.push(RouteUrls.admin.userAdd);
    };

    private handleDelete = async (user: IUser) => {
        await apis.users
            .deleteOneById(user.id)
            .then(() => {
                // dispatch the delete action
            })
            .catch(reason => showErrorNotification(reason));
    };

    private handleEdit = async (user: IUser) => {
        this.props.history.push(`/admin/users/${user.id}/edit`);
    };

    private handleDetails = async (user: IUser) => {
        this.props.history.push(`/admin/users/${user.id}`);
    };

    public render() {
        if (this.state.loadingError) return <div>{getErrorText(this.state.loadingError)}</div>;
        if (!this.state.list) return <div>Loading...</div>;
        return (
            <UserListPaneView
                users={this.state.list}
                onRefresh={this.handleReload}
                onAdd={this.handleAdd}
                onDelete={this.handleDelete}
                onEdit={this.handleEdit}
                onDetails={this.handleDetails}
            />
        );
    }
}

export const UserListPane = connect()(withRouter(UserListPaneSite));
