import React, { Component } from 'react';

interface IProps {
    // todo:以后可能不同页签显示不同内容
    tabKey?: string;
}

/** 代表 {@link MindToolWindow} 的一个 tab 中的内容. */
export default class MindBlankPane extends React.Component<IProps, {}> {
    protected constructor(props: IProps) {
        super(props);
    }

    public render() {
        return (
            <div className="no-selection-item">
                <span className="blank-text">请选中一个主题显示内容</span>
            </div>
        );
    }
}
