import { Avatar, message } from 'antd';
import moment from 'moment';
import { IDiscussionPost, IPrincipal, IBriefUser } from 'neka-common';
import React, { RefObject } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { IDispatch } from '../../../../common/redux-utils';
import { fromNow, showErrorNotification } from '../../../../common/ui-utils';
import { apis } from '../../../../core/server-apis';
import { DiscussionSolveState } from '../../../mind-consts';
import { SetDiscussState } from '../../../store/discussion-redux';
import MindReplyInput from './mind-reply-input';
import MindReplyItem from './mind-reply-item';
import { IReplyInput } from './mind-discussion-from';
import MindDiscussionHandler from './mind-discussion-handler';

interface IProps extends IDispatch {
    discussion: IDiscussionPost;
    principal?: IPrincipal;
    docOwnerId: string;
    hasDiscussion?: boolean;
    docItemId?: string;
}

/** 代表 {@link MindToolWindow} 的一个 tab 中的内容. */
export default class MindDiscussionItems extends React.Component<IProps, IReplyInput> {
    protected constructor(props: IProps) {
        super(props);
        this.state = {
            replyInputFocus: false,
            replyToId: '',
        };
    }

    // 设置帖子的状态是否已解决
    private setSolve: React.EventHandler<React.MouseEvent> = async () => {
        try {
            const { discussion, principal, docOwnerId } = this.props,
                isSolved = !discussion.isSolved;

            if (principal && docOwnerId !== principal.userid && principal.userid !== discussion.creator.id) {
                showErrorNotification('只有帖子创建人/脑图归属人才能进行此操作.');
                return;
            }

            const fetchResult = await apis.discussions.setDiscussSolve(discussion.id + '/' + isSolved);

            if (fetchResult) {
                this.props.dispatch(SetDiscussState(discussion.id!, isSolved));
            }
            const msg = '该帖子成功标记为' + (isSolved ? '已' : '未') + '解决.';
            message.success(msg);
        } catch (e) {
            showErrorNotification(e);
        }
    };

    // 设置输入框的状态
    protected setInputState = (params: IReplyInput): void => {
        const { discussion } = this.props;
        this.setState({
            replyInputFocus: params.replyToId === discussion.id ? !this.state.replyInputFocus : params.replyInputFocus,
            replyToId: params.replyToId,
            replyToUser: params.replyToUser,
        });
    };

    public render() {
        const { discussion, dispatch, principal, hasDiscussion, docItemId } = this.props;
        const creator = discussion.creator;
        return (
            <div className="mind-discussion-items">
                <div className={discussion.isSolved ? 'resolved-btn' : 'unsolved-btn'} onClick={this.setSolve}>
                    {discussion.isSolved ? DiscussionSolveState.solved : DiscussionSolveState.solving}
                </div>
                <div className="user-info">
                    <span className="user-avator">
                        <Avatar size={20} src={creator ? creator.avatar : ''} icon="user" />
                    </span>
                    <span className="name">
                        {creator ? (creator.nickname ? creator.nickname : creator.nickname) : ''}
                    </span>
                    <span className="date" title={moment(discussion.createdAt).format('YYYY/MM/DD HH:mm:ss')}>
                        ▪ {fromNow(discussion.createdAt)}
                    </span>
                </div>
                <div className="discussion-title" dangerouslySetInnerHTML={{ __html: discussion.text }} />
                <MindDiscussionHandler
                    replyId={discussion.id}
                    replyCount={discussion.replies ? discussion.replies.length : 0}
                    showReplyInput={this.setInputState}
                    dispatch={dispatch}
                />
                <div
                    className="discussion-reply-area"
                    style={{
                        display:
                            (discussion.replies && discussion.replies.length > 0) || this.state.replyInputFocus
                                ? 'block'
                                : 'none',
                    }}
                >
                    {discussion.replies &&
                        discussion.replies.map(reply => {
                            const disCreator = discussion.creator;
                            return (
                                <MindReplyItem
                                    showReplyInput={this.setInputState}
                                    isShowAdoptBtn={
                                        disCreator && principal && principal.userid === disCreator.id ? true : false
                                    }
                                    key={reply.id}
                                    reply={reply}
                                    dispatch={dispatch}
                                />
                            );
                        })}
                    <MindReplyInput
                        docItemId={docItemId}
                        postId={discussion.id}
                        hasDiscussion={hasDiscussion}
                        replyInputFocus={this.state.replyInputFocus}
                        replyToUser={this.state.replyToUser}
                        replyToId={this.state.replyToId}
                        placeHolder="发布您的看法"
                        dispatch={dispatch}
                    />
                </div>
            </div>
        );
    }
}
