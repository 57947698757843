import * as React from 'react';
import { Form, Input, Button } from 'antd';
import { IBindWechatArgs } from 'neka-common';
import { getErrorInfoName, showErrorNotification } from '../../../common/ui-utils';
import { ExceptionType, formConstant } from '../../constant';
import { localStoragePrincipal, RouteUrls } from '../../../core/app-config';
import { apis } from '../../../core/server-apis';
import { logIn } from '../../../store/app-redux';
import { doRedirectPath, filterNumberInput, getNameErrorTips } from '../../account-util';
import { IBindAccount, setPwLoginCounter } from '../../store/account-redux';
import { PassworInput } from '../common/browser-forbid-remeber';

class ExistingAccount extends React.Component<IBindAccount, {}> {
    constructor(props: IBindAccount) {
        super(props);
    }

    register: React.EventHandler<React.MouseEvent> = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (this.props.wechatInfo) {
                    this.registerMobile({
                        unionId: this.props.wechatInfo.unionId,
                        ...values,
                        nickname: values.nickname.trim(),
                        username: values.mobile,
                    });
                }
            }
        });
    };

    registerMobile = async (info: IBindWechatArgs) => {
        try {
            const principal = await apis.account.bindWechat(info);
            this.props.dispatch(setPwLoginCounter(0));
            this.props.dispatch(logIn(principal));
            localStoragePrincipal.save(principal);
            // this.props.history.push(RouteUrls.dashboard);
            doRedirectPath(this.props.history);
        } catch (e) {
            let infoName: string = getErrorInfoName(e);
            if (infoName === ExceptionType.UserNameNotExistException) {
                this.props.form.setFields({
                    username: {
                        value: this.props.form.getFieldValue('username'),
                        errors: [new Error('绑定的手机号不存在')],
                    },
                });
            } else if (infoName === ExceptionType.PasswordMismatchException) {
                this.props.form.setFields({
                    password: {
                        value: this.props.form.getFieldValue('password'),
                        errors: [new Error('密码输入错误')],
                    },
                });
            } else {
                showErrorNotification(e);
            }
        }
    };

    validateName = (rule: any, value: string, callback: any) => {
        if (this.props.wechatInfo && this.props.wechatInfo.nickname === value) {
            callback();
        } else {
            callback(getNameErrorTips(value.trim()));
        }
    };

    render() {
        let { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.register} className="login-form">
                <PassworInput />
                <Form.Item>
                    {getFieldDecorator('mobile', {
                        normalize: filterNumberInput,
                        validateTrigger: formConstant.validateTrigger,
                        rules: [
                            { required: true, message: formConstant.validateRules.mobileNullErrorMassage },
                            {
                                pattern: formConstant.validateRules.mobileRegexp,
                                message: formConstant.validateRules.mobileMatchRegexpMassage,
                            },
                        ],
                    })(<Input type="tel" placeholder="输入已注册的手机号码" />)}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('password', {
                        validateTrigger: formConstant.validateTrigger,
                        rules: [{ required: true, message: formConstant.validateRules.passwordNullErrorMassage }],
                    })(<Input type="password" placeholder="输入密码" />)}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('nickname', {
                        validateTrigger: formConstant.validateTrigger,
                        validateFirst: true,
                        rules: [
                            { required: true, message: formConstant.validateRules.nicknameNullErrorMassage },
                            { validator: this.validateName },
                        ],
                        initialValue: this.props.wechatInfo ? this.props.wechatInfo.nickname : '',
                    })(<Input type="text" placeholder="输入名字" className="nw-nickname" />)}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="nw-login-form-button">
                        确定
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default Form.create({ name: 'existingAccount' })(ExistingAccount);
