import { Avatar, Tooltip, message } from 'antd';
import { IDiscussionReply } from 'neka-common';
import React from 'react';
import { IDispatch } from '../../../../common/redux-utils';
import { showErrorNotification } from '../../../../common/ui-utils';
import { apis } from '../../../../core/server-apis';
import { SetReplyState } from '../../../store/discussion-redux';
import MindDiscussionHandler from './mind-discussion-handler';
import { IReplyInput } from './mind-discussion-from';

interface IProps extends IDispatch {
    reply: IDiscussionReply;
    showReplyInput: (params: IReplyInput) => void;
    isShowAdoptBtn: boolean;
}

interface IState {
    showHandlerBtn: boolean;
    currentReplyId?: string;
}

/** 代表 {@link MindToolWindow} 的一个 tab 中的内容. */
export default class MindReplyItem extends React.Component<IProps, IState> {
    private showToolTipRef = React.createRef<HTMLDivElement>();
    private delayShowTime = null as any;

    protected constructor(props: IProps) {
        super(props);
        this.state = {
            showHandlerBtn: false,
            currentReplyId: '',
        };
    }

    private handleMouseMove = (event: React.MouseEvent, reply: IDiscussionReply): void => {
        if (this.delayShowTime) {
            clearTimeout(this.delayShowTime);
            this.delayShowTime = null;
        }
        this.delayShowTime = setTimeout(() => {
            this.setState({ showHandlerBtn: true, currentReplyId: reply.id });
        }, 50);
    };

    private handleMouseLeave = (event: React.MouseEvent, reply: IDiscussionReply) => {
        if (this.delayShowTime) {
            clearTimeout(this.delayShowTime);
            this.delayShowTime = null;
        }
        this.delayShowTime = setTimeout(() => {
            this.setState({ showHandlerBtn: false, currentReplyId: '' });
        }, 50);
    };

    // 设置一个回复的状态= =>>是否采纳
    private setReplyState: React.EventHandler<React.MouseEvent> = async () => {
        try {
            const { reply, isShowAdoptBtn } = this.props,
                isAdopt = !reply.isAdopt;

            // 不是帖子的创建人不允许操作
            if (!isShowAdoptBtn) {
                return;
            }

            const fetchResult = await apis.discussions.setReplyAdopt(reply.id + '/' + isAdopt);

            if (fetchResult) {
                this.props.dispatch(SetReplyState(reply.postId!, reply.id!, isAdopt));
            }
            const msg = '该回复成功标记为' + (isAdopt ? '已' : '未') + '采纳.';
            message.success(msg);
        } catch (e) {
            showErrorNotification(e);
        }
    };

    public render() {
        const { reply, dispatch, showReplyInput, isShowAdoptBtn } = this.props;
        const { currentReplyId, showHandlerBtn } = this.state;

        // 鼠标经过是否显示采纳按钮
        const showSetAdopt =
            reply.isAdopt || (!reply.isAdopt && isShowAdoptBtn && currentReplyId === reply.id && showHandlerBtn);

        return (
            <div
                id={'reply-item' + reply.id}
                ref={this.showToolTipRef}
                className="reply-item"
                onMouseMove={(event: React.MouseEvent) => this.handleMouseMove(event, reply)}
                onMouseLeave={(event: React.MouseEvent) => this.handleMouseLeave(event, reply)}
            >
                <span
                    onClick={this.setReplyState}
                    className={reply.isAdopt ? 'adopt adopted' : 'adopt'}
                    title={reply.isAdopt ? (isShowAdoptBtn ? '已采纳，点击取消采纳' : '已采纳') : '点击采纳'}
                    style={{
                        display: showSetAdopt ? 'inline-block' : 'none',
                        cursor: isShowAdoptBtn ? 'pointer' : 'default',
                    }}
                />
                <span className="user-info">
                    <Avatar size={14} src={reply.creator.avatar} icon="user" />
                    {reply.creator.nickname}
                    {this.getReplyToDom()}
                </span>
                <span className="content" dangerouslySetInnerHTML={{ __html: reply.text }} />
                <div className="discussion-reply-handler-area">
                    {currentReplyId === reply.id && showHandlerBtn ? (
                        <MindDiscussionHandler
                            reply={reply}
                            replyId={reply.id}
                            showReplyInput={showReplyInput}
                            dispatch={dispatch}
                        />
                    ) : (
                        ''
                    )}
                </div>
            </div>
        );
    }

    // 显示获取回复的对象Icon
    private getReplyToDom(): JSX.Element | null {
        const { reply } = this.props;
        if (!reply.replyTo) return null;
        const replyToCreator = reply.replyTo!.creator;
        return (
            <span className="reply-to">
                回复
                {replyToCreator ? '  ' + replyToCreator.nickname : '  佚名'}
                <Tooltip
                    style={{ backgroundColor: '#fff' }}
                    placement="top"
                    arrowPointAtCenter={true}
                    getPopupContainer={() => this.showToolTipRef!.current!}
                    title={
                        <span
                            className="reply-popover-text"
                            dangerouslySetInnerHTML={{ __html: reply.replyTo!.text }}
                        />
                    }
                >
                    <span className="reply-to-icon" />
                </Tooltip>
            </span>
        );
    }
}
