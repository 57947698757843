import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
import { IMultiValueDescriptor } from './style-helper';

interface IProps extends IMultiValueDescriptor<boolean> {
    label: string;
    onValueChanged(value?: boolean): void;
}

/** 逻辑值选择器. */
export class BoolValuePicker extends React.Component<IProps, {}> {
    public constructor(props: IProps) {
        super(props);
    }

    public render() {
        const { label, areSame, valueSource, value } = this.props;

        return (
            <Checkbox indeterminate={!areSame} checked={value} onChange={this.handleValueChange}>
                {label}
            </Checkbox>
        );
    }

    private handleValueChange = (event: CheckboxChangeEvent) => {
        const value = event.target.checked;
        if (this.props.onValueChanged) {
            this.props.onValueChanged(value);
        }
    };
}
