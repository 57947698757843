// *********************************************************
// 预定义常量
// *********************************************************

/** 输入框的最小宽度. */
export const InplaceTextEditorMinWidth = 200;

export const MindDrawerTabPageNames = ['讨论', '样式', '邀请成员', '快捷键'];

// 帖子的状态
export const DiscussionSolveState = {
    solved: '已解决',
    solving: '待解决',
};
