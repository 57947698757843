import { Enum } from '../common';
import { IUser, IBriefUser } from './account.data';

export type MindId = string;
export type MindIds = ReadonlyArray<MindId>;

/** 代表一个持久化的脑图文档. */
export interface IMindDocument {
    /** 文档的 id. */
    id: string;
    /** 文档的标题. */
    title: string;
    /** 文档的结点. */
    items: Array<IMindItemEntity>;
    /** 文档的根结点的 id. */
    rootId: MindId;
    /** (暂未实现) 自由主题的 id. */
    floatIds?: Array<MindId>;
    /** 归属人. */
    ownerId: string;
    /** 创建人 */
    creatorId: string;
    /** 创建时间 */
    createdAt: Date;
    /** 最后修改的人 */
    lastUpdaterId: string;
    /** 更新时间 */
    lastUpdatedAt: Date;
}

export interface IMindDocumentEntity extends IMindDocument {
    owner?: IBriefUser;
    creator?: IBriefUser;
    lastUpdater?: IBriefUser;
}

export type IPartialMindDocumentEntity = Partial<IMindDocumentEntity> & Pick<IMindDocumentEntity, 'id'>;

export const MIND_DOCUMENT_RELATIONS_ALL: Array<keyof IMindDocumentEntity> = [
    'items',
    'creator',
    'lastUpdater',
    'owner',
];

export interface IMindStyles {
    /** 子树的布局方式, 一个结点默认继承其父节点的布局方式, 也可以采用新的布局方式. */
    readonly structureKind?: StructureKind;
    /** 结点至子结点之间连线的样式. */
    readonly lineKind?: LineKind;
    /** 边框: 填充色. */
    readonly fillColor?: string;
    /** 边框: 粗细. 范围: `0` - `5`. `0` 表示无框. */
    readonly borderWidth?: number;
    /** 边框: 颜色. */
    readonly borderColor?: string;
    /** 文字: 字体. */
    readonly textFontFamily?: string;
    /** 文字: 字号. */
    readonly textFontSize?: number;
    /** 文字: 颜色. */
    readonly textFontColor?: string;
    /** 文字: 粗体. */
    readonly textFontBold?: boolean;
    /** 文字: 斜体. */
    readonly textFontItalic?: boolean;
}

/** 代表一个持久化的脑图结点. */
export interface IMindItemEntity {
    /** 结点的 id. */
    id: MindId;
    /** 文档的 id. */
    documentId: string;
    /** 结点的文字. */
    text: string;
    /** 子结点的 id */
    childIds: Array<MindId> | null;
    /** 节点的样式 */
    styles: IMindStyles | null;

    /** 创建时间 */
    createdAt: Date;
    /** 更新时间 */
    lastUpdatedAt: Date;
    /** 创建人 */
    creatorId: string;
    /** 最后修改的人 */
    lastUpdaterId: string;

    /** (暂未实现) 业务类型, 将来可能将结点标记为 "任务" 等. 业务类型可能有特定的附加属性, 如 "任务" 类型可能会为结点增加 "责任人" 等. */
    // kinds?: Array<IMindKind>;

    creator?: IBriefUser;
    lastUpdater?: IBriefUser;
}

export type IPartialMindItemEntity = Partial<IMindItemEntity> & Pick<IMindItemEntity, 'id'>;

/** 结点的布局方式. */
export const StructureKinds = Object.freeze({
    LogicRight: 'logic-right' as 'logic-right',
    LogicLeft: 'logic-left' as 'logic-left',
    OrgDown: 'org-down' as 'org-down',
    OrgUp: 'org-up' as 'org-up',
});

export type StructureKind = Enum<typeof StructureKinds>;

/**
 * 业务类型, 将来可能将结点标记为 "任务" 等.
 * 业务类型可能有特定的附加属性, 如 "任务" 类型可能会为结点增加 "责任人" 等.
 * 业务类型也可能是由用户定义的.
 */
export interface IMindKind {
    id: MindId;
    name: string;
}

/** 结点连接线的类型 */
export const LineKinds = Object.freeze({
    default: 'poly' as 'poly',
    poly: 'poly' as 'poly',
    bezier: 'Bezier' as 'Bezier',
});
export type LineKind = Enum<typeof LineKinds>;
