import { Enum } from '../common';
import {
    MindId,
    IMindStyles,
    StructureKinds,
    LineKinds,
    IMindItemEntity,
    IPartialMindItemEntity,
    IPartialMindDocumentEntity,
} from './mind.data';
import { Min, IsNotEmpty, IsNumber, IsInt } from 'class-validator';

/** 移动一个节点 */
export interface IMoveMindEntryArgs {
    item: MindId;
    oldParent: MindId; // 如果 item 原先是根节点, 则没有 oldParent
    newParent: MindId; // 如果 item 变为根节点, 则没有 newParent
    newIndex: number;
}

/** 添加一个成员 */
export interface ICreateCollaboratorArgs {
    documentId: string;
    userId: string;
    // todo
    role: string[];
}

/** 创建一个节点 */
export interface ICreateMindEntryArgs {
    id?: string;
    documentId: string; // 脑图id
    parentId?: string; // 如果没有parentId 则为根节点
    index: number; // 从0开始
}

export class CreateMindEntryArgs implements ICreateMindEntryArgs {
    id?: string;

    @IsNotEmpty({ message: '脑图id不能为空' })
    documentId: string; // 脑图id

    parentId?: string; // 如果没有parentId 则为根节点

    @IsNumber({ allowInfinity: false }, { message: 'index格式不正确' })
    @IsNotEmpty({ message: 'index不能为空' })
    index: number; // 从0开始
}

/** 移动一个节点 */
export class MoveMindEntryArgs implements IMoveMindEntryArgs {
    @IsNotEmpty({ message: '不能为空' })
    item: MindId;

    oldParent: MindId; // 如果 item 原先是根节点, 则没有 oldParent
    newParent: MindId; // 如果 item 变为根节点, 则没有 newParent

    @IsInt({ message: '必须为整数' })
    @IsNotEmpty({ message: '不能为空' })
    @Min(0, { message: '不能小于0' })
    newIndex: number;
}

/** 添加一个脑图成员 */
export class CreateCollaboratorArgs {
    @IsNotEmpty({ message: '不能为空' })
    documentId: string;

    @IsNotEmpty({ message: '不能为空' })
    userId: string;

    role?: string[];
}

/** 一个主题的最后讨论的时间. */
export interface ILastDiscussionTime {
    id: MindId;
    lastDiscussionTime: Date;
    lastDiscussionReadTime: Date;
}

export interface IMindDocumentCommandBatch {
    commands: (IMindDocumentCommand)[];
}

export const MindDocumentCommandTypes = Object.freeze({
    itemAdd: 'ITEM_ADD' as 'ITEM_ADD',
    itemRemove: 'ITEM_REMOVE' as 'ITEM_REMOVE',
    itemUpdate: 'ITEM_UPDATE' as 'ITEM_UPDATE',
    documentUpdate: 'DOCUMENT_UPDATE' as 'DOCUMENT_UPDATE',
});

export type MindDocumentCommandType = Enum<typeof MindDocumentCommandTypes>;

export type IMindDocumentCommand =
    | IMindDocumentUpdateCommand
    | IMindDocumentItemAddCommand
    | IMindDocumentItemRemoveCommand
    | IMindDocumentItemUpdateCommand;

export interface IMindDocumentUpdateCommand {
    commandType: 'DOCUMENT_UPDATE';
    data: IPartialMindDocumentEntity;
}

export interface IMindDocumentItemAddCommand {
    commandType: 'ITEM_ADD';
    data: IPartialMindItemEntity;
}

export interface IMindDocumentItemRemoveCommand {
    commandType: 'ITEM_REMOVE';
    data: Pick<IMindItemEntity, 'id'>;
}

export interface IMindDocumentItemUpdateCommand {
    commandType: 'ITEM_UPDATE';
    data: IPartialMindItemEntity;
}
