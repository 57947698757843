/******************************************************************************/
//  MindBoard interactions
/******************************************************************************/

import { protectProperty } from 'neka-common';
import React, { ReactNode } from 'react';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { arrayEquals } from '../../common/collections';
import { isDevelopment } from '../../core/app-config';
import { getDebug } from '../../core/app-diag';
import { ChangeToolWindowVisible } from '../../store/app-redux';
import { createRect, IRect, isFarThan, NullPos } from '../mind-draw2d';
import { absoluteBodyPos } from '../mind-layout';
import { getItem, IMindDiagram, IMindItem, MindId, ToolPaneKinds } from '../mind-models';
import { DropActions, getDropIntent, IDropIntent } from '../mind-utils';
import {
    CancelInplaceTextEdit,
    ChangeActiveToolPane,
    ChangeSelection,
    CommitInplaceTextEdit,
    DiagramZoomTo,
    DropSelectionTo,
    ExcludeSelection,
    IncludeSelection,
    InverseItemExpanded,
    StartInplaceTextEdit,
} from '../store/mind-redux';
import { isItemCanBeSelected } from '../store/mind-redux-helper';
import { isAppendingSelectionButton, isDragButton, isSelectionButton, isZoomKey } from './kb-helper';
import { InplaceTextEditor, InplaceTextEditorCompleteHandler } from './mind-inplace-text-editor';
import { MindDropDecorator } from './mind-widget-decorators';
import { MindItemSelectedBorder } from './mind-widget-parts';
import { IHitTest, SvgHelper } from './svg-helper';

const debug = getDebug(__filename);

export interface IBoard {
    readonly svgRef: React.RefObject<SVGSVGElement>;
    readonly svgHelper: SvgHelper;
    readonly diagram: IMindDiagram;
    /** 通知 board 必须刷新. */
    repaint(): void;
    dispatchAction(action: AnyAction): void;
    dispatchThunk(action: ThunkAction<any, any, any, any>): void;
}

export type HandlerResult = {
    preventDefault: boolean;
    stopPropagation: boolean;
};

interface IEventExtras {
    /** 为每个事件附加一个识别码, 使得在日志中容易区分不同事件. */
    eventBatchId: number;
}

interface IMouseEventExtras extends IEventExtras, IHitTest {
    viewportX: number;
    viewportY: number;
}

type ComponentEvent<TReactEvent extends React.SyntheticEvent<any, any>, TExtras extends IEventExtras> = TReactEvent & {
    readonly extras: TExtras;
};
type AnyEvent = ComponentEvent<any, any>;

type ComponentEventHandler<TEvent extends AnyEvent> = (componentEvent: TEvent) => HandlerResult;

type ComponentMouseEvent = ComponentEvent<React.MouseEvent<any>, IMouseEventExtras>;
type ComponentMouseEventHandler = ComponentEventHandler<ComponentMouseEvent>;

type ComponentMouseWheelEvent = ComponentEvent<React.WheelEvent<any>, IMouseEventExtras>;
type ComponentMouseWheelEventHandler = ComponentEventHandler<ComponentMouseWheelEvent>;

type ComponentPointerEvent = ComponentEvent<React.PointerEvent<any>, IMouseEventExtras>;
type ComponentPointerEventHandler = ComponentEventHandler<ComponentPointerEvent>;

/** 用于对 {@link InteractionManager.interactions} 进行排序. */
type InteractionCompare = (a: Interaction, b: Interaction) => number;

/**
 * 管理脑图画布的交互.
 *
 * 一个 {@link InteractionManager} 包含一组 {@link Interaction}.
 * 通过 {@link registerInteraction}/{@link unregisterInteraction} 增加或移除可用的交互.
 *
 * 每个 {@link Interaction} 代表一个特定的用户操作, 如 "通过拖放移动主题". 一个交互
 *
 *  * 可以由单个事件构成, 如选择结点仅由单个 `PointerDown` 事件构成,
 *  * 也可由包含多个事件组成的序列构成, 如 "拖放主题" 操作由 `PointerDown`-`PointerMove`-`PointerUp` 构成.
 *
 * 当一个 {@link Interaction} 由一个交互序列构成时, {@link Interaction.isActive} 表示操作是否正在进行中.
 * 例如, 对于 "拖放主题" 操作, 在 "松开鼠标左键" 之前, 对应的 {@link Interaction} 都处于 active 状态.
 * 一个 {@link InteractionManager} 最多只会有一个 {@link Interaction} 处于 {@link Interaction.isActive()} 状态.
 *
 * 事件通知:
 *
 *  1. 事件首先通知给 {@link Interaction.isActive()} 为 `true` 的 {@link Interaction}.
 *  2. 按注册的倒序通知其他 {@link Interaction}.
 */
export class InteractionManager {
    public constructor(board: IBoard) {
        this.board = board;
    }

    private readonly board: IBoard;
    private readonly interactions: Array<Interaction> = [];

    //#region board property/method wrappers

    public get diagram() {
        return this.board.diagram;
    }

    public get svgHelper() {
        return this.board.svgHelper;
    }

    private getDebugMessage(action: AnyAction, description?: string): string {
        const payload = action.payload ? JSON.stringify(action.payload) : 'undefined';
        const s = `${description}: dispatch action [${action.type}, payload: ${payload}]`;
        return s;
    }

    public dispatchAction(action: AnyAction, description?: string) {
        if (description) {
            const message = this.getDebugMessage(action, description);
            debug(message);
        }
        this.board.dispatchAction(action);
    }

    public dispatchThunk(action: ThunkAction<any, any, any, any>) {
        this.board.dispatchThunk(action);
    }

    //#endregion

    //#region register/unregister interactions

    /** 注册一个 {@link Interaction}. */
    public registerInteraction(interaction: Interaction) {
        const index = this.interactions.findIndex(x => x.interactionId === interaction.interactionId);
        if (index !== -1) throw new Error(`The mode ${interaction.interactionId} has already been registered.`);
        this.interactions.push(interaction);
    }

    /** 移除一个 {@link Interaction}. */
    public unregisterInteraction(interactionId: string) {
        const idx = this.interactions.findIndex(x => x.interactionId === interactionId);
        if (idx) this.interactions.splice(idx, 1);
    }

    private getInteraction(interactionOrId: Interaction | string): Interaction {
        if (!interactionOrId) throw new Error(`Invalid interactionOrId.`);

        const predicate: (x: Interaction) => boolean =
            typeof interactionOrId === 'string'
                ? (x: Interaction) => x.interactionId === interactionOrId
                : (x: Interaction) => x === interactionOrId;
        const ia = this.interactions.find(predicate);
        if (!ia) {
            throw new Error(
                `The interaction ${
                    typeof interactionOrId === 'string' ? interactionOrId : interactionOrId.interactionId
                } not found.`
            );
        }
        return ia;
    }

    //#endregion

    //#region render

    private getRenderInteractions(): Interaction[] {
        if (!this.interactions || this.interactions.length === 0) return [];
        const result = this.interactions.filter(x => x !== this.activeInteraction);
        if (this.activeInteraction) result.splice(0, 0, this.activeInteraction);
        return result;
    }

    /** 由 Board 调用. 渲染交互过程中的视觉反馈. 该方法将依次调用所有 mode 的 render(). 该方法的输出位于 SVG 内. */
    public render(): ReactNode {
        const list = this.getRenderInteractions();

        return (
            <g key={`mind-interaction-manager-render`}>
                {list.map(ia => (
                    <React.Fragment key={ia.interactionId}>{ia.render()}</React.Fragment>
                ))}
            </g>
        );
    }

    /** 由 Board 调用. 渲染交互过程中的视觉反馈. 该方法将依次调用所有 mode 的 render(). 该方法的输出位于 SVG 之上. */
    public renderAbove(): ReactNode {
        const list = this.getRenderInteractions();
        return (
            <div key={`mind-interaction-manager-renderAbove`}>
                {list.map(ia => (
                    <React.Fragment key={ia.interactionId}>{ia.renderAbove()}</React.Fragment>
                ))}
            </div>
        );
    }

    //#endregion

    private getHandlerInteractions(): Interaction[] {
        if (!this.interactions || this.interactions.length === 0) return [];
        const result = this.interactions.filter(x => x !== this.activeInteraction).reverse();
        if (this.activeInteraction) result.splice(0, 0, this.activeInteraction);
        return result;
    }

    private ignoreEventTypes = new Set(['pointermove']);

    /** 分派事件, 最后一个注册的 mode 将首先处理事件. */
    protected handleEvent<TReactEvent extends React.SyntheticEvent<any, any>, TExtras extends IEventExtras>(
        handlerName: string,
        event: TReactEvent,
        handlerProvider: (interaction: Interaction) => ComponentEventHandler<ComponentEvent<TReactEvent, TExtras>>,
        getExtras: (reactEvent: TReactEvent) => TExtras
    ): void {
        if (!this.interactions || this.interactions.length === 0) return;

        const extras = getExtras(event);
        const componentEvent: ComponentEvent<TReactEvent, TExtras> = Object.assign(event, { extras });

        const interactions = this.getHandlerInteractions();

        const logEvent = !this.ignoreEventTypes.has(event.type);
        if (logEvent) {
            const ids = interactions.map(ia => ia.interactionId).join(', ');
            debug(`handleEvent: ${handlerName} - ${event.type} - ${extras.eventBatchId} => ${ids}`);
        }

        let handled = this.invokeInteractions(interactions, handlerProvider, componentEvent);

        if (logEvent) {
            debug(`handleEvent: ${handlerName} - ${event.type} - ${extras.eventBatchId}, handled [${handled}]`);
        }

        if (handled) {
            if (handled.preventDefault) event.preventDefault();
            if (handled.stopPropagation) event.stopPropagation();
            if (handled.preventDefault || handled.stopPropagation) {
                this.board.repaint();
            }
        }
    }

    private invokeInteractions<TEvent extends AnyEvent>(
        interactions: Array<Interaction>,
        handlerProvider: (interaction: Interaction) => ComponentEventHandler<TEvent>,
        event: TEvent
    ): HandlerResult {
        return interactions.reduce<HandlerResult>(
            (previousValue, current) => {
                const result = this.invokeInteraction(current, handlerProvider, event);
                return {
                    preventDefault: previousValue.preventDefault || result.preventDefault,
                    stopPropagation: previousValue.stopPropagation || result.stopPropagation,
                };
            },
            { preventDefault: false, stopPropagation: false }
        );
    }

    private invokeInteraction<TEvent extends AnyEvent>(
        interaction: Interaction,
        handlerProvider: (interaction: Interaction) => ComponentEventHandler<TEvent>,
        event: TEvent
    ): HandlerResult {
        const handler: ComponentEventHandler<TEvent> = handlerProvider(interaction);

        const result: HandlerResult = handler(event);
        this.debugInteractionMessage(event, interaction, result);
        return result;
    }

    public hasOtherActiveInteractions(interaction: Interaction): boolean {
        const ia = this.interactions.find(x => x.isActive() && x !== interaction);
        return ia !== undefined;
    }

    private activeInteraction: Interaction | null;

    public isActive(interactionOrId: Interaction | string): boolean {
        const ia = this.getInteraction(interactionOrId);
        return this.activeInteraction === ia;
    }

    public hasActiveInteraction(): boolean {
        return this.activeInteraction !== undefined && this.activeInteraction !== null;
    }

    public activate(interactionOrId: Interaction | string): void {
        const ia = this.getInteraction(interactionOrId);
        if (ia === this.activeInteraction) return;

        const current = this.activeInteraction;
        if (current) {
            throw new Error(
                `Cannot activate ${ia.interactionId} while ${current.interactionId} is active.` +
                    `Only one interaction can be active at a time.`
            );
        }

        this.activeInteraction = ia;
        this.notifyOthers(colleague => colleague.onOthersActivated(ia), ia);
    }

    public deactivate(interactionOrId: Interaction | string): void {
        const ia = this.getInteraction(interactionOrId);

        const current = this.activeInteraction;
        if (!current) {
            throw new Error(`There is no active interaction.`);
        }
        if (current !== ia) {
            throw new Error(
                `Cannot deactivate ${ia.interactionId} while ${current.interactionId} is active.` +
                    `Only the currently active interaction can be deactivated.`
            );
        }

        this.activeInteraction = null;
        this.notifyOthers(colleague => colleague.onOthersDeactivated(ia), ia);
    }

    private notifyOthers(handler: (companion: Interaction) => void, interaction: Interaction) {
        this.interactions.forEach(other => {
            if (other !== interaction) {
                handler(other);
            }
        });
    }

    /**
     * Store the last interaction messages, keyed by {@link Interaction.interactionId}.
     * The primary purpose is to eliminate multiple `mouse move` messages.
     */
    private lastInteractionMessages = new Map<string, string>();

    private debugInteractionMessage(event: ComponentEvent<any, any>, interaction: Interaction, result: HandlerResult) {
        if (isDevelopment) {
            const id = interaction.interactionId;
            const message = `\t${event.type} ==> ${id}: preventDefault [${result.preventDefault}] stopPropagation: [${
                result.stopPropagation
            }]`;
            const lastMessage = this.lastInteractionMessages.get(id);
            if (message !== lastMessage) {
                this.lastInteractionMessages.set(id, message);
                debug(message, event.extras);
            }
        }
    }

    //#region events handlers

    public handlePointerDown: React.PointerEventHandler = event => {
        this.board.svgRef.current!.setPointerCapture(event.pointerId);
        this.handleEvent('handlePointerDown', event, ia => ia.handlePointerDown, this.getMouseEventExtras);
    };

    public handlePointerMove: React.PointerEventHandler = event => {
        this.handleEvent('handlePointerMove', event, ia => ia.handlePointerMove, this.getMouseEventExtras);
    };

    public handlePointerUp: React.PointerEventHandler = event => {
        this.board.svgRef.current!.releasePointerCapture(event.pointerId);
        this.handleEvent('handlePointerUp', event, ia => ia.handlePointerUp, this.getMouseEventExtras);
    };

    public handleMouseWheel: React.WheelEventHandler = event => {
        this.handleEvent('handleMouseWheel', event, ia => ia.handleMouseWheel, this.getMouseEventExtras);
    };

    public handleClick: React.MouseEventHandler = event => {
        this.handleEvent('handleClick', event, ia => ia.handleClick, this.getMouseEventExtras);
    };

    public handleDoubleClick: React.MouseEventHandler = event => {
        this.handleEvent('handleDoubleClick', event, ia => ia.handleDoubleClick, this.getMouseEventExtras);
    };

    public handleContextMenu: React.MouseEventHandler = event => {
        event.stopPropagation();
        event.preventDefault();
    };

    private lastEventBatchId: number = 1;

    /** 获取 {@link React.PointerEvent} 事件处理过程中的常用信息. */
    private getMouseEventExtras: (event: React.MouseEvent) => IMouseEventExtras = event => {
        const viewportPos = this.svgHelper.getViewportCoord(event);
        const hitTest = this.svgHelper.hitTestForViewportCoord(viewportPos.x, viewportPos.y);
        return {
            eventBatchId: this.lastEventBatchId++,
            viewportX: viewportPos.x,
            viewportY: viewportPos.y,
            ...hitTest,
        };
    };

    //#endregion
}

/** 提供 {@IMode} 的模板类. 对所有的事件, 采用定义一对 property 和 method, 以消除 bind 的需要. */
export abstract class Interaction {
    protected constructor(interactionManager: InteractionManager, interactionId: string) {
        this.interactionId = interactionId;
        this.interactionManager = interactionManager;
        protectProperty(this, 'interactionId');
    }

    public readonly interactionId: string;
    protected readonly interactionManager: InteractionManager;

    protected get diagram() {
        return this.interactionManager.diagram;
    }

    protected get svgHelper() {
        return this.interactionManager.svgHelper;
    }

    protected dispatchAction(action: AnyAction, description?: string) {
        this.interactionManager.dispatchAction(action, description);
    }

    protected dispatchThunk(action: ThunkAction<any, any, any, any>) {
        this.interactionManager.dispatchThunk(action);
    }

    //#region activation/deactivation

    public isActive(): boolean {
        return this.interactionManager.isActive(this);
    }

    public onOthersActivated(other: Interaction): void {
        // noop
    }

    public onOthersDeactivated(companion: Interaction): void {
        // noop
    }

    //#endregion

    /** 在 SVG 中绘制, 返回的元素应当为 SVGElement. */
    public render(): ReactNode {
        return null;
    }

    /** 在 SVG 的上方绘制, 通常用于绘制非 SVGElement. */
    public renderAbove(): ReactNode {
        return null;
    }

    protected handled(activation: boolean | null, preventDefault: boolean, stopPropagation: boolean): HandlerResult {
        if (activation !== null) {
            if (activation && !this.isActive()) {
                this.interactionManager.activate(this);
            }
            if (!activation && this.isActive()) {
                this.interactionManager.deactivate(this);
            }
        }

        return {
            preventDefault,
            stopPropagation,
        };
    }

    //#region event dispatching

    public handlePointerDown: ComponentPointerEventHandler = event => {
        return this.onPointerDown(event);
    };

    protected onPointerDown(event: ComponentPointerEvent): HandlerResult {
        return this.handled(null, false, false);
    }

    public handlePointerMove: ComponentPointerEventHandler = event => {
        return this.onPointerMove(event);
    };

    protected onPointerMove(event: ComponentPointerEvent): HandlerResult {
        return this.handled(null, false, false);
    }

    public handlePointerUp: ComponentPointerEventHandler = event => {
        return this.onPointerUp(event);
    };

    protected onPointerUp(event: ComponentPointerEvent): HandlerResult {
        return this.handled(null, false, false);
    }

    public handleMouseWheel: ComponentMouseWheelEventHandler = event => {
        return this.onMouseWheel(event);
    };

    protected onMouseWheel(event: ComponentMouseWheelEvent): HandlerResult {
        return this.handled(null, false, false);
    }

    public handleClick: ComponentMouseEventHandler = event => {
        // React 在 click 之前, 会首先触发 MouseUp, 所以事件通常应当在 MouseUp 中处理.
        return this.onClick(event);
    };

    protected onClick(event: React.MouseEvent<any>): HandlerResult {
        return this.handled(null, false, false);
    }

    public handleDoubleClick: ComponentMouseEventHandler = event => {
        return this.onDoubleClick(event);
    };

    protected onDoubleClick(event: ComponentMouseEvent): HandlerResult {
        return this.handled(null, false, false);
    }

    //#endregion
}

/** 基础的 interaction, 用于渲染选中的结点. */
export class TrackingInteraction extends Interaction {
    public static readonly interactionId = 'TRACKING_INTERACTION';

    public constructor(modeManager: InteractionManager) {
        super(modeManager, TrackingInteraction.interactionId);
    }

    public render() {
        const diagram = this.diagram;
        const { items, selection } = diagram;

        if (selection === undefined || selection.length === 0) return null;

        return (
            <React.Fragment>
                {selection.map(id => {
                    const item = getItem(items, id, true);

                    // 对于新建的 item, 可能尚未完成其布局
                    if (item.relativePos === undefined || item.bodySize === null) return null;

                    const absolutePos = absoluteBodyPos(diagram, item.id);
                    if (absolutePos === undefined) return null;

                    return <MindItemSelectedBorder key={id} item={item} absoluteBodyPos={absolutePos} />;
                })}
            </React.Fragment>
        );
    }
}

/** 鼠标滚轮缩放. */
export class WheelZoomInteraction extends Interaction {
    public static readonly interactionId = 'WheelZoom_INTERACTION';

    public constructor(modeManager: InteractionManager) {
        super(modeManager, WheelZoomInteraction.interactionId);
    }

    public onMouseWheel(event: ComponentMouseWheelEvent): HandlerResult {
        if (isZoomKey(event)) {
            let { scale } = this.diagram;
            if (event.deltaY !== 0) {
                const grow = event.deltaY < 0 ? 0.1 : -0.1;
                scale = scale + grow;
                this.dispatchAction(DiagramZoomTo(scale));
            }
            return this.handled(false, true, true);
        }
        return super.onMouseWheel(event);
    }
}

export class GripExpansionInteraction extends Interaction {
    public static readonly interactionId = 'GRIP_EXPANSION_INTERACTION';

    public constructor(modeManager: InteractionManager) {
        super(modeManager, GripExpansionInteraction.interactionId);
    }

    public onPointerDown(event: ComponentPointerEvent): HandlerResult {
        const { itemId, inGrip } = event.extras;

        const isButton = isSelectionButton(event) || isAppendingSelectionButton(event);
        if (isButton && itemId && inGrip) {
            this.dispatchAction(InverseItemExpanded(itemId));
        }
        return super.onPointerDown(event);
    }
}

/** 鼠标单选操作. 点击主题选中, 点击空白取消选中, 按 ctrl/cmd 键则追加选中. */
export class SingleSelectInteraction extends Interaction {
    public static readonly interactionId = 'SELECTION_INTERACTION';

    public constructor(modeManager: InteractionManager) {
        super(modeManager, SingleSelectInteraction.interactionId);
    }

    public onPointerDown(event: ComponentPointerEvent): HandlerResult {
        const { itemId, inDiscussionIcon } = event.extras;
        const { selection } = this.diagram;

        const isButton = isSelectionButton(event) || isAppendingSelectionButton(event);
        if (isButton && itemId) {
            if (isAppendingSelectionButton(event)) {
                const inSelection = selection && selection.indexOf(itemId) >= 0;
                const action = inSelection ? ExcludeSelection : IncludeSelection;
                this.dispatchAction(action([itemId]));
            } else {
                // 如果选中的结点不在 selection 中
                if (!selection || selection.indexOf(itemId) < 0) {
                    this.dispatchAction(ChangeSelection([itemId]));
                }
                // 如果点击 "讨论" 图标, 则右侧显示讨论
                if (inDiscussionIcon) {
                    this.dispatchAction(ChangeActiveToolPane(ToolPaneKinds.discussionPane));
                    this.dispatchAction(ChangeToolWindowVisible(true));
                }
            }
            return this.handled(false, true, true);
        }
        return super.onPointerDown(event);
    }
}

/** 鼠标框选操作. 鼠标在空白处 按下 -> 移动 -> 松开. */
export class MultiSelectInteraction extends Interaction {
    public static readonly interactionId = 'MULTI_SELECTION_INTERACTION';

    public constructor(manager: InteractionManager) {
        super(manager, MultiSelectInteraction.interactionId);
    }

    private boxStart?: IMouseEventExtras;
    private boxEnd?: IMouseEventExtras;

    private resetMatching(): void {
        this.boxStart = undefined;
        this.boxEnd = undefined;
    }

    public render() {
        if (!this.isActive()) return null;

        const { boxStart, boxEnd } = this;
        if (!boxStart || !boxEnd) return null;

        const area = createRect(boxStart.viewportX, boxStart.viewportY, boxEnd.viewportX, boxEnd.viewportY);
        const absoluteRect = this.svgHelper.viewportToAbsoluteRect(area);

        return (
            <rect
                x={absoluteRect.x}
                y={absoluteRect.y}
                width={absoluteRect.width}
                height={absoluteRect.height}
                className="mg-selecting-box"
            />
        );
    }

    protected onPointerDown(event: ComponentPointerEvent): HandlerResult {
        const { itemId } = event.extras;

        if (isSelectionButton(event)) {
            this.resetMatching();
            const isButton = isSelectionButton(event) || isAppendingSelectionButton(event);
            if (isButton && itemId === undefined && !this.interactionManager.hasOtherActiveInteractions(this)) {
                this.boxStart = event.extras;
                return this.handled(true, true, true);
            }
        }
        return super.onPointerDown(event);
    }

    protected onPointerMove(event: ComponentPointerEvent): HandlerResult {
        if (this.isActive()) {
            this.boxEnd = event.extras;
            return this.handled(null, true, true);
        }
        return super.onPointerMove(event);
    }

    protected onPointerUp(event: ComponentPointerEvent): HandlerResult {
        if (isSelectionButton(event)) {
            try {
                if (this.isActive()) {
                    const down = this.boxStart;
                    const up = event.extras;

                    if (!down) throw new Error(`lastPointerDown is undefined.`);

                    const area = createRect(down.viewportX, down.viewportY, up.viewportX, up.viewportY);
                    let ids = this.svgHelper.getItemIdsIn(area);
                    ids = ids.filter(x => isItemCanBeSelected(this.diagram.items, x));

                    const isAppending = isAppendingSelectionButton(event);
                    if (isAppending) {
                        if (ids && ids.length > 0) {
                            this.dispatchAction(IncludeSelection(ids));
                        }
                    } else {
                        const selectedIds = this.diagram.selection ? this.diagram.selection : [];
                        if (!arrayEquals(ids, selectedIds)) {
                            this.dispatchAction(ChangeSelection(ids));
                        }
                    }
                    return this.handled(false, true, true);
                }
            } finally {
                this.resetMatching();
            }
        }
        return super.onPointerUp(event);
    }
}

/** 鼠标拖放操作. 鼠标在主题上 按下 -> 移动 -> 松开. */
export class DragItemInteraction extends Interaction {
    public static readonly interactionId = 'DRAG_ITEM_INTERACTION';

    public constructor(modeManager: InteractionManager) {
        super(modeManager, DragItemInteraction.interactionId);
    }

    private lastPointerDown?: IMouseEventExtras;
    private lastPointerMove?: IMouseEventExtras;
    private dropIntent?: IDropIntent;

    protected resetMatching() {
        this.lastPointerDown = undefined;
        this.lastPointerMove = undefined;
        this.dropIntent = undefined;
    }

    public render(): React.ReactElement | null {
        if (!this.isActive()) return null;

        const { lastPointerDown, lastPointerMove, dropIntent } = this;
        if (!lastPointerDown || !lastPointerMove || !dropIntent) return null;

        const { items, rootId } = this.diagram;

        const cursorPos = this.svgHelper.viewportToAbsolutePos({
            x: lastPointerMove.viewportX,
            y: lastPointerMove.viewportY,
        });

        return (
            <MindDropDecorator
                items={items}
                rootId={rootId}
                dropIntent={dropIntent}
                cursorPos={cursorPos}
                svgHelper={this.svgHelper}
            />
        );
    }

    protected onPointerDown(event: ComponentPointerEvent): HandlerResult {
        if (isDragButton(event)) {
            this.resetMatching();
            if (event.extras.itemId) {
                this.lastPointerDown = event.extras;
                this.lastPointerMove = event.extras;
                return this.handled(false, true, true);
            }
        }
        return super.onPointerDown(event);
    }

    protected onPointerMove(event: ComponentPointerEvent): HandlerResult {
        const { selection, items } = this.diagram;
        this.lastPointerMove = event.extras;

        if (this.isActive()) {
            if (!selection) throw new Error(`The selection is undefined when dragging.`);

            const { viewportX, viewportY, itemId } = this.lastPointerMove;

            const cursorInBody = itemId ? this.svgHelper.getBodyRelativePos(itemId, viewportX, viewportY) : NullPos;
            this.dropIntent = getDropIntent(items, itemId, cursorInBody, selection);

            // debug(`DragItemInteraction: aboveId [${aboveItemId}], dropIntent [%j].`, this.dropIntent);
            return this.handled(true, true, true);
        } else {
            const hasSelection = selection !== undefined && selection.length > 0;

            // 如果左键处于按下状态并且移动了一定的距离, 则开始进入拖放操作.
            if (hasSelection && this.lastPointerDown) {
                if (this.isFarEnough(this.lastPointerDown, this.lastPointerMove)) {
                    // 此时不需要更新 dropIntent, 可以等后续的 move 事件中再更新 dropIntent
                    return this.handled(true, true, true);
                }
            }
            return super.onPointerMove(event);
        }
    }

    protected onPointerUp(event: ComponentPointerEvent): HandlerResult {
        if (isDragButton(event)) {
            try {
                if (this.isActive()) {
                    // 尝试放置到新位置
                    const { dropIntent } = this;
                    if (dropIntent === undefined) throw new Error(`dropIntent is undefined`);
                    if (dropIntent && dropIntent.action !== DropActions.notAllow) {
                        debug(`DragItemInteraction: perform drop [%j].`, dropIntent);
                        this.dispatchAction(DropSelectionTo(dropIntent));
                    }
                    return this.handled(false, true, true);
                }
            } finally {
                this.resetMatching();
            }
        }
        return super.onPointerUp(event);
    }

    /** 鼠标按下时并不立刻开始拖动, 需要移动一个较短的距离之后才进入拖动状态. */
    private isFarEnough(a: IMouseEventExtras, b: IMouseEventExtras) {
        const minimalDistance = 4;
        return isFarThan(a.viewportX, a.viewportY, b.viewportX, b.viewportY, minimalDistance);
    }
}

export class EditTextInteraction extends Interaction {
    public static readonly interactionId = 'EDIT_TEXT_INTERACTION';

    public constructor(modeManager: InteractionManager, editorRef: React.RefObject<HTMLInputElement>) {
        super(modeManager, EditTextInteraction.interactionId);
        this.editorRef = editorRef;
    }

    private readonly editorRef: React.RefObject<HTMLInputElement>;

    public renderAbove() {
        const { editingId, items } = this.diagram;
        if (!editingId) return null;
        const item = getItem(items, editingId, true);

        // 对于新创建的结点, 其布局要等该结点的 componentDidMount 完成后才能计算.
        if (!item.regionSize) return null;
        if (!item.bodySize) return null;

        // 因为 editor 的坐标需要采用相对于 svgContainerRef 的相对坐标
        const p = this.svgHelper.getItemBodyCoord(editingId);
        if (!p) return null;

        const textBounds = {
            x: p.x,
            y: p.y,
            width: item.bodySize.width,
            height: item.bodySize.height,
        };

        return (
            <InplaceTextEditor
                text={item.text}
                textBounds={textBounds}
                inputRef={this.editorRef}
                onCommit={this.commitEdit}
                onCancel={this.cancelEdit}
            />
        );
    }

    protected onDoubleClick(event: ComponentMouseEvent): HandlerResult {
        if (!this.isActive()) {
            const { itemId, inBody, inDiscussionIcon } = event.extras;
            const { selection } = this.diagram;

            if (itemId && inBody && !inDiscussionIcon && selection && selection.indexOf(itemId) >= 0) {
                this.startEdit(itemId);
                return this.handled(true, true, true);
            }
        }
        return super.onDoubleClick(event);
    }

    protected onPointerDown(event: ComponentPointerEvent): HandlerResult {
        const { itemId } = event.extras;
        const { editingId } = this.diagram;

        if (this.isActive()) {
            if (itemId !== editingId) {
                const editor = this.editorRef.current;
                if (!editor) throw new Error(`Internal state error: the editor is undefined.`);
                this.commitEdit(editor.value);
            }
            return this.handled(null, true, true);
        }
        return super.onPointerDown(event);
    }

    private startEdit = (itemId: MindId) => {
        this.dispatchAction(StartInplaceTextEdit(itemId));
    };

    private commitEdit = (text: string) => {
        const { editingId } = this.diagram;
        debug(`commitEdit [${editingId}] `);

        if (!editingId) throw new Error(`The editingId is undefined.`);

        this.dispatchAction(CommitInplaceTextEdit(editingId, text));
        this.interactionManager.deactivate(this);
    };

    private cancelEdit = () => {
        const { editingId } = this.diagram;
        debug(`cancelEdit [${editingId}] `);

        if (!editingId) throw new Error(`The editingId is undefined.`);

        this.dispatchAction(CancelInplaceTextEdit(editingId));
        this.interactionManager.deactivate(this);
    };
}
