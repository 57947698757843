import { Enum, NotImplementedException, StructureKinds } from 'neka-common';
import { getDebug } from '../core/app-diag';
import { Directions, IPos } from './mind-draw2d';
import {
    getItem,
    getStructureKind,
    IMindItem,
    ItemGet,
    MindId,
    MindIds,
    MindItemStateException,
    SNoBodyPosErr,
    SNoBodySizeErr,
} from './mind-models';
import { getSubtreeRootIds, isDescendantOf } from './mind-visitors';

const debug = getDebug(__filename);

export function safeStringify(obj: Object | undefined): string | undefined {
    if (!obj) return undefined;
    return JSON.stringify(obj);
}

export function mergeItemKeys(...maps: ReadonlyMap<MindId, any>[]): Set<MindId> {
    const ids = new Set();
    maps.forEach(m => {
        for (let key of m.keys()) {
            ids.add(key);
        }
    });
    return ids;
}

export type OSKinds = 'Unknown' | 'Windows' | 'MacOS' | 'UNIX' | 'Linux';

export function getOS(): OSKinds {
    if (navigator.appVersion.indexOf('Win') !== -1) return 'Windows';
    if (navigator.appVersion.indexOf('Mac') !== -1) return 'MacOS';
    if (navigator.appVersion.indexOf('X11') !== -1) return 'UNIX';
    if (navigator.appVersion.indexOf('Linux') !== -1) return 'Linux';
    return 'Unknown';
}

export function getHTMLElementPosition(el: HTMLElement) {
    // https://www.kirupa.com/html5/get_element_position_using_javascript.htm
    let xPos = 0;
    let yPos = 0;
    if (document && document.documentElement) {
        while (el) {
            if (el.tagName === 'BODY') {
                // deal with browser quirks with body/window/document and page scroll
                const xScroll = el.scrollLeft || document.documentElement.scrollLeft;
                const yScroll = el.scrollTop || document.documentElement.scrollTop;

                xPos += el.offsetLeft - xScroll + el.clientLeft;
                yPos += el.offsetTop - yScroll + el.clientTop;
            } else {
                // for all other non-BODY elements
                xPos += el.offsetLeft - el.scrollLeft + el.clientLeft;
                yPos += el.offsetTop - el.scrollTop + el.clientTop;
            }

            el = el.offsetParent as HTMLElement;
        }
    }
    return {
        x: xPos,
        y: yPos,
    };
}

// =========================================================
// 移动与拖放定位
// =========================================================

export const DropActions = Object.freeze({
    notAllow: 'notAllow' as 'notAllow',
    asParent: 'asParent' as 'asParent',
    asFirstChild: 'asFirstChild' as 'asFirstChild',
    asLastChild: 'asLastChild' as 'asLastChild',
    asPrevSibling: 'asPrevSibling' as 'asPrevSibling',
    asNextSibling: 'asNextSibling' as 'asNextSibling',
});
export type DropActions = Enum<typeof DropActions>;

/** 代表拖放操作将产生的结果. */
export interface IDropIntent {
    sourceIds: MindIds;
    anchorId?: MindId; // 目前 anchorId 必须有值, 将来可能允许拖放称为自由结点, 从而可以为 undefined
    direction?: Directions;
    action: DropActions;
}

/** 获取拖放操作将产生的结果. */
export function getDropIntent(
    items: ItemGet,
    anchorId: MindId | undefined,
    cursorPos: IPos,
    sourceIds: MindIds
): IDropIntent {
    const notAllow: IDropIntent = Object.freeze({
        anchorId,
        sourceIds,
        action: DropActions.notAllow,
    } as IDropIntent);

    if (!anchorId) return notAllow;
    if (!sourceIds || sourceIds.length === 0) return notAllow;

    const anchor = getItem(items, anchorId, true);
    if (!anchor.bodyPos) throw new MindItemStateException(anchor.id, SNoBodyPosErr);
    if (!anchor.bodySize) throw new MindItemStateException(anchor.id, SNoBodySizeErr);

    // 鼠标在目标结点的位置
    const direction = getDropPlace(items, anchor, cursorPos);
    if (direction === undefined) return { ...notAllow, direction };

    // 目标不能是源自身或子孙结点, 即 sourceIds 不能是 anchorId 的或其祖先结点
    const descendantOfSource = sourceIds.find(id => id === anchorId || isDescendantOf(items, anchorId, id));
    if (descendantOfSource !== undefined) {
        console.log(`isDescendantOfSource: ${anchorId}, ${sourceIds}`);
        return { ...notAllow, direction };
    }

    const isAnchorRoot = anchor.parentId === undefined;
    const childCount = anchor.childIds ? anchor.childIds.length : 0;
    const sourceRootIds = getSubtreeRootIds(items, sourceIds);
    const isSingleSource = sourceRootIds.length === 1;
    const parentOfTarget = isAnchorRoot ? undefined : getItem(items, anchor.parentId!, true);
    const anchorStructureKind = getStructureKind(items, anchor);
    const parentStructureKind = isAnchorRoot ? undefined : getStructureKind(items, parentOfTarget!);

    const sharedProps: IDropSharedProps = { anchorId, sourceIds, direction };

    if (isAnchorRoot) {
        switch (anchorStructureKind) {
            case StructureKinds.LogicRight:
                if (childCount < 1) {
                    return notAllow;
                } else {
                    if (isSingleSource) {
                        const actionMapper = directionActionMapper(
                            DropActions.asFirstChild,
                            DropActions.asLastChild,
                            DropActions.asParent,
                            DropActions.asLastChild
                        );
                        return actionMapper(sharedProps, direction);
                    } else {
                        const actionMapper = directionActionMapper(
                            DropActions.asFirstChild,
                            DropActions.asLastChild,
                            DropActions.notAllow,
                            DropActions.asLastChild
                        );
                        return actionMapper(sharedProps, direction);
                    }
                }
            case StructureKinds.OrgDown:
                if (childCount < 1) {
                    return notAllow;
                } else {
                    if (isSingleSource) {
                        const actionMapper = directionActionMapper(
                            DropActions.asParent,
                            DropActions.asLastChild,
                            DropActions.asFirstChild,
                            DropActions.asLastChild
                        );
                        return actionMapper(sharedProps, direction);
                    } else {
                        const actionMapper = directionActionMapper(
                            DropActions.notAllow,
                            DropActions.asLastChild,
                            DropActions.asFirstChild,
                            DropActions.asLastChild
                        );
                        return actionMapper(sharedProps, direction);
                    }
                }
            default:
                throw new NotImplementedException(`structureKind [${anchorStructureKind}]`);
        }
    } else {
        switch (parentStructureKind) {
            case StructureKinds.LogicRight:
                switch (anchorStructureKind) {
                    case StructureKinds.LogicRight:
                        if (childCount < 1) {
                            const actionMapper = directionActionMapper(
                                DropActions.asPrevSibling,
                                DropActions.asNextSibling,
                                DropActions.asParent,
                                DropActions.asLastChild
                            );
                            return actionMapper(sharedProps, direction);
                        } else {
                            const actionMapper = directionActionMapper(
                                DropActions.asPrevSibling,
                                DropActions.asNextSibling,
                                DropActions.asParent,
                                DropActions.asLastChild
                            );
                            return actionMapper(sharedProps, direction);
                        }
                    case StructureKinds.OrgDown:
                        if (childCount < 1) {
                            const actionMapper = directionActionMapper(
                                DropActions.asPrevSibling,
                                DropActions.asNextSibling,
                                DropActions.asParent,
                                DropActions.asLastChild
                            );
                            return actionMapper(sharedProps, direction);
                        } else {
                            const actionMapper = directionActionMapper(
                                DropActions.asPrevSibling,
                                DropActions.asFirstChild,
                                DropActions.asParent,
                                DropActions.asLastChild
                            );
                            return actionMapper(sharedProps, direction);
                        }
                    default:
                        throw new NotImplementedException(`structureKind [${anchorStructureKind}]`);
                }
            case StructureKinds.OrgDown:
                switch (anchorStructureKind) {
                    case StructureKinds.LogicRight:
                        if (childCount < 1) {
                            const actionMapper = directionActionMapper(
                                DropActions.asParent,
                                DropActions.asLastChild,
                                DropActions.asPrevSibling,
                                DropActions.asNextSibling
                            );
                            return actionMapper(sharedProps, direction);
                        } else {
                            const actionMapper = directionActionMapper(
                                DropActions.asParent,
                                DropActions.asLastChild,
                                DropActions.asPrevSibling,
                                DropActions.asFirstChild
                            );
                            return actionMapper(sharedProps, direction);
                        }
                    case StructureKinds.OrgDown:
                        if (childCount < 1) {
                            const actionMapper = directionActionMapper(
                                DropActions.asParent,
                                DropActions.asLastChild,
                                DropActions.asPrevSibling,
                                DropActions.asNextSibling
                            );
                            return actionMapper(sharedProps, direction);
                        } else {
                            const actionMapper = directionActionMapper(
                                DropActions.asParent,
                                DropActions.asLastChild,
                                DropActions.asPrevSibling,
                                DropActions.asNextSibling
                            );
                            return actionMapper(sharedProps, direction);
                        }
                    default:
                        throw new NotImplementedException(`structureKind [${anchorStructureKind}]`);
                }
            default:
                throw new NotImplementedException(`structureKind [${anchorStructureKind}]`);
        }
    }
}

type IDropSharedProps = Pick<IDropIntent, 'anchorId' | 'sourceIds' | 'direction'>;

function directionActionMapper(up: DropActions, down: DropActions, left: DropActions, right: DropActions) {
    return function dropAction(shareProps: IDropSharedProps, direction: Directions): IDropIntent {
        switch (direction) {
            case Directions.up:
                return { ...shareProps, action: up };
            case Directions.down:
                return { ...shareProps, action: down };
            case Directions.left:
                return { ...shareProps, action: left };
            case Directions.right:
                return { ...shareProps, action: right };
            default:
                throw new NotImplementedException(`direction [${direction}]`);
        }
    };
}

function getDropPlace(items: ItemGet, target: IMindItem, cursorInBody: IPos): Directions | undefined {
    if (!target.bodyPos) throw new MindItemStateException(target.id, SNoBodyPosErr);
    if (!target.bodySize) throw new MindItemStateException(target.id, SNoBodySizeErr);

    let { width, height } = target.bodySize;

    let { x, y } = cursorInBody;

    debug(`getDropPlace: bodyPos [%j], bodySize [%j], cursor [%j]`, target.bodyPos, target.bodySize, cursorInBody);

    if (x < 0 || width < x) return undefined;
    if (y < 0 || height < y) return undefined;

    const horiEdge = width * (1 / 4);
    const vertPartition = height / 2;

    if (x <= horiEdge) return Directions.left;
    if (x >= width - horiEdge) return Directions.right;
    if (y <= vertPartition) return Directions.up;
    return Directions.down;
}
