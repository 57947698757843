import React from 'react';
import { IDiscussionPost, IPrincipal } from 'neka-common';
import Scrollbars from 'react-custom-scrollbars';
import { IDispatch } from '../../../../common/redux-utils';
import { showErrorDialog, showErrorNotification } from '../../../../common/ui-utils';
import { IMindDiagram } from '../../../mind-models';
import { IDiscussionView } from '../../../discussion-model';
import { ChangeDiscussionStatus } from '../../../store/mind-redux';
import MindDiscussionFrom from './mind-discussion-from';
import MindDiscussionList from './mind-discussion-list';
import { apis } from '../../../../core/server-apis';
import { FetchDiscussionListComplete } from '../../../store/discussion-redux';

interface IProps extends IDispatch {
    diagram: IMindDiagram;
    principal?: IPrincipal;
    discussions: IDiscussionView;
}

/** 代表 {@link MindToolWindow} 的一个 tab 中的内容. */
export default class MindDiscussionPane extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
        // 判断是否切换了节点，切换节点需要重新加载帖子
        const pSelection = prevProps.diagram.selection,
            selection = this.props.diagram.selection;

        if (
            this.props.diagram.activeToolPaneKind !== prevProps.diagram.activeToolPaneKind ||
            (pSelection && selection && selection[selection.length - 1] !== pSelection[pSelection.length - 1])
        ) {
            // 加载帖子列表
            this.loadingList();
        }
    }

    componentDidMount() {
        // 加载帖子列表
        this.loadingList();
    }

    private async loadingList() {
        try {
            if (!this.props.diagram) {
                throw new Error(`The diagram is undefined for Discussion componentDid.`);
            }
            const { selection, items } = this.props.diagram;

            let docItemId: string = selection && selection.length > 0 ? selection[selection.length - 1] : '';
            if (docItemId) {
                const listData = await apis.discussions.list(docItemId);
                const item = items.get(docItemId);

                if (listData && item) {
                    const model: IDiscussionView = {
                        discussions: listData,
                        docItemId: docItemId,
                        nodeTitle: item.text,
                        isLoading: true,
                        hasDiscussion: item.hasDiscussion,
                    };
                    this.props.dispatch(FetchDiscussionListComplete(model));
                }

                // 设置帖子为已读状态
                if ((listData && listData.length > 0) || (item && item.hasUnreadDiscussion)) {
                    await apis.discussions.setDiscussRead(docItemId);
                    this.props.dispatch(ChangeDiscussionStatus({ id: docItemId, hasUnreadDiscussion: false }));
                }
            }
        } catch (e) {
            showErrorNotification(e);
        }
    }

    public render() {
        const { dispatch, discussions, principal, diagram } = this.props;
        return (
            <div className="mind-discussion-tab">
                <Scrollbars className="discussion-tab-scroll" autoHide={true}>
                    <div className="pane-title">主题：{discussions.nodeTitle}</div>
                    {diagram && discussions.docItemId && (
                        <MindDiscussionFrom
                            docItemId={discussions.docItemId}
                            postId={discussions.docItemId}
                            placeHolder="发布新讨论"
                            replyInputFocus={false}
                            dispatch={dispatch}
                        />
                    )}
                    {discussions && (
                        <MindDiscussionList
                            discussionView={discussions}
                            docOwnerId={diagram.ownerId}
                            principal={principal}
                            dispatch={dispatch}
                        />
                    )}
                </Scrollbars>
            </div>
        );
    }
}
