import { Tooltip } from 'antd';
import React from 'react';
import { IThunkDispatch } from '../../common/redux-utils';
import { getDebug } from '../../core/app-diag';
import { IRootState } from '../../store/app-redux';
import { MindDocumentTitleMaxLength, SDocumentNotLoadedErr } from '../mind-models';
import { ChangeDiagramTitle } from '../store/mind-redux';
import { isCancelTextEditKey, isCommitTextEditKey } from './kb-helper';

import './mind-document-title.less';
import { connect } from 'react-redux';

const debug = getDebug(__filename);

interface IConnectedProps extends Pick<IRootState, 'isDiagramLoading' | 'diagram'> {}

interface IProps extends IConnectedProps, IThunkDispatch {}

interface IState {
    title?: string;
    isEditing: boolean;
    /** 从标题的 span 计算得到, 从而保证在编辑时 input 的宽度与之前标题的显示宽度一致. */
    inputWidth?: number;
}

export class MindDocumentTitleView extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { isEditing: false, inputWidth: 400 };
    }

    private textRef: React.RefObject<HTMLSpanElement> = React.createRef();
    private inputRef: React.RefObject<HTMLInputElement> = React.createRef();

    public static getDerivedStateFromProps: React.GetDerivedStateFromProps<IProps, IState> = (nextProps, prevState) => {
        // const newState: Partial<IState> = {};
        return null;
    };

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (this.textRef.current) {
            const inputWidth = this.textRef.current.clientWidth;
            if (this.state.inputWidth !== inputWidth) {
                this.setState({ inputWidth });
            }
        }
    }

    public render() {
        const { diagram } = this.props;
        if (!diagram) return null;

        const { allowEdit, title } = diagram;
        const { isEditing } = this.state;

        if (isEditing) {
            return (
                <span className="mind-document-title title">
                    <input
                        ref={this.inputRef}
                        autoFocus={true}
                        className="title-input"
                        value={this.state.title}
                        style={{ width: this.state.inputWidth }}
                        onBlur={this.onTitleEditorBlur}
                        onChange={this.onTitleChange}
                        onKeyDown={this.onEditorKeyDown}
                        maxLength={MindDocumentTitleMaxLength}
                    />
                </span>
            );
        } else {
            const titleSpan = (
                <span
                    ref={this.textRef}
                    className="title-text"
                    onClick={allowEdit ? this.handleTitleTextClick : undefined}
                >
                    {title}
                </span>
            );
            return (
                <React.Fragment>
                    <span className="mind-document-title title">
                        {allowEdit ? (
                            <Tooltip placement="bottom" title="点击修改标题">
                                {titleSpan}
                            </Tooltip>
                        ) : (
                            titleSpan
                        )}
                    </span>
                    {!allowEdit && (
                        <span className="title-readonly">
                            <Tooltip
                                placement="bottom"
                                title="只有方案归属人才能对脑图进行编辑，当前您可以参与讨论或邀请其他人"
                            >
                                | <span className="icon">只读</span>
                            </Tooltip>
                        </span>
                    )}
                </React.Fragment>
            );
        }
    }

    private handleTitleTextClick: React.MouseEventHandler = event => {
        if (this.props.diagram && this.props.diagram.allowEdit) {
            this.setState({ isEditing: true, title: this.props.diagram.title });
        }
    };

    private onTitleEditorBlur: React.FocusEventHandler = event => {
        const { diagram } = this.props;
        if (!diagram) throw new Error(SDocumentNotLoadedErr);
        this.commitEdit();
    };

    private onTitleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        this.setState({ title: event.target.value });
    };

    private onEditorKeyDown: React.KeyboardEventHandler<HTMLInputElement> = event => {
        const { diagram } = this.props;
        if (!diagram) throw new Error(SDocumentNotLoadedErr);

        if (isCommitTextEditKey(event)) {
            this.commitEdit();
        } else if (isCancelTextEditKey(event)) {
            this.cancelEdit();
        }
    };

    private commitEdit() {
        const { diagram } = this.props;
        if (!diagram) throw new Error(SDocumentNotLoadedErr);

        const oldTitle = diagram.title;
        let { title } = this.state;
        if (!title || title.trim().length === 0) {
            title = oldTitle;
        }

        this.setState({ isEditing: false });
        this.props.dispatch(ChangeDiagramTitle(title));
    }

    private cancelEdit() {
        const { diagram } = this.props;
        if (!diagram) throw new Error(SDocumentNotLoadedErr);

        const oldTitle = diagram.title;
        this.setState({ title: oldTitle, isEditing: false });
    }
}

const connected = connect<IConnectedProps, {}, {}, IRootState>((state: IRootState) => {
    return {
        diagram: state.diagram,
        isDiagramLoading: state.isDiagramLoading,
    };
})(MindDocumentTitleView);
export default connected;
