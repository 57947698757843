/******************************************************************************/
//  MindBoard based on React & SVG
/******************************************************************************/

import { Modal } from 'antd';
import React, { Component } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IThunkDispatch } from '../../common/redux-utils';
import { shallowEqual } from '../../common/ui-utils';
import { getDebug } from '../../core/app-diag';
import { IRootState } from '../../store/app-redux';
import { IPos, ISize, NullSize } from '../mind-draw2d';
import { getFocusedId, getItem, IMindDiagram, MindId, SDocumentNotLoadedErr, ToolPaneKinds } from '../mind-models';
import { IDropIntent } from '../mind-utils';
import {
    ChangeActiveToolPane,
    CollapseSelection,
    CopyToClipboard,
    CreateSelectionLastChild,
    CreateSelectionNextSibling,
    CutToClipboard,
    DeleteSelection,
    ExpandSelection,
    FocusDown,
    FocusLeft,
    FocusRight,
    FocusUp,
    InverseItemExpanded,
    MoveSelectionDown,
    MoveSelectionLeft,
    MoveSelectionRight,
    MoveSelectionUp,
    PasteFromClipboard,
    ReLayout,
    StartInplaceTextEdit,
} from '../store/mind-redux';

import {
    GuardedActionCreator,
    isCollapseKey,
    isCopyToClipboardKey,
    isCreateChildKey,
    isCreateNextSiblingKey,
    isCutToClipboardKey,
    isDeleteSubtreeKey,
    isExpandKey,
    isFocusDownKey,
    isFocusLeftKey,
    isFocusRightKey,
    isFocusUpKey,
    isIgnoredKeys,
    isMoveDownKey,
    isMoveLeftKey,
    isMoveRightKey,
    isMoveUpKey,
    isPasteFromClipboardKey,
    isStartEditKey,
} from './kb-helper';
import {
    DragItemInteraction,
    EditTextInteraction,
    GripExpansionInteraction,
    IBoard,
    Interaction,
    InteractionManager,
    MultiSelectInteraction,
    SingleSelectInteraction,
    TrackingInteraction,
    WheelZoomInteraction,
} from './mind-interactions';
import { MindPainter } from './mind-painter';
import { MindToolbar } from './mind-toolbar/mind-toolbar';
import { ItemLayoutPendingHandler, IWidgetEventHandlers, MindWidget } from './mind-widget';
import { SvgHelper } from './svg-helper';

const debug = getDebug(__filename);

interface IConnectedProps extends Pick<IRootState, 'diagram'> {}

interface IOwnProps {}

interface IProps extends IConnectedProps, IOwnProps, IThunkDispatch {
    /** The {@link IMindDiagram}, typically passed from its wrapper {@link MindBoard}. */
}

interface IState {
    /** 是否正在选择结点过程中. */
    isSelecting?: boolean;
    /** 是在原有的 selection 上追加, 还是替换原有的 selection. */
    isAppending?: boolean;

    /** 是否正在拖动结点的过程中. */
    isDragging?: boolean;
    dropIntent?: IDropIntent;

    // 在 MouseMove 事件中无法直接得到 Mouse Button 是否被按下, 需要跟踪 MouseDown 事件的结果.
    isLeftButtonDown: boolean;
    lastMouseDownCoord?: IPos;
    lastMouseCoord?: IPos;

    prevSelection?: ReadonlyArray<MindId>;
}

/** 脑图的画布. */
class MindBoardView extends Component<IProps, IState> {
    public constructor(props: IProps) {
        super(props);
        this.state = {
            isSelecting: false,
            isAppending: false,
            isDragging: false,
            isLeftButtonDown: false,
        };

        this.interactionManager = this.createInteractionManagerIfNeeded(true);
    }

    //#region interaction manager

    private static adaptBoard(board: MindBoardView): IBoard {
        class BoardAdapter implements IBoard {
            constructor(private readonly adaptedBoard: MindBoardView) {}

            public get diagram() {
                if (!this.adaptedBoard.props.diagram) throw new Error(SDocumentNotLoadedErr);
                return this.adaptedBoard.props.diagram;
            }

            public get svgHelper() {
                return this.adaptedBoard.svgHelper;
            }

            public get svgRef() {
                return this.adaptedBoard.svgRef;
            }

            public repaint() {
                return this.adaptedBoard.forceUpdate();
            }

            public dispatchAction(action: AnyAction): void {
                this.adaptedBoard.props.dispatch(action);
            }

            public dispatchThunk(action: ThunkAction<any, any, any, any>): void {
                this.adaptedBoard.props.dispatch(action);
            }
        }

        return new BoardAdapter(board);
    }

    private interactionManager: InteractionManager;
    private lastAllowEdit: boolean;

    private createInteractionManagerIfNeeded(allowEdit: boolean): InteractionManager {
        if (allowEdit !== this.lastAllowEdit || this.interactionManager === undefined) {
            this.interactionManager = this.createInteractionManager(allowEdit);
            this.lastAllowEdit = allowEdit;
        }
        return this.interactionManager;
    }

    private createInteractionManager(allowEdit: boolean): InteractionManager {
        const board = MindBoardView.adaptBoard(this);

        const manager = new InteractionManager(board);
        manager.registerInteraction(new TrackingInteraction(manager));
        manager.registerInteraction(new WheelZoomInteraction(manager));
        manager.registerInteraction(new SingleSelectInteraction(manager));
        manager.registerInteraction(new GripExpansionInteraction(manager));
        manager.registerInteraction(new MultiSelectInteraction(manager));

        if (allowEdit) {
            manager.registerInteraction(new DragItemInteraction(manager));
            manager.registerInteraction(new EditTextInteraction(manager, this.editorRef));
        }

        return manager;
    }

    //#endregion

    private boardRef = React.createRef<HTMLDivElement>();
    /** 用于承载 SVG 的 `div` 元素. */
    private siteRef = React.createRef<HTMLDivElement>();
    /** 用于绘图的 SVG, 保留其 ref 可供第三方库使用. */
    private svgRef = React.createRef<SVGSVGElement>();
    /** 文本编辑框中的 `<input>` 组件, 用于设置 focus. */
    private editorRef = React.createRef<HTMLInputElement>();
    /** 所有 widget 的 ref. 从而可以便捷地使用 id 查找对应 widget. */
    private widgetRefs = new Map<MindId, React.RefObject<MindWidget>>();

    private svgHelper: SvgHelper = new SvgHelper(this.svgRef, () => {
        const { diagram } = this.props;
        if (!diagram) throw new Error(SDocumentNotLoadedErr);
        return diagram;
    });

    /** 需要重新布局的结点列表, MindWidget 在绘制过程中向此 Map 登记需要更新的结点, 然后批量地交由 redux 更新. */
    private layoutPendingItems = new Map<MindId, () => ISize>();

    private handleItemLayoutPending: ItemLayoutPendingHandler = (item, layoutFn) => {
        debug(`register layoutPending item: [${item.id}].`);
        this.layoutPendingItems.set(item.id, layoutFn);
    };

    private completeItemLayouts() {
        if (this.layoutPendingItems.size > 0) {
            const textSizes = new Map<MindId, ISize>();

            this.layoutPendingItems.forEach((layoutFn, key) => {
                const size = layoutFn();
                textSizes.set(key, size);
            });

            this.props.dispatch(ReLayout(textSizes));
            this.layoutPendingItems.clear();
        }
    }

    private enableKeyboardEvents() {
        if (this.svgRef.current) this.svgRef.current.focus();
    }

    public shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        const same =
            shallowEqual(nextProps, this.props) &&
            shallowEqual(this.state, nextState) &&
            this.layoutPendingItems.size === 0;

        if (nextProps.diagram) {
            this.createInteractionManagerIfNeeded(nextProps.diagram.allowEdit);
        }

        return !same;
    }

    public componentDidMount(): void {
        debug(`componentDidMount`);
        this.completeItemLayouts();
        this.enableKeyboardEvents();

        window.addEventListener('resize', this.updateBoardSize);
    }

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.updateBoardSize);
    }

    private updateBoardSize = () => {
        if (this.boardRef && this.boardRef.current) {
            const rc = this.boardRef.current.getBoundingClientRect();
            this.boardSize = {
                width: rc.width + 8,
                height: rc.height + 8,
            };
            this.forceUpdate();
        }
    };

    private boardSize?: ISize;
    private svgDebugOriginRect: DOMRect | undefined;

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        // debug(`componentDidUpdate with layoutPendingItems: [${this.layoutPendingItems.size}]`);
        const { diagram } = this.props;
        if (diagram) {
            const { editingId } = diagram;

            if (this.layoutPendingItems.size > 0) {
                this.completeItemLayouts();
                // 当 DidUpdate 是由部分结点变化(如新增)引发时, 需要在 redux 引发的重绘之后才能得到这些结点的新坐标,
                // 所以需要在 redux 引发的重绘完成后, 再次引发重绘以使得 InplaceTextEditor 出现在正确位置.c
                if (editingId) {
                    this.setState({}, () => {
                        this.forceUpdate();
                    });
                }
            } else {
                if (editingId) {
                    this.scrollItemIntoView(editingId);
                }
                if (editingId && this.editorRef.current) {
                    this.editorRef.current.focus();
                    this.interactionManager.activate(EditTextInteraction.interactionId);
                } else {
                    this.enableKeyboardEvents();
                }
            }
        }

        if (this.boardRef.current && !this.boardSize) {
            this.updateBoardSize();
        }

        this.svgDebugOriginRect = this.svgHelper.createSvgRect(0, 0, 2, 2);
    }

    /** 如果元素不在可见区域内, 则将其滚动至可见区域 */
    private scrollItemIntoView(id: MindId) {
        if (id) {
            const focusedEl = this.svgHelper.getBodyEl(id);
            if (focusedEl && !this.svgHelper.isBodyVisible(id)) {
                focusedEl.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            }
        }
    }

    public render() {
        return (
            <div className="mind-board" ref={this.boardRef}>
                {this.renderBoardContent()}
            </div>
        );
    }

    private renderBoardContent() {
        const { diagram } = this.props;
        if (!diagram) return null;

        return (
            <React.Fragment>
                <MindToolbar diagram={diagram} dispatch={this.props.dispatch} />
                <Scrollbars className="mind-board-scroll" autoHide={false}>
                    <div
                        ref={this.siteRef}
                        className="mind-canvas-container"
                        // tabIndex={0}
                        onKeyDown={this.handleKeyDown}
                    >
                        {this.renderBoardSvg()}
                        {this.interactionManager.renderAbove()}
                    </div>
                </Scrollbars>
            </React.Fragment>
        );
    }

    public renderBoardSvg() {
        const { diagram } = this.props;
        if (!diagram) return null;

        const { items, theme, rootId, scale } = diagram;

        const rootItem = getItem(items, rootId, true);
        const rootSize = rootItem.regionSize ? rootItem.regionSize : NullSize;
        const viewOptions = this.svgHelper.calcViewBox(rootSize, this.boardSize, scale);

        return (
            <svg
                ref={this.svgRef}
                xmlns="http://www.w3.org/2000/svg"
                xlinkHref="http://www.w3.org/1999/xlink"
                className="mg-canvas"
                tabIndex={0}
                width={viewOptions.width}
                height={viewOptions.height}
                viewBox={viewOptions.viewBox}
                onClick={this.handleBoardClick}
                onPointerDown={this.handlePointerDown}
                onPointerMove={this.handlePointerMove}
                onPointerUp={this.handlePointerUp}
                onWheel={this.handleSiteWheel}
                onDoubleClick={this.handleDoubleClick}
                onContextMenu={this.handleContextMenu}
            >
                {this.renderDebugViewportOrigin()}
                {this.renderDebugUserCoordinateOrigin()}
                <g data-special="board-canvas-with-scale" transform={`scale(${scale} ${scale})`}>
                    {this.renderDebugRootRegion(rootSize)}
                    <MindPainter
                        items={items}
                        theme={theme}
                        handlers={this.widgetHandlers}
                        rootId={rootId}
                        svgHelper={this.svgHelper}
                        widgetRefs={this.widgetRefs}
                    />
                    {this.interactionManager.render()}
                </g>
            </svg>
        );
    }

    /** 将 SVG viewport 的原点展示在画布上. */
    private renderDebugViewportOrigin() {
        if (!this.props.diagram || !this.props.diagram.renderDebugHelpers) return null;

        if (this.svgRef && this.svgRef.current) {
            const svg = this.svgRef.current;
            const pt = svg.createSVGPoint();
            pt.x = 0;
            pt.y = 0;
            const ctm = svg.getCTM();
            if (ctm) {
                const trans = pt.matrixTransform(ctm.inverse());
                // debug(`transformed viewport origin: %o`, trans);
                return <rect x={trans.x} y={trans.y} width={4} height={4} className="mg-debug-svg-ctm-origin" />;
            }
        }
        return null;
    }

    private renderDebugUserCoordinateOrigin() {
        if (!this.props.diagram || !this.props.diagram.renderDebugHelpers) return null;
        return <rect x={0} y={0} width={4} height={4} className="mg-debug-svg-origin" />;
    }

    private renderDebugRootRegion(rootSize: ISize) {
        if (!this.props.diagram || !this.props.diagram.renderDebugHelpers) return null;
        return <rect x={0} y={0} width={rootSize.width} height={rootSize.height} className="mg-debug-root-region" />;
    }

    private handlePointerDown: React.PointerEventHandler = event => {
        this.interactionManager.handlePointerDown(event);
    };

    private handlePointerMove: React.PointerEventHandler = event => {
        this.interactionManager.handlePointerMove(event);
    };

    private handlePointerUp: React.PointerEventHandler = event => {
        this.interactionManager.handlePointerUp(event);
    };

    private handleBoardClick: React.MouseEventHandler = event => {
        this.interactionManager.handleClick(event);
    };

    private handleDoubleClick: React.MouseEventHandler = event => {
        this.interactionManager.handleDoubleClick(event);
    };

    private handleSiteWheel: React.WheelEventHandler = event => {
        this.interactionManager.handleMouseWheel(event);
    };

    private handleContextMenu: React.MouseEventHandler = event => {
        this.interactionManager.handleContextMenu(event);
    };

    private keyHandlers: Array<GuardedActionCreator> = [
        [isStartEditKey, StartInplaceTextEdit],
        [isCreateChildKey, CreateSelectionLastChild],
        [isCreateNextSiblingKey, CreateSelectionNextSibling],
        [isDeleteSubtreeKey, DeleteSelection],
        [isFocusUpKey, FocusUp],
        [isFocusDownKey, FocusDown],
        [isFocusLeftKey, FocusLeft],
        [isFocusRightKey, FocusRight],
        [isMoveUpKey, MoveSelectionUp],
        [isMoveDownKey, MoveSelectionDown],
        [isMoveLeftKey, MoveSelectionLeft],
        [isMoveRightKey, MoveSelectionRight],
        [isExpandKey, ExpandSelection],
        [isCollapseKey, CollapseSelection],
        [isCutToClipboardKey, CutToClipboard],
        [isCopyToClipboardKey, CopyToClipboard],
        [isPasteFromClipboardKey, PasteFromClipboard],
    ];

    private handleKeyDown: React.KeyboardEventHandler = event => {
        debug(`handleKeyDown [${event.key}, meta: [${event.metaKey}]] %o`, event);

        if (isIgnoredKeys(event)) return;

        // disables all keyboard event when an mouse interaction is active
        if (this.interactionManager.hasActiveInteraction()) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        const { diagram, dispatch } = this.props;
        if (!diagram) return;

        const { editingId, isExecutingRemoteCommands, remoteCommands } = diagram;
        const executing = isExecutingRemoteCommands || (remoteCommands && remoteCommands.length > 0);

        if (editingId === undefined) {
            if (!executing) {
                const guarded = this.keyHandlers.find(x => {
                    const [guard] = x;
                    return guard(event) === true;
                });

                if (guarded !== undefined) {
                    const [guard, creator] = guarded;

                    const executeKeyAction = () => {
                        const focusedId = getFocusedId(diagram);
                        const action = creator(focusedId);
                        dispatch(action);
                    };

                    if (
                        (creator === CutToClipboard || creator === DeleteSelection) &&
                        this.shouldConfirmDelete(diagram)
                    ) {
                        Modal.confirm({
                            title: '确认',
                            content: '您试图删除多个主题或者被删除的主题包含讨论, 撤销功能尚未开发~请谨慎确认~!',
                            autoFocusButton: 'cancel',
                            onOk: () => {
                                executeKeyAction();
                            },
                        });
                    } else {
                        executeKeyAction();
                    }

                    event.preventDefault();
                    event.stopPropagation();
                }
            }
        }
        // 如果有正在编辑的元素, 则继续 Propagation, 并由 React 转发至 InplaceTextEditor 处理按键.
    };

    private shouldConfirmDelete(diagram: IMindDiagram): boolean {
        const { items, selection } = diagram;
        if (!selection) return false;
        if (selection.length > 1) return true;
        const item = getItem(items, selection[0], true);
        const hasChild = item.childIds && item.childIds.length > 0;
        return hasChild || item.hasDiscussion === true;
    }

    private handleDiscussionIconPointerDown: React.MouseEventHandler = event => {
        this.props.dispatch(ChangeActiveToolPane(ToolPaneKinds.discussionPane));
    };

    private readonly widgetHandlers: IWidgetEventHandlers = Object.freeze({
        onLayoutPending: this.handleItemLayoutPending,
        onDiscussionIconPointerDown: this.handleDiscussionIconPointerDown,
    });

    private logMouseEvent(handlerName: string, event: React.MouseEvent) {
        const { nativeEvent, target, currentTarget, relatedTarget } = event;
        debug(
            `${handlerName}: event [%o], nativeEvent [%o], target [%o], currentTarget [%o], relatedTarget [%o]`,
            event,
            nativeEvent,
            target,
            currentTarget,
            relatedTarget
        );
    }
}

const MindBoard = connect<IConnectedProps, {}, IOwnProps, IRootState>((state: IRootState) => ({
    diagram: state.diagram,
}))(MindBoardView);
export default MindBoard;
