import { ArgumentException, ArgumentMissingException, assertUnreachable, Enum } from 'neka-common';
import React from 'react';
import { getDebug } from '../../core/app-diag';
import { assignRect, IPos, IRect, ISize, ISpacer, NullPos } from '../mind-draw2d';
import { absoluteBodyPos } from '../mind-layout';
import { getItem, IMindDiagram, IMindItem, MindId } from '../mind-models';
import { GripHalf, TextPadding, TopicIconSize } from '../mind-styles';

const debug = getDebug(__filename);

export const SvgNS = 'http://www.w3.org/2000/svg';

/** SVG 元素的 data-* 属性. */
export const SvgDataAttr = Object.freeze({
    id: 'data-id',
    kind: 'data-kind',
});

/** 定义了 SVG 元素的 data-kind 类型常量. */
export const SvgElKinds = Object.freeze({
    itemContainer: 'item-container' as 'item-container',

    itemWrapper: 'item-wrapper' as 'item-wrapper',

    bodyWrapper: 'body-wrapper' as 'body-wrapper',

    itemBorder: 'item-border' as 'item-border',

    itemContent: 'item-content' as 'item-content',

    /** 主题文字右侧的 图标. */
    itemDiscussionIcon: 'item-discussion-icon' as 'item-discussion-icon',

    itemPole: 'item-pole' as 'item-pole',

    /** 用于显示/控制结点缩放状态的元素, 它包含两个子元素 {@link gripBoundary} 与 {@link GripArrow}. */
    gripWrapper: 'grip-wrapper' as 'grip-wrapper',

    /** 包含在一个 {@link gripWrapper} 中, 表示 Grip 边框的元素. */
    gripBoundary: 'grip-boundary' as 'grip-boundary',

    /** 包含在一个 {@link gripWrapper} 中, 表示结点是否展开的元素. */
    gripExpander: 'grip-expander' as 'grip-expander',

    /** 结点之间的连线. */
    itemLink: 'item-link' as 'item-link',

    /** 修饰选中结点的边框 */
    selectionDecorator: 'selection-decorator' as 'selection-decorator',
});
export type SvgElKind = Enum<typeof SvgElKinds>;

export interface IHitTest {
    itemId?: MindId;

    /** 以下只有在 {@link itemId} 存在时, 其值才可能为 `true`. */
    inBody?: boolean;
    inContent?: boolean;
    inDiscussionIcon?: boolean;
    inGrip?: boolean;
}

export function getItemIdFromSvgEl(svgEl: SVGElement): MindId | null {
    return svgEl.getAttribute(SvgDataAttr.id);
}

export function isElKind(svgEl: SVGElement, kind: SvgElKind): boolean {
    const kindValue = svgEl.getAttribute(SvgDataAttr.kind);
    return kindValue === kind;
}

interface ISvgViewOptions extends Readonly<ISize> {
    readonly viewBox: string;
}

export class SvgHelper {
    public constructor(svgRef: React.RefObject<SVGSVGElement>, getDiagram: () => IMindDiagram) {
        if (!getDiagram) throw new ArgumentMissingException('getDiagram');
        if (!svgRef) throw new ArgumentMissingException('svgRef');

        this.getDiagram = getDiagram;
        this.svgRef = svgRef;
    }

    private readonly getDiagram: () => IMindDiagram;
    private readonly svgRef: React.RefObject<SVGSVGElement>;

    private static readonly miniSize = { width: 1, height: 1 };
    private static readonly defaultViewBoxSpacer: Readonly<ISpacer> = Object.freeze({
        left: 48,
        top: 48,
        right: 200, // the max width of text input
        bottom: 48,
    });

    /** 根据 root region 的大小, 返回 SVG 大小与 viewBox. 如果 viewBox 的大小和 SVG 的大小不一致, 则会自动缩放 */
    public calcViewBox(rootRegionSize: ISize, minSize: ISize = SvgHelper.miniSize, scale: number = 1): ISvgViewOptions {
        const { left, top, right, bottom } = SvgHelper.defaultViewBoxSpacer;

        const actualWidth = rootRegionSize.width * scale + left + right;
        const actualHeight = rootRegionSize.height * scale + top + bottom;

        const width = Math.max(actualWidth, minSize.width);
        const height = Math.max(actualHeight, minSize.height);

        const rect: IRect = {
            x: -left,
            y: -top,
            width: width,
            height: height,
        };

        return {
            ...rect,
            viewBox: svgViewBoxString(rect),
        };
    }

    private get svgEl() {
        if (!this.svgRef || !this.svgRef.current) throw new Error(`The underlying svg element is null.`);
        return this.svgRef.current;
    }

    private getCanvasEl(): SVGGElement | undefined {
        const svg = this.svgRef.current;
        if (!svg) return undefined;
        const selector = 'g[data-special="board-canvas-with-scale"]';
        return svg.querySelector(selector) as SVGGElement;
    }

    public viewportToAbsolutePos(coord: IPos): IPos {
        const svg = this.svgEl;
        const pt = svg.createSVGPoint();
        pt.x = coord.x;
        pt.y = coord.y;

        const canvasEl = this.getCanvasEl()!;
        const result = pt.matrixTransform(canvasEl.getCTM()!.inverse());
        return result;
    }

    public viewportToAbsoluteRect(area: IRect): IRect {
        const ptLeftTop = this.viewportToAbsolutePos(area);
        const ptRightBottom = this.viewportToAbsolutePos({ x: area.x + area.width, y: area.y + area.height });
        const result = {
            x: ptLeftTop.x,
            y: ptLeftTop.y,
            width: ptRightBottom.x - ptLeftTop.x,
            height: ptRightBottom.y - ptLeftTop.y,
        };
        return result;
    }

    public absoluteToViewportPos(pos: IPos): IPos {
        const svg = this.svgEl;
        const pt = svg.createSVGPoint();
        pt.x = pos.x;
        pt.y = pos.y;

        const canvasEl = this.getCanvasEl()!;
        const result = pt.matrixTransform(canvasEl.getCTM()!);
        return result;
    }

    public coordToRelative(el: SVGGraphicsElement, viewportX: number, viewportY: number): IPos {
        const svg = this.svgEl;
        const pt = svg.createSVGPoint();
        pt.x = viewportX;
        pt.y = viewportY;
        const result = pt.matrixTransform(el.getCTM()!.inverse());
        return result;
    }

    public getBodyEl(id: MindId): SVGRectElement | undefined {
        // 首次重绘之前 svg 还不存在
        const svg = this.svgRef.current;
        if (!svg) return undefined;
        const selector = this.itemBodySelector(id);
        return svg.querySelector(selector) as SVGRectElement;
    }

    /** 将 SVG 控件坐标转化为相对某个结点 body 的左上角的坐标. */
    public getBodyRelativePos(id: MindId, viewportX: number, viewportY: number): IPos {
        const bodyEl: SVGRectElement = this.getBodyEl(id)!;
        return this.coordToRelative(bodyEl, viewportX, viewportY);
    }

    /** 从事件中获取 SVG 的视口坐标, 当需要保存一个事件的坐标时, 应保存此坐标. 此坐标与 SVG 的 transform 无关. */
    public getViewportCoord(event: React.MouseEvent): IPos {
        if (!event) throw new ArgumentMissingException('event');

        const { clientX, clientY } = event;
        const bounding: ClientRect = this.svgEl.getBoundingClientRect();

        // 它等同于 event.nativeEvent.offsetX, event.nativeEvent.offsetY
        const result: IPos = {
            x: clientX - bounding.left,
            y: clientY - bounding.top,
        };

        return result;
    }

    /** 根据 SVG 内部坐标, 获取 {@link IHitTest}. */
    public hitTestForViewportCoord(viewportX: number, viewportY: number): IHitTest | undefined {
        const diagram = this.getDiagram();
        const { items, rootId } = diagram;

        // x, y are in the same level as relativePos
        function deepFirstTest(item: IMindItem, x: number, y: number): IHitTest | undefined {
            const { regionSize, bodyPos, bodySize, textSize, gripPos, childIds } = item;
            const itemId = item.id;

            // process current item
            if (regionSize && bodyPos && bodySize && textSize && gripPos) {
                // 如果落在 region 之外, 直接返回
                if (x < 0 || x > regionSize.width) return;
                if (y < 0 || y > regionSize.height) return;

                // 是否 inBody
                const xInBody = x >= bodyPos.x && x <= bodyPos.x + bodySize.width;
                const yInBody = y >= bodyPos.y && y <= bodyPos.y + bodySize.height;
                const inBody = xInBody && yInBody;
                if (inBody) {
                    // 是否 inContent
                    const xInContent = x >= bodyPos.x + TextPadding.left && x <= bodyPos.x + textSize.width;
                    const yInContent = y >= bodyPos.y + TextPadding.top && x <= bodyPos.y + textSize.height;
                    const inContent = xInContent && yInContent;
                    if (inContent) {
                        return {
                            itemId,
                            inBody,
                            inContent,
                        };
                    }

                    // 是否 inDiscussionIcon
                    if (item.hasDiscussion) {
                        const xIconLeft = bodyPos.x + TextPadding.left + textSize.width + TextPadding.right;
                        const yIconTop = (bodySize.height - TopicIconSize) / 2;

                        const xInIcon = x >= xIconLeft && x <= xIconLeft + TopicIconSize;
                        const yInIcon = y >= yIconTop && y <= yIconTop + TopicIconSize;
                        const inDiscussionIcon = xInIcon && yInIcon;
                        if (inDiscussionIcon) {
                            return {
                                itemId,
                                inBody,
                                inDiscussionIcon,
                            };
                        }
                    }

                    return {
                        itemId,
                        inBody,
                    };
                }

                const xInGrip = x >= gripPos.x - GripHalf && x <= gripPos.x + GripHalf;
                const yInGrip = y >= gripPos.y - GripHalf && y <= gripPos.y + GripHalf;
                const inGrip = xInGrip && yInGrip;
                if (inGrip) return { itemId, inGrip };
            }

            // process children
            if (childIds) {
                for (let i = 0; i < childIds.length; i++) {
                    const child = getItem(items, childIds[i], true);
                    if (child.relativePos) {
                        const childResult = deepFirstTest(child, x - child.relativePos.x, y - child.relativePos.y);
                        if (childResult) return childResult;
                    }
                }
            }
        }

        const hitPos: Readonly<IPos> = this.viewportToAbsolutePos({ x: viewportX, y: viewportY });
        const root = getItem(items, rootId, true);
        const rootRelativePos: IPos = root.relativePos ? root.relativePos : NullPos;
        return deepFirstTest(root, hitPos.x - rootRelativePos.x, hitPos.y - rootRelativePos.y);
    }

    public getItemIdsIn(rect: IRect): MindId[] {
        const diagram = this.getDiagram();
        const { items, rootId } = diagram;

        const ids = new Array<MindId>();

        function deepFirstTest(item: IMindItem, x: number, y: number, width: number, height: number) {
            if (width <= 0 || height <= 0) return;

            const { regionSize, bodyPos, bodySize, childIds } = item;
            if (regionSize && bodyPos && bodySize) {
                // 如果起点落在 region 之外, 直接返回
                if (x > regionSize.width || y > regionSize.height) return;

                const horiContains = x <= bodyPos.x && x + width >= bodyPos.x + bodySize.width;
                const vertContains = y <= bodyPos.y && y + height >= bodyPos.y + bodySize.height;
                const contains = horiContains && vertContains;
                if (contains) {
                    ids.push(item.id);
                }

                if (item.isExpanded) {
                    if (childIds) {
                        for (let i = 0; i < childIds.length; i++) {
                            const child = getItem(items, childIds[i], true);
                            if (child.relativePos) {
                                deepFirstTest(child, x - child.relativePos.x, y - child.relativePos.y, width, height);
                            }
                        }
                    }
                }
            }
        }

        const p1 = this.viewportToAbsolutePos({ x: rect.x, y: rect.y });
        const p2 = this.viewportToAbsolutePos({ x: rect.x + rect.width, y: rect.y + rect.height });
        const root = getItem(items, rootId, true);
        const rootPos: IPos = root.relativePos ? root.relativePos : NullPos;

        deepFirstTest(root, p1.x - rootPos.x, p1.y - rootPos.y, p2.x - p1.x, p2.y - p1.y);
        return ids;
    }

    public getItemBodyCoord(id: MindId): IPos | undefined {
        const bodyEl: SVGRectElement | undefined = this.getBodyEl(id);
        if (!bodyEl) return undefined;

        const absPos = absoluteBodyPos(this.getDiagram(), id);
        return absPos ? this.absoluteToViewportPos(absPos) : undefined;
    }

    public isBodyVisible(id: MindId): boolean {
        // TODO: 将 bodyEl 的坐标转换为 viewport 坐标, 看其 x, y 是否 < 0, 或 > width/height
        const bodyEl = this.getBodyEl(id);
        if (!bodyEl) throw new Error(`Cannot find the SVG element for the body of [${id}].`);

        const body = bodyEl.getBoundingClientRect(); // bodyEl 在屏幕上的绝对位置

        // getBoundingClientRect() 的 width/height 的包含了滚动条, clientWidth/clientHeight 则不包括滚动条
        const svg = this.svgEl.getBoundingClientRect();

        return (
            body.left > svg.left &&
            body.left < svg.right &&
            body.top > svg.top &&
            body.top < svg.bottom &&
            body.right > svg.left &&
            body.right < svg.right &&
            body.bottom > svg.top &&
            body.bottom < svg.bottom
        );
    }

    /** 创建一个 {@link SVGRect}, 其坐标系为 viewport 坐标系. */
    public createSvgRect(x: number, y: number, width: number, height: number): SVGRect {
        const svgRect = this.svgEl.createSVGRect();
        assignRect(svgRect, x, y, width, height);
        return svgRect;
    }

    public calcTextSize(text: string): ISize {
        return calcTextSize(this.svgEl, text);
    }

    private itemBodySelector(id: MindId): string {
        return `rect[${SvgDataAttr.id}="${id}"][${SvgDataAttr.kind}="${SvgElKinds.itemBorder}"]`;
    }
}

/** 计算文本的大小. TODO: 支持不同的样式. */
export function calcTextSize(svg: SVGSVGElement, text: string): ISize {
    const el = document.createElementNS(SvgNS, 'text');
    try {
        el.setAttributeNS(null, 'x', '0');
        el.setAttributeNS(null, 'y', '0');
        el.setAttributeNS(SvgNS, 'alignment-baseline', 'hanging');

        const txt = document.createTextNode(text);
        try {
            el.appendChild(txt);
            svg.appendChild(el);

            const bbox = el.getBBox();
            return {
                width: bbox.width,
                height: bbox.height,
            };
        } finally {
            el.removeChild(txt);
        }
    } finally {
        svg.removeChild(el);
    }
}

export function svgViewBoxString(rect: IRect) {
    if (!rect) throw new ArgumentException('rect');

    const { x, y, width, height } = rect;
    return `${x} ${y} ${width} ${height}`;
}

/** 构造 SVG `transform` 属性的值. */
export function svgTransformString(x: number, y: number) {
    if (x === 0 && y === 0) return undefined;
    return `translate(${x}, ${y})`;
}
