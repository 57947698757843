import { generateId, LineKind, NotImplementedException, StructureKind, StructureKinds } from 'neka-common';
import { arrayEquals, arrayOfAdd, cloneMap, MapWrapper, SIndexOutOfRangeErr } from '../../common/collections';
import { RdxReducerComposer } from '../../common/redux-utils';
import { getDebug } from '../../core/app-diag';
import { DirectionKeys } from '../components/kb-helper';
import { ISize, NullPos, NullSize } from '../mind-draw2d';
import { layoutItems, LayoutScopes } from '../mind-layout';
import {
    checkAllowEdit,
    getItem,
    getStructureKind,
    IMindDiagram,
    IMindItem,
    IMindItemStyles,
    IRemoteCommand,
    ItemGet,
    ItemGetSet,
    itemOf,
    ItemOrId,
    MindDocumentTitleMaxLength,
    MindException,
    MindId,
    MindItemTextMaxLength,
    ToolPaneKind,
} from '../mind-models';
import { calcBodySize } from '../mind-styles';
import { DropActions, IDropIntent } from '../mind-utils';
import { getAncestors, getSubtreeRootIds, isDescendantOf, visitDeepPreLeft } from '../mind-visitors';
import {
    cloneItems,
    copyItemsToClipboard,
    createDocumentRemoteBatchCommand,
    ensureItemsCanSelected,
    fixCollapse,
    getChildCount,
    getFirstChildId,
    getNextSiblingId,
    getParentShip,
    getPrevSiblingId,
    insertChild,
    remoteUpdateDiagram,
    removeItems,
    setItemParent,
    updateLayout,
} from './mind-redux-helper';

const debug = getDebug(__filename);

/** 注册所有 IMindMap 的 reducer */
export const diagramReducerComposer = new RdxReducerComposer<IMindDiagram | null>('DIAGRAM_');

export const ChangeDiagramAllowEdit = diagramReducerComposer.registerHandler(
    'CHANGE_ALLOW_EDIT',
    (allowEdit: boolean) => ({ allowEdit }),
    (state, payload) => {
        if (!state) return state;
        const { allowEdit } = payload;
        return {
            ...state,
            allowEdit,
        };
    }
);

export const ChangeDiagramTitle = diagramReducerComposer.registerHandler(
    'CHANGE_TITLE',
    (newTitle: string) => ({ newTitle }),
    (state, payload) => {
        if (!state) return state;
        if (!state.allowEdit) return state;

        const { id, remoteCommands, title } = state;
        let { newTitle } = payload;
        if (!title || title.trim().length === 0 || title === newTitle) return state;
        if (newTitle.length > MindDocumentTitleMaxLength) {
            newTitle = newTitle.substring(0, MindDocumentTitleMaxLength);
        }

        const commands = [remoteUpdateDiagram({ id, title: newTitle })];

        return {
            ...state,
            title: newTitle,
            remoteCommands: [...remoteCommands, ...commands],
        };
    }
);

export const ChangeActiveToolPane = diagramReducerComposer.registerHandler(
    'CHANGE_ACTIVE_TOOL_PANE',
    (toolPaneKind: ToolPaneKind) => ({ toolPaneKind }),
    (state, payload) => {
        if (!state) return state;

        return {
            ...state,
            activeToolPaneKind: payload.toolPaneKind,
        };
    }
);

export const ChangeRenderDebugHelpers = diagramReducerComposer.registerHandler(
    'CHANGE_RENDER_DEBUG_HELPERS',
    (value: boolean) => ({ value }),
    (state, payload) => {
        if (!state) return state;

        const { value } = payload;
        if (value === state.renderDebugHelpers) return state;

        return {
            ...state,
            renderDebugHelpers: value,
        };
    }
);

// =========================================================
// 全局操作
// =========================================================
export const DiagramZoomTo = diagramReducerComposer.registerHandler(
    'SET_DIAGRAM_SCALE',
    (scale: number) => ({ scale }),
    (state, payload) => {
        if (!state) return state;

        const minScale = 0.1;
        const maxScale = 10;

        let { scale } = payload;
        scale = Math.max(minScale, Math.min(scale, maxScale));

        return {
            ...state,
            scale: scale,
        };
    }
);

// =========================================================
// 需要指明结点的操作
// =========================================================

/** 切换单个结点的展开状态. */
export const InverseItemExpanded = diagramReducerComposer.registerHandler(
    'INVERSE_ITEM_EXPANDED',
    (id: MindId) => ({ id }),
    (state, payload) => {
        if (!state) return state;

        const items = cloneMap(state.items);
        const { id } = payload;
        const item = getItem(items, id, true);

        items.set(id, { ...item, isExpanded: !item.isExpanded });
        updateLayout(items, LayoutScopes.Upward, id);

        const { editingId, selection } = fixCollapse(items, state, id);

        return {
            ...state,
            editingId,
            selection,
            items,
        };
    }
);

/** 设置选中的结点 */
export const ChangeSelection = diagramReducerComposer.registerHandler(
    'CHANGE_SELECTION',
    (ids: Array<MindId> | undefined) => ({ selection: ids }),
    (state, payload) => {
        if (!state) return state;

        const { selection } = payload;
        if (arrayEquals(selection, state.selection)) return state;

        if (selection && selection.length > 0) {
            ensureItemsCanSelected(state.items, selection);
        }
        const editingId = enforceEditingInSelectionOrNoEditing(selection, state.editingId);

        return {
            ...state,
            selection,
            editingId,
        };
    }
);

/** 保证正被编辑的结点是被选中的结点或结点之一, 否则则取消编辑. */
function enforceEditingInSelectionOrNoEditing(
    selection: ReadonlyArray<MindId> | undefined,
    editingId: MindId | undefined
): MindId | undefined {
    const inSelection = editingId && selection && selection.indexOf(editingId) >= 0;
    return inSelection ? editingId : undefined;
}

/** 追加选中的结点 */
export const IncludeSelection = diagramReducerComposer.registerHandler(
    'INCLUDE_SELECTION',
    (ids: Array<MindId> | undefined) => ({ inclusion: ids }),
    (state, payload) => {
        if (!state) return state;

        const { inclusion } = payload;
        if (!inclusion || inclusion.length === 0) return state;

        const oldSelection = state.selection;
        if (inclusion && inclusion.length > 0) {
            ensureItemsCanSelected(state.items, inclusion);
        }

        const selection = oldSelection ? mergeSelection(oldSelection, inclusion) : inclusion;

        return {
            ...state,
            selection,
        };
    }
);

/** 合并选中的结点, 将 newSelection 总是放在选中结点集合的末尾. */
function mergeSelection(oldSelection: ReadonlyArray<MindId>, newSelection: ReadonlyArray<MindId>): Array<MindId> {
    return oldSelection.filter(x => newSelection.indexOf(x) < 0).concat(newSelection);
}

/** 排除选中的结点. */
export const ExcludeSelection = diagramReducerComposer.registerHandler(
    'EXCLUDE_SELECTION',
    (ids: Array<MindId> | undefined) => ({ exclusion: ids }),
    (state, payload) => {
        if (!state) return state;

        let { exclusion } = payload;
        if (!exclusion || exclusion.length === 0) return state;

        const oldSelection = state.selection;
        if (!oldSelection || oldSelection.length === 0) return state;

        const selection = oldSelection.filter(x => exclusion!.indexOf(x) < 0);
        const editingId = enforceEditingInSelectionOrNoEditing(selection, state.editingId);

        return {
            ...state,
            selection,
            editingId,
        };
    }
);

/** 进入文字编辑状态 */
export const StartInplaceTextEdit = diagramReducerComposer.registerHandler(
    'START_INPLACE_TEXT_EDIT',
    (id: MindId) => ({ id }),
    (state, payload) => {
        if (!state) return state;
        if (!state.allowEdit) return state;

        const { id } = payload;
        if (!id) return state;

        return {
            ...state,
            selection: [id],
            editingId: id,
        };
    }
);

/** 退出文字编辑状态并且保存编辑结果. */
export const CommitInplaceTextEdit = diagramReducerComposer.registerHandler(
    'COMMIT_INPLACE_TEXT_EDIT',
    (id: MindId, text: string) => ({ id, text }),
    (state, payload) => {
        if (!state) return state;
        if (!state.allowEdit) return state;

        const { editingId } = state;
        const { id } = payload;
        if (id !== editingId) {
            throw new MindException(`editingId mismatch, expecting [${editingId}] but actual [${id}].`);
        }
        let { text } = payload;
        if (text && text.length > MindItemTextMaxLength) {
            text = text.substring(0, MindItemTextMaxLength);
        }
        const items = new MapWrapper(cloneMap(state.items));

        let item = getItem(items, id, true);
        item = { ...item, text };
        items.set(id, item);

        const command = createDocumentRemoteBatchCommand({ id: state.id }, items);

        return {
            ...state,
            items: items.innerMap,
            editingId: undefined,
            remoteCommands: [...state.remoteCommands, command],
        };
    }
);

/** 退出文字编辑状态并且放弃编辑结果. */
export const CancelInplaceTextEdit = diagramReducerComposer.registerHandler(
    'CANCEL_INPLACE_TEXT_EDIT',
    (id: MindId) => ({ id }),
    (state, payload) => {
        if (!state) return state;
        if (!state.allowEdit) return state;

        const { editingId } = state;
        const { id } = payload;
        if (id !== editingId) {
            throw new MindException(`editingId mismatch, expecting [${editingId}] but actual [${id}].`);
        }

        return {
            ...state,
            editingId: undefined,
        };
    }
);

export type IDiscussionStatus = Pick<IMindItem, 'id' | 'hasDiscussion' | 'hasUnreadDiscussion'>;

export const ChangeDiscussionStatus = diagramReducerComposer.registerHandler(
    'CHANGE_DISCUSSION_STATUS',
    (...changes: Array<IDiscussionStatus>) => ({ changes }),
    (state, payload) => {
        if (!state) return state;

        const { changes } = payload;
        if (!changes || changes.length === 0) return state;

        const items = cloneMap(state.items);

        changes.forEach(status => {
            let item = getItem(items, status.id, true);
            item = { ...item, ...status };
            const bodySize = calcBodySize(item, { textSize: item.textSize ? item.textSize : NullSize });
            items.set(item.id, { ...item, bodySize });
        });

        updateLayout(items, LayoutScopes.Upward, ...changes.map(x => x.id));

        return {
            ...state,
            items,
        };
    }
);

// =========================================================
// 对当前选中的结点的操作
// =========================================================

/** 上移焦点 */
export const FocusUp = diagramReducerComposer.registerHandler(
    'FOCUS_UP',
    () => null,
    state => {
        if (!state) return state;
        return stateOfNavFocus(state, DirectionKeys.Up);
    }
);

/** 下移焦点 */
export const FocusDown = diagramReducerComposer.registerHandler(
    'FOCUS_DOWN',
    () => null,
    state => {
        if (!state) return state;
        return stateOfNavFocus(state, DirectionKeys.Down);
    }
);

/** 左移焦点 */
export const FocusLeft = diagramReducerComposer.registerHandler(
    'FOCUS_LEFT',
    () => null,
    state => {
        if (!state) return state;
        return stateOfNavFocus(state, DirectionKeys.Left);
    }
);

/** 右移焦点 */
export const FocusRight = diagramReducerComposer.registerHandler(
    'FOCUS_RIGHT',
    () => null,
    state => {
        if (!state) return state;
        return stateOfNavFocus(state, DirectionKeys.Right);
    }
);

function stateOfNavFocus(state: IMindDiagram, navKey: DirectionKeys) {
    const selectedId = getLastSelectedId(state);
    if (selectedId === undefined) return state;

    const targetId = getNewFocusedId(state.items, selectedId, navKey);
    if (targetId === undefined) return state;

    const selection = [targetId];
    ensureItemsCanSelected(state.items, selection);
    return {
        ...state,
        selection,
        editingId: undefined,
    };
}

function getNewFocusedId(items: ItemGet, itemOrId: ItemOrId, key: DirectionKeys): MindId | undefined {
    const item = itemOf(items, itemOrId);

    const handledBySelf = getNewFocusedIdForKeyOnSelf(items, item, key);
    if (handledBySelf) return handledBySelf;

    if (item.parentId) {
        const handledByParent = getNewFocusedIdForKeyOnChild(items, item.parentId, item.id, key);
        if (handledByParent) return handledByParent;
    }
    return undefined;
}

function getNewFocusedIdForKeyOnSelf(items: ItemGet, itemOrId: ItemOrId, keyOnSelf: DirectionKeys): MindId | undefined {
    const item = itemOf(items, itemOrId);
    const structureKind = getStructureKind(items, item.id);
    switch (structureKind) {
        case StructureKinds.LogicRight:
            switch (keyOnSelf) {
                case DirectionKeys.Left:
                    return undefined;
                case DirectionKeys.Right:
                    return getFirstChildId(items, item);
                case DirectionKeys.Up:
                    return undefined;
                case DirectionKeys.Down:
                    return undefined;
                default:
                    throw new NotImplementedException(`Direction [${keyOnSelf}]`);
            }
        case StructureKinds.OrgDown:
            switch (keyOnSelf) {
                case DirectionKeys.Left:
                    return undefined;
                case DirectionKeys.Right:
                    return undefined;
                case DirectionKeys.Up:
                    return undefined;
                case DirectionKeys.Down:
                    return getFirstChildId(items, item);
                default:
                    throw new NotImplementedException(`Direction [${keyOnSelf}]`);
            }
        default:
            throw new NotImplementedException(`structureKind [${structureKind}] for item [${item.id}]`);
    }
}

function getNewFocusedIdForKeyOnChild(
    items: ItemGet,
    selfId: ItemOrId,
    childId: ItemOrId,
    keyOnChild: DirectionKeys
): MindId | undefined {
    const item = itemOf(items, selfId);
    const structureKind = getStructureKind(items, item.id);
    switch (structureKind) {
        case StructureKinds.LogicRight:
            switch (keyOnChild) {
                case DirectionKeys.Left:
                    return item.id;
                case DirectionKeys.Right:
                    return undefined;
                case DirectionKeys.Up:
                    return getPrevSiblingId(items, childId);
                case DirectionKeys.Down:
                    return getNextSiblingId(items, childId);
                default:
                    throw new NotImplementedException(`Direction [${keyOnChild}]`);
            }
        case StructureKinds.OrgDown:
            switch (keyOnChild) {
                case DirectionKeys.Left:
                    return getPrevSiblingId(items, childId);
                case DirectionKeys.Right:
                    return getNextSiblingId(items, childId);
                case DirectionKeys.Up:
                    return item.id;
                case DirectionKeys.Down:
                    return undefined;
                default:
                    throw new NotImplementedException(`Direction [${keyOnChild}]`);
            }
        default:
            throw new NotImplementedException(`structureKind [${structureKind}] for item [${item.id}]`);
    }
}

/*#region 移动节点*/

/** 向上移动节点 */
export const MoveSelectionUp = diagramReducerComposer.registerHandler(
    'MOVE_UP',
    () => null,
    state => {
        if (!state) return state;
        if (!state.allowEdit) return state;
        return stateOfMoveFocused(state, DirectionKeys.Up);
    }
);

/** 向下移动节点 */
export const MoveSelectionDown = diagramReducerComposer.registerHandler(
    'MOVE_DOWN',
    () => null,
    state => {
        if (!state) return state;
        if (!state.allowEdit) return state;
        return stateOfMoveFocused(state, DirectionKeys.Down);
    }
);

/** 向左移动节点 */
export const MoveSelectionLeft = diagramReducerComposer.registerHandler(
    'MOVE_LEFT',
    () => null,
    state => {
        if (!state) return state;
        if (!state.allowEdit) return state;
        return stateOfMoveFocused(state, DirectionKeys.Left);
    }
);

/** 向右移动节点 */
export const MoveSelectionRight = diagramReducerComposer.registerHandler(
    'MOVE_RIGHT',
    () => null,
    state => {
        if (!state) return state;
        if (!state.allowEdit) return state;
        return stateOfMoveFocused(state, DirectionKeys.Right);
    }
);

function stateOfMoveFocused(state: IMindDiagram | null, navKey: DirectionKeys) {
    if (!state) return state;

    checkAllowEdit(state);

    const selectedId = getLastSelectedId(state);
    if (selectedId === undefined) return state;
    const { items } = state;

    // 目前占据目标位置的结点
    const stubId = getNewFocusedId(items, selectedId, navKey);
    if (stubId === undefined) return state;
    if (stubId === selectedId) return state;

    const parentShip = getParentShip(items, stubId);
    // 不能替换根节点
    if (parentShip === undefined) return state;

    const { parent, child, index } = parentShip;
    const ancestorsOfStub = getAncestors(items, child.id)!;
    // 不能将一个结点移至其子孙结点下
    if (ancestorsOfStub.find(x => x.id === selectedId) !== undefined) return state;

    return stateOfSetParent(state, selectedId, parent.id, index);
}

function stateOfSetParent(
    state: IMindDiagram,
    id: MindId,
    newParentId: MindId,
    newIndex: number | undefined
): IMindDiagram {
    checkAllowEdit(state);

    const items = new MapWrapper(cloneMap(state.items));
    let { rootId } = state;
    if (id === rootId) {
        rootId = newParentId;
    }
    // 当 id 为根节点时, newParentId 将从原有位置脱离并成为新的根节点.
    let originalParentOfNewParent: MindId | undefined;
    if (isDescendantOf(items, newParentId, id)) {
        const newParent = getItem(items, newParentId, true);
        originalParentOfNewParent = newParent.parentId;
    }

    const changes = setItemParent(items, id, newParentId, newIndex);

    if (changes.newParent) {
        if (rootId === newParentId) {
            items.set(newParentId, { ...changes.newParent, relativePos: NullPos });
        }
        updateLayout(items, LayoutScopes.Subtree, id);
        updateLayout(items, LayoutScopes.Upward, id);
    }
    if (changes.oldParent && changes.oldParent !== changes.newParent) {
        updateLayout(items, LayoutScopes.Upward, changes.oldParent.id);
    }
    if (originalParentOfNewParent) {
        updateLayout(items, LayoutScopes.Upward, originalParentOfNewParent);
    }

    // 更新所有 childIds 发生变化的结点.
    const batchCommand = createDocumentRemoteBatchCommand({ id: state.id, rootId }, items);

    return {
        ...state,
        items: items.innerMap,
        rootId,
        editingId: undefined,
        remoteCommands: [...state.remoteCommands, batchCommand],
    };
}

function getLastSelectedId(state: IMindDiagram): MindId | undefined {
    const { selection } = state;
    if (selection === undefined) return undefined;
    return selection[selection.length - 1];
}

function getSingleSelectedId(state: IMindDiagram): MindId | undefined {
    const { selection } = state;
    if (selection === undefined || selection.length > 1) return undefined; // TODO: 将来需要处理多于一个的移动
    return selection[0];
}

export const DropSelectionTo = diagramReducerComposer.registerHandler(
    'DROP_SELECTION',
    (dropAction: IDropIntent) => ({ dropAction }),
    (state, payload) => {
        if (!state) return state;
        if (!state.allowEdit) return state;

        const { dropAction } = payload;
        if (!dropAction) return state;

        const { selection, rootId } = state;
        if (!selection || selection.length === 0) return state;

        const items = cloneMap(state.items);
        const topIds = getSubtreeRootIds(items, selection);
        // const sources = topIds.map(id => getItem(items, id, true));

        const { anchorId, direction, action } = dropAction;
        if (!anchorId || !action || action === DropActions.notAllow) return state;
        const anchor = getItem(items, anchorId, true);
        const isRoot = anchor.id === state.rootId;

        if (isRoot) {
            if (topIds.length === 0) throw new Error(`Invalid intent [${action}].`);

            switch (action) {
                case DropActions.asParent: {
                    if (topIds.length > 1) throw new Error(`Invalid intent [${action}].`);
                    return stateOfSetParent(state, anchor.id, topIds[0], 0);
                }
                case DropActions.asFirstChild: {
                    return topIds.reduce(
                        (prevState, id, index) => stateOfSetParent(prevState, id, anchor.id, index),
                        state
                    );
                }
                case DropActions.asLastChild: {
                    return topIds.reduce(
                        (prevState, id) => stateOfSetParent(prevState, id, anchor.id, undefined),
                        state
                    );
                }
                default:
                    throw new NotImplementedException(`intent [${action}]`);
            }
        } else {
            switch (action) {
                case DropActions.asParent: {
                    const parentShip = getParentShip(items, anchor, true);
                    const modified = stateOfSetParent(state, anchorId, topIds[topIds.length - 1], 0); // 0 is better be last
                    return topIds.reduce(
                        (prevState, id, index) =>
                            stateOfSetParent(prevState, id, parentShip.parent.id, parentShip.index + index),
                        modified
                    );
                }
                case DropActions.asFirstChild: {
                    return topIds.reduce(
                        (prevState, id, index) => stateOfSetParent(prevState, id, anchor.id, index),
                        state
                    );
                }
                case DropActions.asLastChild: {
                    return topIds.reduce(
                        (prevState, id) => stateOfSetParent(prevState, id, anchor.id, undefined),
                        state
                    );
                }
                case DropActions.asPrevSibling: {
                    const anchorParentShip = getParentShip(items, anchor, true);
                    return topIds.reduce((prevState, id, index) => {
                        let idx = anchorParentShip.index + index;

                        // 如果结点移动前后 parent 不变, 则需要修正 setItemParent 的影响
                        const sourceParentShip = getParentShip(items, id, true);
                        if (anchorParentShip.parent.id === sourceParentShip.parent.id) {
                            if (sourceParentShip.index < anchorParentShip.index) idx--;
                        }

                        idx = Math.max(0, idx);
                        debug(`Move ${id} to index ${idx}`);
                        return stateOfSetParent(prevState, id, anchorParentShip.parent.id, idx);
                    }, state);
                }
                case DropActions.asNextSibling: {
                    const anchorParentShip = getParentShip(items, anchor, true);
                    return topIds.reduce((prevState, id, index) => {
                        let idx = anchorParentShip.index + index + 1;

                        // 如果结点移动前后 parent 不变, 则需要修正 setItemParent 的影响
                        const sourceParentShip = getParentShip(items, id, true);
                        if (anchorParentShip.parent.id === sourceParentShip.parent.id) {
                            if (sourceParentShip.index < anchorParentShip.index) idx--;
                        }

                        idx = Math.max(0, idx);
                        debug(`Move ${id} to index ${idx}`);
                        return stateOfSetParent(prevState, id, anchorParentShip.parent.id, idx);
                    }, state);
                }
                default:
                    throw new NotImplementedException(`intent [${action}]`);
            }
        }
    }
);

/*#endregion*/

/** 通过按键切换结点展开状态. */
export const ExpandSelection = diagramReducerComposer.registerHandler(
    'EXPAND_SELECTION',
    () => null,
    state => {
        if (!state) return state;

        const { selection } = state;
        if (selection === undefined || selection.length === 0) return state;

        const items = cloneMap(state.items);
        selection.forEach(id => {
            const item = getItem(items, id, true);
            items.set(id, { ...item, isExpanded: true });
            updateLayout(items, LayoutScopes.Upward, id);
        });
        return { ...state, items };
    }
);

export const CollapseSelection = diagramReducerComposer.registerHandler(
    'COLLAPSE_SELECTION',
    () => null,
    state => {
        if (!state) return state;

        const { selection } = state;
        if (selection === undefined || selection.length === 0) return state;

        const items = cloneMap(state.items);
        selection.forEach(id => {
            const item = getItem(items, id, true);
            items.set(id, { ...item, isExpanded: false });
            updateLayout(items, LayoutScopes.Upward, id);
        });

        fixCollapse(items, state, ...selection);

        return { ...state, items };
    }
);

/** 更改布局方式. */
export const ChangeSelectionStructureKind = diagramReducerComposer.registerHandler(
    'CHANGE_SELECTION_STRUCTURE_KIND',
    (structureKind: StructureKind | undefined) => ({ structureKind }),
    (state, payload) => {
        if (!state) return state;
        if (!state.allowEdit) return state;

        const { selection } = state;
        if (selection === undefined || selection.length === 0) return state;

        const items = new MapWrapper(cloneMap(state.items));

        selection.forEach(id => {
            let item = getItem(items, id, true);
            item = { ...item, structureKind: payload.structureKind };
            items.set(id, item);
        });

        const subtreeRootIds = getSubtreeRootIds(items, selection);
        subtreeRootIds.forEach(id => {
            updateLayout(items, LayoutScopes.Subtree, id);
        });
        subtreeRootIds.forEach(id => {
            updateLayout(items, LayoutScopes.Upward, id);
        });

        const command = createDocumentRemoteBatchCommand({ id: state.id }, items);

        return {
            ...state,
            items: items.innerMap,
            remoteCommands: [...state.remoteCommands, command],
        };
    }
);

/** 删除节点及子节点 */
export const DeleteSelection = diagramReducerComposer.registerHandler(
    'DELETE_SELECTION',
    () => null,
    state => {
        if (!state) return state;
        if (!state.allowEdit) return state;

        const { selection, id, rootId } = state;
        if (selection === undefined || selection.length === 0) return state;

        const items = new MapWrapper(cloneMap(state.items));
        removeItems(items, selection, state.rootId);

        // 更新所有 childIds 发生变化的结点.
        const batchCommand = createDocumentRemoteBatchCommand({ id: state.id }, items);

        const result: IMindDiagram = {
            ...state,
            items: items.innerMap,
            selection: undefined,
            remoteCommands: [...state.remoteCommands, batchCommand],
        };
        return result;
    }
);

export const CreateSelectionFirstChild = diagramReducerComposer.registerHandler(
    'CREATE_SELECTION_FIRST_CHILD',
    () => null,
    state => {
        if (!state) return state;
        if (!state.allowEdit) return state;

        //  有多选时不做操作
        const parentId = getSingleSelectedId(state);
        if (parentId === undefined) return state;
        const index = 0;

        return stateOfCreateItem(state, parentId, index);
    }
);

/** 在子结点列表的末尾创建一个子结点. */
export const CreateSelectionLastChild = diagramReducerComposer.registerHandler(
    'CREATE_SELECTION_LAST_CHILD',
    () => null,
    state => {
        if (!state) return state;
        if (!state.allowEdit) return state;

        //  有多选时不做操作
        const parentId = getSingleSelectedId(state);
        if (parentId === undefined) return state;
        const index = getChildCount(getItem(state.items, parentId, true));

        return stateOfCreateItem(state, parentId, index);
    }
);

export const CreateSelectionPrevSibling = diagramReducerComposer.registerHandler(
    'CREATE_SELECTION_PREV_SIBLING',
    () => null,
    state => {
        if (!state) return state;
        if (!state.allowEdit) return state;

        //  有多选时不做操作
        const siblingId = getSingleSelectedId(state);
        if (siblingId === undefined) return state;

        if (siblingId === state.rootId) {
            const root = getItem(state.items, state.rootId, true);
            return stateOfCreateItem(state, root.id, root.childIds ? root.childIds.length : 0);
        }

        const { parent, index } = getParentShip(state.items, siblingId, true);
        return stateOfCreateItem(state, parent.id, index);
    }
);

/** 创建一个兄弟结点. */
export const CreateSelectionNextSibling = diagramReducerComposer.registerHandler(
    'CREATE_SELECTION_NEXT_SIBLING',
    () => null,
    state => {
        if (!state) return state;
        if (!state.allowEdit) return state;

        //  有多选时不做操作
        const siblingId = getSingleSelectedId(state);
        if (siblingId === undefined) return state;

        if (siblingId === state.rootId) {
            const root = getItem(state.items, state.rootId, true);
            return stateOfCreateItem(state, root.id, root.childIds ? root.childIds.length : 0);
        }

        const { parent, index } = getParentShip(state.items, siblingId, true);
        return stateOfCreateItem(state, parent.id, index + 1);
    }
);

/** 创建父结点. 如果当前结点是根结点, 则新结点将成为根结点. */
export const CreateSelectionParent = diagramReducerComposer.registerHandler(
    'CREATE_SELECTION_PARENT',
    () => null,
    state => {
        if (!state) return state;
        if (!state.allowEdit) return state;

        const focusedId = getSingleSelectedId(state);
        if (focusedId === undefined) return state;

        let items = new MapWrapper(cloneMap(state.items));
        const { rootId, id } = state;
        const isNewRoot = focusedId === rootId;

        let focused: IMindItem = getItem(items, focusedId, true);
        const parentId = focused.parentId;

        // 创建新结点
        const now = new Date();
        const item: IMindItem = {
            id: generateId(),
            text: '',
            isExpanded: true,
            parentId: parentId,
            childIds: [focusedId],
            structureKind: focused.structureKind,
            creatorId: state.currentUserId,
            createdAt: now,
            lastUpdaterId: state.currentUserId,
            lastUpdatedAt: now,
            relativePos: isNewRoot ? { x: 0, y: 0 } : undefined,
        };
        items.set(item.id, item);

        // 插入至 focused 的父结点下
        if (parentId) {
            let parent = getItem(items, parentId, true);
            if (!parent.childIds) throw new Error(`The parent.childIds is undefined`);

            const childIds = parent.childIds.map(x => (x === focused.id ? item.id : x));
            parent = {
                ...parent,
                childIds,
            };
            items.set(parent.id, parent);
        }

        // 将 focused 变为新结点的子结点
        focused = {
            ...focused,
            parentId: item.id,
        };
        items.set(focused.id, focused);

        const newRootId = isNewRoot ? item.id : rootId;

        const batchCommand = createDocumentRemoteBatchCommand({ id: state.id, rootId: newRootId }, items);

        // 新建的结点应当自动进入编辑状态
        return {
            ...state,
            items: items.innerMap,
            rootId: newRootId,
            selection: [item.id],
            editingId: item.id,
            remoteCommands: [...state.remoteCommands, batchCommand],
        };
    }
);

function stateOfCreateItem(state: IMindDiagram, parentId: MindId, index: number): IMindDiagram {
    checkAllowEdit(state);

    const items = new MapWrapper(cloneMap(state.items));
    let parent = getItem(items, parentId, true);
    const childCount = getChildCount(parent);
    if (index > childCount) throw new Error(SIndexOutOfRangeErr(index, childCount));

    const now = new Date();
    const item: IMindItem = {
        id: generateId(),
        text: '',
        isExpanded: false,
        parentId: parentId,
        creatorId: state.currentUserId,
        createdAt: now,
        lastUpdaterId: state.currentUserId,
        lastUpdatedAt: now,
    };

    parent = insertChild(parent, item.id, index);
    parent = { ...parent, isExpanded: true };

    items.set(item.id, item);
    items.set(parent.id, parent);

    const batchCommand = createDocumentRemoteBatchCommand({ id: state.id }, items);

    // 新建的结点应当自动进入编辑状态
    return {
        ...state,
        items: items.innerMap,
        selection: [item.id],
        editingId: item.id,
        remoteCommands: [...state.remoteCommands, batchCommand],
    };
}

/** 设置结点的样式. */
export const ChangeSelectionStyles = diagramReducerComposer.registerHandler(
    'CHANGE_ITEM_STYLE',
    (styles: Readonly<IMindItemStyles>) => ({ styles }),
    (state, payload) => {
        if (!state) return state;
        if (!state.allowEdit) return state;

        const { styles } = payload;
        return stateOfSelectionSimpleUpdate(state, item => ({
            ...item,
            ...styles,
        }));
    }
);

/** 改变选中结点至子结点的连线的线型. */
export const ChangeSelectionLineKind = diagramReducerComposer.registerHandler(
    'CHANGE_SELECTION_LINE_KIND',
    (lineKind: LineKind | undefined) => ({ lineKind }),
    (state, payload) => {
        if (!state) return state;
        if (!state.allowEdit) return state;

        const { lineKind } = payload;
        // TODO: require layout when textFontSize, lineKind, structureKind changed
        return stateOfSelectionSimpleUpdate(state, item => ({ ...item, lineKind }));
    }
);

function stateOfSelectionSimpleUpdate(state: IMindDiagram, itemUpdater: (item: IMindItem) => IMindItem): IMindDiagram {
    const { selection } = state;
    if (selection === undefined || selection.length === 0) return state;

    const items = new MapWrapper(cloneMap(state.items));

    selection.forEach(id => {
        const item = getItem(items, id, true);
        const newItem = itemUpdater(item);
        items.set(id, newItem);
    });

    const command = createDocumentRemoteBatchCommand({ id: state.id }, items);

    return {
        ...state,
        items,
        remoteCommands: [...state.remoteCommands, command],
    };
}

export const CutToClipboard = diagramReducerComposer.registerHandler(
    'CUT_TO_CLIPBOARD',
    () => null,
    (state, payload) => {
        if (!state) return state;
        if (!state.allowEdit) return state;

        const { selection } = state;
        if (selection === undefined || selection.length === 0) return state;

        const allowCut = selection.filter(x => x !== state.rootId);
        if (allowCut.length === 0) return state;

        const items = new MapWrapper(cloneMap(state.items));
        const clipboard = copyItemsToClipboard(items, allowCut);
        removeItems(items, selection, state.rootId);

        const batchCommand = createDocumentRemoteBatchCommand({ id: state.id }, items);

        const newState: IMindDiagram = {
            ...state,
            items: items.innerMap,
            clipboard,
            selection: undefined,
            editingId: undefined,
            remoteCommands: [...state.remoteCommands, batchCommand],
        };
        return newState;
    }
);

/** 复制结点. */
export const CopyToClipboard = diagramReducerComposer.registerHandler(
    'COPY_TO_CLIPBOARD',
    () => null,
    (state, payload) => {
        if (!state) return state;

        const { selection } = state;
        if (selection === undefined || selection.length === 0) return state;

        const clipboard = copyItemsToClipboard(state.items, selection);

        return {
            ...state,
            clipboard,
            editingId: undefined,
        };
    }
);

/** 粘贴之前复制的结点. */
export const PasteFromClipboard = diagramReducerComposer.registerHandler(
    'PASTE_FROM_CLIPBOARD',
    () => null,
    state => {
        if (!state) return state;
        if (!state.allowEdit) return state;

        // TODO: deal with external system clipboard
        const { selection, clipboard } = state;
        if (selection === undefined || selection.length === 0) return state;
        if (clipboard === undefined || clipboard.clipboardRootIds.length === 0) return state;

        const items = new MapWrapper(cloneMap(state.items));

        selection.forEach(selectedId => {
            clipboard.clipboardRootIds.forEach(fromRootId => {
                pasteSubtree(items, selectedId, clipboard.clipboardItems, fromRootId);
            });

            const item = getItem(items, selectedId, true);
            items.set(item.id, { ...item, isExpanded: true });

            updateLayout(items, LayoutScopes.Subtree, selectedId);
            updateLayout(items, LayoutScopes.Upward, selectedId);
        });

        const batchCommand = createDocumentRemoteBatchCommand({ id: state.id }, items);

        return {
            ...state,
            items: items.innerMap,
            editingId: undefined,
            remoteCommands: [...state.remoteCommands, batchCommand],
        };
    }
);

const SNewIdNotFoundErr = (oldId: MindId) => `New id for [${oldId} not found.`;

function pasteSubtree(targetItems: ItemGetSet, targetId: MindId, sourceItems: ItemGetSet, subtreeRootId: MindId) {
    const oldSubtree = new Array<IMindItem>();
    visitDeepPreLeft(sourceItems, subtreeRootId, source => {
        oldSubtree.push(source);
    });

    const { clonedItems, idMap } = cloneItems(oldSubtree);
    clonedItems[0] = { ...clonedItems[0], parentId: targetId };
    clonedItems.forEach(item => {
        item.hasDiscussion = false;
        item.hasUnreadDiscussion = false;
        targetItems.set(item.id, item);
    });

    const newSubtreeRootId = idMap.get(subtreeRootId);
    if (newSubtreeRootId === undefined) throw Error(SNewIdNotFoundErr(subtreeRootId));

    const target = getItem(targetItems, targetId, true);
    const newTarget: IMindItem = {
        ...target,
        childIds: arrayOfAdd(target.childIds, newSubtreeRootId),
    };
    targetItems.set(newTarget.id, newTarget);
}

/** 批量设置内容区域的大小. */
export const ReLayout = diagramReducerComposer.registerHandler(
    'RE_LAYOUT',
    (textSizes: ReadonlyMap<MindId, ISize>) => ({ textSizes }),
    (state, payload) => {
        if (!state) return state;

        const items = cloneMap(state.items);
        const { textSizes } = payload;

        const bodySizes = new Map<MindId, ISize>();
        textSizes.forEach((textSize, id) => {
            const item = getItem(items, id, true);
            const bodySize = calcBodySize(item, { textSize });
            items.set(id, { ...item, textSize, bodySize });
        });

        if (bodySizes.size === 1) {
            const changedItemId = Array.from(bodySizes.keys())[0]; // 获取唯一的一个 IMindItem.id
            layoutItems(changedItemId, items, LayoutScopes.Upward);
        } else {
            // TODO: 只需要对公共的祖先结点进行两个方向的布局
            layoutItems(state.rootId, items, LayoutScopes.Subtree);
        }

        return { ...state, items };
    }
);

export const ForceReLayoutUpward = diagramReducerComposer.registerHandler(
    'FORCE_RELAYOUT_UPWARD',
    (id: MindId) => ({ id }),
    (state, payload) => {
        if (!state) return state;

        const { id } = payload;
        const items = new MapWrapper(cloneMap(state.items));
        layoutItems(id, items, LayoutScopes.Upward);
        debug(items.getChanges());

        return {
            ...state,
            items: items.innerMap,
        };
    }
);

export const ForceReLayoutSubtree = diagramReducerComposer.registerHandler(
    'FORCE_RELAYOUT_SUBTREE',
    (id: MindId) => ({ id }),
    (state, payload) => {
        if (!state) return state;

        const { id } = payload;
        const items = new MapWrapper(cloneMap(state.items));
        layoutItems(id, items, LayoutScopes.Subtree);
        debug(items.getChanges());

        return {
            ...state,
            items: items.innerMap,
        };
    }
);

export const ExecuteRemoteCommandAsync = diagramReducerComposer.registerRunnable(
    'EXECUTE_REMOTE_COMMAND_ASYNC',
    (commandId: string) => ({ commandId }),
    async (state, payload) => {
        if (!state) throw new Error(`The redux state is null or undefined in ExecuteRemoteCommandAsync.`);

        const { commandId } = payload;
        const { remoteCommands } = state;

        const index = remoteCommands.findIndex(x => x.commandId === commandId);
        if (index < 0) throw new Error(`Invalid index.`);

        const cmd = remoteCommands[index];

        if (cmd.onExecute) {
            const promise: Promise<any> = cmd.onExecute();
            const remoteResult = await promise;
            return { commandId, remoteResult };
        }
        return { commandId };
    },
    {
        onSucceed: (state, successPayload) => {
            if (!state) return state;

            const { remoteCommands } = state;
            const { value } = successPayload;
            const { commandId } = value;
            return {
                ...state,
                remoteCommands: remoteCommands.filter(x => x.commandId !== commandId),
            };
        },
        onFailed: (state, failurePayload) => {
            if (!state) return state;

            const { remoteCommands, remoteCommandErrors } = state;
            const { originalPayload, reason } = failurePayload;
            return {
                ...state,
                remoteCommands: remoteCommands.filter(x => x.commandId !== originalPayload.commandId),
                remoteCommandErrors: remoteCommandErrors ? [...remoteCommandErrors, reason] : [reason],
            };
        },
    }
);

export const ExecuteRemoteCommandBatchAsync = diagramReducerComposer.registerRunnable(
    'EXECUTE_REMOTE_COMMAND_BATCH_ASYNC',
    () => null,
    async (state, payload, dispatch) => {
        if (!state) return state;

        const { remoteCommands } = state;
        if (!remoteCommands || remoteCommands.length === 0) return [];

        const queue = [...remoteCommands];
        for (let i = 0; i < queue.length; i++) {
            const cmd = queue[i];
            await cmd.onExecute();
            // await dispatch(ExecuteRemoteCommandAsync(cmd.commandId));
        }
    },
    {
        onStarted: (state, payload) => {
            if (!state) throw new Error(`The redux state is null or undefined in ExecuteRemoteCommandBatchAsync.`);

            return { ...state, isExecutingRemoteCommands: true };
        },
        onSucceed: (state, result) => {
            if (!state) return state;

            return {
                ...state,
                remoteCommands: [],
                isExecutingRemoteCommands: false,
            };
        },
        onFailed: (state, reason) => {
            if (!state) return state;

            return {
                ...state,
                remoteCommands: [],
                isExecutingRemoteCommands: false,
            };
        },
    }
);
