import { Avatar } from 'antd';
import { IDiscussionReply } from 'neka-common';
import React from 'react';
import { IDispatch } from '../../../../common/redux-utils';
import { fromNow } from '../../../../common/ui-utils';
import moment from 'moment';
import { IReplyInput } from './mind-discussion-from';

interface IProps extends IDispatch {
    replyId?: string;
    reply?: IDiscussionReply;
    replyCount?: number;
    showReplyInput: (params: IReplyInput) => void;
}

/** 代表 帖子中的回复按钮区域. */
export default class MindDiscussionHandler extends React.Component<IProps, {}> {
    protected constructor(props: IProps) {
        super(props);
    }

    public render() {
        return <div className="discussion-reply-handler">{this.getReplyBtnDom()}</div>;
    }

    // 获取回复按钮Dom
    private getReplyBtnDom(): JSX.Element | null {
        const { replyCount, reply, replyId, showReplyInput } = this.props;
        const replyCountTitle = '共' + replyCount + '条回复';
        const replyBtnDom: JSX.Element = (
            <span
                className="reply-btn"
                onClick={() =>
                    showReplyInput({
                        replyToId: reply ? reply.id : '',
                        replyInputFocus: true,
                        replyToUser: reply ? reply.creator : undefined,
                        postId: replyId,
                    } as IReplyInput)
                }
            >
                回复 {replyCount && replyCount > 0 ? <span title={replyCountTitle}>{replyCount}</span> : ''}
            </span>
        );

        if (!reply) {
            return <div className="handler">{replyBtnDom}</div>;
        }

        return (
            <div className="handler">
                <span title={moment(reply.createdAt).format('YYYY/MM/DD HH:mm:ss')}>{fromNow(reply.createdAt)} ▪ </span>
                {replyBtnDom}
            </div>
        );
    }
}
