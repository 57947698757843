import { IPrincipal } from 'neka-common';
import { RdxReducerComposer } from '../../common/redux-utils';
import { getDebug } from '../../core/app-diag';
import { apis } from '../../core/server-apis';
import { createUntitledDocument } from '../../minds/mind-models';
import { IDashboard } from '../dashboard-model';

const debug = getDebug(__filename);

/** 注册所有 dashboard 的 reducer */
export const dashboardReducerComposer = new RdxReducerComposer<IDashboard>('DASHBOARD_');

export const FetchDocumentListAsync = dashboardReducerComposer.registerRunnable(
    `FETCH_DOCUMENT_LIST_ASYNC`,
    (principal: IPrincipal) => ({ principal }),
    async (state, payload) => {
        const documents = await apis.docs.getDocuments(undefined);
        return documents;
    },
    {
        onStarted: (state, payload) => {
            return {
                ...state,
                isDocumentsLoading: true,
                documents: undefined as any,
            };
        },
        onSucceed: (state, successPayload) => {
            const { value } = successPayload;
            return {
                ...state,
                isDocumentsLoading: false,
                documents: value,
            };
        },
        onFailed: (state, failurePayload) => {
            const { reason } = failurePayload;
            return {
                ...state,
                isDocumentsLoading: false,
                documentsLoadingError: reason,
                documents: undefined as any,
            };
        },
    }
);

export const CreateNewDocumentAsync = dashboardReducerComposer.registerRunnable(
    'CREATE_NEW_DOCUMENT_ASYNC',
    (principal: IPrincipal) => ({ principal }),
    async (state, payload) => {
        const document = createUntitledDocument(payload.principal);
        const saved = await apis.docs.createDocument(document);
        return saved;
    },
    {
        onSucceed: (state, successPayload) => {
            const { value } = successPayload;
            const documents = state.documents ? [...state.documents, value] : [value];
            return {
                ...state,
                documents,
            };
        },
    }
);

export const DeleteDocumentAsync = dashboardReducerComposer.registerRunnable(
    'DELETE DOCUMENT_ASYNC',
    (documentId: string) => ({ documentId }),
    async (state, payload) => {
        const { documentId } = payload;
        await apis.docs.deleteDocument(documentId);
        return documentId;
    },
    {
        onSucceed: (state, successPayload) => {
            let { documents } = state;
            const { value } = successPayload;
            if (documents) documents = documents.filter(x => x.id !== value);
            return {
                ...state,
                documents,
            };
        },
    }
);
