import {
    generateId,
    IMindDocumentCommand,
    IMindDocumentUpdateCommand,
    IPartialMindDocumentEntity,
    MindDocumentCommandTypes,
    Mutable,
} from 'neka-common';
import { arrayOfDelete, IMapChange, MapChangeKinds, MapWrapper } from '../../common/collections';
import { getDebug } from '../../core/app-diag';
import { apis } from '../../core/server-apis';
import { layoutItems, LayoutScopes } from '../mind-layout';
import {
    convertItemToEntity,
    getItem,
    IMindClipboard,
    IMindDiagram,
    IMindItem,
    IRemoteCommand,
    ItemGet,
    ItemGetSet,
    itemOf,
    ItemOrId,
    MindId,
    MindItemStateException,
} from '../mind-models';
import { getAncestors, getSubtreeRootIds, isDescendantOf, visitDeepPostLeft, visitDeepPreLeft } from '../mind-visitors';

const debug = getDebug(__filename);

/** 验证一组结点是否可以被选中. */
export function ensureItemsCanSelected(items: ItemGetSet, ids: ReadonlyArray<MindId> | undefined) {
    if (ids !== undefined) {
        ids.forEach(id => ensureItemCanSelected(items, id));
    }
}

/** 验证一个结点是否可以选中. */
export function ensureItemCanSelected(items: ItemGetSet, id: MindId) {
    const canBeSelected = isItemCanBeSelected(items, id);
    if (!canBeSelected) throw new Error(`The item [${id}] cannot be selected.`);
}

export function isItemCanBeSelected(items: ItemGet, id: MindId): boolean {
    const ancestors = getAncestors(items, id);
    return ancestors.every(x => x.isExpanded);
}

/** 获取第一个子结点. */
export function getFirstChildId(items: ItemGet, itemOrId: ItemOrId): MindId | undefined {
    const item = itemOf(items, itemOrId);
    const { childIds } = item;

    if (!childIds || childIds.length === 0) return undefined;

    return childIds[0];
}

/** 获取最后一个子结点. */
export function getLastChildId(items: ItemGet, itemOrId: ItemOrId): MindId | undefined {
    const item = itemOf(items, itemOrId);
    const { childIds } = item;

    if (!childIds || childIds.length === 0) return undefined;

    return childIds[childIds.length - 1];
}

/** 获取上一个兄弟结点. 当结点为根节点, 或已经是第一个子结点时返回 undefined */
export function getPrevSiblingId(items: ItemGet, itemOrId: ItemOrId): MindId | undefined {
    const item = itemOf(items, itemOrId);
    const parentShip = getParentShip(items, item);
    if (parentShip === undefined) return undefined;

    const { parent, index } = parentShip;
    return index > 0 ? parent.childIds[index - 1] : undefined;
}

/** 获取下一个兄弟结点. 当结点为根节点, 或已经是最后一个子结点时返回 undefined */
export function getNextSiblingId(items: ItemGet, itemOrId: ItemOrId): MindId | undefined {
    const item = itemOf(items, itemOrId);
    const parentShip = getParentShip(items, item);
    if (parentShip === undefined) return undefined;

    const { parent, index } = parentShip;
    return index < parent.childIds.length - 1 ? parent.childIds[index + 1] : undefined;
}

export function childIdsWithDelete(childIds: ReadonlyArray<MindId>, index: number): ReadonlyArray<MindId> | undefined {
    const result = arrayOfDelete(childIds, index);
    if (result.length === 0) return undefined;
    return result;
}

type MindItemParent = IMindItem & Required<Pick<IMindItem, 'childIds'>>;

export interface IParentShip {
    parent: MindItemParent;
    child: IMindItem;
    index: number;
}

const SNoParentErr = (id: MindId) => `Item [${id}] has no parent.`;
const SParentShipErr = (id: MindId, childId: MindId) => `The childIds of item [${id}] should contain [${childId}].`;

export function getParentShip(items: ItemGet, itemOrId: ItemOrId): IParentShip | undefined;
export function getParentShip(items: ItemGet, itemOrId: ItemOrId, required: true): IParentShip;
export function getParentShip(items: ItemGet, itemOrId: ItemOrId, required?: true): IParentShip | undefined {
    const child = itemOf(items, itemOrId);
    const id = child.id;

    if (child.parentId === undefined) {
        if (required) throw new MindItemStateException(id, SNoParentErr(id));

        return undefined;
    }

    const parent = getItem(items, child.parentId, true) as MindItemParent;
    if (!parent.childIds) throw new MindItemStateException(parent.id, SParentShipErr(parent.id, id));

    const index = parent.childIds.indexOf(id);
    if (index < 0) throw new MindItemStateException(parent.id, SParentShipErr(parent.id, id));

    return { child, parent, index };
}

export function getChildCount(item: IMindItem): number {
    const { childIds } = item;
    return childIds ? childIds.length : 0;
}

export interface IBreakParentShipResult {
    parent: IMindItem;
    child: IMindItem;
}

export function breakParentShip(items: ItemGetSet, childId: MindId): IBreakParentShipResult {
    const child = getItem(items, childId, true);
    if (child.parentId === undefined) throw new MindItemStateException(childId, SNoParentErr(childId));

    const parent = getItem(items, child.parentId, true);
    if (!parent.childIds) {
        throw new MindItemStateException(parent.id, SParentShipErr(parent.id, child.id));
    }

    const newParent = excludeChild(parent, child.id);
    const newChild = { ...child, parentId: undefined };

    items.set(newParent.id, newParent);
    items.set(newChild.id, newChild);

    return {
        parent: newParent,
        child: newChild,
    };
}

export function updateLayout(items: ItemGetSet, layoutScope: LayoutScopes, ...ids: (MindId | undefined)[]) {
    const validIds = ids ? (ids.filter(x => x !== undefined) as MindId[]) : undefined;
    if (!validIds || validIds.length === 0) return;

    if (validIds.length === 1) {
        layoutItems(validIds[0], items, layoutScope);
        return;
    }

    const preventDuplication = new Set<MindId>();
    const lastOccurFirst = new Array<MindId>();

    // 排序与去重, 在 ids 中排序靠后的 id,  在 toBeUpdated 同样排在较后的位置. 如果一个 id 出现多次, 顺序以最后一次出现的为准
    validIds.reverse();
    validIds.forEach(x => {
        if (!preventDuplication.has(x)) {
            lastOccurFirst.push(x);
        }
    });

    lastOccurFirst.reverse().forEach(x => {
        layoutItems(x, items, layoutScope);
    });
}

/** 从 `items` 中移除一组结点及其子孙结点. 并返回被移除的结点. */
export function removeItems(
    items: Map<MindId, IMindItem>,
    ids: ReadonlyArray<MindId>,
    rootId: MindId
): Array<IMindItem> {
    // 目前除根节点外, 其他结点必然有 parentId, 未来需要考虑 "自由主题"
    const notRoot = ids.filter(x => x !== rootId);
    const subtreeRootIds = getSubtreeRootIds(items, notRoot);

    const removed: Array<IMindItem> = [];

    const parents: Array<MindId> = subtreeRootIds
        .map<MindId | undefined>(id => {
            const item = getItem(items, id, true);
            return item.parentId ? item.parentId : undefined;
        })
        .filter<string>((id): id is string => id !== undefined);

    // 将被删除的结点及其子树从原树中分离
    subtreeRootIds.forEach(id => {
        visitDeepPostLeft(items, id, descendent => {
            removed.push(descendent);
            breakParentShip(items, descendent.id);
            items.delete(descendent.id);
        });
    });

    parents.forEach(id => {
        updateLayout(items, LayoutScopes.Upward, id);
    });

    return removed;
}

const SIsAncestorErr = (ancestorId: MindId, id: MindId) => `Item [${ancestorId}] is an ancestor of [${id}].`;

export interface IParentShipChanges {
    item: IMindItem;
    oldParent?: IMindItem; // 如果 item 原先是根节点, 则没有 oldParent
    oldIndex?: number;
    newParent?: IMindItem; // 如果 item 变为根节点, 则没有 newParent
    newIndex?: number;
    changedItems: Array<IMindItem>;
}

/** 更改一个结点的父节点. */
export function setItemParent(
    items: ItemGetSet,
    itemOrId: MindId,
    newParentId: MindId | undefined,
    newIndex: number | undefined
): IParentShipChanges {
    const item = itemOf(items, itemOrId);
    if (item.id === newParentId) throw new Error(`The id and parent id are same: [${item.id}].`);

    const oldParentShip = getParentShip(items, item);
    const oldParentId = oldParentShip ? oldParentShip.parent.id : undefined;
    const oldIndex = oldParentShip ? oldParentShip.index : undefined;

    const changedItems = new Array<IMindItem>();

    // 如果被移动的结点是目标结点的子孙结点, 需要特别处理
    if (newParentId && isDescendantOf(items, newParentId, item.id)) {
        let innerChanges: IParentShipChanges;
        if (oldParentShip) {
            innerChanges = setItemParent(items, newParentId, oldParentShip.parent.id, oldParentShip.index);
        } else {
            innerChanges = setItemParent(items, newParentId, undefined, undefined);
        }
        changedItems.push(...innerChanges.changedItems);
    }

    // 将 item 从 oldParent 中移除
    let oldParent: IMindItem | undefined = undefined;
    if (oldParentId) {
        oldParent = excludeChild(getItem(items, oldParentId, true), item.id);
        if (oldParent.isExpanded && (oldParent.childIds === undefined || oldParent.childIds.length === 0)) {
            oldParent = { ...oldParent, isExpanded: false };
        }
        items.set(oldParent.id, oldParent);
    }

    // 将 item 加入 newParent 中
    let newParent: IMindItem | undefined = undefined;
    if (newParentId) {
        newParent = insertChild(getItem(items, newParentId, true), item.id, newIndex);
        if (!newParent.isExpanded) {
            newParent = { ...newParent, isExpanded: true };
        }
        items.set(newParent.id, newParent);
    }

    const newItem = { ...getItem(items, item.id, true), parentId: newParentId };
    items.set(newItem.id, newItem);

    changedItems.push(item);
    if (oldParent) changedItems.push(oldParent);
    if (newParent) changedItems.push(newParent);

    return {
        item: newItem,
        oldParent,
        oldIndex,
        newParent,
        newIndex,
        changedItems,
    };
}

export function copyItemsToClipboard(items: ItemGetSet, subtreeRootIds: ReadonlyArray<MindId>): IMindClipboard {
    const clipboardRootIds = getSubtreeRootIds(items, subtreeRootIds);
    const clipboardItems = new Map<MindId, IMindItem>();

    clipboardRootIds.forEach(selectedId => {
        visitDeepPreLeft(items, selectedId, item => {
            clipboardItems.set(item.id, {
                ...item,
                // 对于子树的根结点, 移除 parentId 属性.
                parentId: item.id !== selectedId ? item.parentId : undefined,
            });
        });
    });

    return { clipboardItems, clipboardRootIds };
}

export interface ICloneResult {
    clonedItems: Array<IMindItem>;
    idMap: Map<MindId, MindId>;
}

/** 复制一组结点. */
export function cloneItems(items: ReadonlyArray<IMindItem>): ICloneResult {
    const idMap = new Map(items.map<[MindId, MindId]>(x => [x.id, generateId()]));

    const clonedItems = items.map(source => ({
        ...source,
        ...getMappedRelationIds(source, idMap),
        id: idMap.get(source.id)!,
    }));

    return {
        clonedItems,
        idMap,
    };
}

/** 使用新旧 id 的映射表 更新 {@link IMindItem} 中的结点关系字段. */
export function getMappedRelationIds(
    item: Mutable<IMindItem>,
    idMap: Map<MindId, MindId>
): Pick<IMindItem, 'parentId' | 'childIds'> {
    const parentId = item.parentId ? getMappedIdIfFound(idMap, item.parentId) : undefined;
    const childIds = item.childIds ? item.childIds.map(x => getMappedIdIfFound(idMap, x)) : undefined;
    return {
        parentId,
        childIds,
    };
}

export function getMappedIdIfFound(idMap: Map<MindId, MindId>, originId: MindId): MindId {
    const mapped = idMap.get(originId);
    return mapped ? mapped : originId;
}
export function remoteUpdateDiagram(diagram: Partial<IMindDiagram> & Pick<IMindDiagram, 'id'>): IRemoteCommand {
    return {
        commandId: generateId(),
        commandName: 'updateDiagram',
        onExecute: async () => {
            const args: IPartialMindDocumentEntity = { id: diagram.id, title: diagram.title, rootId: diagram.rootId };
            await apis.docs.updateDocument(args);
        },
    };
}

// 限定可以在 batch 中更新的属性
export type BatchChangeableDocumentProps = Pick<IPartialMindDocumentEntity, 'id' | 'rootId' | 'title'>;
export const BatchChangeableDocumentPropNames: (keyof BatchChangeableDocumentProps)[] = ['rootId', 'title'];

export function createDocumentRemoteBatchCommand(
    document: BatchChangeableDocumentProps,
    items: MapWrapper<MindId, IMindItem>,
    criteria: (change: IMapChange<MindId, IMindItem>) => boolean = defaultChangeCriteria
): IRemoteCommand {
    let changes = items ? items.getChanges() : [];
    if (criteria) changes = changes.filter(x => criteria(x));

    // 对每个 item 的变化, 生成 batch command 的条目
    const batch = changes.map<IMindDocumentCommand>(x => createDocumentBatchCommandEntry(x, document.id));

    // 增加 document 本身的更改
    const documentPropNames = Object.getOwnPropertyNames(document);
    const hasDocumentChanges =
        BatchChangeableDocumentPropNames.find(x => documentPropNames.indexOf(x) >= 0) !== undefined;
    if (hasDocumentChanges) {
        // 避免修改其余的属性
        const changeableProps: BatchChangeableDocumentProps = {
            id: document.id,
            rootId: document.rootId,
            title: document.title,
        };
        const documentUpdateCommand: IMindDocumentUpdateCommand = {
            commandType: MindDocumentCommandTypes.documentUpdate,
            data: changeableProps,
        };
        batch.push(documentUpdateCommand);
    }

    return {
        commandId: generateId(),
        commandName: 'documentBatch',
        onExecute: async () => {
            await apis.docs.executeBatch({
                documentId: document.id,
                commands: batch,
            });
        },
    };
}

export function createDocumentBatchCommandEntry(
    change: IMapChange<MindId, IMindItem>,
    documentId: string
): IMindDocumentCommand {
    switch (change.changeKind) {
        case MapChangeKinds.itemAdded: {
            return {
                commandType: MindDocumentCommandTypes.itemAdd,
                data: convertItemToEntity(change.newItem, documentId),
            };
        }
        case MapChangeKinds.itemRemoved: {
            return {
                commandType: MindDocumentCommandTypes.itemRemove,
                data: convertItemToEntity(change.oldItem, documentId),
            };
        }
        case MapChangeKinds.valueChanged: {
            return {
                commandType: MindDocumentCommandTypes.itemUpdate,
                data: convertItemToEntity(change.newItem, documentId),
            };
        }
        default: {
            throw new Error(`Unknown changeKind.`);
        }
    }
}

export function defaultChangeCriteria(change: IMapChange<MindId, IMindItem>): boolean {
    switch (change.changeKind) {
        case MapChangeKinds.itemAdded:
            return true;
        case MapChangeKinds.itemRemoved:
            return true;
        case MapChangeKinds.valueChanged: {
            const { oldItem, newItem } = change;
            if (oldItem.text !== newItem.text) return true;
            if (oldItem.parentId !== newItem.parentId) return true;
            if (oldItem.childIds !== newItem.childIds) return true;
            if (oldItem.lastUpdatedAt !== newItem.lastUpdatedAt) return true;
            if (oldItem.lastUpdaterId !== newItem.lastUpdaterId) return true;
            if (oldItem.kinds !== newItem.kinds) return true;

            if (oldItem.structureKind !== newItem.structureKind) return true;
            if (oldItem.lineKind !== newItem.lineKind) return true;
            if (oldItem.fillColor !== newItem.fillColor) return true;
            if (oldItem.borderWidth !== newItem.borderWidth) return true;
            if (oldItem.borderColor !== newItem.borderColor) return true;
            if (oldItem.textFontFamily !== newItem.textFontFamily) return true;
            if (oldItem.textFontSize !== newItem.textFontSize) return true;
            if (oldItem.textFontColor !== newItem.textFontColor) return true;
            if (oldItem.textFontBold !== newItem.textFontBold) return true;
            if (oldItem.textFontItalic !== newItem.textFontItalic) return true;

            return false;
        }
        default:
            const _exhausiveCheck: never = change;
            return false;
    }
}

type CollapseFixup = Pick<IMindDiagram, 'editingId' | 'selection'>;

/** 处理结点收起受影响的 {@link IMindDiagram.editingId}, {@link IMindDiagram.selection}. */
export function fixCollapse(items: ItemGet, state: CollapseFixup, ...ids: Readonly<MindId[]>): CollapseFixup {
    let editingId = state.editingId;
    let selection = state.selection;

    ids.forEach(id => {
        if (editingId) {
            if (getAncestors(items, editingId).find(x => x.id === id) !== undefined) {
                editingId = undefined;
            }
        }

        if (selection) {
            selection = selection.filter(x => isDescendantOf(items, x, id) === false);
        }
    });

    return { editingId, selection };
}

export function appendChild(item: IMindItem, childId: MindId): IMindItem {
    return {
        ...item,
        childIds: appendChildId(item.childIds, childId),
    };
}

export function appendChildId(childIds: ReadonlyArray<MindId> | undefined, childId: MindId): Array<MindId> {
    return childIds ? [...childIds, childId] : [childId];
}

export function insertChild(item: IMindItem, childId: MindId, index: number | undefined): IMindItem {
    let childIds = item.childIds ? Array.from(item.childIds) : [];
    if (index === undefined || index > childIds.length) {
        index = childIds.length;
    }

    childIds.splice(index, 0, childId);
    return {
        ...item,
        childIds,
    };
}

/** 从结点的 {@link IMindItem.childIds} 中移除指定子结点的 id. 注意, 本函数未处理子结点的 parentId, 它可能还指向当前结点. */
export function excludeChild(item: IMindItem, childId: MindId): IMindItem {
    if (!item.childIds) throw new MindItemStateException(item.id, SParentShipErr(item.id, childId));
    const result = {
        ...item,
        childIds: excludeChildId(item.childIds, childId),
    };
    return result;
}

export function excludeChildId(childIds: ReadonlyArray<MindId>, childId: MindId): Array<MindId> | undefined {
    const result = childIds.filter(x => x !== childId);
    return result.length > 0 ? result : undefined;
}
