import { IsNotEmpty, MaxLength, MinLength } from 'class-validator';

export interface IDiscussionPostArgs {
    id?: string;
    itemId: string;
    text: string;
}

export interface IDiscussionReplyArgs {
    id?: string;
    postId?: string;
    replyToId?: string;
    text: string;
}

export class DiscussionPostArgs implements IDiscussionPostArgs {
    id?: string;

    @IsNotEmpty({ message: '节点id不能为空' })
    itemId: string;

    @IsNotEmpty({ message: '内容不能为空' })
    @MaxLength(2000, { message: '不能超过2000字符' })
    text: string;
}

export class DiscussionReplyArgs implements IDiscussionReplyArgs {
    id?: string;

    postId?: string;

    replyToId?: string;

    @MaxLength(2000, { message: '不能超过2000字符' })
    text: string;
}
