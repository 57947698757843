import React from 'react';

/** 代表 {@link MindToolWindow} 的一个 tab 中的内容. */
export default class MindShortcutPane extends React.Component<{}, {}> {
    public render() {
        return (
            <div className="mind-shortcut-tab">
                <div className="pane-title">快捷键</div>

                <div className="shortcut-keys-area">
                    <div className="item">
                        选择主题
                        <span className="key-area ">
                            <span className="key-name">鼠标左键</span>
                        </span>
                    </div>
                    <div className="item">
                        追加选择
                        <span className="key-area ">
                            <span className="key-name">ctrl/command</span>+<span className="key-name">鼠标左键</span>
                        </span>
                    </div>
                    <div className="item">
                        创建兄弟主题
                        <span className="key-area ">
                            <span className="key-name">enter</span>
                        </span>
                    </div>
                    <div className="item">
                        创建子主题
                        <span className="key-area ">
                            <span className="key-name">TAB</span>
                        </span>
                    </div>
                    <div className="item">
                        删除主题
                        <span className="key-area ">
                            <span className="key-name">delete | backspace</span>
                        </span>
                    </div>
                    <div className="item">
                        移动选中主题
                        <span className="key-area ">
                            <span className="key-name">ctrl/command</span>+
                            <span className="key-name">up/dn/left/right</span>
                        </span>
                    </div>
                    <div className="item">
                        切换选中节点
                        <span className="key-area ">
                            <span className="key-name">up/dn/left/right</span>
                        </span>
                    </div>
                    <div className="item">
                        展开子主题
                        <span className="key-area ">
                            <span className="key-name">]</span>
                        </span>
                    </div>
                    <div className="item">
                        收起子主题
                        <span className="key-area ">
                            <span className="key-name">[</span>
                        </span>
                    </div>
                    <div className="item">
                        编辑主题
                        <span className="key-area ">
                            <span className="key-name">space</span>
                        </span>
                    </div>
                    <div className="item">
                        复制
                        <span className="key-area ">
                            <span className="key-name">ctrl/command</span>+<span className="key-name">c</span>
                        </span>
                    </div>
                    <div className="item">
                        剪切
                        <span className="key-area ">
                            <span className="key-name">ctrl/command</span>+<span className="key-name">x</span>
                        </span>
                    </div>
                    <div className="item">
                        粘贴
                        <span className="key-area ">
                            <span className="key-name">ctrl/command</span>+<span className="key-name">v</span>
                        </span>
                    </div>
                    <div className="item">
                        缩放
                        <span className="key-area ">
                            <span className="key-name">alt/command</span>+<span className="key-name">鼠标滚轮</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
