import * as React from 'react';
import { RouteComponentProps } from 'react-router';

export const NoMatch: React.FunctionComponent<RouteComponentProps> = props => (
    <div>
        There is no Route matched for
        <span style={{ marginLeft: '14px', color: 'red' }}>
            {props && props.location ? props.location.pathname : 'unknown path'}
        </span>
        .
    </div>
);

export const UnderConstruction: React.FunctionComponent = () => <div>Content is under construction (开发中)......</div>;
