import { isDevelopment } from '../../core/app-config';
import { IMindDiagram, IMindItem, MindId } from '../mind-models';
import { visitDeepPreLeft } from '../mind-visitors';

function showError(message: string) {
    console.error(message);
    return message;
}

export function checkDiagram(diagram: IMindDiagram, removeIsolated: boolean): void {
    if (!diagram) return;

    const { items, rootId } = diagram;

    if (items) {
        const visited = new Map<MindId, IMindItem>();
        visitDeepPreLeft(items, rootId, item => {
            const { id, text, childIds } = item;
            if (childIds) {
                const idSet = new Set<MindId>();
                for (let childId of childIds) {
                    // childIds 中不应有 null 或 undefined
                    if (!childId) {
                        showError(`Item [${id} (${text})]'s childIds contains null or undefined or empty id`);
                    }

                    if (idSet.has(childId)) {
                        showError(`Item [${id} (${text})]'s childIds contains duplicated childId [${childId}].`);
                    }
                    idSet.add(childId);

                    // 子结点的 parentId 必须等于当前结点的 id
                    const child = items.get(childId);
                    if (!child) showError(`The item [${childId}] does not exist.`);
                    if (child && child.parentId !== id) {
                        showError(`The parentId of [${childId} (${child.text})] should be [${id} (${text})].`);
                    }
                }
            }

            // checkLayoutResult(item);
            visited.set(item.id, item);
        });

        // check isolated items
        const isolated = new Map<MindId, IMindItem>();
        items.forEach(item => {
            if (!visited.has(item.id)) {
                isolated.set(item.id, item);
            }
        });
        if (isolated.size > 0) {
            let isolatedIds = '';
            for (let item of isolated.values()) {
                isolatedIds = isolated + `id: [${item.id}], text: [${item.text}], `;
            }
            showError(`There are isolated items: ${isolatedIds}.`);

            // remove isolated items to prevent further errors.
            if (removeIsolated) {
                if (!isDevelopment) {
                    isolated.forEach(item => {
                        items.delete(item.id);
                    });
                }
            }
        }
    }
}

export function checkLayoutResult(item: IMindItem) {
    const { id, text, regionSize, relativePos, bodyPos, poleStartPos, gripPos, inPortPos, outPortPos } = item;
    if (regionSize) {
        if (regionSize.width < 0) {
            throw new Error(`Item [${id} (${text}]'s regionSize.height [${regionSize.width}] < 0`);
        }
        if (regionSize.height < 0) {
            throw new Error(`Item [${id} (${text}]'s regionSize.width [${regionSize.height}] < 0`);
        }
    }
    if (relativePos) {
        if (relativePos.x < 0) throw new Error(`Item [${id} (${text}]'s relativePos.x [${relativePos.x}] < 0`);
        if (relativePos.y < 0) throw new Error(`Item [${id} (${text}]'s relativePos.x [${relativePos.y}] < 0`);
    }
    if (bodyPos) {
        if (bodyPos.x < 0) throw new Error(`Item [${id} (${text}]'s bodyPos.x [${bodyPos.x}] < 0`);
        if (bodyPos.y < 0) throw new Error(`Item [${id} (${text}]'s bodyPos.x [${bodyPos.y}] < 0`);
    }
    if (poleStartPos) {
        if (poleStartPos.x < 0) throw new Error(`Item [${id} (${text}]'s poleStartPos.x [${poleStartPos.x}] < 0`);
        if (poleStartPos.y < 0) throw new Error(`Item [${id} (${text}]'s poleStartPos.x [${poleStartPos.y}] < 0`);
    }
    if (gripPos) {
        if (gripPos.x < 0) throw new Error(`Item [${id} (${text}]'s gripPos.x [${gripPos.x}] < 0`);
        if (gripPos.y < 0) throw new Error(`Item [${id} (${text}]'s gripPos.x [${gripPos.y}] < 0`);
    }
    if (inPortPos) {
        if (inPortPos.x < 0) throw new Error(`Item [${id} (${text}]'s inPortPos.x [${inPortPos.x}] < 0`);
        if (inPortPos.y < 0) throw new Error(`Item [${id} (${text}]'s inPortPos.x [${inPortPos.y}] < 0`);
    }
    if (outPortPos) {
        if (outPortPos.x < 0) throw new Error(`Item [${id} (${text}]'s outPortPos.x [${outPortPos.x}] < 0`);
        if (outPortPos.y < 0) throw new Error(`Item [${id} (${text}]'s outPortPos.x [${outPortPos.y}] < 0`);
    }
}
