import * as React from 'react';
import { RouteUrls } from '../../../core/app-config';

export class AdminHomePane extends React.Component<{}, {}> {
    public render() {
        return (
            <div>
                <div>This is the admin home page.</div>
                <div>
                    <a href={RouteUrls.admin.users}>users</a>
                </div>
            </div>
        );
    }
}
