import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, Route } from 'react-router-dom';
import { IDispatch } from '../../common/redux-utils';
import { logoImg, posterImg } from '../constant';
import { RouteUrls } from '../../core/app-config';
// import { IRootState } from '../../store/app-redux';
import MobileLogin from './login/mobile-login';
import WechatLogin from './login/wechat-login';
import './account.less';

interface IConnectedProps {}
interface IOwnProps {}
export interface IProps extends IConnectedProps, IOwnProps, IDispatch, RouteComponentProps {}

class Login extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    componentWillMount(): void {
        // console.log(this.props.location);
        // if (this.props.location.pathname === RouteUrls.users.login) {
        //     this.props.history.replace(RouteUrls.users.mobileLogin);
        // }
    }

    render() {
        return (
            <div className="nw-login-page">
                <div className="nw-login-header">
                    <img src={logoImg} />
                    NaoWen
                </div>
                <div className="nw-login-poster">
                    <img src={posterImg} />
                </div>
                <div className="nw-login-content-bg">
                    <div className="nw-login-content">
                        <div className="nw-login-content-header">登录</div>
                        <Route path={RouteUrls.users.mobileLogin} component={MobileLogin} />
                        <Route path={RouteUrls.users.wechatLogin} component={WechatLogin} />
                        <div className="nw-login-content-footer">
                            <div className="nw-register">
                                新用户？
                                <Link to={RouteUrls.users.register} className="nw-reg">
                                    请先注册
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// const LoginPage = connect<IConnectedProps, {}, IOwnProps, IRootState>((state: IRootState) => ({
//     account: state.account,
// }))(Login);
const LoginPage = connect()(Login);
export default LoginPage;
