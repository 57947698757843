import { Button, Input } from 'antd';
import { IUser } from 'neka-common';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { IDispatch } from '../../../common/redux-utils';
import { getErrorText, showErrorNotification } from '../../../common/ui-utils';
import { apis } from '../../../core/server-apis';

import '../../../styles/common-styles.less';

interface IFormValues extends Readonly<Partial<IUser>> {}

interface IViewProps {
    user: IFormValues;
    onEdit: (user: IFormValues) => void;
    onDelete: (user: IFormValues) => void;
    onDiscard: (user: IFormValues) => void;
}

const UserDetailsView = (props: IViewProps) => {
    const user = props.user;
    return (
        <div>
            <div className="form-title">User Details</div>
            <div>
                <Input name="username" placeholder="User Name" className="form-row" value={user.name} />
                <Input name="email" placeholder="Email" className="form-row" value={user.email} />
                <Input name="mobile" placeholder="Mobile" className="form-row" value={user.mobile} />
                <Input
                    name="lastLoginAt"
                    placeholder="Last Login At"
                    className="form-row"
                    value={user.lastLoginAt ? user.lastLoginAt.toString() : ''}
                />
                <Input
                    name="createdAt"
                    placeholder="Last Created At"
                    className="form-row"
                    value={user.createdAt ? user.createdAt.toString() : ''}
                />
                <Input
                    name="lastUpdatedAt"
                    placeholder="Last Updated At"
                    className="form-row"
                    value={user.lastUpdatedAt ? user.lastUpdatedAt.toString() : ''}
                />
                <div className="form-row">
                    <Button className="form-button" onClick={() => props.onDiscard(user)}>
                        Go Back
                    </Button>
                    <Button className="form-button" onClick={() => props.onEdit(user)}>
                        Edit
                    </Button>
                    <Button className="form-button" onClick={() => props.onDelete(user)}>
                        Delete
                    </Button>
                </div>
            </div>
        </div>
    );
};

// TODO: put `user` in props ? or a `getUser` without parameter `userId`
type ISiteProps = RouteComponentProps<{ userId: string }> & IDispatch;

interface ISiteState {
    user?: IUser;
    loadingError?: any;
}

class UserDetailsSite extends React.Component<ISiteProps, ISiteState> {
    constructor(props: ISiteProps) {
        super(props);
        this.state = {};
    }

    private getUserId(): string {
        return this.props.match.params.userId;
    }

    private async loadUser() {
        const userId = this.getUserId();
        try {
            const user = await apis.users.findOneById(userId);
            this.setState({ user });
        } catch (e) {
            this.setState({ loadingError: e });
        }
    }

    public async componentDidMount() {
        await this.loadUser();
    }

    private handleDiscard = (user: IFormValues) => {
        this.props.history.goBack();
    };

    private handleEdit = (user: IFormValues) => {
        this.props.history.push(`/admin/users/${user.id}/edit`);
    };

    private handleDelete = async (user: IFormValues) => {
        const userId = this.getUserId();
        await apis.users
            .deleteOneById(userId)
            .then(() => this.handleDiscard(user))
            .catch(reason => showErrorNotification(reason));
    };

    public render() {
        if (this.state.loadingError) return <div>{getErrorText(this.state.loadingError)}</div>;
        if (!this.state.user) return <div>Loading...</div>;
        return (
            <UserDetailsView
                user={this.state.user}
                onEdit={this.handleEdit}
                onDelete={this.handleDelete}
                onDiscard={this.handleDiscard}
            />
        );
    }
}

export const UserDetailsPane = connect()(withRouter(UserDetailsSite));
