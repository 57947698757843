import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Modal, Button, Checkbox, Form, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { IBasicCredential, IMobileCredential } from 'neka-common';
import { IDispatch } from '../../../common/redux-utils';
import { getErrorInfoName, showErrorNotification } from '../../../common/ui-utils';
import { ExceptionType, formConstant } from '../../constant';
import {
    localStorageIsRememberLogin,
    localStoragePrincipal,
    localStorageRedirectUrl,
    RouteUrls,
} from '../../../core/app-config';
import { apis } from '../../../core/server-apis';
import { IRootState, logIn } from '../../../store/app-redux';
import { IAccountMap, handleTabChange, setPwLoginCounter } from '../../store/account-redux';
import SendSMSCode from '../common/account-send-smscode';
import { doRedirectPath, filterNumberInput } from '../../account-util';
import ImageCode from '../common/account-imagecode';

interface IConnectedProps {
    account: IAccountMap;
}
interface IOwnProps {}
export interface IProps extends IConnectedProps, IOwnProps, IDispatch, RouteComponentProps, FormComponentProps {}

class MobileLogin extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    state = {
        modelName: '此手机号尚未注册脑问账号',
        modelContent: '是否使用该手机号注册新账号',
        modelVisible: false,
        validateModelVisible: false,
    };

    // 切换tab
    changeTab: React.MouseEventHandler = () => {
        let { selectedTabValue } = this.props.account;
        this.props.dispatch(handleTabChange(selectedTabValue === 'password' ? 'sms' : 'password'));
    };

    pwdLoginHandler: React.EventHandler<React.MouseEvent> = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (this.props.account.selectedTabValue === 'password') {
                    if (this.props.account.passwordLoginCount > 2) {
                        this.setState({
                            validateModelVisible: true,
                        });
                    } else {
                        this.login({ username: values.mobile as string, password: values.password as string });
                    }
                } else {
                    this.login({ mobile: values.mobile as string, verifyCode: values.verifyCode as string });
                }
            }
        });
    };

    login = async (values: IBasicCredential | IMobileCredential) => {
        try {
            let principal = await apis.account.token(values);
            // 保存token, 根据remember的不同保存remember字段
            localStorageIsRememberLogin.save(Boolean(this.props.form.getFieldValue('remember')));
            localStoragePrincipal.save(principal);
            this.props.dispatch(setPwLoginCounter(0));
            this.props.dispatch(logIn(principal));
            // if (localStorageRedirectUrl.load()) {
            //     this.props.history.push(localStorageRedirectUrl.load()!.url);
            //     localStorageRedirectUrl.clear();
            // } else {
            //     this.props.history.push(RouteUrls.dashboard);
            // }
            doRedirectPath(this.props.history);
        } catch (e) {
            console.log(this.props.account.selectedTabValue);
            if (this.props.account.selectedTabValue === 'password') {
                this.props.dispatch(setPwLoginCounter(this.props.account.passwordLoginCount + 1));
            }
            let infoName: string = getErrorInfoName(e);
            if (
                infoName === ExceptionType.MobileNotExistException ||
                infoName === ExceptionType.UserNameNotExistException
            ) {
                this.setState({
                    modelVisible: true,
                    modelName: '此手机号尚未注册脑问账号',
                    modelContent: '是否使用该手机号注册新账号',
                });
            } else if (infoName === ExceptionType.PasswordMismatchException) {
                this.props.form.setFields({
                    password: {
                        value: this.props.form.getFieldValue('password'),
                        errors: [new Error('密码输入错误')],
                    },
                });
            } else if (infoName === ExceptionType.SmsCodeIncorrectException) {
                this.props.form.setFields({
                    verifyCode: {
                        value: this.props.form.getFieldValue('verifyCode'),
                        errors: [new Error(formConstant.validateRules.smsCodeInputErrorMassage)],
                    },
                });
            } else {
                showErrorNotification(e);
            }
        }
    };

    matchImageLogin = () => {
        this.setState({
            validateModelVisible: false,
        });
        let username = this.props.form.getFieldValue('mobile');
        let password = this.props.form.getFieldValue('password');
        this.login({ username: username as string, password: password as string });
    };

    clickModelCancel = () => {
        this.setState({ modelVisible: false });
    };

    clickModelOK = () => {
        this.setState({ modelVisible: false });
        this.props.history.push(RouteUrls.users.register);
    };

    render() {
        let { selectedTabValue } = this.props.account;
        let { getFieldDecorator, getFieldValue } = this.props.form;
        return (
            <div className="nw-login-content-center">
                <Form onSubmit={this.pwdLoginHandler} className="login-form">
                    <Form.Item>
                        {getFieldDecorator('mobile', {
                            normalize: filterNumberInput,
                            validateTrigger: formConstant.validateTrigger,
                            rules: [
                                {
                                    required: true,
                                    message: formConstant.validateRules.mobileNullErrorMassage,
                                },
                                {
                                    pattern: formConstant.validateRules.mobileRegexp,
                                    message: formConstant.validateRules.mobileMatchRegexpMassage,
                                },
                            ],
                        })(<Input type="tel" placeholder="输入已注册的手机号" />)}
                    </Form.Item>
                    {selectedTabValue === 'password' ? (
                        <Form.Item>
                            {getFieldDecorator('password', {
                                validateTrigger: formConstant.validateTrigger,
                                rules: [
                                    {
                                        required: true,
                                        message: formConstant.validateRules.passwordNullErrorMassage,
                                    },
                                ],
                            })(
                                <Input
                                    autoComplete="off"
                                    type="password"
                                    placeholder="请输入密码"
                                    className="nw-password-input"
                                />
                            )}
                            {/*TODO: 这期不做先预留*/}
                            {/*<div className="password-button">*/}
                            {/*<Link to={RouteUrls.users.forgetPasswor} className="reg">*/}
                            {/*忘记密码*/}
                            {/*</Link>*/}
                            {/*</div>*/}
                        </Form.Item>
                    ) : (
                        <Form.Item>
                            {getFieldDecorator('verifyCode', {
                                normalize: filterNumberInput,
                                validateTrigger: formConstant.validateTrigger,
                                rules: [
                                    {
                                        required: true,
                                        message: formConstant.validateRules.smsCodeNullErrorMassage,
                                    },
                                    {
                                        pattern: formConstant.validateRules.smsCodeRegexp,
                                        message: formConstant.validateRules.smsCodeMatchRegexpMassage,
                                    },
                                ],
                            })(<Input autoComplete="off" placeholder="请输入验证码" className="nw-smscode-input" />)}
                            <SendSMSCode mobile={getFieldValue('mobile')} />
                        </Form.Item>
                    )}
                    <Form.Item className="nw-login-submit-item">
                        {getFieldDecorator('remember', {
                            valuePropName: 'checked',
                            initialValue: false,
                        })(<Checkbox>下次自动登录</Checkbox>)}
                        <a className="nw-login-form-toggle" onClick={this.changeTab}>
                            {selectedTabValue === 'password' ? '验证码登录' : '密码登录'}
                        </a>
                        <Button htmlType="submit" className="nw-login-form-button">
                            登录
                        </Button>
                    </Form.Item>
                </Form>
                <div className="nw-login-divider">
                    <div className="nw-login-divider-line" />
                    <div className="nw-login-divider-desc">第三方登录</div>
                </div>
                {/*<Button className="nw-login-wechat-button">*/}
                {/**/}
                {/*</Button>*/}
                <Link to={'/users/login/wechat'} className="nw-login-wechat-button">
                    微信登录
                </Link>
                <Modal
                    title={this.state.modelName}
                    visible={this.state.modelVisible}
                    onOk={this.clickModelOK}
                    onCancel={this.clickModelCancel}
                    okText="注册新账号"
                    cancelText="使用其他账号"
                    className="nw-account-model"
                >
                    <div>{this.state.modelContent}</div>
                </Modal>
                <Modal
                    title="请先完成下方验证"
                    visible={this.state.validateModelVisible}
                    onCancel={() => {
                        this.setState({ validateModelVisible: false });
                    }}
                    className="nw-account-model"
                    destroyOnClose={true}
                    footer={null}
                >
                    <ImageCode imageUrl={require('../../assets/image-code-bg.svg')} onMatch={this.matchImageLogin} />
                </Modal>
            </div>
        );
    }
}

const MobileLoginPage = connect<IConnectedProps, {}, IOwnProps, IRootState>((state: IRootState) => ({
    account: state.account,
}))(MobileLogin);
export default Form.create({ name: 'mobileLogin' })(MobileLoginPage);
