// =========================================================
// 交互状态的修饰性元素
// =========================================================

import { StructureKinds, NotImplementedException } from 'neka-common';
import React from 'react';
import { getDebug } from '../../core/app-diag';
import {
    bottomMidpoint,
    Directions,
    IPos,
    IRect,
    ISize,
    leftMidpoint,
    rightMidpoint,
    topMidpoint,
    transposeSize,
} from '../mind-draw2d';
import { absoluteBodyRect } from '../mind-layout';
import { getItem, getStructureKind, ItemGet, itemOf, ItemOrId, MindId } from '../mind-models';
import { LogicRightStyles } from '../mind-styles';
import { DropActions, IDropIntent } from '../mind-utils';
import { getParentShip } from '../store/mind-redux-helper';
import { SvgHelper } from './svg-helper';

const debug = getDebug(__filename);

interface IMindDropDecoratorProps {
    items: ItemGet;
    rootId: MindId;
    dropIntent: IDropIntent;
    /** 鼠标在 SVG 坐标系内的坐标. */
    cursorPos: IPos;
    svgHelper: SvgHelper;
}

interface IDropStub {
    body: IRect;
    linkStart: IPos;
    linkEnd: IPos;
}

/** 拖放操作占位符的参数. */
interface IStubOptions {
    readonly parentSize: Readonly<ISize>;
    /** 大小. */
    readonly size: Readonly<ISize>;
    /** 与 兄弟 之间的距离 */
    readonly space: number;
}

const LogicRightStubOptions: IStubOptions = Object.freeze({
    parentSize: Object.freeze({ width: 4, height: 48 }),
    size: Object.freeze({ width: 48, height: 4 }),
    space: 4,
});

const OrgDownStubOptions: IStubOptions = Object.freeze({
    parentSize: Object.freeze({ width: 48, height: 4 }),
    size: Object.freeze({ width: 4, height: 48 }),
    space: 4,
});

export class MindDropDecorator extends React.Component<IMindDropDecoratorProps, {}> {
    public constructor(props: IMindDropDecoratorProps) {
        super(props);
    }

    public render() {
        const { items, dropIntent, cursorPos } = this.props;
        if (!dropIntent) return null;
        if (!cursorPos) return null;

        const { anchorId, action, direction } = dropIntent;
        debug(`render: anchorId [${anchorId}], action [${action}], direction [${direction}]`);

        if (!anchorId) {
            return this.renderDragSourceIcon(cursorPos);
        }

        const anchorBody = absoluteBodyRect(this.props, anchorId, true);

        return (
            <React.Fragment>
                {this.renderDragSourceIcon(cursorPos)}
                {this.renderDirectionIndicator(anchorBody, dropIntent)}
                {this.renderStub(items, anchorBody, dropIntent)}
            </React.Fragment>
        );
    }

    /** 在鼠标附近显示代表源结点的装饰符. */
    private renderDragSourceIcon(cursorPos: IPos) {
        // debug(`renderDragSourceIcon [%j]`, cursorPos);
        return <rect x={cursorPos.x} y={cursorPos.y} width={20} height={8} className="mg-drag-source-box" />;
    }

    /** 在锚点结点显示鼠标位于 `上/下/左/右` 区域中的哪一个. */
    private renderDirectionIndicator(anchorBody: IRect, dropIntent: IDropIntent) {
        const { direction, action } = dropIntent;
        if (!direction) return null;

        const directionRect = this.getDirectionRect(anchorBody, direction);
        const allowable = action !== DropActions.notAllow ? 'allow' : 'not-allow';
        return <rect {...directionRect} className={`mg-drop-direction ${allowable}`} />;
    }

    /** 显示目标结点将被放置的位置. */
    private renderStub(items: ItemGet, anchorBody: IRect, dropIntent: IDropIntent) {
        const { action, direction } = dropIntent;
        if (action === DropActions.notAllow) return null;
        if (direction === undefined) return null;

        const stub = this.getSimpleStub(anchorBody, direction);
        const { body, linkStart, linkEnd } = stub;

        // TODO: refactor MindLink to share the link points calculation algorithm
        return (
            <React.Fragment>
                <rect {...body} className="mg-drop-stub" rx={4} ry={4} />
                <line x1={linkStart.x} y1={linkStart.y} x2={linkEnd.x} y2={linkEnd.y} className="mg-drop-stub-link" />
            </React.Fragment>
        );
    }

    /** 返回一个矩形, 位于锚点内部的 `上/下/左/右` 区域之一. */
    private getDirectionRect(body: IRect, direction: Directions): React.SVGProps<SVGRectElement> {
        const { x, y, width, height } = body;
        const horiPercent = 1 / 4;

        switch (direction) {
            case Directions.left:
                return {
                    x: x,
                    y: y,
                    width: width * horiPercent,
                    height: height,
                };
            case Directions.right:
                return {
                    x: x + width - width * horiPercent,
                    y: y,
                    width: width * horiPercent,
                    height: height,
                };
            case Directions.up:
                return {
                    x: x,
                    y: y,
                    width: width,
                    height: height / 2,
                };
            case Directions.down:
                return {
                    x: x,
                    y: y + height / 2,
                    width: width,
                    height: height / 2,
                };
            default:
                throw new Error(`Unhandled direction [${direction}].`);
        }
    }

    private getSimpleStub(anchorBody: IRect, direction: Directions): IDropStub {
        const distance = 4; // Stub 到 anchor 边缘的距离
        const thickness = 4;

        switch (direction) {
            case Directions.left: {
                const body: IRect = {
                    x: anchorBody.x - distance - thickness,
                    y: anchorBody.y,
                    width: thickness,
                    height: anchorBody.height,
                };
                const linkStart = leftMidpoint(anchorBody);
                const linkEnd = rightMidpoint(body);
                return { body, linkStart, linkEnd };
            }
            case Directions.right: {
                const body: IRect = {
                    x: anchorBody.x + anchorBody.width + distance,
                    y: anchorBody.y,
                    width: thickness,
                    height: anchorBody.height,
                };
                const linkStart = rightMidpoint(anchorBody);
                const linkEnd = leftMidpoint(body);
                return { body, linkStart, linkEnd };
            }
            case Directions.up: {
                const body: IRect = {
                    x: anchorBody.x,
                    y: anchorBody.y - distance - thickness,
                    width: anchorBody.width,
                    height: thickness,
                };
                const linkStart = topMidpoint(anchorBody);
                const linkEnd = bottomMidpoint(body);
                return { body, linkStart, linkEnd };
            }
            case Directions.down: {
                const body: IRect = {
                    x: anchorBody.x,
                    y: anchorBody.y + anchorBody.height + distance,
                    width: anchorBody.width,
                    height: thickness,
                };
                const linkStart = bottomMidpoint(anchorBody);
                const linkEnd = topMidpoint(body);
                return { body, linkStart, linkEnd };
            }
            default:
                throw new NotImplementedException(`direction [${direction}]`);
        }
    }

    /** (目前未使用, 有待完善的) 高级策略, 显示 stub 的同时还显示其与父结点之间的连线. */
    private getStub(items: ItemGet, dropIntent: IDropIntent): IDropStub {
        const { anchorId, action } = dropIntent;
        if (!anchorId) throw new Error(`The anchorId is undefined.`);
        if (action === DropActions.notAllow) throw new Error(`Unexpected action [${DropActions.notAllow}].`);

        const anchor = getItem(items, anchorId, true);
        const anchorStructureKind = getStructureKind(items, anchor);

        const parentShip = getParentShip(items, anchor);

        switch (action) {
            case DropActions.asParent: {
                const parent = parentShip ? parentShip.parent : undefined;
                const parentStructureKind = parent ? getStructureKind(items, parent) : undefined;
                const lk = parentStructureKind || anchorStructureKind;

                switch (lk) {
                    case StructureKinds.LogicRight:
                        return this.logicRightParentStub(items, anchor);
                    case StructureKinds.OrgDown:
                        return this.orgDownParentStub(items, anchor);
                    default:
                        throw new NotImplementedException(`structureKind [${lk}]`);
                }
            }
            case DropActions.asFirstChild: {
                return this.getChildStub(items, anchor, 0);
            }
            case DropActions.asLastChild: {
                const index = anchor.childIds ? anchor.childIds.length : 0;
                return this.getChildStub(items, anchor, index);
            }
            case DropActions.asPrevSibling: {
                if (!parentShip) throw new Error(`parentShip is undefined.`);
                return this.getChildStub(items, parentShip.parent, parentShip.index);
            }
            case DropActions.asNextSibling: {
                if (!parentShip) throw new Error(`parentShip is undefined.`);
                return this.getChildStub(items, parentShip.parent, parentShip.index + 1);
            }
            default:
                throw new NotImplementedException(`DropActions: [${action}]`);
        }
    }

    private getChildStub(items: ItemGet, itemOrId: ItemOrId, index: number) {
        const item = itemOf(items, itemOrId);
        const structureKind = getStructureKind(items, item);
        debug(`getChildStub: ${item.id}, index [${index}]`);

        switch (structureKind) {
            case StructureKinds.LogicRight:
                return this.logicRightStub(items, item, index);
            case StructureKinds.OrgDown:
                return this.orgDownStub(items, item, index);
            default:
                throw new NotImplementedException(`structureKind [${structureKind}]`);
        }
    }

    private logicRightParentStub(
        items: ItemGet,
        itemOrId: ItemOrId,
        options: IStubOptions = LogicRightStubOptions
    ): IDropStub {
        const item = itemOf(items, itemOrId);
        const refBody = absoluteBodyRect(this.props, item.id, true);
        const { space, parentSize } = options;

        const linkEnd = leftMidpoint(refBody);

        const body = {
            ...parentSize,
            x: refBody.x - parentSize.width - space,
            y: linkEnd.y - parentSize.height / 2,
        };

        const linkStart = rightMidpoint(body);

        return { body, linkStart, linkEnd };
    }

    private logicRightStub(
        items: ItemGet,
        parentOrId: ItemOrId,
        index: number,
        options: IStubOptions = LogicRightStubOptions
    ): IDropStub {
        const parent = itemOf(items, parentOrId);
        const { childIds } = parent;
        const { size, space } = options;

        const parentBody = absoluteBodyRect(this.props, parent.id, true);
        const linkStart = rightMidpoint(parentBody);

        let body: IRect;
        if (childIds && childIds.length > 0 && parent.isExpanded) {
            if (index < 0) throw new Error(`Invalid index [${index}].`);
            if (index < childIds.length) {
                const refBody = absoluteBodyRect(this.props, childIds[index], true);
                body = {
                    ...size,
                    x: refBody.x,
                    y: refBody.y - size.height - space,
                };
            } else {
                const refBody = absoluteBodyRect(this.props, childIds[childIds.length - 1], true);
                body = {
                    ...size,
                    x: refBody.x,
                    y: refBody.y + refBody.height + space - size.height,
                };
            }
        } else {
            const sizeFix = transposeSize(size);
            body = {
                ...sizeFix,
                x: linkStart.x + LogicRightStyles.hSpace,
                y: linkStart.y - sizeFix.height / 2,
            };
        }
        const linkEnd = leftMidpoint(body);

        return {
            body,
            linkStart,
            linkEnd,
        };
    }

    private orgDownParentStub(
        items: ItemGet,
        itemOrId: ItemOrId,
        options: IStubOptions = OrgDownStubOptions
    ): IDropStub {
        const item = itemOf(items, itemOrId);
        const refBody = absoluteBodyRect(this.props, item.id, true);
        const { parentSize, space } = options;

        const linkEnd = topMidpoint(refBody);

        const body = {
            ...parentSize,
            x: linkEnd.x - parentSize.width / 2,
            y: linkEnd.y - parentSize.height - space,
        };

        const linkStart = bottomMidpoint(body);

        return { body, linkStart, linkEnd };
    }

    private orgDownStub(
        items: ItemGet,
        parentOrId: ItemOrId,
        index: number,
        options: IStubOptions = OrgDownStubOptions
    ): IDropStub {
        const rootId = this.props.rootId;
        const parent = itemOf(items, parentOrId);
        const { childIds } = parent;
        const { size, space } = options;

        const parentBody = absoluteBodyRect(this.props, parent.id, true);
        const linkStart = bottomMidpoint(parentBody);

        let body: IRect;
        if (childIds && childIds.length > 0 && parent.isExpanded) {
            if (index < 0) throw new Error(`Invalid index [${index}].`);
            if (index < childIds.length) {
                const refBody = absoluteBodyRect(this.props, childIds[index], true);
                body = {
                    ...size,
                    x: refBody.x - size.width - space,
                    y: refBody.y,
                };
            } else {
                const refBody = absoluteBodyRect(this.props, childIds[childIds.length - 1], true);
                body = {
                    ...size,
                    x: refBody.x + refBody.width + space,
                    y: refBody.y,
                };
            }
        } else {
            const sizeFix = transposeSize(size);
            body = {
                ...sizeFix,
                x: linkStart.x - sizeFix.width / 2,
                y: linkStart.y + space,
            };
        }
        const linkEnd = topMidpoint(body);

        return {
            body,
            linkStart,
            linkEnd,
        };
    }
}
