import { deepLock, IPrincipal } from 'neka-common';
import { LocalStorageItem } from '../common/browser-utils';
import { Anonymous } from './app-consts';

export const isDevelopment = process && process.env && process.env.NODE_ENV === 'development';

/*
    不能写成 process.env[NAOWEN_apiBaseURL] 的格式!!
    因为 env 值的替换是在 parcel build 过程中完成的.
    在运行时由 parcel 提供的 process.env 为空对象 `{}`.

    The values are stored in `.env` files.
    see https://github.com/motdotla/dotenv#readme
*/
export const ServerInfo = Object.freeze({
    apiBaseUrl: process.env.NAOWEN_apiBaseURL!,
    webBaseUrl: process.env.NAOWEN_webBaseURL!,
});

// TODO: 每个路由还需要有一个 构造函数, 如 userInfo(userId: string)
// 该函数还有一个 `path` 属性, 用于 `<Route path={RouteUrls.users.login} component={Login}/>` 中, 如 `<Route path={RouteUrls.users.login.path}`

export const RouteUrls = deepLock({
    home: '/',
    mind: {
        _base: '/minds', // The MindBench
        edit: '/minds/:id',
        editUrl: (documentId: string) => `/minds/${documentId}`,
    },
    users: {
        _base: '/users',
        register: '/users/register',
        login: '/users/login',
        mobileLogin: '/users/login/mobile',
        wechatLogin: '/users/login/wechat',
        ForgetPassword: '/users/forgetPassword',
        bindAccount: '/users/bindAccount',
        callback: '/users/wechatCallback',
        profile: '/users/profile',
        invite: '/users/invite/:code',
        inviteUrl: (inviteCode: string) => `/users/invite/${inviteCode}`,
    },
    dashboard: '/dashboard',
    admin: {
        _base: '/admin',
        home: '/admin/home',
        users: '/admin/users',
        userAdd: '/admin/users/add',
        userInfo: '/admin/users/:userId',
        userEdit: '/admin/users/:userId/edit',
    },
});

// 登录退出相关的常量
export const SignAuthConsts = {
    neKaAuth: 'neka-auth',
    isWeChatRegister: 'isWeChatRegister',
    isRememberLogin: 'isRememberLogin',
    redirectUrl: 'redirectUrl',
};

interface IRedirectUrl {
    url: string;
}

const LocalStorageKeys = {
    // 用于恢复帖子未提交的文本框内容
    discussionInputCache: (userId: string, docItemId?: string, postId?: string, replyId?: string) =>
        (userId + '-' + docItemId + '-' + postId + '-' + replyId) as 'discussionInputCache',
};

export const localStoragePrincipal = new LocalStorageItem<IPrincipal>(SignAuthConsts.neKaAuth, Anonymous);
export const localStorageIsWeChatRegister = new LocalStorageItem<boolean>(SignAuthConsts.isWeChatRegister, false);
export const localStorageIsRememberLogin = new LocalStorageItem<boolean>(SignAuthConsts.isRememberLogin, false);
export const localStorageRedirectUrl = new LocalStorageItem<IRedirectUrl>(SignAuthConsts.redirectUrl);

export const localStorageDiscussCache = (docItemId?: string, postId?: string, replyId?: string) => {
    const user = localStoragePrincipal && localStoragePrincipal.load();
    return new LocalStorageItem<string>(
        LocalStorageKeys.discussionInputCache(user ? user.userid : '', docItemId, postId, replyId)
    );
};
