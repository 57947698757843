// *********************************************************
// 脑图的领域对象.
// *********************************************************

import moment from 'moment';
import {
    Exception,
    IMindDocument,
    IMindItemEntity,
    IMindKind,
    IMindStyles,
    IPrincipal,
    IUser,
    LineKind,
    protectProperty,
    StructureKind,
    generateId,
    Enum,
    ArgumentMissingException,
    Mutable,
} from 'neka-common';
import { IKeyGet, IMapLike } from '../common/collections';
import { IPos, ISize } from './mind-draw2d';

export type MindId = string;
export type MindIds = ReadonlyArray<MindId>;

export class MindException extends Exception {
    constructor(message: string, innerException?: Exception) {
        super(message, innerException);
    }
}

export const SDocumentNotLoadedErr = '文档尚未加载.';

export class MindItemNotFoundException extends MindException {
    constructor(public readonly mindId: MindId) {
        super(`Cannot find IMindItem for id [${mindId}].`);
        protectProperty(this, 'mindId');
    }
}

export const SNoRelativePosErr = 'relativePos missing.';
export const SNoRegionSizeErr = 'regionSize missing.';
export const SNoBodyPosErr = 'bodyPos missing';
export const SNoBodySizeErr = `bodySize missing.`;
export const SNoPolePosErr = 'polePos missing.';
export const SNoGripPosErr = 'gripPos missing';
export const SNoInPortError = 'inPort missing';
export const SNoOutPortError = 'outPort missing';

export class MindItemStateException extends MindException {
    constructor(public readonly mindId: MindId, reason: string) {
        super(`Invalid state of IMindItem [${mindId}]: ${reason}`);
        protectProperty(this, 'mindId');
    }
}

export interface IRemoteCommand {
    /** 每个实例有一个唯一的 id. */
    commandId: string;
    /** 用与调试的名称. */
    commandName: string;
    /** 返回一个包含服务端调用的 Promise. */
    onExecute: () => Promise<any>;
}

export const ToolPaneKinds = Object.freeze({
    developerPane: 'developer-pane' as 'developer-pane',
    discussionPane: 'discussion-pane' as 'discussion-pane',
    stylesPane: 'styles-pane' as 'style-pane',
    membersPane: 'memberPane' as 'memberPane',
    helpPane: 'help-pane' as 'help-pane',
});

export type ToolPaneKind = Enum<typeof ToolPaneKinds>;

export const MindDocumentTitleMaxLength = 50;

/**
 * 代表一个脑图. 它包含 {@link IMindDocument} 所不具备的可视化信息
 * 它可由 {@link loadMindDocument} 加载 {@link IMindDocument} 得到.
 * 说明: 接口名之所以叫 `IMindDiagram` 而非 `IMindMap`, 是避免与 {@link Map} 产生混淆.
 */
export interface IMindDiagram {
    readonly id: string;
    readonly title: string;
    readonly items: Map<MindId, IMindItem>;
    readonly rootId: MindId;
    readonly creatorId: string;
    readonly ownerId: string;

    /** 缩放比例, 默认为 1. */
    readonly scale: number;
    readonly allowEdit: boolean;

    /** 当前选中的主题, 最后一个元素是 "焦点" 元素 */
    readonly selection?: ReadonlyArray<MindId>;
    /** 正处于文本编辑状态的结点. 为 undefined 时表明没有结点处于编辑状态. */
    readonly editingId?: MindId;
    readonly isEditingNew?: boolean;

    /** 将被复制的元素. */
    readonly clipboard?: IMindClipboard;
    /** 样式. */
    readonly theme: IMindItemStyles;

    readonly currentUserId: string;
    readonly remoteCommands: ReadonlyArray<IRemoteCommand>;
    readonly isExecutingRemoteCommands: boolean;
    readonly remoteCommandErrors?: ReadonlyArray<string>;

    /** 是否显示调试用的元素. */
    readonly renderDebugHelpers?: boolean;
    readonly activeToolPaneKind?: ToolPaneKind;
}

export interface IMindTree {
    readonly items: ItemGet;
    readonly rootId: MindId;
}

export interface IMindItemStyles extends IMindStyles {}

export const MindItemTextMaxLength = 1000;

/** 代表脑图 ({@link IMindDiagram} 中的一个结点. */
export interface IMindItem extends IMindItemStyles {
    /** 结点的 id. */
    readonly id: MindId;
    /** 结点的文字. */
    readonly text: string;
    /** 子结点的 id */
    readonly childIds?: ReadonlyArray<MindId>;
    /** 创建时间 */
    createdAt: Date;
    /** 更新时间 */
    lastUpdatedAt: Date;
    /** 创建人 */
    creatorId: string;
    /** 最后修改的人 */
    lastUpdaterId: string;

    /** 讨论数. */
    hasDiscussion?: boolean;
    /** 未读讨论数. */
    hasUnreadDiscussion?: boolean;

    /** 缓存父节点 {@link IMindItem.id}. 用于提高性能. */
    readonly parentId?: MindId;
    /** 结点当前是否展开, true 表示展开, false 表示收起. */
    readonly isExpanded: boolean;

    /** 结点在其父节点整体 (即整个子树所占的) 区域中的位置. */
    readonly relativePos?: Readonly<IPos>;
    /** 结点及其子孙结点的大小. */
    readonly regionSize?: Readonly<ISize>;
    /** 结点内容区域的位置. 以 region 左上角为参考点. */
    readonly bodyPos?: Readonly<IPos>;
    /** 结点内容区域的大小 (包括 {@link textSize}, 不包括子结点及 Grip) */
    readonly bodySize?: Readonly<ISize>;
    /** 文字的大小. */
    readonly textSize?: Readonly<ISize>;
    /** pole 的起始位置. 以 region 左上角为参考点. 它通常位于 border 的边缘, 由本结点的 {@link structureKind} 决定. */
    readonly poleStartPos?: Readonly<IPos>;
    /** 子结点展开/收起按钮的位置. 以 region 左上角为参考点. */
    readonly gripPos?: Readonly<IPos>;
    /**
     * 从父结点的连线在本结点处的端口位置. 其坐标值以 region 左上角为参考点.
     * 该值由父结点的 {@link structureKind} 与本结点的 border 样式 (如矩形/下划线) 共同决定.
     */
    readonly inPortPos?: Readonly<IPos>;
    /**
     * 向子结点的连线在本结点处的端口位置. 其坐标值以 region 左上角为参考点.
     * 该值的位置由本结点的 {@link structureKind} 与本结点的 border 样式 (如矩形/下划线) 共同决定.
     */
    readonly outPortPos?: Readonly<IPos>;
    /** (暂未实现) 业务类型, 将来可能将结点标记为 "任务" 等. 业务类型可能有特定的附加属性, 如 "任务" 类型可能会为结点增加 "责任人" 等. */
    readonly kinds?: ReadonlyArray<IMindKind>;
}

// TODO: use getOS
const chineseFont = navigator.appVersion.indexOf('Mac') !== -1 ? 'PingFang SC' : 'Microsoft YaHei';
// TODO: move to a separate file
export const DefaultTheme: Required<IMindItemStyles> = {
    structureKind: 'logic-right',
    lineKind: 'poly',
    fillColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: 'rgba(0,0,0, 0.1)',

    textFontFamily: chineseFont,
    textFontSize: 14,
    textFontColor: '#494949',
    textFontBold: false,
    textFontItalic: false,
};

export type ItemOrId = IMindItem | MindId;
export type WithId = { id: MindId };
export type ItemGet<T extends WithId = IMindItem> = IKeyGet<MindId, T>;
export type ItemGetSet<T extends WithId = IMindItem> = IMapLike<MindId, T>;

/** 获取结点的布局方式, 如果值为 undefined, 则返回其祖先结点中第一个不为 undefined 的值. */
export function getStructureKind(items: ItemGet, itemOrId: ItemOrId): StructureKind {
    let item: IMindItem | undefined = itemOf(items, itemOrId);
    while (item !== undefined) {
        if (item.structureKind) return item.structureKind;
        item = item.parentId ? getItem(items, item.parentId, true) : undefined;
    }
    return DefaultTheme.structureKind;
}

/** 获取结点连线的类型. */
export function getLineKind(items: ItemGet, itemOrId: ItemOrId): LineKind {
    let item: IMindItem | undefined = itemOf(items, itemOrId);
    while (item !== undefined) {
        if (item.lineKind) return item.lineKind;
        item = item.parentId ? getItem(items, item.parentId, true) : undefined;
    }
    return DefaultTheme.lineKind;
}

/** 复制/剪切/粘贴时的临时存放区. 复制/剪切可以对一组结点及其子孙结点生效. */
export interface IMindClipboard {
    /** 被复制的所有结点. */
    readonly clipboardItems: Map<MindId, IMindItem>;
    /** 被复制的结点中的 `根` 结点. */
    readonly clipboardRootIds: ReadonlyArray<MindId>;
}

export function getFocusedId(diagram: IMindDiagram): MindId | undefined {
    const { selection } = diagram;
    if (selection !== undefined && selection.length > 0) {
        return selection[selection.length - 1];
    }
    return undefined;
}

/** 用于处理 {@link ItemOrId} 类型的参数. */
export function itemOf(items: ItemGet, itemOrId: IMindItem | MindId): IMindItem {
    if (typeof itemOrId === 'string') {
        return getItem(items, itemOrId, true);
    }
    return itemOrId;
}

export function getItem<T extends WithId = IMindItem>(items: ItemGet<T>, id: MindId): T | undefined;
export function getItem<T extends WithId = IMindItem>(items: ItemGet<T>, id: MindId, required: false): T | undefined;
export function getItem<T extends WithId = IMindItem>(items: ItemGet<T>, id: MindId, required: true): T;
export function getItem<T extends WithId = IMindItem>(
    items: ItemGet<T>,
    id: MindId,
    required?: boolean
): T | undefined {
    const result = items.get(id);
    if (result === undefined && required === true) throw new MindItemNotFoundException(id);
    return result;
}

/** 将 {@link IMindDocument} 转换为 {@link IMindDiagram}, 用于加载脑图文档. */
export function loadMindDocument(doc: IMindDocument, currentUserId: string): IMindDiagram {
    const { rootId } = doc;

    const items = convertEntitiesToItems(doc.items);
    const root = getItem(items, rootId, true);
    items.set(rootId, { ...root, relativePos: { x: 0, y: 0 } });

    return {
        id: doc.id,
        title: doc.title,
        items,
        rootId,
        creatorId: doc.creatorId,
        ownerId: doc.ownerId,

        allowEdit: doc.ownerId === currentUserId,

        scale: 1,
        selection: [rootId],
        theme: DefaultTheme,

        currentUserId,
        remoteCommands: [],
        isExecutingRemoteCommands: false,
    };
}

function convertEntitiesToItems(entities: ReadonlyArray<IMindItemEntity>): Map<MindId, IMindItem> {
    const itemArr = entities.map(convertEntityToItem);

    const itemMap = new Map<MindId, IMindItem>();
    itemArr.forEach(item => itemMap.set(item.id, item));

    // key: 父结点的 id, value: 其 childIds 中无效的 Id.
    const brokenItems = new Map<MindId, Array<MindId>>();

    // fixes parentIds
    itemArr.forEach(parent => {
        if (parent.childIds && parent.childIds.length > 0) {
            parent.childIds.forEach(childId => {
                const child: { parentId?: MindId } | undefined = itemMap.get(childId);
                if (child === undefined) {
                    let brokenIds: Array<MindId> = brokenItems.has(parent.id) ? brokenItems.get(parent.id)! : [];
                    brokenIds.push(childId);
                    brokenItems.set(parent.id, brokenIds);

                    // throw new Error(`The item [${parent.id}] contains an invalid child [${childId}].`);
                } else {
                    child.parentId = parent.id;
                }
            });
        }
    });

    if (brokenItems.size > 0) {
        let brokenChildIds = 'Broken childId(s):\n';
        brokenItems.forEach((brokenIds, parentId) => {
            const parent = itemMap.get(parentId);
            if (parent && parent.childIds && parent.childIds.length > 0) {
                brokenChildIds =
                    brokenChildIds + `\t for parent [${parent.id}], cannot find child [${brokenIds.join(',')}].\n`;
                const childIds = parent.childIds.filter(x => brokenIds.indexOf(x) === -1);
                (parent as Mutable<IMindItem>).childIds = childIds;
            }
        });
        console.error(brokenChildIds);
    }

    return itemMap;
}

function convertEntityToItem(entity: IMindItemEntity): IMindItem {
    const { childIds, ...rest } = entity;
    return {
        ...rest,
        ...rest.styles,
        childIds: childIds ? childIds : undefined,
        isExpanded: true,
        relativePos: undefined,
        bodySize: undefined,
    };
}

export function convertItemToEntity(item: IMindItem, documentId: string): IMindItemEntity {
    const entity: IMindItemEntity = {
        id: item.id,
        documentId,
        text: item.text,
        childIds: item.childIds ? Array.from(item.childIds) : null,
        styles: {
            structureKind: item.structureKind,
            lineKind: item.lineKind,
            fillColor: item.fillColor,
            borderWidth: item.borderWidth,
            borderColor: item.borderColor,
            textFontFamily: item.textFontFamily,
            textFontSize: item.textFontSize,
            textFontColor: item.textFontColor,
            textFontBold: item.textFontBold,
            textFontItalic: item.textFontItalic,
        },
        creatorId: item.creatorId,
        createdAt: item.createdAt,
        lastUpdaterId: item.lastUpdaterId,
        lastUpdatedAt: item.lastUpdatedAt,
    };
    return entity;
}

/** 创建一个空文档, 用于 "新建" 文档时. */
export function createUntitledDocument(creator: IPrincipal): IMindDocument {
    const now = new Date();
    const documentId = generateId();
    const creatorId = creator.userid;
    const title = `未命名 - ${titleFromDate(now)}`;
    const root: IMindItemEntity = {
        id: generateId(),
        text: '主题',
        documentId,
        childIds: null,
        styles: null,
        creatorId: creatorId,
        createdAt: now,
        lastUpdaterId: creatorId,
        lastUpdatedAt: now,
    };

    return {
        id: documentId,
        title,
        items: [root],
        rootId: root.id,
        ownerId: creatorId,
        creatorId: creatorId,
        createdAt: now,
        lastUpdaterId: creatorId,
        lastUpdatedAt: now,
    };
}

function titleFromDate(d: Date) {
    return moment(d).format('YYYY-MM-DD HH:mm');
}

export function createUntitledDiagram(creator: IPrincipal): IMindDiagram {
    return loadMindDocument(createUntitledDocument(creator), creator.userid);
}

/** 画布抽屉的页面样式，预留以后可拖动情况 */
export const LayoutMindDrawer = Object.freeze({
    Width: '420px',
    ZIndex: 100, //  需小于标准的modal的Z-index值，保证能被覆盖住
    SwitchIconZIndex: 101, // 开关按钮大于抽屉，浮在上面
    Top: '60px', // 中间有1px缝隙
    PaddingLeft: '12px',
    PaddingRight: '9px',
});

/**
 * 表示脑图右侧抽屉的数据结构
 */
export interface IMindDrawerModel {
    readonly tabName: string;
    // 当前的节点信息
    readonly currentItem: IMindItem;
}

export function checkAllowEdit(diagram: IMindDiagram) {
    if (!diagram.allowEdit) throw new Error(`只有脑图的归属人才可以修改脑图.`);
}
