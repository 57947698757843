/** The argument of `POST /apis/users/register` */
import {
    IBasicCredential,
    BasicCredential,
    MobileCredential,
    maxSecuredPasswordReg,
    WechatCredential,
    IWechatCredential,
    IMobileCredential,
} from './auth.data';
import { Matches, MaxLength, MinLength, IsNotEmpty, IsPhoneNumber } from 'class-validator';
import { Compare } from '../common/vali-utils';

/** 普通用户注册 */
export interface IRegisterUserArgs {
    userid?: string; // The client may provide the id or let the server to generate a new one
    username: string;
    password?: string;
    email?: string;
    mobile?: string;
}

/** 绑定微信到系统现有用户 */
export interface IBindWechatArgs extends IBasicCredential, IWechatCredential {
    nickname: string;
}

/** 手机用户注册 */
export interface IMobileUserArgs {
    mobile: string;
    verifyCode: string;
    userid?: string;
    nickname: string;
    password: string;
    confirmPassword: string;
}

/** 微信用户注册 微信注册可以直接跳过手机输入 */
export interface IWechatUserArgs extends IWechatCredential {
    mobile?: string;
    verifyCode?: string;
    userid?: string;
    nickname?: string;
    avatar?: string;
    password?: string;
    confirmPassword?: string;
}

export class RegisterUserArgs extends BasicCredential implements IRegisterUserArgs {
    userid?: string;
    email?: string;
    mobile?: string;
}

export class WechatUserArgs extends WechatCredential implements IWechatUserArgs {
    userid?: string;

    mobile?: string;

    verifyCode?: string;

    nickname?: string;

    password?: string;

    confirmPassword?: string;
}

export class MobileUserArgs extends MobileCredential implements IMobileUserArgs {
    userid?: string;

    @MaxLength(32, { message: '不能超出 32 个字符' })
    @IsNotEmpty({ message: '不能为空' })
    nickname: string;

    @MinLength(6, { message: '不能少于 6 个字符' })
    @MaxLength(20, { message: '不能超出 20 个字符' })
    @Matches(maxSecuredPasswordReg, { message: '必须包含大小写+数字+特殊字符' })
    password: string;

    @Compare('password', { message: '密码不一致' })
    confirmPassword: string;
}

export class BindWechatArgs extends BasicCredential implements IBindWechatArgs {
    @MaxLength(32, { message: '不能超出 32 个字符' })
    @IsNotEmpty({ message: '不能为空' })
    nickname: string;

    avatar: string;

    @IsNotEmpty({ message: '不能为空' })
    unionId: string;
}
