import {
    validate,
    ValidationError,
    ValidationOptions,
    ValidatorOptions,
    ValidatorConstraintInterface,
    registerDecorator,
    ValidationArguments,
    ValidatorConstraint,
} from 'class-validator';
import { plainToClass } from 'class-transformer';
import { ArgumentException, ArgumentMissingException } from './ex-utils';
import { IClassOf, protectProperty } from './sys-utils';
import { Exception } from './ex-utils';

/**
 * Represents the result of {@link validateObject}.
 */
export class ValidationResult<T extends Object> {
    constructor(data: T, errors: ValidationError[]) {
        this.data = data;
        this.errors = errors ? errors : [];
        this.isValid = this.errors && this.errors.length === 0;
    }

    /** The object being validated. */
    public readonly data: T;

    /** The validation errors. An empty array `[]` when no error. */
    public readonly errors: ValidationError[];

    /** Indicates if {@link data} is valid. */
    public readonly isValid: boolean;

    /** Returns the {@link ValidationError} for a given property in {@typeparam T}. */
    public ofProperty(property: keyof T): ValidationError | undefined {
        return propertyErrorOf(this, property);
    }
}

export class ValidationException extends Exception {
    constructor(public readonly validationErrors: ValidationError[]) {
        super(validationErrors.toString());
        protectProperty(this, 'validationErrors');
    }
}

/**
 * Validates an object and throws {@ValidationException} when the validation fails.
 * @param schemaType the class which contains validation rules
 * @param data the instance to be validated
 */
export async function checkedObject<S extends Object, T extends Object>(schemaType: IClassOf<S>, data: T): Promise<T> {
    const vr = await validateObject(schemaType, data);
    if (!vr.isValid) throw new ValidationException(vr.errors);
    return data;
}

/**
 * Validates an object and returns the {@ValidationResult}.
 * @param schemaType the class which contains validation rules
 * @param data the instance to be validated
 * @param options optional options for `class-validator`'s `validate`
 */
export async function validateObject<S extends Object, T extends Object>(
    schemaType: IClassOf<S>,
    data: T,
    options?: ValidatorOptions
): Promise<ValidationResult<T>> {
    if (!schemaType) throw new ArgumentMissingException('schemaType');
    if (!data) throw new ArgumentMissingException('data');
    if (!(typeof data === 'object')) throw new ArgumentException('The data should be of an object.');

    // class-validator validates objects created using `new Class()` only.
    // see https://github.com/typestack/class-validator#validating-plain-objects
    // inspiring https://github.com/19majkel94/class-transformer-validator
    const mayBeTransformed = data instanceof schemaType ? (data as any) : plainToClass<S, Object>(schemaType, data);
    const validationErrors = await validate(mayBeTransformed, options);
    return new ValidationResult(data, validationErrors);
}

/** Returns the {@link ValidationError} for a given property in {@typeparam T}. */
export function propertyErrorOf<T>(
    validationResult: ValidationResult<T> | undefined,
    property: keyof T
): ValidationError | undefined {
    if (validationResult && validationResult.errors) {
        return validationResult.errors.find(e => e.property === property);
    }
    return undefined;
}

@ValidatorConstraint({ name: 'Compare' })
class CompareConstraint implements ValidatorConstraintInterface {
    validate(value: any, args: ValidationArguments) {
        const [relatedPropertyName] = args.constraints;
        const relatedValue = (args.object as any)[relatedPropertyName];
        return value === relatedValue;
    }
}

// the compare decorator
// determine two values in an object are equal
export function Compare(property: string, validationOptions?: ValidationOptions) {
    return function(target: Object, propertyName: string) {
        registerDecorator({
            target: target.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [property],
            validator: CompareConstraint,
        });
    };
}
