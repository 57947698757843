/** Represents an entity which has `id`. */
import uuid from 'uuid';

export interface IObjectWithId<TKey> {
    id: TKey;
}

/** Represents the partial entity with its `id`. */
export type PartialWithId<TKey, TEntity extends IObjectWithId<TKey>> = Partial<TEntity> & IObjectWithId<TKey>;

/** 生成各类 id, 如 {@link IMindDocument.id}, {@link IMindItemEntity.id}, {@link IMindDiagram.id}, {@link IMindItem.id}. */
export function generateId(): string {
    return uuid.v4();
}
