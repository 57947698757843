import React from 'react';

export interface ISvgIconProps {
    width?: number;
    height?: number;
    color?: string;
    stroke?: string;
    fill?: string;
    fillOpacity?: number;
}

// tslint:disable:max-line-length

export const SvgIconZoomOut = React.memo((props: ISvgIconProps) => {
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon fillOpacity="0.85" fill="#000000" points="6 9 13 9 13 9.938 6 9.938" />
            </g>
        </svg>
    );
});

export const SvgIconZoomIn = React.memo((props: ISvgIconProps) => {
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    d="M10,9 L13,9 L13,10 L10,10 L10,13 L9,13 L9,10 L6,10 L6,9 L9,9 L9,6 L10,6 L10,9 Z"
                    fillOpacity="0.85"
                    fill="#000000"
                />
            </g>
        </svg>
    );
});

export const SvgIconAddChildItem = React.memo((props: ISvgIconProps) => {
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect id="Rectangle-48-Copy-19" x="0" y="0" width="20" height="20" />
                <path d="M3,2 L10,2 L10,9 L3,9 L3,2 Z M4,3 L4,8 L9,8 L9,3 L4,3 Z" fillOpacity="0.85" fill="#000000" />
                <path d="M7,13 L10,13 L10,14 L7,14 L6,14 L6,9 L7,9 L7,13 Z" fillOpacity="0.85" fill="#000000" />
                <path
                    d="M13,13 L11,13 L11,14 L13,14 L13,16 L14,16 L14,14 L16,14 L16,13 L14,13 L14,11 L13,11 L13,13 Z M10,10 L17,10 L17,17 L10,17 L10,10 Z"
                    fillOpacity="0.85"
                    fill="#000000"
                />
            </g>
        </svg>
    );
});

export const SvgIconAddPrevSiblingItem = React.memo((props: ISvgIconProps) => {
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
            <g id="脑问" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="插图、图标" transform="translate(-285.000000, -31.000000)">
                    <g id="同级向上节点" transform="translate(285.000000, 31.000000)">
                        <rect id="Rectangle-48-Copy-19" x="0" y="0" width="20" height="20" />
                        <path
                            d="M7,11 L16,11 L16,18 L7,18 L7,11 Z M8,12 L8,17 L15,17 L15,12 L8,12 Z"
                            id="Combined-Shape"
                            fillOpacity="0.85"
                            fill="#000000"
                            transform="translate(11.500000, 14.500000) scale(1, -1) translate(-11.500000, -14.500000) "
                        />
                        <path
                            d="M5,6 L5,14 L7,14 L7,15 L4,15 L4,14 L4,5 L5,5 L7,5 L7,6 L5,6 Z"
                            id="Combined-Shape"
                            fillOpacity="0.85"
                            fill="#000000"
                            transform="translate(5.500000, 10.000000) scale(1, -1) translate(-5.500000, -10.000000) "
                        />
                        <path
                            d="M11,5 L9,5 L9,6 L11,6 L11,8 L12,8 L12,6 L14,6 L14,5 L12,5 L12,3 L11,3 L11,5 Z M7,2 L16,2 L16,9 L7,9 L7,2 Z"
                            id="Combined-Shape"
                            fillOpacity="0.85"
                            fill="#000000"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
});

export const SvgIconAddNextSibling = React.memo((props: ISvgIconProps) => {
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
            <g id="脑问" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="插图、图标" transform="translate(-285.000000, -52.000000)">
                    <g id="同级向下节点" transform="translate(285.000000, 52.000000)">
                        <rect id="Rectangle-48-Copy-19" x="0" y="0" width="20" height="20" />
                        <path
                            d="M11,14 L9,14 L9,15 L11,15 L11,17 L12,17 L12,15 L14,15 L14,14 L12,14 L12,12 L11,12 L11,14 Z M7,11 L16,11 L16,18 L7,18 L7,11 Z"
                            id="Combined-Shape"
                            fillOpacity="0.85"
                            fill="#000000"
                            transform="translate(11.500000, 14.500000) scale(1, -1) translate(-11.500000, -14.500000) "
                        />
                        <path
                            d="M5,6 L5,14 L7,14 L7,15 L4,15 L4,14 L4,5 L5,5 L7,5 L7,6 L5,6 Z"
                            id="Combined-Shape"
                            fillOpacity="0.85"
                            fill="#000000"
                            transform="translate(5.500000, 10.000000) scale(1, -1) translate(-5.500000, -10.000000) "
                        />
                        <path
                            d="M7,2 L16,2 L16,9 L7,9 L7,2 Z M8,3 L8,8 L15,8 L15,3 L8,3 Z"
                            id="Combined-Shape"
                            fillOpacity="0.85"
                            fill="#000000"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
});

export const SvgIconAddFirstChildItem = React.memo((props: ISvgIconProps) => {
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
            <g id="脑问" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="插图、图标" transform="translate(-261.000000, -73.000000)">
                    <g id="尾子节点" transform="translate(261.000000, 73.000000)">
                        <rect id="Rectangle-48-Copy-19" x="0" y="0" width="20" height="20" />
                        <path
                            d="M2,9 L8,9 L8,14 L2,14 L2,9 Z M3,10 L3,13 L7,13 L7,10 L3,10 Z"
                            id="Combined-Shape"
                            fillOpacity="0.85"
                            fill="#000000"
                        />
                        <path
                            d="M10,17 L9,17 L9,12 L8,12 L8,11 L9,11 L9,6 L10,6 L11,6 L11,7 L10,7 L10,11 L11,11 L11,12 L10,12 L10,16 L11,16 L11,17 L10,17 Z"
                            id="Combined-Shape"
                            fillOpacity="0.85"
                            fill="#000000"
                        />
                        <path
                            d="M11,9 L18,9 L18,13 L11,13 L11,9 Z M12,10 L12,12 L17,12 L17,10 L12,10 Z"
                            id="Combined-Shape"
                            fillOpacity="0.85"
                            fill="#000000"
                        />
                        <path
                            d="M11,14 L18,14 L18,18 L11,18 L11,14 Z M12,15 L12,17 L17,17 L17,15 L12,15 Z"
                            id="Combined-Shape"
                            fillOpacity="0.85"
                            fill="#000000"
                        />
                        <path
                            d="M15,5 L15,4 L14,4 L14,5 L13,5 L13,6 L14,6 L14,7 L15,7 L15,6 L16,6 L16,5 L15,5 Z M11,3 L18,3 L18,8 L11,8 L11,3 Z"
                            id="Combined-Shape"
                            fillOpacity="0.85"
                            fill="#000000"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
});

export const SvgIconAddLastChildItem = React.memo((props: ISvgIconProps) => {
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
            <g id="脑问" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="插图、图标" transform="translate(-285.000000, -73.000000)">
                    <g id="尾子节点" transform="translate(285.000000, 73.000000)">
                        <rect id="Rectangle-48-Copy-19" x="0" y="0" width="20" height="20" />
                        <path
                            d="M2,8 L8,8 L8,13 L2,13 L2,8 Z M3,9 L3,12 L7,12 L7,9 L3,9 Z"
                            id="Combined-Shape"
                            fillOpacity="0.85"
                            fill="#000000"
                        />
                        <path
                            d="M10,16 L9,16 L9,11 L8,11 L8,10 L9,10 L9,5 L10,5 L11,5 L11,6 L10,6 L10,10 L11,10 L11,11 L10,11 L10,15 L11,15 L11,16 L10,16 Z"
                            id="Combined-Shape"
                            fillOpacity="0.85"
                            fill="#000000"
                        />
                        <path
                            d="M11,8 L18,8 L18,12 L11,12 L11,8 Z M12,9 L12,11 L17,11 L17,9 L12,9 Z"
                            id="Combined-Shape"
                            fillOpacity="0.85"
                            fill="#000000"
                        />
                        <path
                            d="M11,3 L18,3 L18,7 L11,7 L11,3 Z M12,4 L12,6 L17,6 L17,4 L12,4 Z"
                            id="Combined-Shape"
                            fillOpacity="0.85"
                            fill="#000000"
                        />
                        <path
                            d="M14,15 L13,15 L13,16 L14,16 L14,17 L15,17 L15,16 L16,16 L16,15 L15,15 L15,14 L14,14 L14,15 Z M11,13 L18,13 L18,18 L11,18 L11,13 Z"
                            id="Combined-Shape"
                            fillOpacity="0.85"
                            fill="#000000"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
});

export const SvgIconAddParentItem = React.memo((props: ISvgIconProps) => {
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
            <g id="脑问" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect id="Rectangle-48-Copy-19" x="0" y="0" width="20" height="20" />
                <path
                    d="M6,5 L4,5 L4,6 L6,6 L6,8 L7,8 L7,6 L9,6 L9,5 L7,5 L7,3 L6,3 L6,5 Z M3,2 L10,2 L10,9 L3,9 L3,2 Z"
                    fillOpacity="0.85"
                    fill="#000000"
                />
                <path d="M7,14 L10,14 L10,15 L6,15 L6,14 L6,9 L7,9 L7,14 Z" fillOpacity="0.85" fill="#000000" />
                <path
                    d="M10,11 L17,11 L17,18 L10,18 L10,11 Z M11,12 L11,17 L16,17 L16,12 L11,12 Z"
                    fillOpacity="0.85"
                    fill="#000000"
                />
            </g>
        </svg>
    );
});

export const SvgIconUnknown = React.memo((props: ISvgIconProps) => {
    return (
        <svg width="16px" height="16px" viewBox="0 0 16 16" {...props}>
            <g id="脑问" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.65">
                <g id="插图、图标" transform="translate(-80.000000, -31.000000)" fill="#000000">
                    <g id="39-Question" transform="translate(81.000000, 32.000000)">
                        <path d="M7.04771429,1.14285714 C3.89685714,1.14285714 1.33342857,3.70571429 1.33342857,6.85714286 C1.33342857,10.0085714 3.89685714,12.5714286 7.04771429,12.5714286 C10.1985714,12.5714286 12.762,10.0085714 12.762,6.85714286 C12.762,3.70571429 10.1985714,1.14285714 7.04771429,1.14285714 Z M7.04771429,13.7142857 C3.26657143,13.7142857 0.190571429,10.6371429 0.190571429,6.85714286 C0.190571429,3.07714286 3.26657143,0 7.04771429,0 C10.8288571,0 13.9048571,3.07714286 13.9048571,6.85714286 C13.9048571,10.6371429 10.8288571,13.7142857 7.04771429,13.7142857 Z" />
                        <path d="M6.33485714,10.1485714 C6.33485714,9.75428571 6.65485714,9.43428571 7.04914286,9.43428571 C7.44371429,9.43428571 7.76342857,9.75428571 7.76342857,10.1485714 C7.76342857,10.5428571 7.44371429,10.8628571 7.04914286,10.8628571 C6.65485714,10.8628571 6.33485714,10.5428571 6.33485714,10.1485714" />
                        <path d="M7.04828571,8.88285714 C6.73257143,8.88285714 6.47685714,8.62571429 6.47685714,8.31142857 L6.47685714,8.13142857 C6.47685714,7.40571429 6.93885714,6.74285714 7.68228571,6.39714286 C8.05828571,6.22285714 8.45257143,5.82 8.32714286,5.25714286 C8.23514286,4.84857143 7.82371429,4.50285714 7.32657143,4.41714286 C6.87571429,4.34285714 6.43571429,4.45142857 6.11914286,4.71714286 C5.87971429,4.92 5.748,5.18571429 5.748,5.46285714 C5.748,5.78 5.49228571,6.03428571 5.17657143,6.03428571 C4.86114286,6.03428571 4.60514286,5.78 4.60514286,5.46285714 C4.60514286,4.85142857 4.88828571,4.26285714 5.38171429,3.84571429 C5.956,3.36 6.734,3.15714286 7.51771429,3.29142857 C8.47542857,3.45428571 9.24885714,4.14285714 9.44228571,5.00857143 C9.66457143,6 9.15028571,6.97714286 8.16285714,7.43428571 C7.82771429,7.58857143 7.61971429,7.85714286 7.61971429,8.13142857 L7.61971429,8.31142857 C7.61971429,8.62571429 7.36371429,8.88285714 7.04828571,8.88285714" />
                    </g>
                </g>
            </g>
        </svg>
    );
});
