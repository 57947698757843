import { IsNotEmpty, Matches, MaxLength, MinLength, IsPhoneNumber } from 'class-validator';

/**
 * 用户的身份信息. 通常用于身份验证系统, 如 passport 使用.
 * 不同与数据库中的 `UserEntity`, {@link IIdentity} 只包含少量的最常访问的信息.
 * 这些信息可以包含在客户端的 cookie 或 token 中.
 */
export interface IIdentity {
    userid: string;
    nickname: string;
    avatar: string;
}

/** @summary 用户的 OAuth2 认证信息. */
export interface IAuthInfo {
    access_token: string;
    token_type: string; // 'bearer';
    expires_in: number; // in seconds
    /**
     *  The authorization server MAY issue a new refresh token, in which case
     *  the client MUST discard the old refresh token and replace it with the
     *  new refresh token.  The authorization server MAY revoke the old
     *  refresh token after issuing a new refresh token to the client.
     */
    refresh_token?: string;
}

/** @summary 代表用户身份及其认证信息. */
export interface IPrincipal extends IIdentity {
    authInfo?: IAuthInfo;
}

/** @summary 微信用户的个人基本信息 */
export interface IWechatUserProfile {
    openId: string;
    nickname: string;
    headImgURL: string;
    unionId: string;
}

// A regular for validating the password complexity. A password should
// both upper and lower case letters, one or more numbers, one or special characters
// tslint:disable-next-line:max-line-length
// export const maxSecuredPasswordReg: RegExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@\[\\\]^_`{|}~])[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@\[\\\]^_`{|}~]{6,128}$/;
export const maxSecuredPasswordReg: RegExp = /^[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@\[\\\]^_`{|}~]{6,128}$/;

/** @summary 基本用户凭据 */
export interface IBasicCredential {
    // 该值可以是username/email/mobile
    username: string;
    password: string;
}

/** @summary 手机用户凭据 */
export interface IMobileCredential {
    mobile: string;
    verifyCode: string;
}

/** @summary 微信账户凭据 */
export interface IWechatCredential {
    unionId: string;
}

export class BasicCredential implements IBasicCredential {
    @IsNotEmpty({ message: '必填' })
    @MinLength(6, { message: '不能少于 6 个字符' })
    @MaxLength(64, { message: '不能超出 64 个字符' })
    username: string;

    @IsNotEmpty({ message: '必填' })
    @MinLength(6, { message: '不能少于 6 个字符' })
    @MaxLength(20, { message: '不能超出 20 个字符' })
    @Matches(maxSecuredPasswordReg, { message: '必须包含大小写+数字+特殊字符' })
    password: string;
}

export class MobileCredential implements IMobileCredential {
    @IsNotEmpty({ message: '必填' })
    @IsPhoneNumber('CN', { message: '手机号码不正确' })
    mobile: string;

    @IsNotEmpty({ message: '必填' })
    @Matches(/^\d{4}$/, { message: '必须是4位数字' })
    verifyCode: string;
}

export class WechatCredential implements IWechatCredential {
    @IsNotEmpty({ message: '必填' })
    unionId: string;
}
