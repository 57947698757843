import { maxSecuredPasswordReg } from 'neka-common';

export const logoImg = require('./assets/logo.svg');
export const posterImg = require('./assets/bg.svg');

// 接口执行出错返回的type
export enum ExceptionType {
    UserIdExistsException = 'UserIdExistsException', // 用户ID已经存在
    UserIdNotExistException = 'UserIdNotExistException', // 用户ID不存在
    MobileExistsException = 'MobileExistsException', // 用户手机号已经存在
    MobileNotExistException = 'MobileNotExistException', // 用户手机号不存在
    UserNameExistsException = 'UserNameExistsException', // 用户名已经存在
    UserNameNotExistException = 'UserNameNotFoundException', // 用户名不存在
    SmsCodeIncorrectException = 'SmsCodeIncorrectException', // 验证码不正确
    UnionIdExistsException = 'UnionIdExistsException', // 该账号已经绑定了系统用户
    UnionIdNotExistsException = 'UnionIdNotExistsException', // 未注册的微信号
    PasswordMismatchException = 'PasswordMismatchException', // 用户密码错误
}

// form表单校验公共参数
export const formConstant = {
    validateTrigger: ['onBlur'],
    validateRules: {
        // tslint:disable-next-line:max-line-length
        mobileRegexp: /(^13\d{9}$)|(^14)[5-9]\d{8}$|(^15[0,1,2,3,5,6,7,8,9]\d{8}$)|(^16[5,6]\d{8}$)|(^17)[0-9]\d{8}$|(^18\d{9}$)|(^19\d{9}$)/g,
        mobileNullErrorMassage: '手机号不为空',
        mobileMatchRegexpMassage: '请输入正确的手机号',
        smsCodeRegexp: /^\d{4}$/,
        smsCodeNullErrorMassage: '验证码不为空',
        smsCodeMatchRegexpMassage: '请输入4位数字验证码',
        smsCodeInputErrorMassage: '验证码输入错误',
        passwordRegexp: maxSecuredPasswordReg,
        passwordNullErrorMassage: '密码不为空',
        // passwordMatchRegexpMassage: '请输入不少于6位包含数字，字母，特殊字符的密码',
        passwordMatchRegexpMassage: '请输入至少6位字母、数字或符号，字母区分大小写',
        confirmPasswordMatchMassage: '两次密码输入不一致，请重新输入',
        nicknameNullErrorMassage: '请输入您的名字',
        nicknameLengthErrorMassage: '名字不能超过20个字',
        nicknameErrorMassage: '名字包含不支持的符号，请检查并重新输入',
        sensitiveWordErrorMassage: '不能使用{%s}作为名字，请重新输入',
    },
};

// 敏感词汇数组
export const sensitiveWordsArr = ['administrator', 'admin', '超级管理员', '脑问管理员', '管理员', '脑问客服'];
