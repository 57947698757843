import { deepLock, IPrincipal, SYS_ANONYMOUS_USER_ID, generateId } from 'neka-common';

export const Anonymous: IPrincipal = deepLock({
    userid: SYS_ANONYMOUS_USER_ID,
    nickname: 'Anonymous',
    avatar: '',
    authInfo: undefined,
});

export const NoSuchDiagramId = 'NO-SUCH-DIAGRAM-' + generateId();
