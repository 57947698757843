import { Button, Input } from 'antd';
import { Form, Formik } from 'formik';
import { IUser } from 'neka-common';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import * as yup from 'yup';
import { IDispatch } from '../../../common/redux-utils';
import { getErrorText, showErrorNotification } from '../../../common/ui-utils';
import { apis } from '../../../core/server-apis';

import '../../../styles/common-styles.less';

interface IFormValues extends Partial<IUser> {}

const validationSchema: yup.ObjectSchema<IFormValues> = yup.object<IFormValues>().shape({
    name: yup.string().required(),
    password: yup.string().required(),
    email: yup.string().required(),
});

interface IViewProps {
    user: IFormValues;
    onDiscard: (values: IFormValues) => void;
    onSave: (values: IFormValues) => void;
}

const UserEditView = (props: IViewProps) => (
    <div>
        <h4 className="form-title">修改用户</h4>
        <Formik<IFormValues>
            initialValues={props.user}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
                try {
                    await props.onSave(values);
                } finally {
                    actions.setSubmitting(false);
                }
            }}
            render={bag => (
                <Form>
                    <Input
                        name="username"
                        placeholder="User Name"
                        className="form-row"
                        value={bag.values.name}
                        onChange={bag.handleChange}
                        onBlur={bag.handleBlur}
                    />
                    <Input
                        name="password"
                        placeholder="Password"
                        className="form-row"
                        value={bag.values.password}
                        onChange={bag.handleChange}
                        onBlur={bag.handleBlur}
                    />
                    <Input
                        name="email"
                        placeholder="Email"
                        className="form-row"
                        value={bag.values.email}
                        onChange={bag.handleChange}
                        onBlur={bag.handleBlur}
                    />
                    <div className="form-row">
                        <Button color="primary" className="form-button" disabled={bag.isSubmitting}>
                            Save
                        </Button>
                        <Button
                            className="form-button"
                            disabled={bag.isSubmitting}
                            onClick={() => props.onDiscard(bag.values)}
                        >
                            Discard
                        </Button>
                    </div>
                </Form>
            )}
        />
    </div>
);

// TODO: pass the userId in props
interface ISiteProps extends RouteComponentProps<{ userId: string }>, IDispatch {}

interface ISiteState {
    user?: IUser;
    loadingError?: any;
}

class UserEditSite extends React.Component<ISiteProps, ISiteState> {
    constructor(props: ISiteProps) {
        super(props);
        this.state = {};
    }

    private getUserId(): string {
        return this.props.match.params.userId;
    }

    private async loadUser() {
        const userId = this.getUserId();
        try {
            const user = await apis.users.findOneById(userId);
            this.setState({ user });
        } catch (e) {
            this.setState({ loadingError: e });
        }
    }

    public async componentDidMount() {
        await this.loadUser();
    }

    private handleDiscard = () => {
        this.goBack();
    };

    private goBack = () => {
        this.props.history.goBack();
    };

    private handleSave = async (values: IFormValues) => {
        try {
            const user = Object.assign({}, this.state.user, values);
            await apis.users.updateOneById(user);
            // TODO: dispatch a redux action to add the created user to admin.users

            this.goBack();
        } catch (e) {
            showErrorNotification(e);
        }
    };

    public render() {
        if (this.state.loadingError) return <div>{getErrorText(this.state.loadingError)}</div>;
        if (!this.state.user) return <div>Loading...</div>;
        return <UserEditView user={this.state.user} onSave={this.handleSave} onDiscard={this.handleDiscard} />;
    }
}

export const UserEditPane = connect()(withRouter(UserEditSite));
