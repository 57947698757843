export interface IClassOf<T> {
    new (...args: any[]): T;
}

export type Class = IClassOf<Object>;

/** Represents a interface-like type which has the same property set as {@typeparam T}. */
export type PropsOf<T> = { [P in keyof T]: T[P] };

/** 匹配 `__filename` 中的路径 */
const dirnameMatcher: RegExp = /^.*[\\\/]/;

/** 从 `__filename` 中获取文件名 (含扩展名) */
export function filenameOf(pathName: string): string {
    return pathName.replace(dirnameMatcher, '');
}

export function delay<T>(milliseconds: number, value?: T): Promise<T> {
    return new Promise<T>(resolve => {
        setTimeout(() => {
            resolve(value);
        }, milliseconds);
    });
}

export function applyMixins(derivedCtor: any, baseCtors: any[]) {
    baseCtors.forEach(baseCtor => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
            derivedCtor.prototype[name] = baseCtor.prototype[name];
        });
    });
}

const asReadonly = {
    configurable: false,
    enumerable: true,
    writable: false,
};

/**
 * @summary Protect the value of property from being changed.
 */
export function protectProperty<T extends Object>(obj: T, propertyName: keyof T) {
    Object.defineProperty(obj, propertyName, asReadonly);
}

/** Protect an array of properties. */
export function protectProperties<T extends Object>(obj: T, ...propertyNames: Array<keyof T>) {
    if (propertyNames && propertyNames.length) {
        propertyNames.forEach(name => protectProperty<T>(obj, name));
    }
}

/** 判断一个对象是不是某个interface的实例 */
export function interfaceOf<T>(obj: any, propertyName: string): obj is T {
    return propertyName in obj;
}

/** Typically for checking the exhaustive of switch/case statement. */
export function assertUnreachable(x: never): never {
    throw new Error(`Dit NOT expect to get here. ${x ? (x as any).toString() : ''}`);
}
