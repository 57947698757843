import React from 'react';
import { ICreateCollaboratorArgs, IPrincipal, IMindDocument } from 'neka-common';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { showErrorNotification, getErrorText } from '../common/ui-utils';
import { RouteUrls } from '../core/app-config';
import { apis } from '../core/server-apis';
import { IRootState } from '../store/app-redux';

interface IProps extends RouteComponentProps {
    match: IMatch<{ code: string }>;
    principal: IPrincipal;
}

interface IMatch<Params extends { [K in keyof Params]?: string } = {}> {
    params: Params;
    isExact: boolean;
    path: string;
    url: string;
}

/** 代表 被邀请人通过'链接'加入脑问的文档. */
class UserInvite extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    componentWillMount(): void {
        // 通过邀请码获取脑图信息
        const { match, history } = this.props;

        // 判断路由是否带code参数
        if (match.params && match.params.code) {
            this.inviteHandle(match.params.code);
        }
    }

    private async inviteHandle(inviteCode: string) {
        const { principal, history, match } = this.props;
        let doc: IMindDocument = null as any;
        try {
            // 获取脑图信息
            doc = await apis.members.findDoc(inviteCode);
            // 加入成员
            const createModel: ICreateCollaboratorArgs = {
                documentId: doc.id,
                userId: principal.userid,
                role: new Array<string>(),
            };
            const addResult = await apis.members.add(createModel);
            if (addResult) {
                history.replace({
                    pathname: RouteUrls.mind.editUrl(doc.id),
                    state: { from: RouteUrls.users.invite },
                });
            }
        } catch (e) {
            const errText = getErrorText(e);
            if (errText && doc && errText.match('已存在')) {
                history.replace(RouteUrls.mind.editUrl(doc.id));
            } else {
                showErrorNotification(e, () => {
                    // 出现过期等错误跳转到列表页
                    history.replace(RouteUrls.dashboard);
                });
            }
        }
    }

    render() {
        // 暂时没有view显示，用于后台交互及跳转用
        return <div>跳转中...</div>;
    }
}

const routed = withRouter(UserInvite);
export const UserInvitePage = connect((state: IRootState) => ({
    principal: state.principal,
}))(routed);
