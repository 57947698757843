import { getDebug } from 'neka-common';
import React, { Component, RefObject } from 'react';
import { InplaceTextEditorMinWidth } from '../mind-consts';
import { IRect, ISize } from '../mind-draw2d';
import { MindItemTextMaxLength } from '../mind-models';
import { isCancelTextEditKey, isCommitTextEditKey } from './kb-helper';

const debug = getDebug(__filename);

export type InplaceTextEditorCompleteHandler = (newText: string) => void;

interface IInplaceTextEditorProps {
    text: string;
    textBounds: IRect;
    /** 父组件通过此 ref 获取 {@link InplaceTextEditor} 内部的 `<input>` 组件, 以便在合适的时候设置 `<input>` 组件的 `focus`. */
    inputRef: RefObject<HTMLInputElement>;
    onCommit: InplaceTextEditorCompleteHandler;
    onCancel: InplaceTextEditorCompleteHandler;
}

interface IInplaceTextEditorState {
    text: string;
    size?: ISize;
}

export class InplaceTextEditor extends Component<IInplaceTextEditorProps, IInplaceTextEditorState> {
    constructor(props: IInplaceTextEditorProps) {
        super(props);
        this.state = {
            text: props.text,
        };
    }

    public render(): React.ReactNode {
        let { x, y, width, height } = this.props.textBounds;
        width = width < InplaceTextEditorMinWidth ? InplaceTextEditorMinWidth : width;

        return (
            <div
                // ref={this.foreignRef}
                className="mind-inplace-text-editor"
                style={{ position: 'absolute', left: x, top: y, width, height }}
            >
                <div className="wrapper">
                    <input
                        ref={this.props.inputRef}
                        className="text-input"
                        onBlur={this.handleBlur}
                        onClick={this.handleInputClick}
                        onChange={this.handleTextChange}
                        onKeyDown={this.handleKeyDown}
                        value={this.state.text}
                        maxLength={MindItemTextMaxLength}
                    />
                </div>
            </div>
        );
    }

    private handleInputClick: React.EventHandler<React.MouseEvent> = event => {
        event.stopPropagation();
    };

    private handleTextChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        this.setState({ text: event.target.value });
    };

    private handleBlur: React.FocusEventHandler<HTMLInputElement> = event => {
        const { onCommit } = this.props;
        if (onCommit !== undefined) onCommit(this.state.text);
    };

    private handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = event => {
        debug(`handleKeyDown: %s`, event.key);
        event.stopPropagation();

        const { onCommit, onCancel } = this.props;

        if (isCommitTextEditKey(event)) {
            if (onCommit !== undefined) onCommit(this.state.text);
        } else if (isCancelTextEditKey(event)) {
            if (onCancel !== undefined) onCancel(this.state.text);
        }
    };
}
