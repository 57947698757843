import { Avatar } from 'antd';
import { IBriefUser } from 'neka-common';
import React from 'react';
import { IDispatch } from '../../../../common/redux-utils';
import MindDiscussionFrom, { IReplyInput } from './mind-discussion-from';

interface IProps extends IDispatch, IReplyInput {
    // 帖子的Id
    postId?: string;
    docItemId?: string;
    placeHolder: string;
}

/** 代表 {@link MindToolWindow} 的一个 tab 中的内容. */
export default class MindReplyInput extends React.Component<IProps, {}> {
    private replyBtnClick: number = 0;

    protected constructor(props: IProps) {
        super(props);
    }

    componentWillUpdate(nextProps: Readonly<IProps>, nextState: Readonly<{}>, nextContext: any): void {
        this.replyBtnClick += 1;
    }

    public render() {
        const {
            postId,
            replyToId,
            dispatch,
            placeHolder,
            replyInputFocus,
            replyToUser,
            hasDiscussion,
            docItemId,
        } = this.props;
        return (
            <div className="discussion-reply-input-area">
                <span className="user-info">
                    <Avatar size={14} icon="user" />
                </span>
                <MindDiscussionFrom
                    docItemId={docItemId}
                    hasDiscussion={hasDiscussion}
                    replyToUser={replyToUser}
                    replyInputFocus={replyInputFocus}
                    replyBtnClick={this.replyBtnClick}
                    replyToId={replyToId}
                    postId={postId}
                    placeHolder={placeHolder}
                    dispatch={dispatch}
                />
            </div>
        );
    }
}
