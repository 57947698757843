/* =========================================================
// MindWidget 中的各组件
========================================================= */

import { StructureKinds, NotImplementedException, StructureKind } from 'neka-common';
import React, { CSSProperties } from 'react';
import { IPos, NullPos, NullSize } from '../mind-draw2d';
import { IMindItem, IMindItemStyles, MindItemStateException, SNoBodySizeErr, SNoRegionSizeErr } from '../mind-models';
import { GripHalf, TextPadding } from '../mind-styles';
import { SvgElKind, SvgElKinds } from './svg-helper';
import classnames from 'classnames';

/** 选中结点边框 */
export const MindItemSelectedBorder = (props: { item: IMindItem; absoluteBodyPos: IPos }) => {
    const { item, absoluteBodyPos } = props;
    const { id, bodyPos, bodySize } = item;
    if (bodyPos === undefined) throw new MindItemStateException(id, SNoRegionSizeErr);
    if (bodySize === undefined) throw new MindItemStateException(id, SNoBodySizeErr);

    const offset = 3;

    const bounds = {
        x: absoluteBodyPos.x - offset,
        y: absoluteBodyPos.y - offset,
        width: bodySize.width + 2 * offset,
        height: bodySize.height + 2 * offset,
    };
    return (
        <rect
            data-id={id}
            data-kind={SvgElKinds.selectionDecorator}
            className="mg-selected-border"
            rx={4}
            ry={4}
            {...bounds}
        />
    );
};

interface IMindItemProps {
    readonly theme: IMindItemStyles;
    readonly item: IMindItem;
}

/** 结点边框 */
export const MindItemBorder = (props: IMindItemProps) => {
    const { item, theme } = props;
    const { id } = item;

    // 初次绘制时, bodySize 和 layout 可能尚未计算, 此时使用空值避免 reflow
    const bodySize = item.bodySize ? item.bodySize : NullSize;

    const styles: CSSProperties = {
        strokeWidth: item.borderWidth !== undefined ? item.borderWidth : theme.borderWidth,
        stroke: item.borderColor ? item.borderColor : theme.borderColor,
        fill: item.fillColor ? item.fillColor : theme.fillColor,
    };

    return (
        <rect
            data-id={id}
            data-kind={SvgElKinds.itemBorder}
            className="mg-border"
            style={styles}
            x={0}
            y={0}
            width={bodySize.width}
            height={bodySize.height}
            rx={4}
            ry={4}
        />
    );
};

/** 结点文字 */
export const MindItemText = React.forwardRef<SVGTextElement, IMindItemProps>((props, ref) => {
    const { id, text, textFontSize, textFontColor, textFontBold, textFontItalic, textFontFamily } = props.item;

    const styles: CSSProperties = {
        fontFamily: textFontFamily,
        fontSize: textFontSize,
        fill: textFontColor,
        fontWeight: textFontBold ? 'bold' : 'normal',
        fontStyle: textFontItalic ? 'italic' : 'normal',
    };

    return (
        <text
            ref={ref}
            data-id={id}
            data-kind={SvgElKinds.itemContent}
            className="mg-text"
            style={styles}
            fontFamily={textFontFamily}
            alignmentBaseline="hanging"
            x={TextPadding.left}
            y={TextPadding.top + 2 /* TODO: 应该根据 Text Height 计算 */}
        >
            {text}
        </text>
    );
});

/** 水平方向的展开箭头 */
const GripHoriExpand = React.memo(() => (
    <path data-kind={SvgElKinds.gripExpander} className="mg-arrow" d="M-2 -4 L2 0 L-2 4" />
));
/** 水平方向的收起箭头 */
const GripHoriCollapse = React.memo(() => (
    <path data-kind={SvgElKinds.gripExpander} className="mg-arrow" d="M2 -4 L-2 0 L2 4" />
));
/** 竖直方向的展开箭头 */
const GripVertExpand = React.memo(() => (
    <path data-kind={SvgElKinds.gripExpander} className="mg-arrow" d="M-4 -2 L0 2 L4 -2" />
));
/** 竖直方向的收起箭头 */
const GripVertCollapse = React.memo(() => (
    <path data-kind={SvgElKinds.gripExpander} className="mg-arrow" d="M-4 2 L0 -2 L4 2" />
));

/** 表示子结点当前展开/收起状态的箭头 */
const GripArrow = (props: { item: IMindItem; defaultStructureKind: StructureKind }) => {
    const { item, defaultStructureKind } = props;
    const structureKind = item.structureKind ? item.structureKind : defaultStructureKind;

    if (!item.childIds || item.childIds.length === 0) return null;

    switch (structureKind) {
        case StructureKinds.LogicRight:
            return item.isExpanded ? <GripHoriCollapse /> : <GripHoriExpand />;
        case StructureKinds.OrgDown:
            return item.isExpanded ? <GripVertCollapse /> : <GripVertExpand />;
        default:
            throw new NotImplementedException(`structureKind [${structureKind}]`);
    }
};
/** 展开/收起收起子结点的把手. */
export const MindItemGrip = (props: { item: IMindItem; defaultStructureKind: StructureKind }) => {
    const { item, defaultStructureKind } = props;
    const poleStartPos = item.poleStartPos || NullPos;
    const gripPos = item.gripPos || NullPos;
    const hasChildren = item.childIds && item.childIds.length > 0;

    if (!hasChildren) return null;

    const gripClasses = classnames('mg-grip', !item.isExpanded && 'collapsed');

    return (
        <React.Fragment>
            <line
                data-id={item.id}
                data-kind={SvgElKinds.itemPole}
                className="mg-pole"
                x1={poleStartPos.x}
                y1={poleStartPos.y}
                x2={gripPos.x}
                y2={gripPos.y}
            />
            <g
                className={gripClasses}
                data-id={item.id}
                data-kind={SvgElKinds.gripWrapper}
                transform={`translate(${gripPos.x},${gripPos.y})`}
            >
                <circle className="mg-grip-border" data-id={item.id} data-kind={SvgElKinds.gripBoundary} r={GripHalf} />
                <GripArrow item={item} defaultStructureKind={defaultStructureKind} />
            </g>
        </React.Fragment>
    );
};
