import { Exception, protectProperty, SecurityException } from '../common';

export class UserNameNotFoundException extends SecurityException {
    constructor(public readonly username: string) {
        super(`User of name [${username}] not found.`, `用户 [${username}] 不存在.`);
        protectProperty(this, 'username');
    }
}

export class PasswordMismatchException extends SecurityException {
    constructor(public readonly username: string) {
        super(`Password mismatch for user [${username}].`, `用户 [${username}] 的密码错误.`);
        protectProperty(this, 'username');
    }
}

export class NotInRoleException extends SecurityException {
    constructor(public readonly userId: string, public readonly roleName: string) {
        super(`User [${userId}] is not assigned with role [${roleName}]`);
    }
}

export class NoUserException extends SecurityException {
    constructor(innerException?: Exception) {
        super('The current user is unspecified.', '未指定用户.', innerException);
    }
}

/** Indicates an object is not an IUser */
export class InvalidUserObjectException extends SecurityException {
    constructor(message: string = 'Invalid user object.', friendlyMessage?: string, innerException?: Exception) {
        super(message, friendlyMessage, innerException);
    }
}
