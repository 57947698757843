/******************************************************************************/
//  MindBoard toolbar
/******************************************************************************/

import { getDebug } from 'neka-common';
import React from 'react';
import { IDispatch } from '../../../common/redux-utils';
import { getItem, IMindDiagram } from '../../mind-models';
import {
    CreateSelectionFirstChild,
    CreateSelectionLastChild,
    CreateSelectionNextSibling,
    CreateSelectionParent,
    CreateSelectionPrevSibling,
    DiagramZoomTo,
} from '../../store/mind-redux';
import {
    SvgIconAddChildItem,
    SvgIconAddFirstChildItem,
    SvgIconAddLastChildItem,
    SvgIconAddNextSibling,
    SvgIconAddParentItem,
    SvgIconAddPrevSiblingItem,
    SvgIconUnknown,
    SvgIconZoomIn,
    SvgIconZoomOut,
} from './mind-svg-icons';

import './mind-toolbar.less';
import { ToolbarButton } from './toolbar-button';
import { Action } from 'redux';

const debug = getDebug(__filename);

interface IConnectedProps {
    diagram: IMindDiagram;
}

interface IOwnProps {}

interface IProps extends IConnectedProps, IOwnProps, IDispatch {
    /** The {@link IMindDiagram}, typically passed from its wrapper {@link MindBoard}. */
}

export class MindToolbar extends React.Component<IProps, {}> {
    public render() {
        const {
            scale,
            selection,
            items,
            rootId,
            allowEdit,
            isExecutingRemoteCommands,
            remoteCommands,
        } = this.props.diagram;
        const focusedId = selection && selection.length > 0 ? selection[0] : undefined;
        const isRoot = focusedId === rootId;
        const focused = focusedId ? getItem(items, focusedId, true) : undefined;
        const hasChild = focused && focused.childIds && focused.childIds.length > 0;

        const executing = isExecutingRemoteCommands || (remoteCommands && remoteCommands.length > 0);

        return (
            // 通过先放置一个默认的 relative 布局的 div, 从而使工具栏在画布上方水平居中
            <div className="mind-tool-bar-wrapper">
                <div className="mind-tool-bar">
                    <div className="mind-tool-bar-content">
                        <div className="center">
                            <ToolbarButton title="缩小(等熊航图标)" onClick={this.zoomOutClick}>
                                <SvgIconZoomOut />
                            </ToolbarButton>
                            <span className="scale">{Math.round(scale * 100)} %</span>
                            <ToolbarButton title="放大(等熊航图标)" onClick={this.zoomInClick}>
                                <SvgIconZoomIn />
                            </ToolbarButton>

                            <ToolbarButton
                                title="增加上级主题"
                                disabled={!allowEdit || !focused || executing}
                                onClick={this.addParentClick}
                            >
                                <SvgIconAddParentItem />
                            </ToolbarButton>
                            <ToolbarButton
                                title="在上方增加主题"
                                disabled={!allowEdit || !focused || isRoot || executing}
                                onClick={this.addPrevSiblingClick}
                            >
                                <SvgIconAddPrevSiblingItem />
                            </ToolbarButton>
                            <ToolbarButton
                                title="在下方增加主题"
                                disabled={!allowEdit || !focused || isRoot || executing}
                                onClick={this.addNextSiblingClick}
                            >
                                <SvgIconAddNextSibling />
                            </ToolbarButton>
                            <ToolbarButton
                                title="增加子主题"
                                disabled={!allowEdit || !focused || executing}
                                onClick={this.addLastChildClick}
                            >
                                <SvgIconAddChildItem />
                            </ToolbarButton>
                        </div>
                        <div className="right-bg" />
                    </div>
                </div>
            </div>
        );
    }

    private zoomInClick: React.MouseEventHandler = event => {
        let { scale } = this.props.diagram;
        scale = (Math.round(scale * 100) + 10) / 100;
        this.props.dispatch(DiagramZoomTo(scale));
    };

    private zoomOutClick: React.MouseEventHandler = event => {
        let { scale } = this.props.diagram;
        scale = (Math.round(scale * 100) - 10) / 100;
        this.props.dispatch(DiagramZoomTo(scale));
    };

    private dispatchAdd(action: Action<any>) {
        const { selection } = this.props.diagram;
        if (selection && selection.length === 1) {
            this.props.dispatch(action);
        }
    }

    private addParentClick: React.MouseEventHandler = event => {
        if (!this.props.diagram.isExecutingRemoteCommands) {
            this.dispatchAdd(CreateSelectionParent());
        }
    };

    private addPrevSiblingClick: React.MouseEventHandler = event => {
        if (!this.props.diagram.isExecutingRemoteCommands) {
            this.dispatchAdd(CreateSelectionPrevSibling());
        }
    };

    private addNextSiblingClick: React.MouseEventHandler = event => {
        if (!this.props.diagram.isExecutingRemoteCommands) {
            this.dispatchAdd(CreateSelectionNextSibling());
        }
    };

    private addLastChildClick: React.MouseEventHandler = event => {
        if (!this.props.diagram.isExecutingRemoteCommands) {
            this.dispatchAdd(CreateSelectionLastChild());
        }
    };
}
