/** Manages the data which is saved in browser's local storage. */
export class LocalStorageItem<T> {
    constructor(private readonly key: string, private readonly defaultValue?: T) {}

    public save(value: T) {
        if (window && window.localStorage) {
            window.localStorage.setItem(this.key, JSON.stringify(value));
        }
    }

    public load(): T | undefined {
        if (window && window.localStorage) {
            const str = window.localStorage.getItem(this.key);
            if (str) return JSON.parse(str) as T;
        }
        if (this.defaultValue) {
            return this.defaultValue;
        }
        return undefined;
    }

    public clear(): void {
        if (window && window.localStorage) {
            window.localStorage.removeItem(this.key);
        }
    }
}
