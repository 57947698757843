import { Button, Input } from 'antd';
import { Form, Formik } from 'formik';
import { IUser } from 'neka-common';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import * as yup from 'yup';
import { IDispatch } from '../../../common/redux-utils';
import { showErrorNotification } from '../../../common/ui-utils';
import { apis } from '../../../core/server-apis';

import '../../../styles/common-styles.less';

interface IFormValues extends Partial<IUser> {}

const validationSchema: yup.ObjectSchema<IFormValues> = yup.object<IFormValues>().shape({
    name: yup.string().required(),
    password: yup.string().required(),
    email: yup.string().required(),
});

interface IViewProps {
    user: IFormValues;
    onDiscard: (values: IFormValues) => void;
    onAdd: (values: IFormValues) => void;
}

const UserAddView = (props: IViewProps) => (
    <div>
        <div className="form-title">新增用户</div>
        <Formik<IFormValues>
            initialValues={props.user}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
                try {
                    await props.onAdd(values);
                } finally {
                    actions.setSubmitting(false);
                }
            }}
            render={bag => (
                <Form>
                    <Input
                        name="username"
                        placeholder="User Name"
                        className="form-row"
                        value={bag.values.name}
                        onChange={bag.handleChange}
                        onBlur={bag.handleBlur}
                    />
                    <Input
                        name="password"
                        placeholder="Password"
                        className="form-row"
                        value={bag.values.password}
                        onChange={bag.handleChange}
                        onBlur={bag.handleBlur}
                    />
                    <Input
                        name="email"
                        placeholder="Email"
                        className="form-row"
                        value={bag.values.email}
                        onChange={bag.handleChange}
                        onBlur={bag.handleBlur}
                    />
                    <div className="form-row">
                        <Button type="primary" className="form-button" disabled={bag.isSubmitting}>
                            Add
                        </Button>
                        <Button
                            type="primary"
                            className="form-button"
                            disabled={bag.isSubmitting}
                            onClick={() => props.onDiscard(bag.values)}
                        >
                            Discard
                        </Button>
                    </div>
                </Form>
            )}
        />
    </div>
);

type ISiteProps = RouteComponentProps & IDispatch;

export class UserAddSite extends React.Component<ISiteProps, {}> {
    constructor(props: ISiteProps) {
        super(props);
    }

    private static initialValues: Partial<IUser> = { name: '', email: '' };

    private handleDiscard = () => {
        this.props.history.goBack();
    };

    private handleAdd = async (values: IFormValues) => {
        try {
            await apis.users.createOne(values);
            // TODO: dispatch a redux action to add the created user to admin.users

            this.handleDiscard();
        } catch (e) {
            showErrorNotification(e);
        }
    };

    public render() {
        return <UserAddView user={UserAddSite.initialValues} onAdd={this.handleAdd} onDiscard={this.handleDiscard} />;
    }
}

export const UserAddPane = connect()(withRouter(UserAddSite));
