import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Form, Icon, Input, Modal, Tabs, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { IMobileUserArgs } from 'neka-common';
import { ExceptionType, formConstant, logoImg, posterImg } from '../constant';
import { IDispatch } from '../../common/redux-utils';
import { showErrorNotification, getErrorInfoName } from '../../common/ui-utils';
import { localStoragePrincipal, RouteUrls } from '../../core/app-config';
import { apis } from '../../core/server-apis';
import { IRootState, logIn } from '../../store/app-redux';
import { doRedirectPath, filterNumberInput, getNameErrorTips } from '../account-util';
import { IAccountMap, setPwLoginCounter } from '../store/account-redux';
import SendSMSCode from './common/account-send-smscode';
import WechatView from './common/account-wechatview';
import { PassworInput, TextInput } from './common/browser-forbid-remeber';
import './account.less';

const PhoneInputPreFix = () => {
    return (
        <Tooltip placement="topLeft" title="目前仅支持中国大陆手机号注册">
            +86
            <Icon type="question-circle" style={{ color: 'rgba(0,0,0,.25)' }} />
            <span className="prefix-divider" />
        </Tooltip>
    );
};

const NameInputOnAfter = () => {
    return (
        <Tooltip placement="topRight" title="建议您填写真实姓名方便协作者快速认出您">
            <div className="nw-input-on-after">
                <Icon type="question-circle" style={{ color: 'rgba(0,0,0,.25)' }} />
            </div>
        </Tooltip>
    );
};

// declare let window;
interface IConnectedProps {
    account: IAccountMap;
}
interface IOwnProps {}
export interface IRegister extends IConnectedProps, IOwnProps, IDispatch, RouteComponentProps, FormComponentProps {}

class Register extends React.Component<IRegister, {}> {
    constructor(props: IRegister) {
        super(props);
    }

    state = {
        confirmDirty: false,
        modelVisible: false,
    };

    register: React.EventHandler<React.MouseEvent> = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.registerHandle({ ...values, nickname: values.nickname.trim() });
            }
        });
    };

    registerHandle = async (RegisterUserArgs: IMobileUserArgs) => {
        try {
            const principal = await apis.account.register(RegisterUserArgs);
            this.props.dispatch(setPwLoginCounter(0));
            this.props.dispatch(logIn(principal));
            localStoragePrincipal.save(principal);
            // this.props.history.push(RouteUrls.dashboard);
            doRedirectPath(this.props.history);
        } catch (e) {
            let infoName: string = getErrorInfoName(e);
            if (infoName === ExceptionType.MobileExistsException) {
                this.setState({
                    modelVisible: true,
                });
            } else if (infoName === ExceptionType.SmsCodeIncorrectException) {
                this.props.form.setFields({
                    verifyCode: {
                        value: this.props.form.getFieldValue('verifyCode'),
                        errors: [new Error(formConstant.validateRules.smsCodeInputErrorMassage)],
                    },
                });
            } else {
                showErrorNotification(e);
            }
        }
    };

    handleConfirmBlur: React.FocusEventHandler<HTMLInputElement> = e => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    compareToFirstPassword = (rule: any, value: any, callback: any) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback(formConstant.validateRules.confirmPasswordMatchMassage);
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule: any, value: any, callback: any) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirmPassword'], { force: true });
        }
        callback();
    };

    clickModelCancel = () => {
        this.setState({ modelVisible: false });
    };

    clickModelOK = () => {
        this.setState({ modelVisible: false });
        this.props.history.push(RouteUrls.home);
    };

    validateName = (rule: any, value: string, callback: any) => {
        callback(getNameErrorTips(value.trim()));
    };

    render() {
        let { getFieldDecorator, getFieldValue } = this.props.form;
        return (
            <div className="nw-login-page">
                <div className="nw-login-header">
                    <img src={logoImg} />
                    NaoWen
                </div>
                <div className="nw-login-poster">
                    <img src={posterImg} />
                </div>
                <div className="nw-login-content-bg">
                    <div className="nw-login-content">
                        <div className="nw-login-content-header">注册</div>
                        <div className="nw-login-content-center nw-login-content-center-hastab">
                            <Tabs defaultActiveKey={'phone'}>
                                <Tabs.TabPane tab={'手机注册'} key="phone" forceRender={true}>
                                    <Form autoComplete="off" className="register-form">
                                        <PassworInput />
                                        <Form.Item>
                                            {getFieldDecorator('mobile', {
                                                normalize: filterNumberInput,
                                                validateTrigger: formConstant.validateTrigger,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: formConstant.validateRules.mobileNullErrorMassage,
                                                    },
                                                    {
                                                        pattern: formConstant.validateRules.mobileRegexp,
                                                        message: formConstant.validateRules.mobileMatchRegexpMassage,
                                                    },
                                                ],
                                            })(
                                                <Input
                                                    type="tel"
                                                    prefix={<PhoneInputPreFix />}
                                                    placeholder="请输入手机号"
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item>
                                            {getFieldDecorator('verifyCode', {
                                                normalize: filterNumberInput,
                                                validateTrigger: formConstant.validateTrigger,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: formConstant.validateRules.smsCodeNullErrorMassage,
                                                    },
                                                    {
                                                        pattern: formConstant.validateRules.smsCodeRegexp,
                                                        message: formConstant.validateRules.smsCodeMatchRegexpMassage,
                                                    },
                                                ],
                                            })(
                                                <Input
                                                    type="tel"
                                                    placeholder="请输入验证码"
                                                    className="nw-smscode-input"
                                                />
                                            )}
                                            <SendSMSCode mobile={getFieldValue('mobile')} />
                                        </Form.Item>
                                        <TextInput />
                                        <Form.Item>
                                            {getFieldDecorator('password', {
                                                validateTrigger: formConstant.validateTrigger,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: formConstant.validateRules.passwordNullErrorMassage,
                                                    },
                                                    {
                                                        pattern: formConstant.validateRules.passwordRegexp,
                                                        message: formConstant.validateRules.passwordMatchRegexpMassage,
                                                    },
                                                    { validator: this.validateToNextPassword },
                                                ],
                                            })(<Input type="password" placeholder="密码" />)}
                                        </Form.Item>
                                        <Form.Item>
                                            {getFieldDecorator('confirmPassword', {
                                                validateTrigger: formConstant.validateTrigger,
                                                validateFirst: true,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: formConstant.validateRules.passwordNullErrorMassage,
                                                    },
                                                    {
                                                        pattern: formConstant.validateRules.passwordRegexp,
                                                        message: formConstant.validateRules.passwordMatchRegexpMassage,
                                                    },
                                                    { validator: this.compareToFirstPassword },
                                                ],
                                            })(
                                                <Input
                                                    type="password"
                                                    placeholder="二次确认密码"
                                                    onBlur={this.handleConfirmBlur}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item>
                                            {getFieldDecorator('nickname', {
                                                validateTrigger: formConstant.validateTrigger,
                                                validateFirst: true,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: formConstant.validateRules.nicknameNullErrorMassage,
                                                    },
                                                    { validator: this.validateName },
                                                ],
                                            })(<Input type="text" placeholder="输入名字" className="nw-nickname" />)}
                                            <NameInputOnAfter />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                onClick={this.register}
                                                className="nw-login-form-button"
                                            >
                                                免费注册
                                            </Button>
                                        </Form.Item>
                                        <div className="nw-register-service-agreement">
                                            注册则表示您已同意 《服务协议》
                                        </div>
                                    </Form>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={'微信扫码注册'} key="wechat" forceRender={true}>
                                    <WechatView currentPage="register" />
                                </Tabs.TabPane>
                            </Tabs>
                        </div>
                        <div className="nw-login-content-footer">
                            <div className="nw-register">
                                已有账号?
                                <Link to={RouteUrls.home} className="nw-reg">
                                    点我登录
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    title="此手机号已注册"
                    visible={this.state.modelVisible}
                    onOk={this.clickModelOK}
                    onCancel={this.clickModelCancel}
                    okText="去登录"
                    cancelText="继续注册"
                    className="nw-account-model"
                >
                    <div>此手机号已经注册脑问账号，请登录，或者换个手机号继续注册</div>
                </Modal>
            </div>
        );
    }
}

const RegisterPage = connect<IConnectedProps, {}, IOwnProps, IRootState>((state: IRootState) => ({
    account: state.account,
}))(Register);

export default Form.create({ name: 'register' })(RegisterPage);
