import { BusinessException, protectProperty } from '../common';

export class UserIdExistsException extends BusinessException {
    constructor(public readonly userid: string) {
        super(`User of id [${userid}] already exists.`, `用户 ID [${userid}] 已经存在.`);
        protectProperty(this, 'userid');
    }
}

export class UserIdNotExistException extends BusinessException {
    constructor(public readonly userid: string) {
        super(`User of id [${userid}] does not exist.`, `用户 ID [${userid}] 不存在.`);
        protectProperty(this, 'userid');
    }
}

export class UserNameExistsException extends BusinessException {
    constructor(public readonly username: string) {
        super(`User of name [${username}] already exists.`, `用户 [${username}] 已经存在.`);
        protectProperty(this, 'username');
    }
}

export class UserNameNotExistException extends BusinessException {
    constructor(public readonly username: string) {
        super(`User of name [${username}] does not exist.`, `用户 [${username}] 不存在.`);
        protectProperty(this, 'username');
    }
}

export class MobileExistsException extends BusinessException {
    constructor(public readonly mobile: string) {
        super(`mobile [${mobile}] already exists.`, `手机号为 [${mobile}] 的用户已经存在.`);
        protectProperty(this, 'mobile');
    }
}

export class MobileNotExistException extends BusinessException {
    constructor(public readonly mobile: string) {
        super(`mobile [${mobile}] does not exist.`, `手机号为 [${mobile}] 的用户不存在.`);
        protectProperty(this, 'mobile');
    }
}

export class SmsCodeIncorrectException extends BusinessException {
    constructor() {
        super(`incorrect sms code.`, `手机验证码不正确.`);
    }
}

export class UnionIdExistsException extends BusinessException {
    constructor() {
        super(`wechat user already exists.`, `该账号已经绑定了系统用户.`);
    }
}

export class UnionIdNotExistsException extends BusinessException {
    constructor() {
        super(`wechat user not register.`, `未注册的微信用户.`);
    }
}
