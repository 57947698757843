// *********************************************************
// Keyboard helpers
// *********************************************************
import React from 'react';
import { IRdxAction } from '../../common/redux-utils';
import { getOS } from '../mind-utils';

export type DirectionKeys = 'Up' | 'Down' | 'Left' | 'Right';
export const DirectionKeys = Object.freeze({
    Up: 'Up' as 'Up',
    Down: 'Down' as 'Down',
    Left: 'Left' as 'Left',
    Right: 'Right' as 'Right',
});

/** {@link IKeyboardSpec} 与 {@link IMouseSpec} 的公共部分. */
export interface IEventSpec {
    readonly altKey?: boolean;
    readonly ctrlKey?: boolean;
    readonly metaKey?: boolean;
    readonly shiftKey?: boolean;
}

/**
 * 代表一个快捷键的定义.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
 */
export interface IKeyboardSpec extends IEventSpec {
    readonly key: string;
}

/**
 * 代表一个鼠标按键的定义.
 * https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
 */
export interface IMouseSpec extends IEventSpec {
    button?: number;
}

export type EventGuard = (event: React.SyntheticEvent) => boolean;

/** 代表一个按键与 Action Creator 的组合. */
export type GuardedActionCreator = [EventGuard, ((...args: any[]) => IRdxAction<any, any>)];

/** 事件是否匹配一个预设值. */
export function createMatcher<T extends IEventSpec>(...specs: T[]): (event: React.SyntheticEvent) => boolean {
    function eventMatcher(event: React.SyntheticEvent): boolean {
        const firstMatched = specs.find(spec => {
            return matchSpec((event as any) as T, spec);
        });
        return firstMatched !== undefined;
    }

    return eventMatcher;
}

function matchSpec<T extends IEventSpec>(event: T, spec: T): boolean {
    const specKeys = Object.keys(spec);

    for (let i = 0; i < specKeys.length; i++) {
        const k = specKeys[i];
        const expected = (spec as any)[k];
        const actual = (event as any)[k];
        if (actual !== expected) return false;
    }

    return true;
}

// =========================================================
// 简化 IEventSpec 创建的辅助方法
// =========================================================

const DefaultEventSpec: IEventSpec = Object.freeze({
    altKey: false,
    ctrlKey: false,
    shiftKey: false,
    metaKey: false,
});

const DefaultKeyboardSpec: IKeyboardSpec = Object.freeze({
    ...DefaultEventSpec,
    key: '',
});

const DefaultMouseSpec: IEventSpec = Object.freeze({
    ...DefaultEventSpec,
    button: 0,
});

// see https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
export const MouseButtons = Object.freeze({
    main: 0,
    auxiliary: 1,
    secondary: 2,
    fourth: 3,
    fifth: 4,
});

function buttonOf(button: number): IMouseSpec {
    return { ...DefaultMouseSpec, button };
}

function keyOf(key: string): IKeyboardSpec {
    return { ...DefaultKeyboardSpec, key };
}

const ctrlSpec: IEventSpec = getOS() === 'MacOS' ? { metaKey: true } : { ctrlKey: true };

function ctrl<T extends IEventSpec>(spec: T): T {
    return Object.assign({}, spec, ctrlSpec);
}

function alt<T extends IEventSpec>(spec: T): T {
    return Object.assign({}, spec, { altKey: true });
}

function shift<T extends IEventSpec>(spec: T): T {
    return Object.assign({}, spec, { shiftKey: true });
}

// =========================================================
// 界面的预定义按键
// =========================================================

export const isIgnoredKeys = createMatcher(ctrl(keyOf('K')));

export const isZoomKey = createMatcher(getOS() === 'MacOS' ? ctrl({}) : alt({}));

/** 上移焦点 */
export const isFocusUpKey = createMatcher(keyOf('ArrowUp'));
/** 下移焦点 */
export const isFocusDownKey = createMatcher(keyOf('ArrowDown'));
/** 左移焦点 */
export const isFocusLeftKey = createMatcher(keyOf('ArrowLeft'));
/** 右移焦点 */
export const isFocusRightKey = createMatcher(keyOf('ArrowRight'));

/** 上移节点 */
export const isMoveUpKey = createMatcher(ctrl(keyOf('ArrowUp')));
/** 下移节点 */
export const isMoveDownKey = createMatcher(ctrl(keyOf('ArrowDown')));
/** 左移节点 */
export const isMoveLeftKey = createMatcher(ctrl(keyOf('ArrowLeft')));
/** 右移节点 */
export const isMoveRightKey = createMatcher(ctrl(keyOf('ArrowRight')));

/** 展开结点 */
export const isExpandKey = createMatcher(keyOf(']'));
/** 收起结点 */
export const isCollapseKey = createMatcher(keyOf('['));

/** 进入编辑状态. */
export const isStartEditKey = createMatcher(keyOf(' '));
/** 保存结点文字的修改. */
export const isCommitTextEditKey = createMatcher(keyOf('Enter'));
/** 取消结点文字编辑. */
export const isCancelTextEditKey = createMatcher(keyOf('Escape'));
/** 创建新的子结点. */
export const isCreateChildKey = createMatcher(keyOf('Tab'), keyOf('Insert'));
/** 创建新的兄弟结点. */
export const isCreateNextSiblingKey = createMatcher(keyOf('Enter'));
/** 删除结点及其子树. */
export const isDeleteSubtreeKey = createMatcher(keyOf('Delete'), keyOf('Backspace'));

/** 将选中的结点剪切到剪贴板. */
export const isCutToClipboardKey = createMatcher(ctrl(keyOf('x')), ctrl(keyOf('X')));
/** 将选中的结点复制到剪贴板. */
export const isCopyToClipboardKey = createMatcher(ctrl(keyOf('c')), ctrl(keyOf('C')));
/** 从剪贴板中复制结点. */
export const isPasteFromClipboardKey = createMatcher(ctrl(keyOf('v')), ctrl(keyOf('V')));

/** 选中 */
export const isSelectionButton = createMatcher(buttonOf(MouseButtons.main));
/** 追加选中 */
export const isAppendingSelectionButton = createMatcher(ctrl(buttonOf(MouseButtons.main)));
/** 拖放 */
export const isDragButton = createMatcher(buttonOf(MouseButtons.main));
