import { LineKinds, Enum, assertUnreachable } from 'neka-common';
import { getItem, IMindDiagram, IMindItem, IMindItemStyles, itemOf, ItemOrId } from '../../mind-models';

export const StyleValueSources = Object.freeze({
    none: 'none' as 'none',
    local: 'local' as 'local',
    inherited: 'inherited' as 'inherited',
    mixed: 'mixed' as 'mixed',
});
export type StyleValueSource = Enum<typeof StyleValueSources>;

export interface IStyleValueDescriptor<T> {
    /** 当所有结点的属性值都是本地值时为 true, 否则为 false. */
    valueSource: StyleValueSource;
    /** 当所有结点的属性值相同时, 值为结点的属性值, 否则为 undefined. */
    value: T | undefined;
}

/** 返回单个结点的样式值. */
export function getStyleValueDescriptor<P extends keyof IMindItemStyles>(
    diagram: IMindDiagram,
    itemOrId: ItemOrId,
    property: P,
    canInherit: boolean
): IStyleValueDescriptor<IMindItemStyles[P]> {
    const { items, theme, rootId } = diagram;

    let current: IMindItem = itemOf(items, itemOrId);
    let value = current[property];

    if (value !== undefined && value !== null) {
        return {
            valueSource: StyleValueSources.local,
            value,
        };
    }

    if (canInherit) {
        while (current.parentId && current.id !== rootId) {
            current = getItem(items, current.parentId, true);
            value = current[property];
            if (value !== undefined && value !== null) {
                return {
                    valueSource: StyleValueSources.inherited,
                    value,
                };
            }
        }
    }

    value = theme[property];
    if (value !== undefined && value !== null) {
        return {
            valueSource: StyleValueSources.inherited,
            value,
        };
    }

    return {
        valueSource: StyleValueSources.none,
        value,
    };
}

export interface IMultiValueDescriptor<T> extends IStyleValueDescriptor<T> {
    isMulti: boolean;
    areSame: boolean;
}

/** 返回一组结点的共同的样式值. */
export function getItemsStyleValueDescriptor<P extends keyof IMindItemStyles>(
    diagram: IMindDiagram,
    styleItems: ReadonlyArray<IMindItem>,
    property: P,
    canInherit: boolean
): IMultiValueDescriptor<IMindItemStyles[P]> {
    if (!styleItems || styleItems.length === 0) {
        return {
            isMulti: false,
            areSame: true,
            valueSource: StyleValueSources.none,
            value: undefined,
        };
    }

    const first = getStyleValueDescriptor(diagram, styleItems[0], property, canInherit);
    if (styleItems.length === 1) {
        return {
            isMulti: false,
            areSame: true,
            ...first,
        };
    }

    let source = first.valueSource;
    for (let i = 1; i < styleItems.length; i++) {
        const current = getStyleValueDescriptor(diagram, styleItems[i], property, canInherit);
        if (source !== current.valueSource) {
            source = StyleValueSources.mixed;
        }
        if (current.value !== first.value) {
            return {
                isMulti: true,
                areSame: false,
                valueSource: source,
                value: undefined,
            };
        }
    }

    return {
        isMulti: true,
        areSame: true,
        valueSource: source,
        value: first.value,
    };
}

/** 获取用于在可视化组件上显示的值. */
export function getStyleDisplayValue<ValueType, DisplayType>(
    descriptor: IMultiValueDescriptor<ValueType>,
    defaultValue: DisplayType
): ValueType | DisplayType | undefined {
    const { areSame, valueSource, value } = descriptor;
    if (areSame) {
        if (value !== undefined && value !== null) {
            if (valueSource !== StyleValueSources.inherited) return value;
        }
        return defaultValue;
    } else {
        return undefined;
    }
}

export function getOptionControlValue<V, O>(
    isMulti: boolean,
    areSame: boolean,
    valueSource: StyleValueSource,
    value: V,
    multiOptionValue: O,
    defaultOptionValue: O
) {
    if (isMulti) {
        if (areSame) {
            switch (valueSource) {
                case StyleValueSources.none:
                    return defaultOptionValue;
                case StyleValueSources.local:
                    return value;
                case StyleValueSources.inherited:
                    return defaultOptionValue;
                case StyleValueSources.mixed:
                    return multiOptionValue;
                default:
                    assertUnreachable(valueSource);
            }
        } else {
            return multiOptionValue;
        }
    } else {
        if (valueSource === StyleValueSources.inherited || valueSource === StyleValueSources.none) {
            return defaultOptionValue;
        } else {
            return value;
        }
    }
}
