import { Exception } from './ex-utils';
import { Class, protectProperty } from './sys-utils';
import { BusinessException } from './ex-utils';

export abstract class EntityException extends BusinessException {
    protected constructor(entityType: Class, message: string, friendlyMessage?: string, innerException?: Exception) {
        super(message, friendlyMessage, innerException);
        this.entityType = entityType;
        protectProperty(this, 'entityType');
    }

    public readonly entityType: Class;
}

/** Represents the exception of an entity does not exist. */
export class EntityNotExistException extends EntityException {
    constructor(entityType: Class, message?: string, friendlyMessage?: string, innerException?: Exception) {
        super(
            entityType,
            message ? message : `Entity of Type [${entityType.name}] does not exist.`,
            friendlyMessage,
            innerException
        );
    }
}

export class EntityNotDeletableException extends EntityException {
    constructor(entityType: Class, message?: string, friendlyMessage?: string, innerException?: Exception) {
        super(
            entityType,
            message ? message : `Entity of Type [${entityType.name}] is not deletable.`,
            friendlyMessage,
            innerException
        );
    }
}
