import { IDiscussionPost } from 'neka-common';

// 代表一个节点对应的帖子列表
export interface IDiscussionView {
    // 结点的 id
    readonly docItemId?: string;
    // 结点的 标题
    readonly nodeTitle?: string;
    // 帖子列表
    readonly discussions?: ReadonlyArray<IDiscussionPost>;

    // 是列表否加载完成
    readonly isLoading: boolean;

    // 文档节点是否有讨论
    hasDiscussion?: boolean;
}

export function initDiscussionsState(): IDiscussionView {
    return {
        docItemId: '',
        nodeTitle: '',
        discussions: [],
        isLoading: false,
    };
}
