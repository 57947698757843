import { LineKind, StructureKind } from 'neka-common';
import React from 'react';
import { AnyAction } from 'redux';
import { IDispatch } from '../../../common/redux-utils';
import { getItem, IMindDiagram } from '../../mind-models';
import { ChangeSelectionLineKind, ChangeSelectionStructureKind, ChangeSelectionStyles } from '../../store/mind-redux';
import { BoolValuePicker } from './bool-value.picker';
import { ColorPicker } from './color.picker';
import { FontNamePicker } from './font-name.picker';
import { FontSizePicker } from './font-size.picker';
import { LineKindPicker } from './line-kind.picker';

import './mind-style.pane.less';
import { StrokeWidthPicker } from './stroke-width.picker';
import { StructureKindPicker } from './structure-kind.picker';
import { getItemsStyleValueDescriptor } from './style-helper';
import classnames from 'classnames';

interface IProps extends IDispatch {
    /** The {@link IMindDiagram}, typically passed from its wrapper {@link MindBoard}. */
    diagram: IMindDiagram;
}

interface IState {}

export class MindStylePane extends React.Component<IProps, IState> {
    public constructor(props: IProps) {
        super(props);

        this.state = {};
    }

    public render() {
        const { diagram } = this.props;
        const { selection, items } = this.props.diagram;
        if (!selection || selection.length === 0) return this.renderNoSelected();

        const item = selection.length === 1 ? getItem(items, selection[0], true) : undefined;
        const itemText = item ? item.text : '(多个主题)';
        const itemTextClasses = classnames('title', item === undefined && 'multiple');
        const selected = selection ? selection.map(id => getItem(items, id, true)) : [];

        const structureKinds = getItemsStyleValueDescriptor(diagram, selected, 'structureKind', true);
        const lineKinds = getItemsStyleValueDescriptor(diagram, selected, 'lineKind', true);
        const fillColors = getItemsStyleValueDescriptor(diagram, selected, 'fillColor', false);
        const borderStrokeWidths = getItemsStyleValueDescriptor(diagram, selected, 'borderWidth', false);
        const borderColors = getItemsStyleValueDescriptor(diagram, selected, 'borderColor', false);

        const textFontNames = getItemsStyleValueDescriptor(diagram, selected, 'textFontFamily', false);
        const textFontSizes = getItemsStyleValueDescriptor(diagram, selected, 'textFontSize', false);
        const textFontColors = getItemsStyleValueDescriptor(diagram, selected, 'textFontColor', false);
        const textFontBolds = getItemsStyleValueDescriptor(diagram, selected, 'textFontBold', false);
        const textFontItalics = getItemsStyleValueDescriptor(diagram, selected, 'textFontItalic', false);

        return (
            <div className="mind-tool-pane mind-style-pane">
                <div className="pane-title">
                    主题：<span className={itemTextClasses}>{itemText}</span>
                </div>
                <div className="mind-style-pane-content">
                    <section>
                        <header>布局</header>
                        <div className="row">
                            <span className="label">结构</span>
                            <StructureKindPicker {...structureKinds} onValueChanged={this.handleStructureKindChanged} />
                        </div>
                        <div className="row">
                            <span className="label">连线</span>
                            <LineKindPicker {...lineKinds} onValueChanged={this.handleLineKindChanged} />
                        </div>
                    </section>

                    <hr />
                    <section>
                        <header>形状</header>
                        <div className="row">
                            <span className="label">填充</span>
                            <ColorPicker {...fillColors} onValueChanged={this.handleFillColorChanged} />
                        </div>
                        <div className="row">
                            <span className="label">边框</span>
                            <span className="prop-value">
                                <StrokeWidthPicker
                                    {...borderStrokeWidths}
                                    onValueChanged={this.handleBorderWidthChanged}
                                />
                            </span>
                            <span className="prop-value">
                                <ColorPicker {...borderColors} onValueChanged={this.handleBorderColorChanged} />
                            </span>
                        </div>
                    </section>
                    <hr />
                    <section>
                        <header>文字</header>
                        <div className="row">
                            <span className="label"> 字体</span>
                            <span className="prop-value">
                                <FontNamePicker {...textFontNames} onValueChanged={this.handleTextFontNameChanged} />
                            </span>
                        </div>
                        <div className="row">
                            <span className="label"> 字号</span>
                            <span className="prop-value">
                                <FontSizePicker {...textFontSizes} onValueChanged={this.handleTextFontSizeChanged} />
                            </span>
                        </div>
                        <div className="row">
                            <span className="label"> 颜色</span>
                            <span className="prop-value">
                                <ColorPicker {...textFontColors} onValueChanged={this.handleTextFontColorChanged} />
                            </span>
                        </div>
                        <div className="row">
                            <span className="label"> 样式</span>
                            <span className="prop-value">
                                <BoolValuePicker
                                    label="粗体"
                                    {...textFontBolds}
                                    onValueChanged={this.handleTextFontBoldChanged}
                                />
                                <BoolValuePicker
                                    label="斜体"
                                    {...textFontItalics}
                                    onValueChanged={this.handleTextFontItalicChanged}
                                />
                            </span>
                        </div>
                    </section>
                    <hr />
                </div>
            </div>
        );
    }

    private renderNoSelected() {
        return <div>在脑图中选中主题后, 可在此调整其样式</div>;
    }

    private handleStructureKindChanged = (value?: StructureKind) => {
        this.dispatchChangeForSelection(() => ChangeSelectionStructureKind(value));
    };

    private handleLineKindChanged = (value?: LineKind) => {
        this.dispatchChangeForSelection(() => ChangeSelectionLineKind(value));
    };

    private handleFillColorChanged = (value?: string) => {
        this.dispatchChangeForSelection(() => ChangeSelectionStyles({ fillColor: value }));
    };

    private handleBorderWidthChanged = (value: number) => {
        this.dispatchChangeForSelection(() => ChangeSelectionStyles({ borderWidth: value }));
    };

    private handleBorderColorChanged = (value?: string) => {
        this.dispatchChangeForSelection(() => ChangeSelectionStyles({ borderColor: value }));
    };

    private handleTextFontNameChanged = (value?: string) => {
        this.dispatchChangeForSelection(() => ChangeSelectionStyles({ textFontFamily: value }));
    };

    private handleTextFontSizeChanged = (value?: number) => {
        this.dispatchChangeForSelection(() => ChangeSelectionStyles({ textFontSize: value }));
    };

    private handleTextFontColorChanged = (value?: string) => {
        this.dispatchChangeForSelection(() => ChangeSelectionStyles({ textFontColor: value }));
    };

    private handleTextFontBoldChanged = (value?: boolean) => {
        this.dispatchChangeForSelection(() => ChangeSelectionStyles({ textFontBold: value }));
    };

    private handleTextFontItalicChanged = (value?: boolean) => {
        this.dispatchChangeForSelection(() => ChangeSelectionStyles({ textFontItalic: value }));
    };

    private dispatchChangeForSelection(createAction: () => AnyAction) {
        const { diagram, dispatch } = this.props;
        const { selection } = diagram;
        if (!selection || selection.length === 0) return;

        const action = createAction();
        this.props.dispatch(action);
    }
}

export default MindStylePane;
