import React from 'react';
import { IDiscussionPostArgs, IDiscussionReplyArgs, IBriefUser } from 'neka-common';
import { IDispatch } from '../../../../common/redux-utils';
import { showErrorNotification } from '../../../../common/ui-utils';
import { apis } from '../../../../core/server-apis';
import { localStorageDiscussCache } from '../../../../core/app-config';

import { MergeDiscussionToArray, MergeReplyToArray } from '../../../store/discussion-redux';
import { ChangeDiscussionStatus } from '../../../store/mind-redux';

export interface IReplyInput {
    // 输入框是否显示及获得焦点
    replyInputFocus: boolean;
    // 回复的Id
    replyToId?: string;
    // 帖子的Id
    postId?: string;

    replyToUser?: IBriefUser;
    // 节点是否已有讨论
    hasDiscussion?: boolean;
}

interface IProps extends IReplyInput, IDispatch {
    docItemId?: string;
    placeHolder: string;
    replyBtnClick?: number;
}

interface IState {
    text: string;
    replyName: string;
}

/** 代表 {@link MindDiscussionPane} 的问答发帖/回复框. */
export default class MindDiscussionFrom extends React.Component<IProps, IState> {
    private textAreaRef = React.createRef<HTMLTextAreaElement>();
    // 防止连击重复提交
    private submitLock: boolean = false;

    protected constructor(props: IProps) {
        super(props);
        this.state = {
            text: '',
            replyName: '',
        };
        this.submitLock = false;
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        const { replyInputFocus, docItemId, replyToId, postId, replyBtnClick } = this.props;
        if (
            replyBtnClick !== prevProps.replyBtnClick ||
            replyInputFocus !== prevProps.replyInputFocus ||
            replyToId !== prevProps.replyToId ||
            postId !== prevProps.postId ||
            docItemId !== prevProps.docItemId
        ) {
            this.setTextAreaFocus();
        }
    }

    componentDidMount(): void {
        this.setTextAreaFocus();
    }

    private setTextAreaFocus = (): void => {
        const { replyInputFocus, replyToId, docItemId, postId, replyToUser } = this.props;
        let minRows = 1;
        if (replyInputFocus || (docItemId && docItemId === postId)) {
            minRows = 2;
            if (this.textAreaRef) {
                if (replyInputFocus) {
                    this.textAreaRef.current!.focus();
                }
                this.textAreaRef.current!.rows = minRows;
            }
        }
        // 用户未提交的编辑内容恢复
        const inputCache = localStorageDiscussCache(
            docItemId,
            replyToId,
            docItemId && docItemId === postId ? undefined : postId
        ).load()!;
        const replyName = replyToUser ? '回复 ' + replyToUser.nickname + '： ' : '';
        this.setState({
            replyName: replyName,
            text: inputCache ? replyName + inputCache : replyName,
        });
    };

    // 发布帖子/回复
    private publishDiscuss: React.EventHandler<React.MouseEvent> = async event => {
        let text = this.getPublishContent(this.state.text);
        if (!this.state.text || !text.trim() || this.submitLock) {
            return;
        }
        this.submitLock = true;
        try {
            const { docItemId, replyToId, postId, hasDiscussion } = this.props;
            localStorageDiscussCache(
                docItemId,
                replyToId,
                docItemId && docItemId === postId ? undefined : postId
            ).clear();

            // 换行符转换
            text = text
                .replace(/\r\n/g, '<br/>')
                .replace(/\n/g, '<br/>')
                .replace(/\s/g, ' ');
            if (docItemId && docItemId === postId) {
                const publishData: IDiscussionPostArgs = {
                    itemId: docItemId,
                    text: text,
                };
                const discussionPostResult = await apis.discussions.createDiscuss(publishData);
                if (discussionPostResult) {
                    this.props.dispatch(MergeDiscussionToArray(discussionPostResult));
                    // 节点没有讨论图标通知更新
                    if (!hasDiscussion) {
                        this.props.dispatch(ChangeDiscussionStatus({ id: docItemId, hasDiscussion: true }));
                    }
                    this.setState({ text: '' });
                }
            } else {
                const replyData: IDiscussionReplyArgs = {
                    postId: this.props.postId,
                    replyToId: this.state.replyName ? this.props.replyToId : '',
                    text: text,
                };
                const discussionReplyResult = await apis.discussions.createReplies(replyData);
                if (discussionReplyResult) {
                    this.props.dispatch(MergeReplyToArray(discussionReplyResult));
                    this.setState({ text: '', replyName: '' });
                    this.textAreaRef.current!.rows = 1;
                    this.textAreaRef.current!.blur();
                }
            }
            if (docItemId) {
                await apis.discussions.setDiscussRead(docItemId);
            }
        } catch (e) {
            showErrorNotification(e);
        } finally {
            this.submitLock = false;
        }
    };

    private getPublishContent = (text: string) => {
        if (this.state.replyName && text) {
            text = text.replace(this.state.replyName, '');
        }

        return text;
    };

    public render() {
        const { placeHolder, docItemId, replyToId, postId } = this.props;
        return (
            <div className="discussion-form-area">
                <div className="input-area">
                    <textarea
                        wrap="hard"
                        id={docItemId ? docItemId : postId}
                        onFocus={() => (this.textAreaRef.current!.rows = 2)}
                        //   onBlur={() => (docItemId !== postId ? (this.textAreaRef.current!.rows = 1) : null)}
                        className="discuss-reply-input"
                        ref={this.textAreaRef}
                        maxLength={2000}
                        placeholder={placeHolder}
                        value={this.state.text}
                        onChange={event => {
                            localStorageDiscussCache(
                                docItemId,
                                replyToId,
                                docItemId && docItemId === postId ? undefined : postId
                            ).save(this.getPublishContent(event.target.value));
                            this.setState({ text: event.target.value });
                        }}
                        rows={docItemId && docItemId === postId ? 2 : 1}
                    />
                    <div className="btn-area">
                        <button
                            className={
                                this.getPublishContent(this.state.text).trim()
                                    ? 'submit-btn'
                                    : 'submit-btn submit-btn-disabled'
                            }
                            onClick={this.publishDiscuss}
                        >
                            发布
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
