// *********************************************************
// 包含一组用于绘图的基本对象.
// *********************************************************

import { Enum } from 'neka-common';

export const Directions = Object.freeze({
    left: 'left' as 'left',
    right: 'right' as 'right',
    up: 'up' as 'up',
    down: 'down' as 'down',
});
export type Directions = Enum<typeof Directions>;

export function isHori(direction: Directions): boolean {
    return direction === Directions.left || direction === Directions.right;
}

export function isVert(direction: Directions): boolean {
    return direction === Directions.up || direction === Directions.down;
}

/** 表示一个位置. */
export interface IPos {
    x: number;
    y: number;
}

export const NullPos: IPos = Object.freeze({
    x: 0,
    y: 0,
});

/** 比较两个 {@link IPos} 是否相等. */
export function posEquals(a: IPos | undefined, b: IPos | undefined): boolean {
    if (a === b) return true;
    if (a === undefined || b === undefined) return false;

    return a.x === b.x && a.y === b.y;
}

/** 判断两个点的距离是否大于给定的值. */
export function isFarThan(x1: number, y1: number, x2: number, y2: number, distance: number): boolean {
    // 简单起见, 没有计算斜边
    return Math.abs(x2 - x1) > distance || Math.abs(y2 - y1) > distance;
}

/** 表示一个大小. */
export interface ISize {
    width: number;
    height: number;
}

export const NullSize: ISize = Object.freeze({
    width: 0,
    height: 0,
});

/** 比较两个 {@link ISize} 是否相等. */
export function sizeEquals(a: ISize | undefined, b: ISize | undefined): boolean {
    if (a === b) return true;
    if (a === undefined || b === undefined) return false;

    return a.width === b.width && a.height === b.height;
}

export function transposeSize(size: ISize): ISize {
    // noinspection JSSuspiciousNameCombination
    return {
        width: size.height,
        height: size.width,
    };
}

export type IRect = {
    x: number;
    y: number;
    width: number;
    height: number;
};

export const NullRect: IRect = Object.freeze({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
});

export function rectEquals(a: IRect | undefined, b: IRect | undefined): boolean {
    if (a === b) return true;
    if (a === undefined || b === undefined) return false;

    return a.x === b.x && a.y === b.y && a.width === b.width && a.height === b.height;
}

export function createRect(a: IPos, b: IPos): IRect;
export function createRect(x1: number, y1: number, x2: number, y2: number): IRect;
export function createRect(aOrX1: number | IPos, bOrY1: number | IPos, x2?: number, y2?: number): IRect {
    if (typeof aOrX1 === 'number' && typeof bOrY1 === 'number') {
        return createRectFrom(aOrX1, bOrY1, x2!, y2!);
    } else {
        const a = aOrX1 as IPos;
        const b = bOrY1 as IPos;
        return createRectFrom(a.x, a.y, b.x, b.y);
    }
}

function createRectFrom(x1: number, y1: number, x2: number, y2: number): IRect {
    const x = Math.min(x1, x2);
    const y = Math.min(y1, y2);

    const width = Math.max(x1, x2) - x;
    const height = Math.max(y1, y2) - y;
    return { x, y, width, height };
}

export function assignRect(target: SVGRect, source: IRect): void;
export function assignRect(target: SVGRect, x: number, y: number, width: number, height: number): void;
export function assignRect(
    target: SVGRect,
    sourceOrX: number | IRect,
    y?: number,
    width?: number,
    height?: number
): void {
    if (typeof sourceOrX === 'number') {
        return assignRectFrom(target, sourceOrX, y!, width!, height!);
    } else {
        const source = sourceOrX as IRect;
        return assignRectFrom(target, source.x, source.y, source.width, source.height);
    }
}

function assignRectFrom(target: IRect, x: number, y: number, width: number, height: number): void {
    target.x = x;
    target.y = y;
    target.width = width;
    target.height = height;
}

/** 表示一个间隔, 可以用于 margin 或 padding. */
export interface ISpacer {
    left: number;
    top: number;
    right: number;
    bottom: number;
}

/** 比较两个 {@link ISpacer} 是否相等. */
export function spacerEquals(a: ISpacer | undefined, b: ISpacer | undefined): boolean {
    if (a === b) return true;
    if (a === undefined || b === undefined) return false;

    return a.left === b.left && a.top === b.top && a.right === b.right && a.bottom === b.bottom;
}

export function spacerOf(num: number): ISpacer {
    return { left: num, top: num, right: num, bottom: num };
}

export function withSpacer(size: Readonly<ISize>, spacing: Readonly<ISpacer>): ISize {
    return {
        width: size.width + spacing.left + spacing.right,
        height: size.height + spacing.top + spacing.bottom,
    };
}

export function leftMidpoint(rect: IRect): IPos {
    return {
        x: rect.x,
        y: rect.y + rect.height / 2,
    };
}

export function topMidpoint(rect: IRect): IPos {
    return {
        x: rect.x + rect.width / 2,
        y: rect.y,
    };
}

export function rightMidpoint(rect: IRect): IPos {
    return {
        x: rect.x + rect.width,
        y: rect.y + rect.height / 2,
    };
}

export function bottomMidpoint(rect: IRect): IPos {
    return {
        x: rect.x + rect.width / 2,
        y: rect.y + rect.height,
    };
}
