import { Select } from 'antd';
import React from 'react';
import { getOptionControlValue, IMultiValueDescriptor } from './style-helper';

interface IProps extends IMultiValueDescriptor<number> {
    /** 选中颜色后的回调函数. `color` 为 `undefined` 表示点击了 `自动` 按钮. */
    onValueChanged(value?: number): void;
}

/** 颜色选择器. */
export class StrokeWidthPicker extends React.Component<IProps, {}> {
    public constructor(props: IProps) {
        super(props);
    }

    private static readonly defaultOptionValue = -1;
    private static readonly multiOptionValue = -2;

    public render() {
        const { isMulti, areSame, value, valueSource } = this.props;

        const normalized = getOptionControlValue(
            isMulti,
            areSame,
            valueSource,
            value,
            StrokeWidthPicker.multiOptionValue,
            StrokeWidthPicker.defaultOptionValue
        );

        const { Option } = Select;
        return (
            <Select<number | undefined> style={{ width: 82 }} value={normalized} onChange={this.handleValueChanged}>
                {isMulti && (
                    <Option value={-2}>
                        <span style={{ fontStyle: 'italic' }}>-</span>
                    </Option>
                )}
                <Option value={-1}>默认</Option>
                <Option value={0}>无</Option>
                <Option value={1}>细</Option>
                <Option value={2}>中</Option>
                <Option value={3}>粗</Option>
            </Select>
        );
    }

    private handleValueChanged = (value: number | undefined) => {
        if (value !== StrokeWidthPicker.multiOptionValue) {
            this.props.onValueChanged(value !== StrokeWidthPicker.defaultOptionValue ? value : undefined);
        }
    };
}
