// *********************************************************
// 为 redux 服务的一组集合类.
// *********************************************************

import { ArgumentException, Enum, Exception, protectProperty } from 'neka-common';

/** 提供从 Map 中读取元素的最小接口, 以减少参数类型的匹配问题. */
export type IKeyGet<K, V> = Pick<Map<K, V>, 'get'>;

/** 提供向 Map 中写入元素的最小接口, 以减少参数类型的匹配问题 */
export type IKeySet<K, V> = Pick<Map<K, V>, 'set'>;

/** 提供向 Map 中读取/写入元素的最小接口, 以减少参数类型的匹配问题 */
export type IMapLike<K, V> = IKeyGet<K, V> & IKeySet<K, V>;

export class KeyNotFoundException extends Exception {
    constructor(public readonly key: any) {
        super(`Key [${key}] not found.`);
        protectProperty(this, 'key');
    }
}

export function cloneMap<K, V>(source: Map<K, V>): Map<K, V> {
    return new Map(source);
}

export class PropertyMissingException extends Exception {
    constructor(public readonly property: any) {
        super(`Property [${property}] is undefined.`);
        protectProperty(this, 'property');
    }
}

/** 代表一个 key-value 查找函数. */
// tslint:disable-next-line:interface-name
export interface Lookup<K, V> {
    (key: K, required: true): V;
    (key: K, required: false): V | undefined;
}

type GetPropValue<T, P> = (item: T) => P | undefined;

/** 从一系列同类型的对象中获取第一个非 undefined 的属性值. */
export function getFirstValue<T, P extends keyof T>(
    getValue: GetPropValue<T, T[P]>,
    required: true,
    a?: T,
    b?: T,
    ...more: Readonly<T[]>
): T;
export function getFirstValue<T, P extends keyof T>(
    getValue: GetPropValue<T, T[P]>,
    required: false,
    a?: T,
    b?: T,
    ...more: Readonly<T[]>
): T | undefined;
export function getFirstValue<T, P extends keyof T>(
    getValue: GetPropValue<T, T[P]>,
    required: boolean,
    a?: T,
    b?: T,
    ...more: Readonly<T[]>
): T[P] | undefined {
    if (a !== undefined) {
        const value = getValue(a);
        if (value !== undefined) return value;
    }
    if (b !== undefined) {
        return getValue(b);
    }
    if (more !== undefined) {
        for (let i = 0; i < more.length - 1; i++) {
            const source = more[i];
            if (source !== undefined) {
                const value = getValue(source);
                if (value !== undefined) return value;
            }
        }
    }
    if (required) throw new PropertyMissingException(`${getValue.toString()}`);
    return undefined;
}

export function lookupProp<K, O, V>(
    propName: keyof O,
    getProp: GetPropValue<O, V>
): (...maps: Readonly<IKeyGet<K, O>[]>) => Lookup<K, V> {
    return (...maps: Readonly<IKeyGet<K, O>[]>) => {
        function lookupPropImpl(key: K, required: true): V;
        function lookupPropImpl(key: K, required: false): V | undefined;
        function lookupPropImpl(key: K, required: boolean = false): V | undefined {
            let keyExists: boolean = false;
            for (let i = 0; i < maps.length; i++) {
                const lookup: IKeyGet<K, O> = maps[i];
                const obj = lookup.get(key);
                if (obj !== undefined) {
                    keyExists = true;
                    const value = getProp(obj);
                    if (value !== undefined) return value;
                }
            }

            if (required) {
                throw keyExists ? new PropertyMissingException(`${propName} of ${key}`) : new KeyNotFoundException(key);
            }
        }

        return lookupPropImpl;
    };
}

export const SIndexOutOfRangeErr = (index: number, limit: number) =>
    `The index [${index}] must be less than or equal to [${limit}].`;

export function arrayOfInsert<T>(array: ReadonlyArray<T> | undefined, index: number, value: T): Array<T> {
    array = array !== undefined ? array : [];
    if (index > array.length) throw new ArgumentException(SIndexOutOfRangeErr(index, array.length));

    const result = Array.from(array);
    result.splice(index, 0, value);
    return result;
}

export function arrayOfAdd<T>(array: ReadonlyArray<T> | undefined, value: T): Array<T> {
    array = array !== undefined ? array : [];
    const result = Array.from(array);
    result.push(value);
    return result;
}

export function arrayOfDelete<T>(array: ReadonlyArray<T>, index: number): Array<T> {
    if (index >= array.length) throw new ArgumentException(SIndexOutOfRangeErr(index, array.length - 1));

    const result = Array.from(array);
    result.splice(index, 1);
    return result;
}

export function arrayOfMove<T>(array: ReadonlyArray<T>, oldIndex: number, newIndex: number): Array<T> {
    if (oldIndex >= array.length) throw new ArgumentException(SIndexOutOfRangeErr(oldIndex, array.length - 1));
    if (newIndex >= array.length) throw new ArgumentException(SIndexOutOfRangeErr(newIndex, array.length - 1));

    const result = Array.from(array);
    const item = result.splice(oldIndex, 1)[0];
    result.splice(newIndex, 0, item);
    return result;
}

export function arrayEquals<T>(a: ReadonlyArray<T> | undefined, b: ReadonlyArray<T> | undefined) {
    if (a === b) return true;
    if (a !== undefined && b !== undefined) {
        if (a.length !== b.length) return false;
        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }
    return false;
}

export const MapChangeKinds = Object.freeze({
    itemAdded: 'MAP_CHANGE_ITEM_ADDED' as 'MAP_CHANGE_ITEM_ADDED',
    itemRemoved: 'MAP_CHANGE_ITEM_REMOVED' as 'MAP_CHANGE_ITEM_REMOVED',
    valueChanged: 'MAP_CHANGE_VALUE_CHANGED' as 'MAP_CHANGE_VALUE_CHANGED',
});
export type MapChangeKind = Enum<typeof MapChangeKinds>;

export interface IMapChangeItemAdded<K, V> {
    changeKind: 'MAP_CHANGE_ITEM_ADDED';
    key: K;
    newItem: V;
}

export interface IMapChangeItemRemoved<K, V> {
    changeKind: 'MAP_CHANGE_ITEM_REMOVED';
    key: K;
    oldItem: V;
}

export interface IMapChangeValueChanged<K, V> {
    changeKind: 'MAP_CHANGE_VALUE_CHANGED';
    key: K;
    oldItem: V;
    newItem: V;
}

export type IMapChange<K, V> = IMapChangeItemAdded<K, V> | IMapChangeItemRemoved<K, V> | IMapChangeValueChanged<K, V>;

export class MapWrapper<K, V> implements ReadonlyMap<K, V>, Map<K, V> {
    constructor(innerMap: Map<K, V> = new Map<K, V>()) {
        this._innerMap = innerMap;
    }

    private readonly _innerMap: Map<K, V>;

    public get innerMap() {
        return this._innerMap;
    }

    get size(): number {
        return this._innerMap.size;
    }

    get [Symbol.toStringTag]() {
        return this._innerMap[Symbol.toStringTag];
    }

    [Symbol.iterator](): IterableIterator<[K, V]> {
        return this._innerMap[Symbol.iterator]();
    }

    clear() {
        this.forEach((value, key) => {
            this.enlistRemoved(key, value);
        });
        this._innerMap.clear();
    }

    delete(key: K): boolean {
        const value = this._innerMap.get(key);
        if (value !== undefined) {
            this._innerMap.delete(key);
            this.enlistRemoved(key, value);
            return true;
        }
        return false;
    }

    entries(): IterableIterator<[K, V]> {
        return this._innerMap.entries();
    }

    forEach(callbackFn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any): void;
    forEach(callbackFn: (value: V, key: K, map: ReadonlyMap<K, V>) => void, thisArg?: any): void;
    forEach(
        callbackFn: ((value: V, key: K, map: ReadonlyMap<K, V>) => void) | ((value: V, key: K, map: Map<K, V>) => void),
        thisArg?: any
    ): void {
        this._innerMap.forEach(callbackFn, thisArg);
    }

    get(key: K): V | undefined;
    get(key: K, required: true): V;
    get(key: K, required: boolean = false): V | undefined {
        const result = this._innerMap.get(key);
        if (result === undefined && required) throw new KeyNotFoundException(key);
        return result;
    }

    has(key: K): boolean {
        return this._innerMap.has(key);
    }

    keys(): IterableIterator<K> {
        return this._innerMap.keys();
    }

    set(key: K, value: V) {
        const existed = this.get(key);
        this._innerMap.set(key, value);
        if (existed !== undefined) {
            if (value !== undefined) {
                this.enlistValueChange(key, existed, value);
            } else {
                this.enlistRemoved(key, existed);
            }
        } else {
            if (value !== undefined) {
                this.enlistAdded(key, value);
            } else {
                // no change
            }
        }
        return this;
    }

    values(): IterableIterator<V> {
        return this._innerMap.values();
    }

    private changes: Array<IMapChange<K, V>> = [];

    private enlistAdded(key: K, value: V) {
        const change: IMapChangeItemAdded<K, V> = {
            changeKind: 'MAP_CHANGE_ITEM_ADDED',
            key,
            newItem: value,
        };
        this.changes.push(change);
    }

    private enlistRemoved(key: K, value: V) {
        const change: IMapChangeItemRemoved<K, V> = {
            changeKind: 'MAP_CHANGE_ITEM_REMOVED',
            key,
            oldItem: value,
        };
        this.changes.push(change);
    }

    private enlistValueChange(key: K, oldValue: V, newValue: V) {
        const change: IMapChangeValueChanged<K, V> = {
            changeKind: 'MAP_CHANGE_VALUE_CHANGED',
            key,
            oldItem: oldValue,
            newItem: newValue,
        };
        this.changes.push(change);
    }

    public getChanges(): Array<IMapChange<K, V>> {
        return Array.from(this.changes);
    }

    public resetChanges() {
        this.changes = [];
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * 用于支持 Redux-dev-tools.
     * @see https://github.com/zalmoxisus/redux-devtools-extension/issues/124#issuecomment-221972997
     */
    toJSON() {
        const obj = {} as any;
        this._innerMap.forEach((value, key) => (obj[key] = value));
        return obj;
    }
}
