import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './core/App';
import { isDevelopment } from './core/app-config';
import CheckBrowserPage from './components/check-browser.page';
import './index.less';
import registerServiceWorker from './registerServiceWorker';

(() => {
    const userAgent = navigator.userAgent;
    console.log(isDevelopment);
    if (!isDevelopment && userAgent && userAgent.indexOf('Chrome') < 0) {
        ReactDOM.render(<CheckBrowserPage />, document.getElementById('root'));
    } else {
        //  使用 `index.html` 的 `root` 元素绘制 `App`Snackbar
        ReactDOM.render(
            <Router>
                <App />
            </Router>,
            document.getElementById('root')
        );
    }
})();
registerServiceWorker();
