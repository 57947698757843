import * as React from 'react';
import { ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { UnderConstruction } from './common.helpers';

interface IViewProps extends RouteComponentProps {
    children?: ReactNode;
    title?: string;
}

const AccountPageView: React.FunctionComponent<IViewProps> = props => {
    return <div className="account-page">{props.children ? props.children : <UnderConstruction />}</div>;
};

const routed = withRouter(AccountPageView);

export const AccountPage = routed;
