import { FormComponentProps } from 'antd/lib/form';
import { IWechatUserProfile } from 'neka-common';
import { RouteComponentProps } from 'react-router';
import { IDispatch, RdxReducerComposer } from '../../common/redux-utils';

export interface IBindConnectedProps {
    wechatInfo: IWechatUserProfile | null;
}
export interface IBindOwnProps {}
export interface IBindAccount
    extends IBindConnectedProps,
        IBindOwnProps,
        IDispatch,
        RouteComponentProps,
        FormComponentProps {}

export interface IAccountMap {
    selectedTabValue: string;
    wechatInfo: IWechatUserProfile | null;
    passwordLoginCount: number;
}

const initState: IAccountMap = {
    selectedTabValue: 'sms',
    wechatInfo: null,
    passwordLoginCount: 0,
};

export const accountReducerComposer = new RdxReducerComposer<IAccountMap>('LOGIN_');

export const init = accountReducerComposer.registerHandler(
    'INIT',
    () => null,
    state => {
        return {
            ...state,
            ...initState,
        };
    }
);

export const handleTabChange = accountReducerComposer.registerHandler(
    'HANDLE_TAB_CHANGE',
    value => ({ value }),
    (state, payload) => {
        let { value } = payload;
        return {
            ...state,
            selectedTabValue: value,
        };
    }
);

export const saveWechatInfo = accountReducerComposer.registerHandler(
    'SAVE_WECHAT_INFO',
    value => ({ value }),
    (state, payload) => {
        let { value } = payload;
        return {
            ...state,
            wechatInfo: value,
        };
    }
);

export const setPwLoginCounter = accountReducerComposer.registerHandler(
    'SET_PW_LOGIN_COUNTER',
    value => ({ value }),
    (state, payload) => {
        let { value } = payload;
        return {
            ...state,
            passwordLoginCount: value,
        };
    }
);
