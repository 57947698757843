import fetch from 'node-fetch';

/** 定义 http method 字面量, 以避免打字错误. */
export enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
    HEAD = 'HEAD',
    CONNECT = 'CONNECT',
    TRACE = 'TRACE',
    OPTIONS = 'OPTIONS',
}

export const ACCEPT_JSON = 'application/json';
