import { IPrincipal, IUser, Mutable } from 'neka-common';
import { Action, AnyAction, combineReducers, Reducer } from 'redux';
import { accountReducerComposer, IAccountMap } from '../account/store/account-redux';
import { RdxReducerComposer } from '../common/redux-utils';
import { localStorageIsRememberLogin, localStoragePrincipal } from '../core/app-config';
import { Anonymous } from '../core/app-consts';
import { apis } from '../core/server-apis';
import { IDashboard } from '../dashboard/dashboard-model';
import { dashboardReducerComposer } from '../dashboard/store/dashboard-redux';
import { IDiscussionView, initDiscussionsState } from '../minds/discussion-model';
import { getItem, IMindDiagram, IMindItem, loadMindDocument } from '../minds/mind-models';
import { checkDiagram } from '../minds/store/diagram-checks';
import { discussionReducerComposer } from '../minds/store/discussion-redux';
import { diagramReducerComposer } from '../minds/store/mind-redux';

/******************************************************************************/
// principal
/******************************************************************************/
const principalComposer = new RdxReducerComposer<IPrincipal>('APP_PRINCIPAL_');

/** 表示用户已经登入 */
export const logIn = principalComposer.registerHandler(
    'LOG_IN',
    principal => principal,
    (state, payload) => {
        return Object.assign({}, state, payload);
    }
);

/** 表示用户已经登出 */
export const logOut = principalComposer.registerHandler(
    'LOG_OUT',
    () => undefined,
    (state, payload) => {
        localStorageIsRememberLogin.clear();
        localStoragePrincipal.clear();
        return Object.assign({}, state, Anonymous);
    }
);

/******************************************************************************/
// Admin Management
/******************************************************************************/

export interface IAdmin {
    users?: IUser[];
}

const adminComposer = new RdxReducerComposer<IAdmin>('APP_ADMIN_');

export const setUsers = adminComposer.registerHandler(
    'SET_USERS',
    (users: IUser[]) => ({ users }),
    (state, payload) => {
        return { ...state, users: payload.users };
    }
);

/******************************************************************************/
// config
/******************************************************************************/

/** 代表应用程序的配置信息. */
export interface IConfigState {
    apiBaseUrl: string;
}

const initialConfig = {
    apiBaseUrl: 'Unknown API server, check the configuration of ServerInfo.apiBaseUrl.',
};

/******************************************************************************/
// root
/******************************************************************************/

/** 代表 Redux 的根 state */
export interface IRootState {
    config: IConfigState;
    principal: IPrincipal;
    admins: IAdmin;
    isDiagramLoading?: boolean;
    diagramLoadingError?: any;
    diagram: IMindDiagram | null;
    toolWindowVisible: boolean;
    account: IAccountMap;
    dashboard: IDashboard;
    discussions: IDiscussionView;
}

const combinedRootReducer = combineReducers<IRootState, Action<string>>({
    config: state => (state ? state : initialConfig),
    principal: principalComposer.composeReducer<IRootState>(rootState => rootState.principal, undefined, Anonymous),
    admins: adminComposer.composeReducer<IRootState>(rootState => rootState.admins, undefined, { users: [] }),
    isDiagramLoading: value => (value !== undefined ? value : false),
    diagramLoadingError: value => (value !== undefined ? value : null),
    diagram: diagramReducerComposer.composeReducer<IRootState>(rootState => rootState.diagram!, undefined, null),
    toolWindowVisible: state => (state !== undefined ? state : true),
    account: accountReducerComposer.composeReducer<IRootState>(
        rootState => rootState.account,
        undefined,
        {} as IAccountMap
    ),
    dashboard: dashboardReducerComposer.composeReducer<IRootState>(rootState => rootState.dashboard, undefined, {
        isDocumentsLoading: false,
        documents: [],
    }),
    discussions: discussionReducerComposer.composeReducer<IRootState>(
        rootState => rootState.discussions,
        undefined,
        initDiscussionsState()
    ),
});

// ---------------------------------------------------------
// 针对 IRootState 的 reducer
// ---------------------------------------------------------

const rootComposer = new RdxReducerComposer<IRootState>('APP_ROOT_');

export const CloseDocument = rootComposer.registerHandler(
    'CLOSE_DOCUMENT',
    () => null,
    state => {
        return {
            ...state,
            diagram: null,
        };
    }
);

export const FetchDocumentAsync = rootComposer.registerRunnable(
    'FETCH_DOCUMENT_ASYNC',
    (documentId: string) => ({ documentId }),
    async (state, payload) => {
        const { documentId } = payload;
        const { principal } = state;

        try {
            const [document, lastDiscussionTimes] = await Promise.all([
                apis.docs.getDocumentById(documentId),
                apis.docs.getLastDiscussionTimes(documentId),
            ]);

            const diagram = loadMindDocument(document, principal.userid);
            const { items } = diagram;
            if (lastDiscussionTimes) {
                lastDiscussionTimes.forEach(t => {
                    const item: Mutable<IMindItem> = getItem(items, t.id, true);
                    item.hasDiscussion = true;
                    if (!t.lastDiscussionReadTime || t.lastDiscussionReadTime < t.lastDiscussionTime) {
                        item.hasUnreadDiscussion = true;
                    }
                });
            }
            checkDiagram(diagram, true);
            return diagram;
        } catch (e) {
            console.error(e);
            throw e;
        }
    },
    {
        onStarted: (state, payload) => {
            return {
                ...state,
                isDiagramLoading: true,
                diagramLoadingError: undefined,
                diagram: null,
            };
        },
        onSucceed: (state, successPayload) => {
            const { value } = successPayload;
            return {
                ...state,
                isDiagramLoading: false,
                diagramLoadingError: undefined,
                diagram: value,
            };
        },
        onFailed: (state, failurePayload) => {
            const { reason } = failurePayload;
            return {
                ...state,
                isDiagramLoading: false,
                diagramLoadingError: reason,
                diagram: null,
            };
        },
    }
);

export const ChangeToolWindowVisible = rootComposer.registerHandler(
    'FORCE_TOOL_WINDOW_VISIBLE',
    (visible: boolean) => ({ visible }),
    (state, payload) => {
        if (!state) return state;
        const { visible } = payload;
        if (state.toolWindowVisible === visible) return state;
        return {
            ...state,
            toolWindowVisible: visible,
        };
    }
);

export const rootReducer: Reducer<IRootState, AnyAction> = rootComposer.composeReducer<IRootState>(
    rootState => rootState,
    combinedRootReducer
);
