import { Select } from 'antd';
import React from 'react';
import { getOptionControlValue, IMultiValueDescriptor } from './style-helper';

interface IProps extends IMultiValueDescriptor<number> {
    /** 选中颜色后的回调函数. `color` 为 `undefined` 表示点击了 `自动` 按钮. */
    onValueChanged(value?: number): void;
}

const fontSizes: Array<number> = [8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24, 36, 48, 56];

/** 字号选择器. */
export class FontSizePicker extends React.Component<IProps, {}> {
    public constructor(props: IProps) {
        super(props);
    }

    private static readonly defaultOptionValue = -1;
    private static readonly multiOptionValue = -2;

    public render() {
        const { isMulti, areSame, value, valueSource } = this.props;

        const normalized = getOptionControlValue(
            isMulti,
            areSame,
            valueSource,
            value,
            FontSizePicker.multiOptionValue,
            FontSizePicker.defaultOptionValue
        );

        const { Option } = Select;
        return (
            <Select<number | undefined> style={{ width: 82 }} value={normalized} onChange={this.handleValueChanged}>
                {isMulti && (
                    <Option value={-2}>
                        <span style={{ fontStyle: 'italic' }}>-</span>
                    </Option>
                )}
                <Option value={-1}>默认</Option>
                {fontSizes.map(size => {
                    return (
                        <Option key={size} value={size}>
                            {size}
                        </Option>
                    );
                })}
            </Select>
        );
    }

    private handleValueChanged = (value: number | undefined) => {
        if (value !== FontSizePicker.multiOptionValue) {
            this.props.onValueChanged(value !== FontSizePicker.defaultOptionValue ? value : undefined);
        }
    };
}
