import React from 'react';
import { IBriefUser } from 'neka-common';
import { Input, message, Avatar, Spin } from 'antd';
import Scrollbars from 'react-custom-scrollbars';
import { IDispatch } from '../../../common/redux-utils';
import { showErrorNotification } from '../../../common/ui-utils';
import { IMindDiagram } from '../../mind-models';
import { ServerInfo } from '../../../core/app-config';
import { apis } from '../../../core/server-apis';
import { RouteUrls } from '../../../core/app-config';

interface IProps extends IDispatch {
    diagram: IMindDiagram;
}

interface IState {
    // 文档未开发完成，临时用
    docId: string;
    inviteLink: string;
    members: IBriefUser[];
    spinning: boolean;
}

/** 代表 {@link MindToolWindow} 的一个 tab 中的内容. */
export default class MindMembersPanel extends React.Component<IProps, IState> {
    // 复制成功消息显示的区域
    private copyMsgAreaRef = React.createRef<HTMLDivElement>();

    protected constructor(props: IProps) {
        super(props);
        this.state = {
            docId: this.props.diagram.id,
            inviteLink: ServerInfo.webBaseUrl,
            members: [],
            spinning: true,
        };
    }

    componentWillUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): void {
        if (this.props.diagram.activeToolPaneKind !== nextProps.diagram.activeToolPaneKind) {
            // 获取邀请码，用户不点复制按钮手动复制文本框信息
            this.getInviteCode();
            this.getMemberList();
        }
    }

    componentWillMount(): void {
        // 获取邀请码，用户不点复制按钮手动复制文本框信息
        this.getInviteCode();
        this.getMemberList();
    }

    // 获取邀请码
    private async getInviteCode() {
        try {
            const code = await apis.members.getInviteCode(this.state.docId);
            if (code && code.data) {
                this.setState({
                    inviteLink: this.state.inviteLink + RouteUrls.users.inviteUrl(code.data),
                    spinning: false,
                });
            }
        } catch (e) {
            showErrorNotification(e);
        }
    }

    private async getMemberList() {
        try {
            const list = await apis.members.list(this.state.docId);
            if (list && list.length > 0) {
                this.setState({ members: list });
            }
        } catch (e) {
            showErrorNotification(e);
        }
    }

    private copyText: React.EventHandler<React.MouseEvent> = event => {
        let input = event.target as HTMLInputElement;
        try {
            // 如果点击的是复制按钮
            if (input && input.type === 'button' && input.parentElement && input.parentElement.parentElement) {
                input = input.parentElement!.parentElement!.firstChild as HTMLInputElement;
            }
            // 判断点击的是文本框提示已经复制成功
            if (input && input.type === 'text') {
                input.select();

                // 执行浏览器复制命令
                document.execCommand('Copy');
                message.success('复制成功,可以粘贴发送给好友了');
            }
        } catch (e) {
            showErrorNotification('浏览器不支持点击复制到剪贴板,请手动复制');
        }
    };

    public render() {
        return (
            <div className="mind-members-tab">
                <Scrollbars className="members-tab-scroll" autoHide={true}>
                    <div className="pane-title">成员</div>

                    <div className="invite-members-area">
                        <div className="title">成员邀请链接</div>
                        <Spin tip="正在获取邀请码..." spinning={this.state.spinning}>
                            <div className="input-btn" onClick={this.copyText} ref={this.copyMsgAreaRef}>
                                <Input.Search
                                    value={this.state.inviteLink}
                                    placeholder="请点击右侧复制链接按钮，生成并复制链接"
                                    enterButton="复制链接"
                                    size="large"
                                    onSearch={value => console.log(value)}
                                />
                            </div>
                        </Spin>
                        <div className="in-date">链接有效期7天</div>
                    </div>
                    <div className="member-list-area">
                        <div className="title">成员列表</div>
                        <div className="list">
                            {this.state.members.map((user, key) => {
                                return (
                                    <div key={key} className="item">
                                        <span className="user-avator">
                                            <Avatar size={24} src={user.avatar} icon="user" />
                                        </span>
                                        <span className="name">{user.nickname}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Scrollbars>
            </div>
        );
    }
}
