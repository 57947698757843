import * as React from 'react';
import { Form, Input, Button } from 'antd';
import { IWechatUserArgs } from 'neka-common';
import { getErrorInfoName, showErrorNotification } from '../../../common/ui-utils';
import { ExceptionType, formConstant } from '../../constant';
import { localStoragePrincipal, RouteUrls } from '../../../core/app-config';
import { apis } from '../../../core/server-apis';
import { logIn } from '../../../store/app-redux';
import { doRedirectPath, filterNumberInput, getNameErrorTips } from '../../account-util';
import { IBindAccount, setPwLoginCounter } from '../../store/account-redux';
import SendSMSCode from '../common/account-send-smscode';
import { PassworInput, TextInput } from '../common/browser-forbid-remeber';

const PhoneInputPreFix = () => {
    return (
        <span>
            +86
            <span className="prefix-divider" />
        </span>
    );
};

class NewAccount extends React.Component<IBindAccount, {}> {
    constructor(props: IBindAccount) {
        super(props);
    }

    state = {
        confirmDirty: false,
    };

    handleConfirmBlur: React.FocusEventHandler<HTMLInputElement> = e => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    compareToFirstPassword = (rule: any, value: any, callback: any) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback(formConstant.validateRules.confirmPasswordMatchMassage);
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule: any, value: any, callback: any) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirmPassword'], { force: true });
        }
        callback();
    };

    register: React.EventHandler<React.MouseEvent> = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log(values);
            if (!err) {
                console.log('register');
                if (this.props.wechatInfo) {
                    this.registerNewMobile({
                        unionId: this.props.wechatInfo.unionId,
                        ...values,
                        nickname: values.nickname.trim(),
                    });
                }
            }
        });
    };

    registerNewMobile = async (info: IWechatUserArgs) => {
        try {
            const principal = await apis.account.register(info);
            // 注册 成功跳到list页面以及保存session
            this.props.dispatch(logIn(principal));
            this.props.dispatch(setPwLoginCounter(0));
            localStoragePrincipal.save(principal);
            // this.props.history.push(RouteUrls.dashboard);
            doRedirectPath(this.props.history);
        } catch (e) {
            let infoName: string = getErrorInfoName(e);
            if (infoName === ExceptionType.MobileExistsException) {
                this.props.form.setFields({
                    mobile: {
                        value: this.props.form.getFieldValue('mobile'),
                        errors: [new Error('绑定的手机号已经存在')],
                    },
                });
            } else if (infoName === ExceptionType.SmsCodeIncorrectException) {
                this.props.form.setFields({
                    verifyCode: {
                        value: this.props.form.getFieldValue('verifyCode'),
                        errors: [new Error(formConstant.validateRules.smsCodeInputErrorMassage)],
                    },
                });
            } else {
                showErrorNotification(e);
            }
        }
    };

    validateName = (rule: any, value: string, callback: any) => {
        if (this.props.wechatInfo && this.props.wechatInfo.nickname === value) {
            callback();
        } else {
            callback(getNameErrorTips(value.trim()));
        }
    };

    render() {
        let { getFieldDecorator, getFieldValue } = this.props.form;
        return (
            <Form onSubmit={this.register} className="bind-form">
                <PassworInput />
                <Form.Item>
                    {getFieldDecorator('mobile', {
                        normalize: filterNumberInput,
                        validateTrigger: formConstant.validateTrigger,
                        rules: [
                            { required: true, message: formConstant.validateRules.mobileNullErrorMassage },
                            {
                                pattern: formConstant.validateRules.mobileRegexp,
                                message: formConstant.validateRules.mobileMatchRegexpMassage,
                            },
                        ],
                    })(
                        <Input
                            prefix={<PhoneInputPreFix />}
                            type="tel"
                            placeholder="请输入手机号"
                            className="bind-account-input"
                            // onChange={this.inputBlur}
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('verifyCode', {
                        normalize: filterNumberInput,
                        validateTrigger: formConstant.validateTrigger,
                        rules: [
                            { required: true, message: formConstant.validateRules.smsCodeNullErrorMassage },
                            {
                                pattern: formConstant.validateRules.smsCodeRegexp,
                                message: formConstant.validateRules.smsCodeMatchRegexpMassage,
                            },
                        ],
                    })(<Input type="tel" placeholder="请输入验证码" className="nw-smscode-input" />)}
                    <SendSMSCode mobile={getFieldValue('mobile')} />
                </Form.Item>
                <TextInput />
                <Form.Item>
                    {getFieldDecorator('password', {
                        validateTrigger: formConstant.validateTrigger,
                        rules: [
                            { required: true, message: formConstant.validateRules.passwordNullErrorMassage },
                            {
                                pattern: formConstant.validateRules.passwordRegexp,
                                message: formConstant.validateRules.passwordMatchRegexpMassage,
                            },
                            { validator: this.validateToNextPassword },
                        ],
                    })(<Input type="password" placeholder="密码" />)}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('confirmPassword', {
                        validateTrigger: formConstant.validateTrigger,
                        validateFirst: true,
                        rules: [
                            { required: true, message: formConstant.validateRules.passwordNullErrorMassage },
                            {
                                pattern: formConstant.validateRules.passwordRegexp,
                                message: formConstant.validateRules.passwordMatchRegexpMassage,
                            },
                            { validator: this.compareToFirstPassword },
                        ],
                    })(<Input type="password" placeholder="二次确认密码" onBlur={this.handleConfirmBlur} />)}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('nickname', {
                        validateTrigger: formConstant.validateTrigger,
                        validateFirst: true,
                        rules: [
                            { required: true, message: formConstant.validateRules.nicknameNullErrorMassage },
                            { validator: this.validateName },
                        ],
                        initialValue: this.props.wechatInfo ? this.props.wechatInfo.nickname : '',
                    })(<Input type="text" placeholder="输入名字" className="nw-nickname" />)}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="nw-login-form-button">
                        免费注册
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default Form.create({ name: 'newAccount' })(NewAccount);
