import { Select } from 'antd';
import React from 'react';
import { getOptionControlValue, IMultiValueDescriptor } from './style-helper';

interface IProps extends IMultiValueDescriptor<string> {
    /** 选中颜色后的回调函数. `color` 为 `undefined` 表示点击了 `自动` 按钮. */
    onValueChanged(value?: string): void;
}

interface IFontDescriptor {
    code: string;
    name: string;
}

// Arial, Verdana, Tahoma, Trebuchet MS, Georgia
const fontDescriptors: Array<IFontDescriptor> = [
    { code: 'Arial', name: 'Arial' },
    { code: 'Verdana', name: 'Verdana' },
    { code: 'Tahoma', name: 'Tahoma' },
    { code: 'Georgia', name: 'Georgia' },
];

/** 字体选择器. */
export class FontNamePicker extends React.Component<IProps, {}> {
    public constructor(props: IProps) {
        super(props);
    }

    private static readonly multiOptionValue = '-';
    private static readonly defaultOptionValue = '默认';

    public render() {
        const { isMulti, areSame, value, valueSource } = this.props;

        const normalized = getOptionControlValue(
            isMulti,
            areSame,
            valueSource,
            value,
            FontNamePicker.multiOptionValue,
            FontNamePicker.defaultOptionValue
        );

        const { Option } = Select;
        return (
            <Select<string | undefined> style={{ width: 120 }} value={normalized} onChange={this.handleValueChanged}>
                {isMulti && (
                    <Option value={FontNamePicker.multiOptionValue}>
                        <span style={{ fontStyle: 'italic' }}>-</span>
                    </Option>
                )}
                <Option value={FontNamePicker.defaultOptionValue}>默认</Option>
                {fontDescriptors.map(font => {
                    return (
                        <Option key={font.code} value={font.code}>
                            {font.name}
                        </Option>
                    );
                })}
            </Select>
        );
    }

    private handleValueChanged = (value: string | undefined) => {
        if (value !== FontNamePicker.multiOptionValue) {
            this.props.onValueChanged(value !== FontNamePicker.defaultOptionValue ? value : undefined);
        }
    };
}
