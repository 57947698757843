import { IPrincipal } from 'neka-common';
import Scrollbars from 'react-custom-scrollbars';
import React, { Component, RefObject } from 'react';
import { IDispatch } from '../../../../common/redux-utils';
import { IDiscussionView } from '../../../discussion-model';
import MindDiscussionItems from './mind-discussion-items';

interface IProps extends IDispatch {
    discussionView: IDiscussionView;
    principal?: IPrincipal;
    docOwnerId: string;
}

/** 代表 {@link MindDiscussionPane} 的一个 帖子的列表. */
export default class MindDiscussionList extends React.Component<IProps, {}> {
    protected constructor(props: IProps) {
        super(props);
    }

    public render() {
        const { discussionView, principal, dispatch, docOwnerId } = this.props;
        return (
            <div className="mind-discussion-list">
                {discussionView.discussions &&
                    discussionView.discussions.map(discussion => {
                        return (
                            <MindDiscussionItems
                                principal={principal}
                                hasDiscussion={discussionView.hasDiscussion}
                                key={discussion.id}
                                discussion={discussion}
                                docOwnerId={docOwnerId}
                                dispatch={dispatch}
                                docItemId={discussionView.docItemId}
                            />
                        );
                    })}
            </div>
        );
    }
}
