import * as React from 'react';

export const PassworInput = () => {
    return (
        <input
            type="password"
            style={{
                display: 'block',
                position: 'absolute',
                zIndex: -1,
                opacity: 0,
                width: '0px',
                height: '0px',
            }}
        />
    );
};

export const TextInput = () => {
    return (
        <input
            type="tel"
            style={{
                display: 'block',
                position: 'absolute',
                zIndex: -1,
                opacity: 0,
                width: '0px',
                height: '0px',
            }}
        />
    );
};
