import { IUser } from './account.data';
import { IPrincipal } from './auth.data';
import { generateId } from './entity-common';
import { IMindDocument, IMindItemEntity, MindId, StructureKind, StructureKinds } from './mind.data';

export type IMindItemSampleFields = Pick<IMindItemEntity, 'id' | 'text' | 'childIds' | 'styles'>;

export interface IMindDocumentSampleOptions extends Partial<Exclude<IMindDocument, 'title'>> {
    title: string;
    creatorId: string;
    structureKind?: StructureKind;
}

export interface IMindItemSampleOptions {
    structureKind?: StructureKind;
}

export interface IMindItemSample {
    items: IMindItemSampleFields[];
    rootId: MindId;
}

/** 当 option 不需要时. */
export const EmptyDocumentSampleOption: IMindDocumentSampleOptions = {
    title: 'untitled',
    creatorId: 'user-id-not-existed',
};

export function createSampleDocumentFor(
    sampleItems: IMindItemSample,
    options: IMindDocumentSampleOptions = EmptyDocumentSampleOption
): IMindDocument {
    const document = patchDocumentSample(options);
    const patchedItems = patchItemSample(sampleItems, document.id, options);

    return {
        ...document,
        items: patchedItems,
        rootId: sampleItems.rootId,
    };
}

export function patchDocumentSample(options: IMindDocumentSampleOptions): IMindDocument {
    const documentId = generateId();
    const now = new Date();
    let { title, creatorId } = options;
    title = title ? title : '未命名';
    return {
        id: documentId,
        title,
        items: [],
        rootId: '',
        ownerId: creatorId,
        creatorId: creatorId,
        createdAt: now,
        lastUpdaterId: creatorId,
        lastUpdatedAt: now,
        ...options,
    };
}

export function patchItemSample(
    itemSample: IMindItemSample,
    documentId: string,
    options: IMindDocumentSampleOptions
): IMindItemEntity[] {
    const { items, rootId } = itemSample;
    const { creatorId } = options;
    const now = new Date();

    const result: IMindItemEntity[] = items.map(item => ({
        documentId,
        creatorId: creatorId,
        createdAt: now,
        lastUpdaterId: creatorId,
        lastUpdatedAt: now,
        ...item,
    }));
    return result;
}

const DefaultItemSampleOptions: IMindItemSampleOptions = { structureKind: StructureKinds.LogicRight };

/** 仅包含根节点的文档. */
export function rootOnlySample(options: IMindItemSampleOptions = DefaultItemSampleOptions): IMindItemSample {
    const rootId = 'ROOT';
    const structureKind = options.structureKind ? options.structureKind : StructureKinds.LogicRight;
    const items: IMindItemSampleFields[] = [
        {
            id: 'ROOT',
            text: 'ROOT',
            childIds: null,
            styles: { structureKind },
        },
    ];
    return { items, rootId };
}

// noinspection JSUnusedGlobalSymbols
/** 仅包含根节点及一个子结点的文档. */
export function oneChildSample(options: IMindItemSampleOptions = DefaultItemSampleOptions): IMindItemSample {
    const rootId = 'ROOT';
    const structureKind = options.structureKind ? options.structureKind : StructureKinds.LogicRight;
    const items: IMindItemSampleFields[] = [
        {
            id: rootId,
            text: 'ROOT',
            childIds: ['NODE-1'],
            styles: { structureKind },
        },
        {
            id: 'NODE-1',
            text: 'NODE-1',
            childIds: null,
            styles: null,
        },
    ];
    return { items, rootId };
}

/** 包含根结点和两个子结点的文档. */
export function miniSample(options: IMindItemSampleOptions = DefaultItemSampleOptions): IMindItemSample {
    const rootId = 'ROOT';
    const structureKind = options.structureKind ? options.structureKind : StructureKinds.LogicRight;
    const items: IMindItemSampleFields[] = [
        {
            id: rootId,
            text: 'ROOT',
            childIds: ['NODE-1', 'NODE-2'],
            styles: { structureKind },
        },
        {
            id: 'NODE-1',
            text: 'NODE-1',
            childIds: ['NODE-1-1', 'NODE-1-2', 'NODE-1-3'],
            styles: null,
        },
        {
            id: 'NODE-1-1',
            text: 'NODE-1-1',
            childIds: null,
            styles: null,
        },
        {
            id: 'NODE-1-2',
            text: 'NODE-1-2',
            childIds: null,
            styles: null,
        },
        {
            id: 'NODE-1-3',
            text: 'NODE-1-3',
            childIds: null,
            styles: null,
        },
        {
            id: 'NODE-2',
            text: 'NODE-2',
            childIds: null,
            styles: null,
        },
    ];
    return { items, rootId };
}

/** 一个带有工作场景的样例. */
export function workSample(options: IMindItemSampleOptions = DefaultItemSampleOptions): IMindItemSample {
    const rootId = '0';
    const structureKind = options.structureKind ? options.structureKind : StructureKinds.LogicRight;
    const items: IMindItemSampleFields[] = [
        {
            id: rootId,
            text: ' 脑问',
            childIds: ['1', '2'],
            styles: { structureKind: structureKind },
        },
        {
            id: '1',
            text: '节奏',
            childIds: ['1-1', '1-2', '1-3'],
            styles: null,
        },
        {
            id: '1-1',
            text: '2019 上半年',
            childIds: ['1-1-1', '1-1-2', '1-1-3'],
            styles: null,
        },
        {
            id: '1-1-1',
            text: '可以绘制基本的脑图',
            childIds: null,
            styles: null,
        },
        {
            id: '1-1-2',
            text: '针对要素的讨论完成',
            childIds: null,
            styles: null,
        },
        {
            id: '1-1-3',
            text: '有群组与模板管理',
            childIds: null,
            styles: null,
        },
        {
            id: '1-2',
            text: '2019 下半年',
            childIds: ['1-2-1', '1-2-2'],
            styles: null,
        },
        {
            id: '1-2-1',
            text: '试运营',
            childIds: null,
            styles: null,
        },
        {
            id: '1-2-2',
            text: '支持多种布局方式',
            childIds: ['1-2-2-1', '1-2-2-2', '1-2-2-3'],
            styles: { structureKind: StructureKinds.LogicRight },
        },
        {
            id: '1-2-2-1',
            text: '支持 Logic Left',
            childIds: null,
            styles: null,
        },
        {
            id: '1-2-2-2',
            text: '支持 Fish-bone',
            childIds: null,
            styles: null,
        },
        {
            id: '1-2-2-3',
            text: '支持 OrgChart',
            childIds: null,
            styles: null,
        },
        {
            id: '1-3',
            text: '2020',
            childIds: ['1-3-1'],
            styles: null,
        },
        {
            id: '1-3-1',
            text:
                '正式运营, 需要做较大力度的市场推广活动, \n并且需要专家配合制作模板. 我不嫌啰嗦地说这么长的话只是为了测试一下绘制长文本是不是正常啊, 程序狗的心思你懂吗?',
            childIds: ['1-3-1-1'],
            styles: null,
        },
        {
            id: '1-3-1-1',
            text: '再来一个也是很长很长的标题看看彻底超出边界之后有什么不行的地方是不是有那么一点点点的小变态',
            childIds: ['1-3-1-1-1', '1-3-1-1-2'],
            styles: null,
        },
        {
            id: '1-3-1-1-1',
            text: '这之后来个短的会发生什么呢',
            childIds: null,
            styles: null,
        },
        {
            id: '1-3-1-1-2',
            text: '好',
            childIds: null,
            styles: null,
        },
        {
            id: '2',
            text: '人员',
            childIds: ['2-1', '2-2', '2-3', '2-4', '2-5', '2-6', '2-7', '2-8', '2-9'],
            styles: { structureKind: StructureKinds.OrgDown },
        },
        {
            id: '2-1',
            text: '姚健',
            childIds: null,
            styles: null,
        },
        {
            id: '2-2',
            text: '张波',
            childIds: null,
            styles: null,
        },
        {
            id: '2-3',
            text: '陶逸兴',
            childIds: null,
            styles: null,
        },
        {
            id: '2-4',
            text: '叶彬成',
            childIds: null,
            styles: null,
        },
        {
            id: '2-5',
            text: '庄亮',
            childIds: null,
            styles: null,
        },
        {
            id: '2-6',
            text: '宋兰',
            childIds: null,
            styles: null,
        },
        {
            id: '2-7',
            text: '陈浩',
            childIds: null,
            styles: null,
        },
        {
            id: '2-8',
            text: '键盘侠',
            childIds: null,
            styles: null,
        },
        {
            id: '2-9',
            text: '破坏狂',
            childIds: null,
            styles: null,
        },
    ];
    return { items, rootId };
}

/** 批量生成结点. */
export function generateBulkSample(
    width: number,
    depth: number,
    maxTextLength: number,
    options: IMindItemSampleOptions = DefaultItemSampleOptions
): IMindItemSample {
    if (width < 0 || depth < 0) throw new Error('Both width and depth should be greater than 0.');

    const structureKind = options.structureKind ? options.structureKind : StructureKinds.LogicRight;
    const rootId = generateId();
    const root: IMindItemSampleFields = {
        id: rootId,
        text: generateText(maxTextLength * Math.random()),
        childIds: null,
        styles: { structureKind },
    };
    const items = generateSubtree(
        root,
        width,
        depth,
        () => ({
            id: generateId(),
            text: generateText(maxTextLength * Math.random()),
            childIds: null,
            styles: null,
        }),
        (current, children) => {
            current.childIds = children.map(x => x.id);
        }
    );
    return { items, rootId };
}

// ---------------------------------------------------------
// 后台 WebApi 未完成前的模拟方法
// ---------------------------------------------------------
export function createSampleDocumentList(principal: IPrincipal): IMindDocument[] {
    function fakeMindDocumentCreator(): IUser {
        const now = new Date();
        const creator: IUser = {
            id: generateId(),
            name: 'fake-user',
            nickname: 'fake-user',
            email: 'fake-user@example.com',
            mobile: '13300000000',
            password: 'no-such-user-1',
            passwordFormat: 'clear',
            createdAt: now,
            lastUpdatedAt: now,
            lastLoginAt: now,
            wechatId: '',
            avatar: '',
        };
        return creator;
    }

    const zhangSan = { ...fakeMindDocumentCreator(), name: '张三' };
    const liSi = { ...fakeMindDocumentCreator(), name: '李四' };
    const me = { ...fakeMindDocumentCreator(), id: principal.userid, name: principal.nickname };

    const doc1 = createSampleDocumentFor(miniSample(), {
        title: '张三的文档',
        creatorId: zhangSan.id,
        createdAt: new Date(2019, 2, 15),
        lastUpdatedAt: new Date(2019, 2, 20),
        ownerId: zhangSan.id,
    });

    const doc2 = createSampleDocumentFor(workSample(), {
        title: '张三创建后转给李四的文档',
        createdAt: new Date(2018, 0, 1),
        lastUpdatedAt: new Date(2018, 2, 20),
        ownerId: liSi.id,
        creatorId: zhangSan.id,
    });

    const doc3 = createSampleDocumentFor(rootOnlySample(), {
        title: '我的文档-1',
        createdAt: new Date(2019, 2, 22),
        lastUpdatedAt: doc2.createdAt,
        ownerId: me.id,
        creatorId: me.id,
    });

    return [doc1, doc2, doc3];
}

/**
 *  生成一组树结点.
 */
export function generateSubtree<T extends {}>(
    root: T,
    width: number,
    depth: number,
    onCreate: (parent: T) => T,
    onChildrenCreated?: (current: T, children: T[]) => void
): T[] {
    // if (width <= 0 || depth <= 0) throw new Error('Both width and depth should be greater than 0.');
    if (root === undefined || root === null) throw new Error('The root should be an object.');

    const allNodes: T[] = [root];

    if (width > 0 && depth > 0) {
        generateChildren(1, root, width, depth, allNodes, onCreate, onChildrenCreated);
    }

    return allNodes;
}

function generateChildren<T>(
    currentDepth: number,
    parent: T,
    width: number,
    depth: number,
    allNodes: T[],
    onCreate: (parent: T) => T,
    onChildrenCreated?: (current: T, children: T[]) => void // the depth of the child
): T[] {
    const children: T[] = [];
    for (let i = 0; i < width; i++) {
        const child = generateNode(currentDepth, parent, width, depth, allNodes, onCreate, onChildrenCreated);
        children.push(child);
    }
    if (onChildrenCreated) onChildrenCreated(parent, children);
    return children;
}

function generateNode<T>(
    currentDepth: number,
    parent: T,
    width: number,
    depth: number,
    allNodes: T[],
    onCreate: (parent: T) => T,
    onChildrenCreated?: (current: T, children: T[]) => void
): T {
    if (currentDepth > depth) throw new Error(`The depth [${currentDepth}] is greater than the max depth [${depth}]`);

    const current = onCreate(parent);
    allNodes.push(current);

    if (currentDepth < depth) {
        generateChildren(currentDepth + 1, current, width, depth, allNodes, onCreate, onChildrenCreated);
    }
    return current;
}

function generateText(length: number) {
    // noinspection SpellCheckingInspection
    const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    // Repeat `chars` when maxLength > charSet.length
    const duplications = Math.floor(length / validChars.length) + 1;
    const charSet = validChars.repeat(duplications);

    let text = '';
    for (let i = 0; i < length; i++) {
        // join with the random char in charSet
        text += charSet.charAt(Math.floor(Math.random() * charSet.length));
    }

    return text;
}
