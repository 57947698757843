/** 密码格式 */
import { IObjectWithId } from './entity-common';

export enum PasswordFormats {
    Unknown = 'Unknown',
    Clear = 'Clear',
}

/** 代表一个用户. */
export interface IUser extends IObjectWithId<string> {
    name: string;
    nickname: string;
    email: string;
    mobile: string;
    password: string;
    passwordFormat: string;
    createdAt: Date;
    lastUpdatedAt: Date;
    lastLoginAt?: Date;
    wechatId?: string;
    avatar: string;
}

// TODO: Avatar should NOT be required?
type BriefUserRequiredFields = 'id' | 'nickname' | 'avatar';
export type IBriefUser = Pick<IUser, BriefUserRequiredFields> & Partial<Exclude<IUser, BriefUserRequiredFields>>;

export const SYS_ANONYMOUS_USER_ID = '00000000-0000-0000-0000-000000000000';
export const SYS_ADMIN_USER_ID = '00000000-0000-0000-0000-000000000001';

/** 代表一个角色. */
export interface IRole extends IObjectWithId<string> {
    name: string;
    createdAt: Date;
    lastUpdatedAt: Date;
}

// constants of predefined roles, created in database initialization scripts.
export const SYS_ADMINS_ROLE_ID = '00000000-0000-0000-0000-000000000001';
export const SYS_ADMINS_ROLE_NAME = 'sysadmins';

export interface IMembership extends IObjectWithId<string> {
    userId: string;
    roleId: string;
}

export function assignUser<TTarget extends IUser>(target: TTarget, source: IUser): TTarget {
    target.id = source.id;
    target.name = source.name;
    target.nickname = source.nickname;
    target.email = source.email;
    target.mobile = source.mobile;
    target.password = source.password;
    target.passwordFormat = source.passwordFormat;
    target.lastLoginAt = source.lastLoginAt;
    target.createdAt = source.createdAt;
    target.lastUpdatedAt = source.lastUpdatedAt;
    target.wechatId = source.wechatId;
    return target;
}
