import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';
import { LineKind, LineKinds } from 'neka-common';
import { getStyleDisplayValue, IMultiValueDescriptor } from './style-helper';

interface IProps extends IMultiValueDescriptor<LineKind> {
    onValueChanged(value?: LineKind): void;
}

const useDefault = 'use-default' as 'use-default';

/** 线型选择器. */
export class LineKindPicker extends React.Component<IProps, {}> {
    public constructor(props: IProps) {
        super(props);
    }

    public render() {
        const displayValue = getStyleDisplayValue(this.props, useDefault);

        return (
            <Radio.Group value={displayValue} buttonStyle="solid" onChange={this.handleValueChange}>
                <Radio.Button value={useDefault}>默认</Radio.Button>
                <Radio.Button value={LineKinds.poly}>折线</Radio.Button>
                <Radio.Button value={LineKinds.bezier}>曲线</Radio.Button>
            </Radio.Group>
        );
    }

    private handleValueChange = (event: RadioChangeEvent) => {
        const value = event.target.value !== useDefault ? event.target.value : undefined;
        if (this.props.onValueChanged) {
            this.props.onValueChanged(value);
        }
    };
}
