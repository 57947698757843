import { BusinessException, protectProperty } from '../common';

export class MindDocNotExistsException extends BusinessException {
    constructor(public readonly docId: string) {
        super(`doc of id [${docId}] not exists.`, `该文档不存在或已被删除 [${docId}].`);
        protectProperty(this, 'docId');
    }
}

export class MindEntryNotExistsException extends BusinessException {
    constructor(public readonly entryId: string) {
        super(`entry of id [${entryId}] not exists.`, `主题 [${entryId}] 不存在.`);
        protectProperty(this, 'entryId');
    }
}

export class RootEntityExistsException extends BusinessException {
    constructor(public readonly docId: string) {
        super(`root of id [${docId}] already exists.`, `文档 [${docId}] 根结点已经存在.`);
        protectProperty(this, 'docId');
    }
}

export class InviteCodeNotExistsException extends BusinessException {
    constructor(public readonly inviteCode: string) {
        super(`invite code [${inviteCode}] not exists.`, `邀请码 [${inviteCode}] 不存在.`);
        protectProperty(this, 'inviteCode');
    }
}

export class InviteCodeExpiredException extends BusinessException {
    constructor(public readonly inviteCode: string) {
        super(`invite code [${inviteCode}] expired.`, `邀请码 [${inviteCode}] 已过期.`);
        protectProperty(this, 'inviteCode');
    }
}

export class CollaboratorExistsException extends BusinessException {
    constructor(public readonly identity: string) {
        super(`[${identity}] already exists.`, `[${identity}] 已存在.`);
        protectProperty(this, 'identity');
    }
}

export class MindDocumentAccessException extends BusinessException {
    constructor(public readonly userId: string) {
        super(`[${userId}] is not a collaborator of this document.`, `禁止访问，[${userId}]不是该文档成员`);
    }
}
