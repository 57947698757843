import { Dropdown, Icon } from 'antd';
import React from 'react';
import { ColorResult, CompactPicker } from 'react-color';
import { getStyleDisplayValue, IMultiValueDescriptor, StyleValueSources } from './style-helper';

import './color.picker.less';

interface IProps extends IMultiValueDescriptor<string> {
    /** 选中颜色后的回调函数. `color` 为 `undefined` 表示点击了 `自动` 按钮. */
    onValueChanged(value?: string): void;
}

const useDefault = 'use-default' as 'use-default';

/** 颜色选择器. */
export class ColorPicker extends React.Component<IProps, {}> {
    public constructor(props: IProps) {
        super(props);
    }

    public render() {
        const { areSame, valueSource, value } = this.props;
        const displayValue = getStyleDisplayValue(this.props, 'white');

        // TODO: 如果 boxText 为 '' , 则其位置会向上偏移, 原因未知. 所以强制在其中填入文字并设置字体颜色与底色一致, 从而对用户隐藏
        let boxText = '';
        if (areSame) {
            if (valueSource === StyleValueSources.inherited) {
                boxText = '默认';
            }
        } else {
            boxText = ' - ';
        }

        const boxStyle = { backgroundColor: displayValue };
        if (boxText === '') {
            boxText = '---';
            Object.assign(boxStyle, { color: displayValue });
        }

        const popup = (
            <div className="mind-color-picker-popup">
                <div className="button-container">
                    <button onClick={this.handleDefaultValueButtonClick}>自动</button>
                </div>
                <CompactPicker color={displayValue} onChangeComplete={this.handleColorChangeComplete} />
            </div>
        );

        return (
            <div className="mind-color-picker">
                <Dropdown trigger={['click']} overlay={popup}>
                    <div>
                        <span className="color-box" style={boxStyle}>
                            {boxText ? boxText : '-'}
                        </span>
                        <Icon type="down" />
                    </div>
                </Dropdown>
            </div>
        );
    }

    private handleDefaultValueButtonClick: React.MouseEventHandler = event => {
        this.props.onValueChanged(undefined);
    };

    private handleColorChangeComplete = (value: ColorResult) => {
        this.props.onValueChanged(value ? value.hex : undefined);
    };
}
