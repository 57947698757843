// *********************************************************
// 提供一组针对脑图的树结构的辅助方法.
// *********************************************************

import { NotImplementedException } from 'neka-common';
import { IKeyGet } from '../common/collections';
import { getItem, IMindItem, ItemGet, MindId, MindItemNotFoundException } from './mind-models';

/**
 * 遍历过程中结点的处理函数. 返回除 `undefined` 之外的值将中止遍历, 并以该值作为遍历函数的返回值.
 * 该处理函数接收以下参数:
 * @param item 正被处理的结点.
 * @param index 当前结点的序号.
 * @param id 当前结点的 {@link IMindItem.id}.
 */
export type Visit<T, R> = (item: T, index: number | undefined, id: MindId) => R | void;
export type VisitEx<T, R> = (item: T, index: number | undefined, id: MindId, level: number) => R | void;

/** item 必须至少包含 {@link IMindItem.childIds} 属性. 在遍历时可以使用泛型参数以限制可访问的 {@link IMindItem} 的属性集. */
export type WithChildren = Pick<IMindItem, 'id' | 'childIds'>;

/** 供遍历函数定义其参数使用, 表明 item 至少包含 {@link IMindItem.parentId} 属性. */
export type WithParent = Pick<IMindItem, 'id' | 'parentId'>;

/** 深度优先遍历, 先处理子结点, 再处理当前结点, 同一结点的子结点按下标从小到大的顺序处理. */
export function visitDeepPostLeft<T extends WithChildren, R>(items: ItemGet<T>, id: MindId, visit: VisitEx<T, R>) {
    function recursive(currentId: MindId, currentIndex: number | undefined, level: number): R | void {
        const current = getItem(items, currentId, true);
        const { childIds } = current;

        if (childIds && childIds.length > 0) {
            for (let i = 0; i < childIds.length; i++) {
                const childResult = recursive(childIds[i], i, level + 1);
                if (childResult !== undefined) return childResult;
            }
        }

        return visit(current, currentIndex, currentId, level);
    }

    return recursive(id, undefined, 0);
}

/** 深度优先遍历, 先处理子结点, 再处理当前结点, 同一结点的子结点按下标从大到小的顺序处理. */
export function visitDeepPostRight<T extends WithChildren, R>(items: ItemGet<T>, id: MindId, visit: VisitEx<T, R>) {
    function recursive(currentId: MindId, currentIndex: number | undefined, level: number): R | void {
        const current = getItem(items, currentId, true);
        const { childIds } = current;

        if (childIds && childIds.length > 0) {
            for (let i = childIds.length - 1; i >= 0; i--) {
                const childResult = recursive(childIds[i], i, level + 1);
                if (childResult !== undefined) return childResult;
            }
        }

        return visit(current, currentIndex, currentId, level);
    }

    return recursive(id, undefined, 0);
}

export function visitDeepPreLeft<T extends WithChildren, R>(
    items: ItemGet<T>,
    id: MindId,
    visit: VisitEx<T, R>
): void | R {
    function recursive(currentId: MindId, currentIndex: number | undefined, level: number): R | void {
        const current = getItem(items, currentId, true);
        const { childIds } = current;

        const selfResult = visit(current, currentIndex, currentId, level);
        if (selfResult !== undefined) return selfResult;

        if (childIds && childIds.length > 0) {
            for (let i = 0; i < childIds.length; i++) {
                const childResult = recursive(childIds[i], i, level + 1);
                if (childResult !== undefined) return childResult;
            }
        }
    }

    return recursive(id, undefined, 0);
}

export function visitDeepPreRight<T extends WithChildren, R>(
    items: ItemGet<T>,
    id: MindId,
    visit: VisitEx<T, R>
): void | R {
    function recursive(currentId: MindId, currentIndex: number | undefined, level: number): R | void {
        const current = getItem(items, currentId, true);
        const { childIds } = current;

        const selfResult = visit(current, currentIndex, currentId, level);
        if (selfResult !== undefined) return selfResult;

        if (childIds && childIds.length > 0) {
            for (let i = childIds.length - 1; i >= 0; i--) {
                const childResult = recursive(childIds[i], i, level + 1);
                if (childResult !== undefined) return childResult;
            }
        }
    }

    return recursive(id, undefined, 0);
}

export function visitUpward<T extends WithParent, R>(items: ItemGet<T>, id: MindId, visit: VisitEx<T, R>): void | R {
    let index = 0;
    let currentId: MindId | undefined = id;
    let level = 0;
    while (currentId !== undefined) {
        let current: T = getItem(items, currentId, true);

        const visitResult = visit(current, index, currentId, level);
        if (visitResult !== undefined) return visitResult;

        index++;
        level++;
        currentId = current.parentId;
    }
}

export function getUpwardPath<T extends WithParent>(items: ItemGet<T>, id: MindId): Array<T> {
    const ids: Array<T> = [];
    visitUpward(items, id, current => {
        ids.push(current);
    });
    return ids;
}

/** 返回一个数组, 包含结点的祖先结点, 当结点非根节点时, 返回值中首个元素为该结点的父结点. */
export function getAncestors<T extends WithParent>(items: ItemGet<T>, id: MindId): Array<T> {
    const self = items.get(id);
    if (self === undefined) throw new MindItemNotFoundException(id);
    if (self.parentId === undefined) return [];
    return getUpwardPath(items, self.parentId);
}

/** 如果 `id` 是 `ancestorId` 的直接或间接子结点, 则返回 true, 否则返回 false. */
export function isDescendantOf<T extends WithParent>(items: ItemGet<T>, id: MindId, ancestorId: MindId): boolean {
    const self = getItem(items, id, true);
    if (!self.parentId) return false;
    const visitResult = visitUpward(items, self.parentId, x => {
        if (x.id === ancestorId) {
            return true;
        }
    });
    return visitResult === true;
}

export function getDownwardPath<T extends WithParent>(items: ItemGet<T>, id: MindId): Array<T> {
    return getUpwardPath(items, id).reverse();
}

/** 针对一组结点, 返回一个数组, 包括传入的结点及每个结点的祖先结点. 如果传入的结点中有共同的祖先结点, 则在结果中只会出现一次. */
export function getCommonUpwards<T extends WithParent>(items: ItemGet<T>, sourceIds: Readonly<MindId[]>): Array<T> {
    if (!sourceIds) return [];

    let maxPathLength = 0;

    // 创建一个数组, 其中每个元素是一个路径数组
    const paths: Array<Array<T>> = sourceIds.map(id => {
        const path = getDownwardPath(items, id);
        maxPathLength = Math.max(path.length, maxPathLength);
        return path;
    });

    const cache = new Set<T>(); // 避免加入重复元素
    const result = new Array<T>();

    // 遍历每一个 path, 并将每个 path 中序号相同的元素加入到结果中, 因为每个 path 都是从根开始的, 所以结果中靠近根结点的结点总是排在数组的前面.
    for (let i = 0; i < maxPathLength; i++) {
        paths.forEach(p => {
            if (i < p.length) {
                const item = p[i];
                if (!cache.has(item)) {
                    cache.add(item);
                }
            }
        });
    }

    return result;
}

export function getCommonAncestor<T extends WithParent>(items: ItemGet<T>, sourceIds: Readonly<MindId[]>): T {
    throw new NotImplementedException();
}

/** 如果传入的一组结点中有结点是其他结点的子孙结点, 则去除它. */
export function getSubtreeRootIds<T extends WithParent>(
    items: ItemGet<T>,
    sourceIds: ReadonlyArray<MindId>
): Array<MindId> {
    return sourceIds.filter(id => {
        const ancestorIds = getAncestors(items, id).map(x => x.id);
        const isDescendant = ancestorIds.find(x => sourceIds.indexOf(x) >= 0);
        return !isDescendant;
    });
}
