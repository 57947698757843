import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';
import { StructureKind, StructureKinds } from 'neka-common';
import { getStyleDisplayValue, IMultiValueDescriptor } from './style-helper';

interface IProps extends IMultiValueDescriptor<StructureKind> {
    onValueChanged(value?: StructureKind): void;
}

const useDefault = 'use-default' as 'use-default';

/** 布局方式选择器. */
export class StructureKindPicker extends React.Component<IProps, {}> {
    public constructor(props: IProps) {
        super(props);
    }

    public render() {
        const displayValue = getStyleDisplayValue(this.props, useDefault);

        return (
            <Radio.Group value={displayValue} buttonStyle="solid" onChange={this.handleValueChange}>
                <Radio.Button value={useDefault}>默认</Radio.Button>
                <Radio.Button value={StructureKinds.LogicRight}>向右</Radio.Button>
                <Radio.Button value={StructureKinds.OrgDown}>向下</Radio.Button>
            </Radio.Group>
        );
    }

    private handleValueChange = (event: RadioChangeEvent) => {
        const value = event.target.value !== useDefault ? event.target.value : undefined;
        if (this.props.onValueChanged) {
            this.props.onValueChanged(value);
        }
    };
}
