import { Icon, message } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { IThunkDispatch } from '../../common/redux-utils';
import { showErrorDialog } from '../../common/ui-utils';
import { MainBar } from '../../components/main-bar';
import { isDevelopment, RouteUrls } from '../../core/app-config';
import { getDebug } from '../../core/app-diag';
import { ChangeToolWindowVisible, CloseDocument, FetchDocumentAsync, IRootState } from '../../store/app-redux';
import { LayoutMindDrawer } from '../mind-models';
import { ExecuteRemoteCommandBatchAsync } from '../store/mind-redux';
import MindBoard from './mind-board';
import './mind-components-styles';
import MindDocumentTitle from './mind-document-title';
import MindToolWindow from './tool-panes/mind-tool-window';

interface IProps
    extends IThunkDispatch,
        RouteComponentProps<{ id: string }>,
        Pick<IRootState, 'diagram' | 'isDiagramLoading' | 'diagramLoadingError'> {
    toolWindowVisible: boolean;
}

interface IState {}

const debug = getDebug(__filename);

class MindBenchPageView extends Component<IProps, IState> {
    public constructor(props: IProps) {
        super(props);
    }

    public async componentDidMount() {
        const { dispatch, history } = this.props;
        const id: string = this.props.match.params.id;
        try {
            await dispatch(FetchDocumentAsync(id));

            // 被邀请的成员第一次进来提示他加入成功
            if (history.location.state && history.location.state.from === RouteUrls.users.invite) {
                message.info('已成功加入');
                // 解决刷新页面重复提示问题
                history.replace({
                    state: { from: '' },
                });
            }
        } catch (reason) {
            showErrorDialog(reason, {
                onOk: () => {
                    history.goBack();
                },
            });
        }
    }

    public componentWillUnmount(): void {
        this.props.dispatch(CloseDocument());
    }

    public async componentDidUpdate() {
        this.persistChangesIfNeeded();
    }

    private persistChangesIfNeeded() {
        const { diagram, dispatch } = this.props;
        if (
            diagram &&
            diagram.remoteCommands &&
            diagram.remoteCommands.length > 0 &&
            !diagram.isExecutingRemoteCommands
        ) {
            dispatch(ExecuteRemoteCommandBatchAsync())
                .then(() => {
                    // noop
                })
                .catch(reason => {
                    showErrorDialog(reason);
                });
        }
    }

    render() {
        const { diagram, isDiagramLoading, diagramLoadingError } = this.props;

        if (diagramLoadingError) {
            return isDevelopment ? <span>{diagramLoadingError.toString()}</span> : null;
        }

        const isExecutingRemoteCommands = diagram ? diagram.isExecutingRemoteCommands : false;
        const isOpen = this.props.toolWindowVisible;
        return (
            <div className="mind-workbench">
                <div className="mind-appbar">
                    <MainBar
                        leftView={
                            <React.Fragment>
                                <Icon type="left" onClick={this.onGoBackClick} style={{ fontSize: '20px' }} />
                                <MindDocumentTitle />
                                <span className={isDiagramLoading ? 'doc-loading' : 'save-loading'}>
                                    {isDiagramLoading && <Icon type="loading" style={{ fontSize: '20px' }} />}
                                    {!isDiagramLoading && isExecutingRemoteCommands && (
                                        <Icon type="save" style={{ fontSize: '20px' }} />
                                    )}
                                </span>
                            </React.Fragment>
                        }
                    />
                </div>
                {diagram && (
                    <div className="mind-main-content">
                        <MindBoard />
                        <MindToolWindow className={isOpen ? 'mind-tool-move-left' : 'mind-tool-move-right'} />
                        <div
                            className="mind-tool-switch-btn"
                            onClick={this.handleToolWindowVisibleClick}
                            style={{ zIndex: LayoutMindDrawer.SwitchIconZIndex, top: LayoutMindDrawer.Top }}
                        >
                            <span className={isOpen ? 'mind-tool-close-icon' : 'mind-tool-open-icon'} />
                        </div>
                    </div>
                )}
            </div>
        );
    }

    private onGoBackClick: React.MouseEventHandler = event => {
        this.props.history.push(RouteUrls.dashboard);
    };

    private handleToolWindowVisibleClick: React.MouseEventHandler = () => {
        this.props.dispatch(ChangeToolWindowVisible(!this.props.toolWindowVisible));
    };
}

const connected = connect((state: IRootState) => ({
    isDiagramLoading: state.isDiagramLoading,
    diagramLoadingError: state.diagramLoadingError,
    diagram: state.diagram,
    toolWindowVisible: state.toolWindowVisible,
}))(MindBenchPageView);
const routed = withRouter(connected);
export default routed;
